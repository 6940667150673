import axios from 'axios';
import { createContext, useContext, useState } from 'react';
import EdenContext from '../edenContext';
import moment from 'moment';

const MpnQueueContext = createContext();

export const MpnQueueProvider = ({ children }) => {
	const { userMail } = useContext(EdenContext);
	const [mpnQueueList, setMpnQueueList] = useState([]);
	const [mpnQueueRowData, setMpnQueueRowData] = useState([]);
	const [mpnQueueLoading, setMpnQueueLoading] = useState(false);
	const [mpnQueueError, setMpnQueueError] = useState(null);
	const [mpnQueueHttpStatusCode, setMpnQueueHttpStatusCode] = useState();

	const createCustomerSummaryTable = (dataArray, customerSalesRepArray) => {
		console.log(customerSalesRepArray, 'arr');
		// Create a map to quickly lookup sales rep by customer
		const customerSalesRepMap = customerSalesRepArray.reduce((map, item) => {
			map[item.ACCOUNT_NAME] = item.SALES_REP_NAME || 'No Sales Rep';
			return map;
		}, {});

		const summary = {};

		dataArray.forEach((item) => {
			const customer = item.accountName;
			const salesRep = customerSalesRepMap[customer] || 'No Sales Rep'; // Get sales rep from the map

			if (!summary[customer]) {
				summary[customer] = {
					salesReps: new Set(), // Use Set to track unique sales reps
					customer: customer,
					noOfLines: 0,
					noOfRFQs: new Set(), // Use Set to track unique RFQ ids
					lastRFQ: item.inqDateTime,
				};
			}

			// Update the values
			summary[customer].salesReps.add(salesRep); // Add sales rep to the Set
			summary[customer].noOfLines += item.noOfLines;
			summary[customer].noOfRFQs.add(item.id); // Add RFQ id to the Set

			// Update last RFQ if the current one is more recent
			if (new Date(item.inqDateTime) > new Date(summary[customer].lastRFQ)) {
				summary[customer].lastRFQ = item.inqDateTime;
			}
		});

		// Transform summary to the desired format
		const summaryTable = Object.values(summary).map((item) => ({
			salesRep: Array.from(item.salesReps).join(', '), // Convert Set to array and join with comma
			customer: item.customer,
			noOfLines: item.noOfLines,
			noOfRFQs: item.noOfRFQs.size, // Convert Set size to number
			lastRFQ: moment(item.lastRFQ).format('MMM-DD-YYYY hh:mm A'),
		}));

		return summaryTable;
	};

	const createSalesRepSummaryTable = (dataArray) => {
		const summary = {};

		const currentYear = new Date().getFullYear();
		const fiscalYearStart = new Date(`${currentYear}-04-01`);
		const fiscalYearEnd = new Date(`${currentYear + 1}-03-31`);

		dataArray.forEach((item) => {
			const salesRep = item.salesRepCrmName || 'No Sales Rep';
			const inqDate = new Date(item.inqDateTime);

			// Check if inqDate is within the current fiscal year
			if (inqDate >= fiscalYearStart && inqDate <= fiscalYearEnd) {
				if (!summary[salesRep]) {
					summary[salesRep] = {
						salesRep: salesRep,
						totalRFQcurrentFY: new Set(), // Use Set to track unique RFQ ids
						totalnoOfLinesCurrentFY: 0,
						april: 0,
						may: 0,
						june: 0,
						july: 0,
						august: 0,
						september: 0,
						october: 0,
						november: 0,
						december: 0,
						january: 0,
						february: 0,
						march: 0,
						aprilRFQs: new Set(),
						mayRFQs: new Set(),
						juneRFQs: new Set(),
						julyRFQs: new Set(),
						augustRFQs: new Set(),
						septemberRFQs: new Set(),
						octoberRFQs: new Set(),
						novemberRFQs: new Set(),
						decemberRFQs: new Set(),
						januaryRFQs: new Set(),
						februaryRFQs: new Set(),
						marchRFQs: new Set(),
					};
				}

				// Update fiscal year totals
				summary[salesRep].totalnoOfLinesCurrentFY += item.noOfLines;
				summary[salesRep].totalRFQcurrentFY.add(item.id);

				// Correct month mapping
				let fiscalMonthIndex = (inqDate.getMonth() + 9) % 12; // Shift the months to match fiscal year

				const monthNames = [
					'april',
					'may',
					'june',
					'july',
					'august',
					'september',
					'october',
					'november',
					'december',
					'january',
					'february',
					'march',
				];

				const monthRFQNames = [
					'aprilRFQs',
					'mayRFQs',
					'juneRFQs',
					'julyRFQs',
					'augustRFQs',
					'septemberRFQs',
					'octoberRFQs',
					'novemberRFQs',
					'decemberRFQs',
					'januaryRFQs',
					'februaryRFQs',
					'marchRFQs',
				];

				summary[salesRep][monthNames[fiscalMonthIndex]] += item.noOfLines;
				summary[salesRep][monthRFQNames[fiscalMonthIndex]].add(item.id);
			}
		});

		// Transform summary to the desired format
		const summaryTable = Object.values(summary).map((item) => ({
			salesRep: item.salesRep,
			totalRFQcurrentFY: item.totalRFQcurrentFY.size, // Convert Set size to number
			totalnoOfLinesCurrentFY: item.totalnoOfLinesCurrentFY,
			april: item.april,
			may: item.may,
			june: item.june,
			july: item.july,
			august: item.august,
			september: item.september,
			october: item.october,
			november: item.november,
			december: item.december,
			january: item.january,
			february: item.february,
			march: item.march,
			aprilRFQs: item.aprilRFQs.size,
			mayRFQs: item.mayRFQs.size,
			juneRFQs: item.juneRFQs.size,
			julyRFQs: item.julyRFQs.size,
			augustRFQs: item.augustRFQs.size,
			septemberRFQs: item.septemberRFQs.size,
			octoberRFQs: item.octoberRFQs.size,
			novemberRFQs: item.novemberRFQs.size,
			decemberRFQs: item.decemberRFQs.size,
			januaryRFQs: item.januaryRFQs.size,
			februaryRFQs: item.februaryRFQs.size,
			marchRFQs: item.marchRFQs.size,
		}));

		return summaryTable;
	};

	const sourcingMpnQueue = () => {
		setMpnQueueLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=getallmpnque&email=${userMail}`
			)
			.then((response) => {
				setMpnQueueList(response?.data?.data);
				setMpnQueueHttpStatusCode(response?.data?.http_response_code);
				setMpnQueueError(null);
			})
			.catch((err) => {
				setMpnQueueError(err);
				setMpnQueueList(null);
				setMpnQueueLoading(false);
			})
			.finally(() => {
				setMpnQueueLoading(false);
			});
	};
	return (
		<MpnQueueContext.Provider
			value={{
				mpnQueueList,
				mpnQueueLoading,
				mpnQueueError,
				setMpnQueueList,
				mpnQueueRowData,
				setMpnQueueRowData,
				mpnQueueHttpStatusCode,
				setMpnQueueHttpStatusCode,
				sourcingMpnQueue,
				createCustomerSummaryTable,
				createSalesRepSummaryTable,
			}}
		>
			{children}
		</MpnQueueContext.Provider>
	);
};

export default MpnQueueContext;
