import { useContext, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Avatar, DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { MdMail, MdOutlineAdminPanelSettings } from 'react-icons/md';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { GrAttachment } from 'react-icons/gr';

import EdenContext from '../../../context/edenContext';
import BreadCrumb from '../../../components/breadcrumb';
import SectionInfo from '../../../components/sectionInfo';
import useFetch from '../../../hooks';
import AccessDenied from '../../../components/accessDenied';
import InnerHeader from '../../../components/innerHeader';
import ToolTip from '../../../components/tooltip';
import Drawer from '../../../components/drawer';
import Button from '../../../components/button';
import { avatarBgColor } from '../../../constant';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';

import styles from './chatGptUsageReport.module.scss';

const dateFilterParams = {
	filters: [
		{
			filter: 'agDateColumnFilter',
			filterParams: {
				suppressMiniFilter: true,
				comparator: (a, b) => {
					return getDate(a).getTime() - getDate(b).getTime();
				},
			},
		},
	],
};

const getDate = (value) => {
	const date = moment.utc(value).tz('Asia/Singapore').format('DD/MM/YYYY');
	var dateParts = date.split('/');
	return new Date(
		Number(dateParts[2]),
		Number(dateParts[1]) - 1,
		Number(dateParts[0])
	);
};

const ChahGPTUsageReport = () => {
	dayjs.extend(customParseFormat);
	const { userType, messageURl } = useContext(EdenContext);

	const previousDate = dayjs().subtract(1, 'day').startOf('day');
	const todayDate = dayjs().endOf('day');

	const [date, setDate] = useState([previousDate, todayDate]);
	const [gridApi, setGridApi] = useState(null);
	const [drawerMail, setDrawerMail] = useState(false);
	const [drawerMailData, setDrawerMailData] = useState(null);
	const [drawerMailLoading, setDrawerMailLoading] = useState(false);
	const [cellData, setCellData] = useState(null);

	const { apiData, isLoading, serverError } = useFetch(
		`https://internal.supremecomponents.com/api/externalgateway.php?route=get_openai_stats&from_date=${date?.[0].format(
			'YYYY-MM-DD'
		)}&to_date=${date?.[1].format('YYYY-MM-DD')}`
	);

	const closeDrawer = () => {
		setDrawerMail(!drawerMail);
		setDrawerMailData(null);
		setCellData(null);
	};

	const openDrawer = (cid) => {
		setDrawerMail(true);
		setDrawerMailLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_mail_html&cid=${cid}`
			)
			.then((response) => {
				setDrawerMailData(response.data);
			})
			.finally(() => {
				setDrawerMailLoading(false);
			});
	};

	const { RangePicker } = DatePicker;

	const onChange = (dates) => {
		setDate(dates);
	};

	const [columnDefs] = useState([
		{
			field: 'EMAIL_DATE_TIME',
			headerName: 'Incoming Date and Time',
			sort: 'desc',
			minWidth: 180,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value
						? moment
								.utc(p.value)
								.tz('Asia/Singapore')
								.format('MMM-DD-YYYY hh:mm A')
						: ''}
				</span>
			),
			filterParams: dateFilterParams,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},
		{
			field: 'OPENAI_PROCESS_DATE',
			headerName: 'OpenAI process Date and Time',
			sort: 'desc',
			minWidth: 180,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value
						? moment
								.utc(p.value)
								.tz('Asia/Singapore')
								.format('MMM-DD-YYYY hh:mm A')
						: ''}
				</span>
			),
			filterParams: dateFilterParams,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},
		{
			field: 'MAIL_TYPE',
			headerName: 'Detection',
		},
		{
			field: 'CID',
			headerName: 'Email',
			floatingFilter: false,
			cellRenderer: (p) => {
				const trimmedString = p?.value?.replace(/=+$/, '');
				return (
					<>
						{p?.value ? (
							<ToolTip title="Preview mail">
								<MdMail
									onClick={(e) => {
										if (e.ctrlKey && e.button === 0) {
											window.open(
												`${messageURl?.url}${trimmedString}%3D.eml`,
												'_blank'
											);
										} else {
											openDrawer(p.value);
											setCellData(p);
										}
									}}
									style={{ fontSize: '16px', cursor: 'pointer' }}
								/>
							</ToolTip>
						) : (
							''
						)}
					</>
				);
			},
			cellStyle: { padding: '4px 0 0 25px' },
			maxWidth: 80,
		},
		{ field: 'SUBJECT', headerName: 'Subject' },
		{ field: 'FROM_EMAIL', headerName: 'From' },
		{ field: 'TO_EMAIL', headerName: 'To' },
		{ field: 'PRE_PROMPT_INPUT_TOKENS', headerName: 'Pre Prompt Input Tokens' },
		{
			field: 'PRE_PROMPT_OUTPUT_TOKENS',
			headerName: 'Pre Prompt Output Tokens',
		},
		{
			field: 'PRE_PROMPT_TOTAL_TOKENS',
			headerName: 'Pre Prompt Total Tokens',
		},
		{
			field: 'PRE_PROMPT_USD_COST',
			headerName: 'Pre Prompt USD Cost',
			cellRenderer: (p) => p.value && `$ ${parseFloat(p.value)?.toFixed(4)}`,
		},
		{ field: 'INPUT_TOKENS', headerName: 'Input Token' },
		{ field: 'OUTPUT_TOKENS', headerName: 'Output Token' },
		{ field: 'TOTAL_TOKENS', headerName: 'Total Token' },
		{
			field: 'USD_COST',
			headerName: '$ USD Cost',
			cellRenderer: (p) => p.value && `$ ${parseFloat(p.value)?.toFixed(4)}`,
		},
		{
			field: 'ATTACHMENT_URL',
			headerName: 'Attachment URL',
			cellStyle: { padding: '3px 0 0 25px' },
			cellRenderer: (p) =>
				p.value && (
					<Link style={{ color: 'blue' }} to={p.value} target="_blank">
						<GrAttachment style={{ fontSize: '14px' }} />
					</Link>
				),
		},
		{ field: 'MESSAGE_SIZE', headerName: 'Message Size' },
	]);

	const onGridReady = (p) => {
		setGridApi(p.api);
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
		}),
		[]
	);

	// export excel sheet style
	const excelStyles = [
		{
			id: 'header',
			font: {
				bold: true,
			},
		},
	];

	const processCellForClipboard = (p) => {
		if (p.column.colId === 'EMAIL_DATE_TIME') {
			if (p?.value) {
				return moment(p.value).format('MMM-DD-YYYY hh:mm A');
			}
			return '';
		}
		if (p.column.colId === 'OPENAI_PROCESS_DATE') {
			if (p?.value) {
				return moment(p.value).format('MMM-DD-YYYY hh:mm A');
			}
			return '';
		}
		if (p.column.colId === 'CID') {
			if (p?.value) {
				const trimmedString = p?.value?.replace(/=+$/, '');
				return `${messageURl?.url}${trimmedString}%3D.eml`;
			}
			return '';
		}
		if (p.column.colId === 'PRE_PROMPT_USD_COST') {
			if (p?.value) {
				return `$ ${parseFloat(p.value)?.toFixed(4)}`;
			}
			return '';
		}
		if (p.column.colId === 'USD_COST') {
			if (p?.value) {
				return `$ ${parseFloat(p.value)?.toFixed(4)}`;
			}
			return '';
		}
		return p.value;
	};

	const exportToExcel = () => {
		const chatGPTExcelParams = {
			sheetName: 'ChatGPT Usage report',
			fileName: 'ChatGPT Usage report.csv',
			columnKeys: [
				'EMAIL_DATE_TIME',
				'OPENAI_PROCESS_DATE',
				'MAIL_TYPE',
				'CID',
				'SUBJECT',
				'FROM_EMAIL',
				'TO_EMAIL',
				'PRE_PROMPT_INPUT_TOKENS',
				'PRE_PROMPT_OUTPUT_TOKENS',
				'PRE_PROMPT_TOTAL_TOKENS',
				'PRE_PROMPT_USD_COST',
				'INPUT_TOKENS',
				'OUTPUT_TOKENS',
				'TOTAL_TOKENS',
				'USD_COST',
				'ATTACHMENT_URL',
				'MESSAGE_SIZE',
			],
			processCellCallback: (p) => {
				if (p.column.colId === 'EMAIL_DATE_TIME') {
					if (p?.value) {
						return moment(p.value).format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				if (p.column.colId === 'OPENAI_PROCESS_DATE') {
					if (p?.value) {
						return moment(p.value).format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				if (p.column.colId === 'SUBJECT') {
					if (p?.value) {
						return p.value.toString();
					}
					return '';
				}
				if (p.column.colId === 'CID') {
					if (p?.value) {
						const trimmedString = p?.value?.replace(/=+$/, '');
						return `${messageURl?.url}${trimmedString}%3D.eml`;
					}
					return '';
				}
				if (p.column.colId === 'PRE_PROMPT_USD_COST') {
					if (p?.value) {
						return `$ ${parseFloat(p.value)?.toFixed(4)}`;
					}
					return '';
				}
				if (p.column.colId === 'USD_COST') {
					if (p?.value) {
						return `$ ${parseFloat(p.value)?.toFixed(4)}`;
					}
					return '';
				}
				return p.value;
			},
		};
		gridApi.exportDataAsCsv(chatGPTExcelParams);
	};

	return (
		<>
			{userType === 'admin' ? (
				<div className={styles['wrapper']}>
					<BreadCrumb
						route1="Admin"
						route2="ChatGPT Usage Report"
						icon={<MdOutlineAdminPanelSettings style={{ fontSize: '20px' }} />}
					/>
					<SectionInfo
						removeshortcutkeys
						title="ChatGPT Usage Report"
						info="This section shows information about ChatGPT Usage"
					/>
					<InnerHeader exportToExcel={exportToExcel}></InnerHeader>
					<div className={styles['api-token-limits']}>
						<RangePicker
							style={{ width: '300px', borderRadius: '2px' }}
							format={'YYYY-MM-DD'}
							value={date}
							onChange={onChange}
						/>

						{isLoading && (
							<div className={styles['loading-wrapper']}>
								<LoadingMessage message="Loading..." />
							</div>
						)}
						{serverError && !isLoading && (
							<div className={styles['error-wrapper']}>
								<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
							</div>
						)}

						{!isNotEmptyArray(apiData?.data) && !serverError && !isLoading && (
							<div className={styles['no-data-wrapper']}>
								<NoData message="Its all empty here. Select a date range." />
							</div>
						)}

						{!isLoading && !serverError && isNotEmptyArray(apiData?.data) && (
							<div className={styles['table-wrapper']}>
								<div className={`ag-theme-custom ${styles['table']} `}>
									<AgGridReact
										onGridReady={onGridReady}
										rowData={apiData?.data}
										columnDefs={columnDefs}
										defaultColDef={defaultColDef}
										animateRows={true}
										processCellForClipboard={processCellForClipboard}
										copyHeadersToClipboard={true}
										pagination={true}
										paginationPageSizeSelector={false}
										paginationPageSize={50}
										enableRangeSelection={true}
										excelStyles={excelStyles}
									/>
								</div>
							</div>
						)}

						{drawerMail && (
							<Drawer
								title={
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										<p className={styles['mail-subject']}>
											<ToolTip
												placement="bottomLeft"
												title={drawerMailData?.subject}
											>
												{drawerMailData?.subject}
											</ToolTip>
										</p>
										<Link
											style={{ textDecoration: 'none' }}
											target="_blank"
											to={`${messageURl?.url}${cellData?.data?.CID?.replace(
												/=+$/,
												''
											)}%3D.eml`}
										>
											<Button type="bordered">Download Mail</Button>
										</Link>
									</div>
								}
								placement="right"
								onClose={closeDrawer}
								open={drawerMail}
								size={'large'}
							>
								{drawerMailLoading && <LoadingOutlined />}
								{drawerMailData && (
									<>
										<div className={styles['mail-details-wrapper']}>
											<div className={styles['mail-user-info']}>
												<Avatar
													className={styles['avatar']}
													style={{
														backgroundColor:
															avatarBgColor[
																drawerMailData?.fromName?.charAt(0)
															] || avatarBgColor.default,
													}}
												>
													{drawerMailData?.fromName?.charAt(0)?.toUpperCase()}
												</Avatar>
												<div className={styles['users-info']}>
													<p className={styles['sender-name']}>
														{drawerMailData?.fromName}
													</p>
													{drawerMailData?.toRecipients && (
														<p className={styles['to']}>
															<span className={styles['title']}>To:</span>{' '}
															{drawerMailData?.toRecipients}
														</p>
													)}
													{drawerMailData?.ccRecipients && (
														<p className={styles['cc']}>
															<span className={styles['title']}>Cc:</span>{' '}
															{drawerMailData?.ccRecipients}
														</p>
													)}
													{drawerMailData?.bccRecipients && (
														<p className={styles['bcc']}>
															<span className={styles['title']}>Bcc:</span>{' '}
															{drawerMailData?.bccRecipients}
														</p>
													)}
												</div>
											</div>

											<p className={styles['date-info']}>
												{moment(drawerMailData?.receivedDateTime).format(
													'MMM-DD-YYYY hh:mm A'
												)}
											</p>
										</div>
										<div
											dangerouslySetInnerHTML={{ __html: drawerMailData?.html }}
										/>
									</>
								)}
							</Drawer>
						)}
					</div>
				</div>
			) : (
				<AccessDenied message="You are not an Admin" />
			)}
		</>
	);
};

export default ChahGPTUsageReport;
