import { Input } from 'antd';

import styles from './textArea.module.scss';

const TextArea = ({
	allowClear,
	rows,
	placeholder,
	value,
	autoSize,
	onChange,
	showCount,
	maxLength,
	defaultValue,
}) => {
	return (
		<Input.TextArea
			allowClear={allowClear}
			rows={rows}
			placeholder={placeholder}
			className={styles['text-area']}
			style={{ borderRadius: '2px' }}
			value={value}
			autoSize={autoSize}
			onChange={onChange}
			showCount={showCount}
			maxLength={maxLength}
			defaultValue={defaultValue}
		/>
	);
};

export default TextArea;
