import { useContext, useMemo, useRef, useState } from 'react';
import { FaBoxArchive } from 'react-icons/fa6';
import { RiInboxUnarchiveFill } from 'react-icons/ri';
import { FaCommentAlt } from 'react-icons/fa';
import { Avatar, Form, message, Tag } from 'antd';
import moment from 'moment';
import axios from 'axios';
import {
	CheckCircleOutlined,
	ClockCircleOutlined,
	CloseCircleOutlined,
	ExclamationCircleOutlined,
	LogoutOutlined,
	SelectOutlined,
	SyncOutlined,
} from '@ant-design/icons';
import JoditEditor from 'jodit-react';

import EdenContext from '../../../context/edenContext';
import HelpDeskTrackerContext from '../../../context/helpdeskTrackerContext';
import Drawer from '../../../components/drawer';
import Button from '../../../components/button';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { avatarBgColor } from '../../../constant';
import ToolTip from '../../../components/tooltip';
import { titleCase } from '../../../utils';

import styles from './actions.module.scss';

const Actions = (p) => {
	const { userType, userInfo } = useContext(EdenContext);
	const { ticketsView, setData } = useContext(HelpDeskTrackerContext);
	const [form] = Form.useForm();
	const [drawer, setDrawer] = useState(false);
	const [remarks, setRemarks] = useState(null);
	const [remarksLoading, setRemarksLoading] = useState(false);
	const [remarksError, setRemarksError] = useState(null);
	const [addingRemarks, setAddingRemarks] = useState(false);
	const [editorContent, setEditorContent] = useState('');
	const [requestingApproval, setRequestingApproval] = useState(false);
	const editor = useRef(null);

	const filterUniqueIdsAndRecent = (arr) => {
		const uniqueIdsMap = new Map();

		arr?.forEach((obj) => {
			const currentId = obj.TICKET_ID;
			const existingObj = uniqueIdsMap.get(currentId);

			if (
				!existingObj ||
				new Date(obj.UPDATED_AT) > new Date(existingObj.UPDATED_AT)
			) {
				uniqueIdsMap.set(currentId, obj);
			}
		});

		return Array.from(uniqueIdsMap.values());
	};

	const buttons = [
		'bold',
		'underline',
		'italic',
		'|',
		'align',
		'|',
		'ul',
		'ol',
		'|',
		'eraser',
	];

	const config = useMemo(
		() => ({
			readonly: false,
			spellcheck: true,
			language: 'en',
			toolbarButtonSize: 'medium',
			toolbarAdaptive: false,
			showCharsCounter: false,
			showWordsCounter: false,
			showXPathInStatusbar: false,
			askBeforePasteHTML: false,
			askBeforePasteFromWord: false,
			placeholder: 'Add Remarks',
			//defaultActionOnPaste: "insert_clear_html",
			buttons: buttons,
			uploader: {
				insertImageAsBase64URI: true,
			},
			width: '100%',
			disabled: remarksLoading || addingRemarks,
			clipboard: {
				match: 'wordHtml', // Options: 'word', 'wordHtml', 'html', 'plain' - match clipboard content type
				processPastedHTML: (html) => {
					// Example: Remove all styles and classes from pasted content
					const doc = new DOMParser().parseFromString(html, 'text/html');
					doc.body.querySelectorAll('*').forEach((el) => {
						el.removeAttribute('style');
						el.removeAttribute('class');
					});
					return doc.body.innerHTML;
				},
				// Additional clipboard settings can be added here
			},
		}), //eslint-disable-next-line
		[]
	);

	const getRemarks = () => {
		setRemarksLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=hd_get_remarks&ticket_id=${p.data.TICKET_ID}`
			)
			.then((response) => {
				setRemarks(response.data?.data);
				setRemarksError(null);
			})
			.catch((err) => {
				setRemarksError(err);
				setRemarks(null);
			})
			.finally(() => {
				setRemarksLoading(false);
			});
	};

	const openDrawer = () => {
		setDrawer(true);
		getRemarks();
	};

	const closeDrawer = () => {
		setDrawer(false);
	};

	const addRemark = (value) => {
		if (editorContent.length > 0) {
			setAddingRemarks(true);
			const formdata = new FormData();
			formdata.append(
				'message_by_email',
				userInfo?.ViewAsLoggedinUser?.SalesRepEmail
			);
			formdata.append('message', editorContent);
			formdata.append('ticket_id', p.data.TICKET_ID);
			formdata.append(
				'message_by_name',
				userInfo?.ViewAsLoggedinUser?.SalesRepName
			);
			formdata.append('ticket_status', p.data.STATUS);

			axios
				.post(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=hd_log_remarks`,
					formdata
				)
				.then((response) => {
					message.success('Remark added');
				})
				.finally(() => {
					setAddingRemarks(false);
					setEditorContent('');
					form.resetFields();
					getRemarks();
				});
		} else {
			message.error('Please enter remarks');
		}
	};

	const handleArchive = (id) => {
		setData((prevData) =>
			filterUniqueIdsAndRecent(
				prevData?.filter((item) => item.TICKET_ID !== id)
			)
		);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=hd_set_archive&id=${id}&status=${
					ticketsView !== 'ARCHIVE' ? true : false
				}`
			)
			.then((response) => {
				message.success(
					ticketsView !== 'ARCHIVE'
						? 'Ticket Archived Succesfully'
						: 'Ticket Unarchived Succesfully'
				);
			});
	};

	const requestForApproval = (id) => {
		setRequestingApproval(true);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=hd_set_approval_required&ticket_id=${id}&value=1`
			)
			.then((response) => {
				axios
					.get(
						`https://internal.supremecomponents.com/api/externalgateway.php?route=get_log_ticket_by_id&id=${id}`
					)
					.then((response) => {
						setData((prevData) => {
							const updatedData = [
								response?.data?.data?.[0],
								...prevData.filter(
									(item) =>
										item.TICKET_ID !== response?.data?.data?.[0].TICKET_ID
								),
							];

							return filterUniqueIdsAndRecent(updatedData);
						});
					});
				message.success('Ticket Requested for Approval');
			})
			.catch((err) => {
				message.error('Something went wrong!');
			})
			.finally(() => {
				setRequestingApproval(false);
			});
	};

	return (
		<div className={styles['wrapper']}>
			<div className={styles['icons']}>
				{userType === 'admin' && ticketsView !== 'ARCHIVE' ? (
					<>
						<ToolTip title="Archive Ticket">
							<FaBoxArchive
								style={{ color: 'red' }}
								className={styles['icon']}
								onClick={() => {
									handleArchive(p.data.TICKET_ID);
								}}
							/>
						</ToolTip>
						{ticketsView !== 'ARCHIVE' &&
						ticketsView !== 'CLOSED' &&
						p.data.APPROVAL_STATUS === '0' ? (
							<Button
								size="small"
								loading={requestingApproval}
								type="xs"
								onClick={() => {
									requestForApproval(p.data.TICKET_ID);
								}}
							>
								Request for Approval
							</Button>
						) : ticketsView !== 'ARCHIVE' &&
						  ticketsView !== 'CLOSED' &&
						  p.data.APPROVAL_STATUS === '1' ? (
							<Tag icon={<ClockCircleOutlined />} color="default">
								Request Sent
							</Tag>
						) : ticketsView !== 'ARCHIVE' &&
						  ticketsView !== 'CLOSED' &&
						  p.data.APPROVAL_STATUS === '2' ? (
							<Tag icon={<CheckCircleOutlined />} color="success">
								Approved
							</Tag>
						) : (
							''
						)}
					</>
				) : userType === 'admin' && ticketsView === 'ARCHIVE' ? (
					<ToolTip title="Unarchive Ticket">
						<RiInboxUnarchiveFill
							style={{ color: '#1677FF' }}
							className={styles['icon']}
							onClick={() => {
								handleArchive(p.data.TICKET_ID);
							}}
						/>
					</ToolTip>
				) : (
					''
				)}
				<ToolTip title="Add Remarks">
					<FaCommentAlt className={styles['icon']} onClick={openDrawer} />
				</ToolTip>
			</div>

			{drawer && (
				<Drawer
					title={
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<p>Remarks</p>
						</div>
					}
					placement="right"
					onClose={closeDrawer}
					open={drawer}
					width={700}
				>
					<>
						<p className={styles['supplier-name']}>
							<b>Ticket ID: </b>
							{p?.data?.TICKET_ID_LBL}
						</p>
						<Form
							name="sourcing-specialist-admin-user-remarks"
							onFinish={addRemark}
							validateTrigger={['onBlur', 'onChange']}
							form={form}
							disabled={addingRemarks}
							className={styles['sourcing-specialist-admin-user-remarks']}
						>
							<Form.Item name="remark">
								<div
									style={{
										opacity: (remarksLoading || addingRemarks) && 0.4,
										pointerEvents: (remarksLoading || addingRemarks) && 'none',
									}}
									className={styles['jodit-container']}
								>
									<JoditEditor
										ref={editor}
										config={config}
										value={editorContent}
										onBlur={(content) => setEditorContent(content)}
									/>
								</div>
							</Form.Item>
							<Form.Item>
								<Button type="bordered" htmlType="submit">
									Add Remarks
								</Button>
							</Form.Item>
						</Form>

						{remarksLoading && (
							<div className={styles['loading-wrapper']}>
								<LoadingMessage height={60} width={60} />
							</div>
						)}

						{!remarksLoading && !remarks && remarksError && (
							<div className={styles['error-message']}>
								<ErrorMessage
									message="Error"
									style={{ width: '300px', marginTop: '90px' }}
								/>
							</div>
						)}

						{!remarksLoading && !remarksError && remarks && (
							<div className={styles['cards-wrapper']}>
								{remarks?.map((el, index) => (
									<div className={styles['card']} key={index}>
										<div className={styles['comment-info']}>
											<div className={styles['comment-user-info']}>
												<Avatar
													className={styles['avatar']}
													style={{
														backgroundColor:
															avatarBgColor[el?.MESSAGE_BY_NAME?.charAt(0)] ||
															avatarBgColor.default,
													}}
												>
													{el?.MESSAGE_BY_NAME?.charAt(0)?.toUpperCase()}
												</Avatar>
												<p className={styles['user-name']}>
													{titleCase(el?.MESSAGE_BY_NAME)}{' '}
													<span className={styles['add-comment']}>
														added a remark | &nbsp;
													</span>
												</p>
												<p className={styles['date-info']}>
													{moment
														.utc(el?.MESSAGE_DATE_TIME)
														.tz('Asia/Singapore')
														.format('MMM-DD-YYYY hh:mm A')}
												</p>
											</div>
											<Tag
												icon={
													el.TICKET_STATUS === 'OPEN' ? (
														<SelectOutlined />
													) : el.TICKET_STATUS === 'IN_PROGRESS' ? (
														<SyncOutlined spin />
													) : el.TICKET_STATUS === 'WAITING_FOR_APPROVAL' ? (
														<ClockCircleOutlined />
													) : el.TICKET_STATUS === 'ESCALATED' ? (
														<LogoutOutlined />
													) : el.TICKET_STATUS ===
													  'PENDING_EMPLOYEE_RESPONSE' ? (
														<ExclamationCircleOutlined />
													) : el.TICKET_STATUS === 'CLOSED_AND_RESOLVED' ? (
														<CheckCircleOutlined />
													) : el.TICKET_STATUS === 'CLOSED_AND_NOT_RESOLVED' ? (
														<CloseCircleOutlined />
													) : (
														''
													)
												}
												color={
													el.TICKET_STATUS === 'OPEN'
														? 'magenta'
														: el.TICKET_STATUS === 'IN_PROGRESS'
														? 'processing'
														: el.TICKET_STATUS === 'WAITING_FOR_APPROVAL'
														? 'default'
														: el.TICKET_STATUS === 'ESCALATED'
														? 'orange'
														: el.TICKET_STATUS === 'PENDING_EMPLOYEE_RESPONSE'
														? 'warning'
														: el.TICKET_STATUS === 'CLOSED_AND_RESOLVED'
														? 'success'
														: el.TICKET_STATUS === 'CLOSED_AND_NOT_RESOLVED'
														? 'error'
														: ''
												}
											>
												{el.TICKET_STATUS === 'OPEN'
													? 'Open/New'
													: el.TICKET_STATUS === 'IN_PROGRESS'
													? 'In-progress'
													: el.TICKET_STATUS === 'WAITING_FOR_APPROVAL'
													? 'Waiting for Approval'
													: el.TICKET_STATUS === 'ESCALATED'
													? 'Escalated'
													: el.TICKET_STATUS === 'PENDING_EMPLOYEE_RESPONSE'
													? 'Pending Employee Response'
													: el.TICKET_STATUS === 'CLOSED_AND_RESOLVED'
													? 'Closed and Resolved'
													: el.TICKET_STATUS === 'CLOSED_AND_NOT_RESOLVED'
													? 'Closed and Not Resolved'
													: ''}
											</Tag>
										</div>
										<div
											className={styles['remark-text']}
											dangerouslySetInnerHTML={{
												__html: el?.MESSAGE,
											}}
										></div>
									</div>
								))}
							</div>
						)}
					</>
				</Drawer>
			)}
		</div>
	);
};

export default Actions;
