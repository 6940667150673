import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MdManageAccounts } from 'react-icons/md';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import axios from 'axios';

import BreadCrumb from '../../../components/breadcrumb';
import SectionInfo from '../../../components/sectionInfo';
import Button from '../../../components/button';
import NotificationBar from '../../../components/notificationBar';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';
import { CountryCellRenderer } from '../../../columns/countryCellRenderer';
import { COUNTRY_CODES } from '../../../columns';
import EdenContext from '../../../context/edenContext';
import AdminHouseAccountContext from '../../../context/adminHouseAccountContext';
import Select from '../../../components/select';
import { dateFilterParams, dollarFilterParams } from '../../../filters';

import styles from './houseAccount.module.scss';

const HouseAccountsAdmin = () => {
	const {
		data,
		isLoading,
		error,
		getData,
		getUserStatics,
		processRowData,
		userStatics,
		setSr,
		sr,
	} = useContext(AdminHouseAccountContext);
	const { srData } = useContext(EdenContext);

	const [selectedRowValue, setSelectedRowValue] = useState([]);
	const [allocatingAcc, setAllocatingAcc] = useState(false);
	const [accAllocated, setAccAllocated] = useState([]);
	const [allocationError, setAllocationError] = useState(false);
	const [showAccAllocatedBanner, setShowAccAllocatedBanner] = useState(false);

	const agGridRef = useRef(null);

	useEffect(() => {
		setSelectedRowValue([]);
		// getUserStatics();
		getData(); //eslint-disable-next-line
	}, []);

	const [columnDefs] = useState([
		{
			field: 'AcctCreatedDate',
			checkboxSelection: true,
			showDisabledCheckboxes: true,
			headerName: 'Created Date',
			minWidth: 120,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value && moment(p.value).format('MMM-DD-YYYY')}
				</span>
			),
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
		},
		{ field: 'Account', headerName: 'Account', minWidth: 300 },
		{ field: 'PrimaryCompanyType', headerName: 'Account Type' },
		{
			field: 'AcctCountry',
			headerName: 'Country',
			cellRenderer: CountryCellRenderer,
			filter: 'agSetColumnFilter',
			filterParams: {
				cellRenderer: CountryCellRenderer,
				cellRendererParams: { isFilterRenderer: true },
			},
		},
		{
			field: 'PastFiveYearBilling',
			headerName: 'Past 5-year Billings',
			sort: 'desc',
			sortingOrder: ['desc', 'asc'],
			filter: 'agSetColumnFilter',
			filterParams: dollarFilterParams,
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
		},
	]);

	const remainingAccounts = 25 - userStatics?.count;

	const isRowSelectable = (params) => {
		return (
			(sr?.email && selectedRowValue.length < remainingAccounts) ||
			params.isSelected()
		);
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
		}),
		[]
	);

	const context = useMemo(() => {
		return {
			COUNTRY_CODES: COUNTRY_CODES,
		};
	}, []);

	const onSelectionChanged = (e) => {
		setSelectedRowValue(e.api.getSelectedRows());
	};

	const allocateAccount = () => {
		setAllocatingAcc(true);
		setShowAccAllocatedBanner(false);
		setAccAllocated([]);
		const promises = selectedRowValue.map((el, index, arr) =>
			axios
				.post(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=allocate_account&email=${sr?.email}&account_id=${el?.AcctId}&master_id=${sr?.id}&account=${el.Account}&sales_rep_name=${sr?.value}`
				)
				.then((response) => {
					setAccAllocated(arr.length);
				})
				.catch((err) => {
					console.log(err);
					setAllocationError(true);
				})
		);
		Promise.all(promises)
			.then(() => {
				setAllocatingAcc(false);
				setShowAccAllocatedBanner(true);
				setSelectedRowValue([]);
				getData();
				getUserStatics();
			})
			.catch((error) => {
				console.error('Error during promise execution:', error);
			});
	};

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="House Accounts - Admin"
				icon={<MdManageAccounts style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="House Accounts - Admin"
				info={
					<div style={{ fontSize: '16px' }}>
						Please take note the following rules before assigning any House
						Accounts to yourself:
						<br />
						<ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
							<li>
								Each Sales Representative may manage up to a <b>maximum</b> of
								25 accounts.
							</li>
							<li>
								Sales Representatives are permitted to select no more than{' '}
								<b>5 accounts per week</b> to avoid hoarding.
							</li>
							<li>
								A newly self-assigned account enters a 30-day probationary
								period.
							</li>
							<li>
								The account is considered successfully assigned if there is an
								incoming email from any CRM-listed contact within those 30 days;
								else, the account will be released back to the House accounts
								list.
							</li>
							<li>
								Accounts that have been inactive for more than 6 months will
								released back to the House accounts list.
							</li>
						</ul>
					</div>
				}
			/>
			<div className={styles['house-accounts']}>
				<Select
					showSearch
					style={{ width: '200px', marginBottom: '10px' }}
					placeholder="Select Sales Rep"
					type="bordered"
					onClear={() => setSr(null)}
					allowClear
					options={srData?.map((el) => ({
						value: el?.salesRepName,
						label: el?.salesRepName,
						id: el?.salesRepCrmId,
						email: el?.salesRepEmail,
					}))}
					onChange={(value, option) => setSr(option)}
				/>

				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(data) && !error && !isLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here. Select a date range." />
					</div>
				)}

				{!isLoading && !error && isNotEmptyArray(data) && (
					<>
						{sr && (
							<NotificationBar
								messageStyle={{ fontSize: '14px', fontWeight: 400 }}
								message={
									<>
										You have <b>{userStatics?.count}</b> accounts allocated. You
										have up to{' '}
										<b>{remainingAccounts < 0 ? 0 : remainingAccounts}</b>{' '}
										accounts you can allocate from the following list. Good
										luck!
									</>
								}
							/>
						)}

						{allocationError && (
							<NotificationBar
								messageStyle={{ fontSize: '14px', fontWeight: 400 }}
								redBar
								message={
									<>
										There seems to be an error tagging your accounts in Focus.
										IT has been notified and HelpDesk has been notified to
										manually tag your accounts. Contact Help Desk today to get a
										status update.
									</>
								}
							/>
						)}

						{showAccAllocatedBanner && !allocationError && (
							<NotificationBar
								greenBar
								messageStyle={{ fontSize: '14px', fontWeight: 400 }}
								message={
									<>
										Congratulations! <b>{accAllocated}</b> accounts have been
										allocated to the SR. ​
									</>
								}
							/>
						)}

						<Button
							onClick={allocateAccount}
							loading={allocatingAcc}
							disabled={allocatingAcc || selectedRowValue.length <= 0}
						>
							{allocatingAcc
								? 'Assigning to SR... Please wait'
								: 'Assign to SR'}
						</Button>

						<div className={styles['table-wrapper']}>
							<div className={`ag-theme-custom ${styles['table']} `}>
								<AgGridReact
									ref={agGridRef}
									rowData={processRowData}
									columnDefs={columnDefs}
									defaultColDef={defaultColDef}
									rowSelection={'multiple'}
									suppressRowClickSelection={true}
									rowMultiSelectWithClick={true}
									onSelectionChanged={onSelectionChanged}
									animateRows={true}
									pagination={true}
									paginationPageSizeSelector={false}
									isRowSelectable={isRowSelectable}
									context={context}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default HouseAccountsAdmin;
