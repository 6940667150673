import { createContext, useState } from 'react';

const CustomerPricesContext = createContext();

export const CustomerPricesProvider = ({ children }) => {
	const [view, setView] = useState('rfq-view');
	return (
		<CustomerPricesContext.Provider value={{ view, setView }}>
			{children}
		</CustomerPricesContext.Provider>
	);
};

export default CustomerPricesContext;
