import { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Upload, message } from 'antd';
import { IoMdCloudUpload } from 'react-icons/io';

import UploadedFile from '../../../../../../components/uploadedFile';
import Select from '../../../../../../components/select';

import styles from './upload.module.scss';

const UploadFile = ({
	excelData,
	setExcelData,
	excelFileName,
	setExcelFileName,
	selectedSheet,
	setSelectedSheet,
	sheetNames,
	setSheetNames,
	workbook,
	setWorkbook,
}) => {
	const props = {
		name: 'file',
		action: '',
		fileList: [],
		accept: '.xlsx',
		showUploadList: false,
		maxCount: 1,
		beforeUpload: (file) => {
			const isExcel =
				file.type ===
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
			if (!isExcel) {
				message.error(
					'The system only accepts Excel files. Please upload an Excel file and try again.'
				);
				return false;
			}
			if (isExcel) {
				setExcelFileName(file);
				const reader = new FileReader();
				reader.readAsBinaryString(file);
				// reader.onload = (e) => {
				// 	const data = e.target.result;
				// 	const workbook = XLSX.read(data, { type: 'binary' });
				// 	const sheetName = workbook.SheetNames[0];
				// 	const sheet = workbook.Sheets[sheetName];

				// 	// Get the range of the sheet (e.g., A2:Z100)
				// 	const range = XLSX.utils.decode_range(sheet['!ref']);
				// 	range.s.r = 1; // start from the second row

				// 	// Specify the new range when calling sheet_to_json
				// 	const parsedData = XLSX.utils.sheet_to_json(sheet, { range });

				// 	// Define an array of required keys
				// 	const requiredKeys = [
				// 		'MPN Req',
				// 		'Brand Req',
				// 		'QTY Req',
				// 		// 'MPN Found',
				// 		// 'Brand Found',
				// 		// 'MOQ',
				// 		// 'SPQ',
				// 		// 'USD Cost',
				// 		// 'Ext Price',
				// 	];

				// 	// Check if all keys are present in each object
				// 	const areAllKeysPresent = parsedData.every((item) => {
				// 		return requiredKeys.every((key) => Object.keys(item).includes(key));
				// 	});

				// 	if (parsedData?.length <= 0) {
				// 		message.error('Please Fill the Excel file');
				// 	}

				// 	if (areAllKeysPresent) {
				// 		setExcelData(parsedData);
				// 	} else {
				// 		message.error(
				// 			'Missing MPN Req, Brand Req, and QTY Req in Excel file.'
				// 		);
				// 	}
				// };

				reader.onload = (e) => {
					const data = e.target.result;
					const workbook = XLSX.read(data, { type: 'binary' });

					// Update state variables
					setWorkbook(workbook);

					const sheetNames = workbook.SheetNames;

					setSheetNames(sheetNames);

					// Set the default selected sheet to the first sheet
					const defaultSheet = sheetNames[0];
					setSelectedSheet(defaultSheet);

					// Load data for the default sheet
					loadSheetData(defaultSheet, workbook);
				};
			}
		},
		customRequest: () => null,
	};

	const loadSheetData = (sheetName, workbook) => {
		const sheet = workbook.Sheets[sheetName];
		const range = XLSX.utils.decode_range(sheet['!ref']);
		range.s.r = 1; // start from the second row

		const parsedData = XLSX.utils.sheet_to_json(sheet, { range });

		const requiredKeys = [
			'MPN Req',
			// 'Brand Req',
			'QTY Req',
			// 'MPN Found',
			// 'Brand Found',
			// 'MOQ',
			// 'SPQ',
			// 'USD Cost',
			// 'Ext Price',
		];

		// Check if all keys are present in each object
		const areAllKeysPresent = parsedData.every((item) => {
			return requiredKeys.every((key) => Object.keys(item).includes(key));
		});

		if (parsedData?.length <= 0) {
			message.error('Please Fill the Excel file');
		}

		if (areAllKeysPresent) {
			setExcelData(parsedData);
		} else {
			message.error('Missing MPN Req, Brand Req, and QTY Req in Excel file.');
		}
	};

	// useEffect to load data for the first sheet by default
	useEffect(() => {
		if (workbook && sheetNames?.length > 0) {
			const defaultSheet = sheetNames[0];
			setSelectedSheet(defaultSheet);
			loadSheetData(defaultSheet, workbook);
		} //eslint-disable-next-line
	}, [workbook, sheetNames]);

	const handleSheetChange = (value) => {
		setSelectedSheet(value);
		loadSheetData(value, workbook);
		// Read data from the selected sheet
		const sheet = workbook.Sheets[value];

		// Get the range of the sheet (e.g., A2:Z100)
		const range = XLSX.utils.decode_range(sheet['!ref']);
		range.s.r = 1; // start from the second row

		// Specify the new range when calling sheet_to_json
		const parsedData = XLSX.utils.sheet_to_json(sheet, { range });

		const requiredKeys = [
			'MPN Req',
			// 'Brand Req',
			'QTY Req',
			// 'MPN Found',
			// 'Brand Found',
			// 'MOQ',
			// 'SPQ',
			// 'USD Cost',
			// 'Ext Price',
		];

		// Check if all keys are present in each object
		const areAllKeysPresent = parsedData.every((item) => {
			return requiredKeys.every((key) => Object.keys(item).includes(key));
		});

		if (parsedData?.length <= 0) {
			message.error('Please Fill the Excel file');
		}

		if (areAllKeysPresent) {
			setExcelData(parsedData);
		} else {
			message.error('Missing MPN Req, Brand Req, and QTY Req in Excel file.');
		}
	};

	return (
		<div className={styles['upload-bom-wrapper']}>
			<div className={styles['point-1-wrapper']}>
				<div className={styles['point1-title']}>
					<p className={styles['point']}>1. Import Excel File</p>
					<a
						href="/sample-file/MPN Cost Book.xlsx"
						download
						target="_blank"
						onClick={(e) => e.stopPropagation()}
						className={styles['download-template']}
					>
						Download Template
					</a>
				</div>

				{!sheetNames && (
					<Upload.Dragger {...props}>
						<IoMdCloudUpload style={{ fontSize: '36px' }} />
						<p>Drag & drop your BOM here (or) Select a file to upload</p>
						<p>Only *.xls, *xlsx files</p>
					</Upload.Dragger>
				)}
				{sheetNames && (
					<>
						<UploadedFile
							style={{ marginTop: '10px' }}
							fileName={excelFileName?.name}
							onRemove={() => {
								setExcelData([]);
								setExcelFileName(null);
								setSheetNames(null);
							}}
						/>
						<div className={styles['point-2-wrapper']}>
							<p>2. Select Worksheet:</p>
							<Select
								showSearch
								type="bordered"
								placeholder="Select Worksheet"
								style={{ width: '300px' }}
								value={selectedSheet}
								onChange={handleSheetChange}
								options={sheetNames?.map((el) => ({
									value: el,
								}))}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default UploadFile;
