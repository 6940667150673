import { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { Col, Divider, Form, Row, message } from 'antd';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { IoMdAddCircle } from 'react-icons/io';

import EdenContext from '../../../context/edenContext';
import BreadCrumb from '../../../components/breadcrumb';
import NotificationBar from '../../../components/notificationBar';
import Button from '../../../components/button';
import Modal from '../../../components/modal';
import Select from '../../../components/select';
import Input from '../../../components/input';
import { chatgptKeywordsColumns } from '../../../columns';
import AccessDenied from '../../../components/accessDenied';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';

import styles from './chatGptKeywords.module.scss';

const ChatGptKeywords = () => {
	const {
		userType,
		chatGptKeywords,
		chatGptKeywordsLoading,
		chatGptKeywordsError,
		chatGptAllkeywords,
	} = useContext(EdenContext);
	const [form] = Form.useForm();
	const [addKeywordModal, setAddKeywordModal] = useState(false);

	useEffect(() => {
		chatGptAllkeywords(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	);

	const keywordType = [
		{
			value: 'MPN',
			label: 'MPN',
		},
		{
			value: 'Brand',
			label: 'Brand',
		},
		{
			value: 'Qty',
			label: 'Qty',
		},
	];

	const openAddKeywordModal = () => {
		setAddKeywordModal(true);
	};

	const closeAddKeywordModal = () => {
		setAddKeywordModal(false);
		form.resetFields();
	};

	const addKeywords = (values) => {
		const formData = new FormData();
		for (var key in values) {
			formData.append(key, values[key]);
		}
		formData.append('is_active', '1');

		axios
			.post(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=add_chatgpt_keyword',
				formData
			)
			.then(() => {
				form.resetFields();
				message.open({
					type: 'success',
					content: 'Keyword added successfully',
				});
				setAddKeywordModal(false);
				chatGptAllkeywords();
			})
			.catch(() => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				form.resetFields();
				setAddKeywordModal(false);
			});
	};

	const lastUpdated = chatGptKeywords
		? chatGptKeywords[chatGptKeywords?.length - 1]?.DATE_ADD
		: '--';

	return (
		<>
			{userType === 'admin' ? (
				<div className={styles['wrapper']}>
					<BreadCrumb
						route1="Admin"
						route2="Header Identifiers"
						icon={<MdOutlineAdminPanelSettings style={{ fontSize: '20px' }} />}
					/>
					<div className={styles['chatgpt-keywords']}>
						<NotificationBar
							message="Please manage any Header Identifiers in this section. Keywords used will be automatically updated for new emails only."
							wraningBar
						/>
						<div className={styles['button-last-updated']}>
							<Button onClick={openAddKeywordModal}>
								<IoMdAddCircle style={{ fontSize: '16px' }} />
								Add Keyword
							</Button>
							<p className={styles['last-report']}>
								Last Updated on :{' '}
								{chatGptKeywords
									? moment(lastUpdated)?.format('DD-MM-YYYY hh:mm A')
									: '--'}
							</p>
						</div>

						{chatGptKeywordsLoading && (
							<div className={styles['loading-wrapper']}>
								<LoadingMessage message="Loading..." />
							</div>
						)}

						{chatGptKeywordsError && !chatGptKeywordsLoading && (
							<div className={styles['error-wrapper']}>
								<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
							</div>
						)}

						{!isNotEmptyArray(chatGptKeywords) &&
							!chatGptKeywordsError &&
							!chatGptKeywordsLoading && (
								<div className={styles['no-data-wrapper']}>
									<NoData message="Its all empty here." />
								</div>
							)}

						{!chatGptKeywordsLoading &&
							!chatGptKeywordsError &&
							isNotEmptyArray(chatGptKeywords) && (
								<div className={styles['table-wrapper']}>
									<div className={`ag-theme-custom ${styles['table']} `}>
										<AgGridReact
											rowData={chatGptKeywords}
											columnDefs={chatgptKeywordsColumns}
											defaultColDef={defaultColDef}
											animateRows={true}
											pagination={true}
											paginationPageSizeSelector={false}
										/>
									</div>
								</div>
							)}
					</div>
					<Modal
						title={
							<>
								<p>Add Keywords</p> <Divider style={{ margin: '5px 0' }} />
							</>
						}
						open={addKeywordModal}
						onCancel={closeAddKeywordModal}
						destroyOnClose
					>
						<Form
							name="add-chatgptkeywords"
							form={form}
							onFinish={addKeywords}
							validateTrigger={['onBlur', 'onChange']}
							labelCol={{ span: 24 }}
							requiredMark={false}
						>
							<Row gutter={16}>
								<Col sm={24} md={12}>
									<Form.Item
										label="Keyword Type"
										name="type"
										rules={[
											{
												required: true,
												message: 'Please select Keyword Type!',
											},
										]}
									>
										<Select
											type="bordered"
											placeholder="Select Keyword Type"
											options={keywordType}
											allowClear={true}
										/>
									</Form.Item>
								</Col>
								<Col sm={24} md={12}>
									<Form.Item
										label="Keyword"
										name="keyword"
										rules={[
											{
												required: true,
												message: 'Please enter Keyword!',
											},
											() => ({
												validator(_, value) {
													const existingKeywords = chatGptKeywords.map(
														(item) => item.KEYWORD
													);
													if (existingKeywords.includes(value)) {
														return Promise.reject(
															new Error('This keyword is already present!')
														);
													}
													return Promise.resolve();
												},
											}),
										]}
									>
										<Input placeholder="Please enter Keyword" />
									</Form.Item>
								</Col>

								<Col sm={24}>
									<Form.Item style={{ marginBottom: '0px' }}>
										<div className={styles['actions']}>
											<Button type="bordered" onClick={closeAddKeywordModal}>
												Cancel
											</Button>
											<Button htmlType="submit">Add</Button>
										</div>
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</Modal>
				</div>
			) : (
				<AccessDenied message="You are not an Admin" />
			)}
		</>
	);
};

export default ChatGptKeywords;
