import React, { useEffect, useState } from 'react';

import styles from './columns.module.scss';

export const CountryCellRenderer = (props) => {
	const [value, setValue] = useState();

	useEffect(() => {
		let url;
		if (!props.value) {
			setValue(props.isFilterRenderer ? '(Blanks)' : props.value);
		} else if (props.value === '(Select All)') {
			setValue(props.value);
		} else {
			url = `https://flags.fmcdn.net/data/flags/mini/${
				props.context.COUNTRY_CODES[props.value]
			}.png`;
			setValue(url);
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			{props.value && (
				<>
					<img src={value} width="17" alt="" />{' '}
					<span className={styles['value']}>{props.value}</span>
				</>
			)}
		</div>
	);
};
