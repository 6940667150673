import ErrorMessageImage from '../../images/errormessage.svg';

import styles from './errormessage.module.scss';

const ErrorMessage = ({ title, message, style, messageStyle }) => {
	return (
		<div className={styles['wrapper']}>
			{title && <h1 className={styles['title']}>{title}</h1>}
			<img
				className={styles['img']}
				src={ErrorMessageImage}
				alt="Server Error"
				style={style}
			/>
			{message && (
				<p className={styles['message']} style={messageStyle}>
					{message}
				</p>
			)}
		</div>
	);
};

export default ErrorMessage;
