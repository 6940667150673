import { FaMailBulk } from 'react-icons/fa';
import { Checkbox, Divider } from 'antd';
import { memo, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { HiOutlineSearch } from 'react-icons/hi';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import Input from '../../components/input';
import NoData from '../../components/nodata';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import { isNotEmptyArray } from '../../utils';
import NotificationBar from '../../components/notificationBar';
import MailConfig from './MailConfig';
import MailPreview from './MailPreview';
import Button from '../../components/button';
import Select from '../../components/select';
import { CountryCellRenderer } from '../../columns/countryCellRenderer';
import { COUNTRY_CODES } from '../../columns';

import styles from './massMail.module.scss';

const MassMail = () => {
	const [leftWidth, setLeftWidth] = useState('50%');
	const [rightWidth, setRightWidth] = useState('50%');
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [isExcat, setIsExcat] = useState(false);
	const [sendMailCount, setSendMailCount] = useState(0);
	const [selectedRows, setSelectedRows] = useState([]);
	const [gridApi, setGridApi] = useState(null);
	const [strengths, setStrengths] = useState([]);
	const [dpValue, setDpValue] = useState('brand');

	const onChange = (value) => {
		setDpValue(value);
	};

	const handleSearch = () => {
		if (searchValue?.length >= 2) {
			gridApi?.removeRowGroupColumns(['Supplier']);
			setSendMailCount(0);
			setIsLoading(true);
			axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=massmail_get_contacts_by_brand&${dpValue}=${searchValue}${
						dpValue === 'supplier' ? '' : `&exact=${isExcat}`
					}`
				)
				.then((response) => {
					setData(response?.data);
					setStrengths([...new Set(response?.data?.map((el) => el.Strength))]);
					setError(null);
				})
				.catch((err) => {
					setError(err);
					setData(null);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const handleMouseDown = (e) => {
		e.preventDefault();
		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
	};

	const handleMouseMove = (e) => {
		const container = document.querySelector(`.${styles['mass-mail']}`);
		const containerRect = container.getBoundingClientRect();
		const newLeftWidth =
			((e.clientX - containerRect.left) / containerRect.width) * 100;
		const newRightWidth = 100 - newLeftWidth;
		setLeftWidth(`${newLeftWidth}%`);
		setRightWidth(`${newRightWidth}%`);
	};

	const handleMouseUp = () => {
		document.removeEventListener('mousemove', handleMouseMove);
		document.removeEventListener('mouseup', handleMouseUp);
	};

	const [centraHubColumnDefs] = useState([
		{
			field: 'Actions',
			headerName: 'Actions',
			checkboxSelection: true,
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			cellRenderer: memo(MailPreview),
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},
		{
			field: 'Supplier',
			headerName: 'Supplier',
			enableRowGroup: true,
		},
		{ field: 'Brand', headerName: 'Brand' },
		{
			field: 'ContactFirstName',
			headerName: 'Contact Person',
			cellRenderer: (p) => {
				const isGrouped = p.node.group;
				if (isGrouped) {
					return '';
				} else {
					return p.data?.ContactFirstName + ' ' + p.data?.ContactLastName;
				}
			},
		},
		{ field: 'ContactEmail', headerName: 'Email' },
		{
			field: 'Strength',
			headerName: 'Strength',
			filter: 'agSetColumnFilter',
			filterParams: {
				applyMiniFilterWhileTyping: true,
			},
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},
		{
			field: 'AcctCountry',
			headerName: 'Country',
			cellRenderer: memo(CountryCellRenderer),
			filter: 'agSetColumnFilter',
			filterParams: {
				cellRenderer: memo(CountryCellRenderer),
				cellRendererParams: { isFilterRenderer: true },
				applyMiniFilterWhileTyping: true,
			},
		},
	]);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	const handleStrength = (checkedValues) => {
		gridApi
			.setColumnFilterModel('Strength', {
				values: checkedValues,
			})
			.then(() => {
				gridApi.onFilterChanged();
			});
	};

	const onGridReady = (e) => {
		setGridApi(e.api);
	};

	const autoGroupColumnDef = useMemo(() => {
		return {
			minWidth: 200,
			filter: 'agGroupColumnFilter',
		};
	}, []);

	const context = useMemo(() => {
		return {
			COUNTRY_CODES: COUNTRY_CODES,
		};
	}, []);

	const onSelectionChanged = (event) => {
		setSelectedRows(event.api.getSelectedRows());
	};

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="Mass Mail"
				icon={<FaMailBulk style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="Mass Mail"
				info={
					<>
						<span>
							This section enables you to reach out to suppliers from our CRM to
							inquire if they can support your Request for Quote (RFQ).
							Typically, you can ask or tell them information about one of the
							following items:
						</span>
						<br />
						<ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
							<li>Can they support your RFQs?</li>
						</ul>
						<br />
						<span>
							Start by reviewing your RFQ and then pick the suppliers you’d like
							to follow-up with.
						</span>
					</>
				}
			/>
			{sendMailCount > 0 && (
				<div style={{ padding: '0 10px' }}>
					<NotificationBar
						greenBar
						message={`Congrats! ${sendMailCount} contacts has been successfully reached out. Good luck generating more business with them.`}
						onClose={() => setSendMailCount(0)}
					/>
				</div>
			)}
			<Divider
				style={{
					margin: '5px 0 0 0',
					borderBlockStart: '1px solid rgb(216, 216, 216)',
				}}
			/>

			<div className={styles['mass-mail']}>
				<div className={styles['left-side']} style={{ width: leftWidth }}>
					<div className={styles['input-checkbox']}>
						<div className={styles['dropdown-input']}>
							<Select
								placeholder="Drill down by"
								style={{
									width: 100,
								}}
								options={[
									{ value: 'brand', label: 'Brand' },
									{ value: 'supplier', label: 'Supplier' },
								]}
								defaultValue={dpValue}
								onChange={onChange}
								disabled={isLoading}
							/>
							<Input
								style={{ width: '350px' }}
								placeholder={'Search for Brand'}
								onChange={(e) => setSearchValue(e.target.value)}
								onPressEnter={handleSearch}
								disabled={isLoading}
								id="searchValue"
								maxLength={30}
								value={searchValue}
								onClear={() => setData(null)}
							/>
						</div>
						<Checkbox
							checked={isExcat}
							onChange={(e) => setIsExcat(e.target.checked)}
							disabled={dpValue === 'supplier' || isLoading}
						>
							Find Exact Match
						</Checkbox>
						<Button
							onClick={handleSearch}
							disabled={searchValue?.length <= 1 || isLoading}
						>
							Search <HiOutlineSearch />
						</Button>
					</div>

					{!searchValue?.length <= 1 && !data && !error && !isLoading && (
						<div className={styles['no-data-wrapper']}>
							<NoData search message="Please Search a Brand to Continue" />
						</div>
					)}

					{isLoading && (
						<div className={styles['loading-wrapper']}>
							<LoadingMessage message="Loading..." />
						</div>
					)}

					{error && !isLoading && (
						<div className={styles['error-wrapper']}>
							<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
						</div>
					)}

					{!isNotEmptyArray(data) && data !== null && !error && !isLoading && (
						<div className={styles['no-data-wrapper']}>
							<NoData
								message={
									<>
										<p style={{ fontSize: '20px', marginBottom: '5px' }}>
											Oops... we didn't find anything that matched this search.
										</p>
										<p style={{ fontSize: '12px' }}>
											Try shaving off characters in your search descriptor to
											expand your search results.
										</p>
									</>
								}
							/>
						</div>
					)}

					{!isLoading && !error && isNotEmptyArray(data) && (
						<>
							<div className={styles['strengths-wrapper']}>
								<p>Strength: </p>
								<Checkbox.Group
									options={strengths.map((el) => ({ label: el, value: el }))}
									onChange={handleStrength}
									defaultValue={strengths}
								/>
							</div>
							<div className={styles['table-wrapper']}>
								<div className={`ag-theme-custom ${styles['table']} `}>
									<AgGridReact
										rowData={data}
										columnDefs={centraHubColumnDefs}
										defaultColDef={defaultColDef}
										animateRows={true}
										pagination={true}
										paginationPageSizeSelector={false}
										enableRangeSelection={true}
										suppressRowClickSelection={true}
										rowSelection={'multiple'}
										onSelectionChanged={onSelectionChanged}
										onGridReady={onGridReady}
										rowGroupPanelShow="always"
										groupDisplayType={'multipleColumns'}
										autoGroupColumnDef={autoGroupColumnDef}
										groupMaintainOrder={true}
										context={context}
										groupSelectsChildren={true}
									/>
								</div>
							</div>
						</>
					)}
				</div>
				<div
					className={styles['divider']}
					onMouseDown={handleMouseDown}
					onDoubleClick={() => {
						setLeftWidth('50%');
						setRightWidth('50%');
					}}
				></div>
				<div className={styles['right-side']} style={{ width: rightWidth }}>
					<MailConfig
						selectedRows={selectedRows}
						gridApi={gridApi}
						setSelectedRows={setSelectedRows}
						setSendMailCount={setSendMailCount}
					/>
				</div>
			</div>
		</div>
	);
};

export default MassMail;
