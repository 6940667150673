import { Cascader as AntdCascader } from 'antd';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

import styles from './cascader.module.scss';

const Cascader = ({
	options,
	onChange,
	placeholder,
	onClear,
	clearIcon,
	disabled,
	title,
	changeOnSelect,
	size,
	style,
	defaultValue,
	autoFocus,
	onBlur,
	defaultOpen,
}) => {
	const filter = (inputValue, path) =>
		path.some(
			(option) =>
				option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
		);

	const displayRender = (labels) => labels[labels.length - 1];

	return (
		<div className={styles['cascader-wrapper']}>
			{title && <p className={styles['title']}>{title}</p>}
			<AntdCascader
				defaultValue={defaultValue}
				size={size}
				placeholder={placeholder}
				options={options}
				expandTrigger="hover"
				changeOnSelect={changeOnSelect}
				displayRender={displayRender}
				onChange={onChange}
				style={style}
				className={styles['cascader']}
				popupClassName={styles['menu']}
				showSearch={{
					filter,
				}}
				allowClear={true}
				clearIcon={clearIcon}
				onClear={onClear}
				disabled={disabled}
				suffixIcon={<IoIosArrowDown className={styles['suffixicon']} />}
				expandIcon={<IoIosArrowForward className={styles['expandicon']} />}
				autoFocus={autoFocus}
				onBlur={onBlur}
				defaultOpen={defaultOpen}
			/>
		</div>
	);
};

export default Cascader;
