import accessDenied from '../../images/accessDenied.svg';

import styles from './accessDenied.module.scss';

const AccessDenied = ({ message }) => {
	return (
		<div className={styles['access-denied']}>
			<img src={accessDenied} alt="Access Denied" className={styles['img']} />
			{message && <p className={styles['message']}>{message}</p>}
		</div>
	);
};

export default AccessDenied;
