import { createContext, useEffect, useState } from 'react';
import axios from 'axios';

import {
	dollarDecimalFilterParams,
	decimalFilterParams1,
	decimalFilterParams,
} from '../../filters';

const ManagementReportContext = createContext();

export const ManagementReportProvider = ({ children }) => {
	const [fy, setFy] = useState(new Date().getFullYear() + 1);
	const [dataType, setDataType] = useState('division-billings-by-month');
	const [columnDefs, setColumnDefs] = useState(null);

	// Get columns
	const getColumnDefs = (selectedFy) => {
		const year = selectedFy - 1;

		const date = new Date();
		const currentYear = date.getFullYear();
		const currentMonth = date.getMonth();

		const lasttwoDigit = selectedFy.toString().slice(-2);
		return [
			{
				field:
					dataType === 'division-billings-by-month'
						? 'Division'
						: dataType === 'supplier-billings-by-month'
						? 'Supplier'
						: dataType === 'customer-billings-by-month'
						? 'Customer'
						: dataType === 'country-billings-by-month'
						? 'Country'
						: dataType === 'salesrep-billings-by-month'
						? 'SalesRep'
						: dataType === 'capitalizing-the-billings-by-month'
						? 'Brand'
						: dataType === 'gpm-by-customer-by-month'
						? 'Customer'
						: dataType === 'gp$-by-customer-by-month'
						? 'Customer'
						: dataType === 'gpm-by-salesrep-by-month'
						? 'SalesRep'
						: '',
				headerName:
					dataType === 'division-billings-by-month'
						? 'Division'
						: dataType === 'supplier-billings-by-month'
						? 'Supplier'
						: dataType === 'customer-billings-by-month'
						? 'Customer'
						: dataType === 'country-billings-by-month'
						? 'Country'
						: dataType === 'salesrep-billings-by-month'
						? 'Sales Rep'
						: dataType === 'capitalizing-the-billings-by-month'
						? 'MFR Brand'
						: dataType === 'gpm-by-customer-by-month' ||
						  dataType === 'gp$-by-customer-by-month'
						? 'Customer'
						: dataType === 'gpm-by-salesrep-by-month'
						? 'Sales Rep'
						: '',
				pinned: 'left',
				filter: 'agMultiColumnFilter',
			},
			{
				field: `CurrY-2`,
				cellClass: 'numberType',
				headerName: `FY${lasttwoDigit - 2}`,
				cellRenderer: (p) => `$ ${p.value.toLocaleString('en-US')}`,
				filterParams: dollarDecimalFilterParams,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
				hide:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month',
			},
			{
				field: `CurrY-1`,
				cellClass: 'numberType',
				headerName: `FY${lasttwoDigit - 1}`,
				cellRenderer: (p) => `$ ${p.value.toLocaleString('en-US')}`,
				filterParams: dollarDecimalFilterParams,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
				hide:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month',
			},
			{
				field: 'CurrY',
				headerName: `FY${lasttwoDigit}`,
				cellClass: 'numberType',
				cellRenderer: (p) => `$ ${p.value.toLocaleString('en-US')}`,
				filterParams: dollarDecimalFilterParams,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
				sort: 'desc',
				hide:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month',
			},
			{
				field: 'SalesTarget',
				headerName: 'FY25 Target',
				hide: dataType !== 'salesrep-billings-by-month',
				cellClass: 'numberType',
				cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
				filterParams: dollarDecimalFilterParams,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
			{
				field: 'PctToGoal',
				headerName: 'FY25 %-to-Goal',
				hide: dataType !== 'salesrep-billings-by-month',
				cellClass: 'decimal',
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
				filterParams: decimalFilterParams,
			},
			{
				field: 'Apr',
				headerName: `April ${year}`,
				cellClass:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? 'decimal'
						: 'numberType',
				cellRenderer: (p) =>
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? p.value
						: dataType === 'gp$-by-customer-by-month'
						? p.value.toLocaleString('en-US')
						: ` $ ${p.value.toLocaleString('en-US')}`,
				filterParams:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? decimalFilterParams1
						: dataType === 'gp$-by-customer-by-month'
						? decimalFilterParams
						: dollarDecimalFilterParams,
				hide: currentYear === year && currentMonth < 3,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
			{
				field: 'May',
				headerName: `May ${year}`,
				cellClass:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? 'decimal'
						: 'numberType',
				cellRenderer: (p) =>
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? p.value
						: dataType === 'gp$-by-customer-by-month'
						? p.value.toLocaleString('en-US')
						: ` $ ${p.value.toLocaleString('en-US')}`,
				filterParams:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? decimalFilterParams1
						: dataType === 'gp$-by-customer-by-month'
						? decimalFilterParams
						: dollarDecimalFilterParams,
				hide: currentYear === year && currentMonth < 4,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
			{
				field: 'Jun',
				headerName: `June ${year}`,
				cellClass:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? 'decimal'
						: 'numberType',
				cellRenderer: (p) =>
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? p.value
						: dataType === 'gp$-by-customer-by-month'
						? p.value.toLocaleString('en-US')
						: ` $ ${p.value.toLocaleString('en-US')}`,
				filterParams:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? decimalFilterParams1
						: dataType === 'gp$-by-customer-by-month'
						? decimalFilterParams
						: dollarDecimalFilterParams,
				hide: currentYear === year && currentMonth < 5,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
			{
				field: 'Jul',
				headerName: `July ${year}`,
				cellClass:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? 'decimal'
						: 'numberType',
				cellRenderer: (p) =>
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? p.value
						: dataType === 'gp$-by-customer-by-month'
						? p.value.toLocaleString('en-US')
						: ` $ ${p.value.toLocaleString('en-US')}`,
				filterParams:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? decimalFilterParams1
						: dataType === 'gp$-by-customer-by-month'
						? decimalFilterParams
						: dollarDecimalFilterParams,
				hide: currentYear === year && currentMonth < 6,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
			{
				field: 'Aug',
				headerName: `August ${year}`,
				cellClass:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? 'decimal'
						: 'numberType',
				cellRenderer: (p) =>
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? p.value
						: dataType === 'gp$-by-customer-by-month'
						? p.value.toLocaleString('en-US')
						: ` $ ${p.value.toLocaleString('en-US')}`,
				filterParams:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? decimalFilterParams1
						: dataType === 'gp$-by-customer-by-month'
						? decimalFilterParams
						: dollarDecimalFilterParams,
				hide: currentYear === year && currentMonth < 7,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
			{
				field: 'Sep',
				headerName: `September ${year}`,
				cellClass:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? 'decimal'
						: 'numberType',
				cellRenderer: (p) =>
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? p.value
						: dataType === 'gp$-by-customer-by-month'
						? p.value.toLocaleString('en-US')
						: ` $ ${p.value.toLocaleString('en-US')}`,
				filterParams:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? decimalFilterParams1
						: dataType === 'gp$-by-customer-by-month'
						? decimalFilterParams
						: dollarDecimalFilterParams,
				hide: currentYear === year && currentMonth < 8,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
			{
				field: 'Oct',
				headerName: `October ${year}`,
				cellClass:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? 'decimal'
						: 'numberType',
				cellRenderer: (p) =>
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? p.value
						: dataType === 'gp$-by-customer-by-month'
						? p.value.toLocaleString('en-US')
						: ` $ ${p.value.toLocaleString('en-US')}`,
				filterParams:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? decimalFilterParams1
						: dataType === 'gp$-by-customer-by-month'
						? decimalFilterParams
						: dollarDecimalFilterParams,
				hide: currentYear === year && currentMonth < 9,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
			{
				field: 'Nov',
				headerName: `November ${year}`,
				cellClass:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? 'decimal'
						: 'numberType',
				cellRenderer: (p) =>
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? p.value
						: dataType === 'gp$-by-customer-by-month'
						? p.value.toLocaleString('en-US')
						: ` $ ${p.value.toLocaleString('en-US')}`,
				filterParams:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? decimalFilterParams1
						: dataType === 'gp$-by-customer-by-month'
						? decimalFilterParams
						: dollarDecimalFilterParams,
				hide: currentYear === year && currentMonth < 10,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
			{
				field: 'Dec',
				headerName: `December ${year}`,
				cellClass:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? 'decimal'
						: 'numberType',
				cellRenderer: (p) =>
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? p.value
						: dataType === 'gp$-by-customer-by-month'
						? p.value.toLocaleString('en-US')
						: ` $ ${p.value.toLocaleString('en-US')}`,
				filterParams:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? decimalFilterParams1
						: dataType === 'gp$-by-customer-by-month'
						? decimalFilterParams
						: dollarDecimalFilterParams,
				hide: currentYear === year && currentMonth < 11,
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
			{
				field: 'Jan',
				headerName: `January ${year + 1}`,
				cellClass:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? 'decimal'
						: 'numberType',
				cellRenderer: (p) =>
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? p.value
						: dataType === 'gp$-by-customer-by-month'
						? p.value.toLocaleString('en-US')
						: ` $ ${p.value.toLocaleString('en-US')}`,
				filterParams:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? decimalFilterParams1
						: dataType === 'gp$-by-customer-by-month'
						? decimalFilterParams
						: dollarDecimalFilterParams,
				hide:
					currentYear < year + 1 ||
					(currentYear === year + 1 && currentMonth < 0),
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
			{
				field: 'Feb',
				headerName: `February ${year + 1}`,
				cellClass:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? 'decimal'
						: 'numberType',
				cellRenderer: (p) =>
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? p.value
						: dataType === 'gp$-by-customer-by-month'
						? p.value.toLocaleString('en-US')
						: ` $ ${p.value.toLocaleString('en-US')}`,
				filterParams:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? decimalFilterParams1
						: dataType === 'gp$-by-customer-by-month'
						? decimalFilterParams
						: dollarDecimalFilterParams,
				hide:
					currentYear < year + 1 ||
					(currentYear === year + 1 && currentMonth < 1),
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
			{
				field: 'Mar',
				headerName: `March ${year + 1}`,
				cellClass:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gp$-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? 'decimal'
						: 'numberType',

				cellRenderer: (p) =>
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? p.value
						: dataType === 'gp$-by-customer-by-month'
						? p.value.toLocaleString('en-US')
						: ` $ ${p.value.toLocaleString('en-US')}`,
				filterParams:
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month'
						? decimalFilterParams1
						: dataType === 'gp$-by-customer-by-month'
						? decimalFilterParams
						: dollarDecimalFilterParams,
				hide:
					currentYear < year + 1 ||
					(currentYear === year + 1 && currentMonth < 2),
				cellStyle: {
					borderRight: '1px solid #EAECEE',
				},
			},
		];
	};

	// const transformDataArray = (apiData) => {
	// 	const requiredKeysWithDefaults = {
	// 		CurrY: 0,
	// 		Apr: 0,
	// 		May: 0,
	// 		Jun: 0,
	// 		Jul: 0,
	// 		Aug: 0,
	// 		Sep: 0,
	// 		Oct: 0,
	// 		Nov: 0,
	// 		Dec: 0,
	// 		Jan: 0,
	// 		Feb: 0,
	// 		Mar: 0,
	// 		'CurrY-1': 0,
	// 		'CurrY-2': 0,
	// 		'CurrY-3': 0,
	// 	};

	// 	return apiData.map((item) => {
	// 		const transformedItem = {};

	// 		for (const key in item) {
	// 			transformedItem[key] = item[key];
	// 		}

	// 		for (const key in requiredKeysWithDefaults) {
	// 			if (!(key in item)) {
	// 				transformedItem[key] = requiredKeysWithDefaults[key];
	// 			} else {
	// 				transformedItem[key] = parseFloat(item[key]);
	// 			}
	// 		}

	// 		return transformedItem;
	// 	});
	// };

	const transformDataArray = (apiData, dataType) => {
		const requiredKeysWithDefaults = {
			CurrY: 0,
			Apr: 0,
			SalesTarget: 0,
			PctToGoal: 0,
			May: 0,
			Jun: 0,
			Jul: 0,
			Aug: 0,
			Sep: 0,
			Oct: 0,
			Nov: 0,
			Dec: 0,
			Jan: 0,
			Feb: 0,
			Mar: 0,
			'CurrY-1': 0,
			'CurrY-2': 0,
			'CurrY-3': 0,
		};

		return apiData.map((item) => {
			const transformedItem = {};

			for (const key in item) {
				transformedItem[key] = item[key];
			}

			for (const key in requiredKeysWithDefaults) {
				if (!(key in item)) {
					transformedItem[key] = requiredKeysWithDefaults[key];
				} else {
					let value = parseFloat(item[key]);
					if (isNaN(value)) {
						value = 0; // handle non-numeric values gracefully
					}

					if (
						dataType === 'gpm-by-customer-by-month' ||
						dataType === 'gpm-by-salesrep-by-month'
					) {
						transformedItem[key] = value.toFixed(2);
					} else if (dataType === 'gp$-by-customer-by-month') {
						transformedItem[key] = parseFloat(value.toFixed(2));
					} else {
						transformedItem[key] = parseFloat(value?.toFixed(2));
					}
				}
			}

			return transformedItem;
		});
	};

	const useReportData = (dataType, fy) => {
		const [data, setData] = useState([]);
		const [isLoading, setIsLoading] = useState(false);
		const [error, setError] = useState(null);
		const [divisionType, setDivisionType] = useState('');

		useEffect(() => {
			if (!dataType) return;

			const fetchData = async () => {
				setIsLoading(true);
				try {
					const response = await axios.get(
						`
						https://internal.supremecomponents.com/api/externalgateway.php?route=${
							dataType === 'division-billings-by-month'
								? 'get_division_billings_by_month'
								: dataType === 'supplier-billings-by-month'
								? 'get_supplier_billings_by_month'
								: dataType === 'customer-billings-by-month'
								? 'get_customer_billings_by_month'
								: dataType === 'country-billings-by-month'
								? 'get_country_billings_by_month'
								: dataType === 'salesrep-billings-by-month'
								? 'get_salesrep_billings_by_month'
								: dataType === 'capitalizing-the-billings-by-month'
								? 'get_capitalizing_billings_by_month'
								: dataType === 'gpm-by-customer-by-month'
								? 'get_gpm_by_customers_by_month'
								: dataType === 'gp$-by-customer-by-month'
								? 'get_gp_by_customers_by_month'
								: dataType === 'gpm-by-salesrep-by-month'
								? 'get_gpm_by_salesrep_by_month'
								: ''
						}&curryear=${fy - 1}`
					);
					const transformedData = transformDataArray(
						response.data?.data,
						dataType
					);

					/**
					 * * (Piyush requirement) Remove rows who have zero value for current FY
					 */

					setData(
						dataType === 'gpm-by-customer-by-month' ||
							dataType === 'gp$-by-customer-by-month' ||
							dataType === 'gpm-by-salesrep-by-month' ||
							dataType === 'salesrep-billings-by-month'
							? transformedData
							: transformedData.filter((el) => el.CurrY > 0)
					);
					setColumnDefs(getColumnDefs(fy, dataType));
					setError(null);
				} catch (err) {
					setError(err);
					setData([]);
				} finally {
					setIsLoading(false);
				}
			};

			fetchData();
		}, [dataType, fy, divisionType]);

		return { data, isLoading, error, setDivisionType };
	};

	const { data, isLoading, error } = useReportData(dataType, fy);

	return (
		<ManagementReportContext.Provider
			value={{
				fy,
				setFy,
				columnDefs,
				dataType,
				setDataType,
				isLoading,
				error,
				data,
			}}
		>
			{children}
		</ManagementReportContext.Provider>
	);
};

export default ManagementReportContext;
