import { useContext, useEffect, useMemo, useState } from 'react';
import { MdManageAccounts } from 'react-icons/md';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import axios from 'axios';
import { QuestionCircleOutlined } from '@ant-design/icons';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import Button from '../../components/button';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import { isNotEmptyArray } from '../../utils';
import NoData from '../../components/nodata';
import { COUNTRY_CODES } from '../../columns';
import EdenContext from '../../context/edenContext';
import AssignToHouseContext from '../../context/assignToHouseContext';
import { CountryCellRenderer } from '../../columns/countryCellRenderer';
import NotificationBar from '../../components/notificationBar';
import Popconfirm from '../../components/popConfirm';
import { dateFilterParams, dollarFilterParams } from '../../filters';

import styles from './assignToHouse.module.scss';

const AssignToHouse = () => {
	const { data, isLoading, error, getData, processRowData } =
		useContext(AssignToHouseContext);
	const { userMail, userData, me, userInfo } = useContext(EdenContext);

	const [selectedRowValue, setSelectedRowValue] = useState([]);
	const [allocatingAcc, setAllocatingAcc] = useState(false);
	const [allocatingError, setAllocatingError] = useState(false);
	const [accAllocated, setAccAllocated] = useState([]);
	const [showAccAllocatedBanner, setShowAccAllocatedBanner] = useState(false);
	const [popCofirm, setPopConfirm] = useState(false);
	const [gridApi, setGridApi] = useState(null);

	useEffect(() => {
		getData(); //eslint-disable-next-line
	}, []);

	const [columnDefs] = useState([
		{
			field: 'createdDate',
			checkboxSelection: true,
			headerName: 'Created Date',
			minWidth: 170,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value && moment(p.value).format('MMM-DD-YYYY')}
				</span>
			),
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
		},
		{
			field: 'SwithoverDate',
			headerName: 'Switchover Date​',
			minWidth: 170,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value && moment(p.value).format('MMM-DD-YYYY')}
				</span>
			),
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
		},
		{ field: 'StrategicCustomer', headerName: 'Account', minWidth: 300 },
		{ field: 'AccountType', headerName: 'Account Type' },
		{
			field: 'Country',
			headerName: 'Country',
			cellRenderer: CountryCellRenderer,
			filter: 'agSetColumnFilter',
			filterParams: {
				cellRenderer: CountryCellRenderer,
				cellRendererParams: { isFilterRenderer: true },
			},
		},
		{
			field: 'PastSixMonthBilling',
			headerName: 'Past 6-month Billings',
			sort: 'desc',
			sortingOrder: ['desc', 'asc'],
			filter: 'agSetColumnFilter',
			filterParams: dollarFilterParams,
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
		},
		{
			field: 'PastFiveYearBilling',
			headerName: 'Past 5-year Billings',
			sort: 'desc',
			sortingOrder: ['desc', 'asc'],
			filter: 'agSetColumnFilter',
			filterParams: dollarFilterParams,
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
		},
		{
			field: 'expiryDate',
			headerName: 'Expiry Date​',
			minWidth: 170,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value && moment(p.value).format('MMM-DD-YYYY')}
				</span>
			),
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
		},
		{
			field: 'lastBilledDate',
			headerName: 'Last Billed Date​',
			minWidth: 170,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value && moment(p.value).format('MMM-DD-YYYY')}
				</span>
			),
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
		},
		{
			field: 'lastBilledSalesRep',
			headerName: 'Last Billed Sales Rep',
			minWidth: 120,
		},
	]);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
		}),
		[]
	);

	const onGridReady = (e) => {
		setGridApi(e.api);
	};

	const context = useMemo(() => {
		return {
			COUNTRY_CODES: COUNTRY_CODES,
		};
	}, []);

	const onSelectionChanged = (e) => {
		setSelectedRowValue(e.api.getSelectedRows());
	};

	const allocateAccount = () => {
		setPopConfirm(false);
		setAllocatingAcc(true);
		setShowAccAllocatedBanner(false);
		setAccAllocated([]);
		const promises = selectedRowValue.map((el, index, arr) =>
			axios
				.post(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=release_account_to_house&email=${userMail}&account_id=${el?.AccountId}&master_id=${userInfo?.ViewAsLoggedinUser?.MasterId}`
				)
				.then((response) => {
					setAccAllocated(arr.length);
				})
				.catch((err) => {
					console.log(err);
					setAllocatingError(true);
				})
		);
		Promise.all(promises)
			.then(() => {
				axios.post(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=send_release_notification&userEmail=${userMail}&userName=${
						userData?.name || me?.givenName || me?.displayName
					}&data=${JSON.stringify(selectedRowValue)}`
				);
			})
			.then(() => {
				setAllocatingAcc(false);
				setShowAccAllocatedBanner(true);
				setSelectedRowValue([]);
				getData();
			})
			.catch((error) => {
				console.error('Error during promise execution:', error);
			});
	};

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="My Assigned Accounts​"
				icon={<MdManageAccounts style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="My Assigned Accounts​"
				info={
					<>
						<p>
							This section allows you to view your currently assigned accounts.
							You are not to work on any other accounts unless you’ve assigned
							them to yourself or Mr. Vick approves.
						</p>{' '}
						<br />
						<p>
							The following table lists all the accounts that are assigned to
							you. You are welcome to <b>release</b> anyone of the accounts to
							the House Accounts list at any point in time.{' '}
						</p>{' '}
						<br />
						<p>
							<b>NOTE: </b>The Expiry Date is calculated based on 6 months after
							either the Created Date or Switchover Date and Past 6-months
							Billings, whichever is most recent.
						</p>
					</>
				}
			/>
			<div className={styles['house-accounts']}>
				{showAccAllocatedBanner && !allocatingError && (
					<NotificationBar
						greenBar
						messageStyle={{ fontSize: '14px', fontWeight: 400 }}
						message={
							<>
								Congratulations! <b>{accAllocated}</b> accounts have been
								release to the House Accounts List.
							</>
						}
					/>
				)}

				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}
				{!isNotEmptyArray(data?.data) && !error && !isLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="You don't have any accounts assigned." />
					</div>
				)}

				{!isLoading && !error && isNotEmptyArray(data?.data) && (
					<>
						<NotificationBar
							messageStyle={{ fontSize: '14px', fontWeight: 400 }}
							message={
								<>
									You have <b>{data?.count}</b> accounts allocated.
								</>
							}
						/>

						{allocatingError && (
							<NotificationBar
								messageStyle={{ fontSize: '14px', fontWeight: 400 }}
								redBar
								message={
									<>
										There seems to be an error tagging your accounts in Focus.
										IT has been notified and HelpDesk has been notified to
										manually tag your accounts. Contact Help Desk today to get a
										status update.
									</>
								}
							/>
						)}
						<Popconfirm
							placement="bottomLeft"
							okButtonColor="red"
							open={popCofirm}
							title="Release Accounts"
							description="Are you sure you want to release this accounts to House List?"
							okText="Yes"
							cancelText="Cancel"
							onConfirm={allocateAccount}
							onCancel={() => setPopConfirm(false)}
							icon={
								<QuestionCircleOutlined
									style={{
										color: 'red',
									}}
								/>
							}
						>
							<Button
								color={allocatingAcc ? 'dark' : 'red'}
								onClick={() => setPopConfirm(true)}
								loading={allocatingAcc}
								disabled={allocatingAcc || selectedRowValue?.length <= 0}
							>
								{allocatingAcc
									? 'Releasing to House Accounts... Please Wait'
									: 'Release to the House Accounts List​'}
							</Button>
						</Popconfirm>
						<div className={styles['table-wrapper']}>
							<div className={`ag-theme-custom ${styles['table']} `}>
								<AgGridReact
									onGridReady={onGridReady}
									rowData={processRowData}
									columnDefs={columnDefs}
									defaultColDef={defaultColDef}
									rowSelection={'multiple'}
									suppressRowClickSelection={true}
									rowMultiSelectWithClick={true}
									onSelectionChanged={onSelectionChanged}
									animateRows={true}
									pagination={true}
									paginationPageSizeSelector={false}
									suppressContextMenu={true}
									context={context}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default AssignToHouse;
