import { useState } from 'react';
import { Divider, Tag } from 'antd';
import {
	CheckCircleOutlined,
	ClockCircleOutlined,
	CloseCircleOutlined,
	ExclamationCircleOutlined,
	LogoutOutlined,
	SelectOutlined,
	SyncOutlined,
} from '@ant-design/icons';

import Modal from '../../../components/modal';
import ToolTip from '../../../components/tooltip';

import styles from './description.module.scss';

const Description = (p) => {
	const [open, setOpen] = useState(false);

	const onCancel = () => {
		setOpen(false);
	};

	return (
		<>
			<div className={styles['wrapper']} onDoubleClick={() => setOpen(true)}>
				<ToolTip placement="bottomLeft" title={p.value}>
					{p.value}
				</ToolTip>
			</div>

			<Modal
				title={
					<>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								gap: '10px',
							}}
						>
							<p>{p.data.TICKET_ID_LBL}</p>{' '}
							<Tag
								style={{ marginRight: '25px' }}
								icon={
									p.data.STATUS === 'OPEN' ? (
										<SelectOutlined />
									) : p.data.STATUS === 'IN_PROGRESS' ? (
										<SyncOutlined spin />
									) : p.data.STATUS === 'WAITING_FOR_APPROVAL' ? (
										<ClockCircleOutlined />
									) : p.data.STATUS === 'ESCALATED' ? (
										<LogoutOutlined />
									) : p.data.STATUS === 'PENDING_EMPLOYEE_RESPONSE' ? (
										<ExclamationCircleOutlined />
									) : p.data.STATUS === 'CLOSED_AND_RESOLVED' ? (
										<CheckCircleOutlined />
									) : p.data.STATUS === 'CLOSED_AND_NOT_RESOLVED' ? (
										<CloseCircleOutlined />
									) : (
										''
									)
								}
								color={
									p.data.STATUS === 'OPEN'
										? 'magenta'
										: p.data.STATUS === 'IN_PROGRESS'
										? 'processing'
										: p.data.STATUS === 'WAITING_FOR_APPROVAL'
										? 'default'
										: p.data.STATUS === 'ESCALATED'
										? 'orange'
										: p.data.STATUS === 'PENDING_EMPLOYEE_RESPONSE'
										? 'warning'
										: p.data.STATUS === 'CLOSED_AND_RESOLVED'
										? 'success'
										: p.data.STATUS === 'CLOSED_AND_NOT_RESOLVED'
										? 'error'
										: ''
								}
							>
								{p.data.STATUS === 'OPEN'
									? 'Open/New'
									: p.data.STATUS === 'IN_PROGRESS'
									? 'In-progress'
									: p.data.STATUS === 'WAITING_FOR_APPROVAL'
									? 'Waiting for Approval'
									: p.data.STATUS === 'ESCALATED'
									? 'Escalated'
									: p.data.STATUS === 'PENDING_EMPLOYEE_RESPONSE'
									? 'Pending Employee Response'
									: p.data.STATUS === 'CLOSED_AND_RESOLVED'
									? 'Closed and Resolved'
									: p.data.STATUS === 'CLOSED_AND_NOT_RESOLVED'
									? 'Closed and Not Resolved'
									: ''}
							</Tag>
						</div>
						<Divider
							style={{
								margin: '10px 0',
								borderBlockStart: '1px solid rgb(216, 216, 216)',
							}}
						/>
					</>
				}
				open={open}
				onCancel={onCancel}
				destroyOnClose
				width={600}
			>
				{p.value}
			</Modal>
		</>
	);
};

export default Description;
