import React from 'react';

import styles from './accountSummaryCard.module.scss';

const AccountSummaryCard = ({ title, children }) => {
	return (
		<div className={styles['account-summary-card-wrapper']}>
			{title && <p className={styles['title']}>{title}</p>}
			{children}
		</div>
	);
};

export default AccountSummaryCard;
