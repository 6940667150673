import { Popconfirm as AntdPopconfirm } from 'antd';

import styles from './popConfirm.module.scss';
import Button from '../button';

const Popconfirm = ({
	disabled,
	icon,
	title,
	description,
	onConfirm,
	onCancel,
	children,
	cancelText,
	okText,
	okButtonColor,
	open,
	okButtonLoading,
	placement,
}) => {
	return (
		<AntdPopconfirm
			disabled={disabled}
			icon={icon}
			showCancel={false}
			title={<p className={styles['popconfirm-title']}>{title}</p>}
			open={open}
			placement={placement}
			description={
				<>
					<p className={styles['popconfirm-description']}>{description}</p>
					<div className={styles['footer-buttons']}>
						<Button size="small" onClick={onCancel} type="bordered">
							{cancelText}
						</Button>
						<Button
							onClick={onConfirm}
							size="small"
							color={okButtonColor}
							loading={okButtonLoading}
						>
							{okText}
						</Button>
					</div>
				</>
			}
		>
			{children}
		</AntdPopconfirm>
	);
};

export default Popconfirm;
