import { useContext, useMemo, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { MdMail } from 'react-icons/md';
import axios from 'axios';
import { HiOutlineSearch } from 'react-icons/hi';
import { AgGridReact } from 'ag-grid-react';
import { Base64 } from 'js-base64';

import EdenContext from '../../../../../context/edenContext';
import ToolTip from '../../../../../components/tooltip';
import NotificationBar from '../../../../../components/notificationBar';
import Input from '../../../../../components/input';
import Button from '../../../../../components/button';
import LoadingMessage from '../../../../../components/loaderMessage';
import ErrorMessage from '../../../../../components/errorMessage';
import { isNotEmptyArray } from '../../../../../utils';
import NoData from '../../../../../components/nodata';

import styles from './addMail.module.scss';

const dateFilterParams = {
	filters: [
		{
			filter: 'agDateColumnFilter',
			filterParams: {
				comparator: (a, b) => {
					return getDate(a).getTime() - getDate(b).getTime();
				},
			},
		},
	],
};

const getDate = (value) => {
	const date = moment.utc(value).tz('Asia/Singapore').format('DD/MM/YYYY');
	var dateParts = date.split('/');
	return new Date(
		Number(dateParts[2]),
		Number(dateParts[1]) - 1,
		Number(dateParts[0])
	);
};

const AddMail = ({
	setRowSelectionData,
	customerProspectData,
	setCustomerProspectData,
	setCurrent,
}) => {
	const { messageURl } = useContext(EdenContext);
	const [searchValue, setSearchValue] = useState('');
	const [customerProspectLoading, setCustomerProspectLoading] = useState(false);
	const [customerProspectError, setCustomerProspectError] = useState(null);
	const [columnDefs] = useState([
		{
			checkboxSelection: true,
			field: 'subject',
			headerName: 'Subject Line',
		},
		{
			field: 'sciCustomer',
			headerName: 'Customer',
		},
		{
			field: 'contactName',
			headerName: 'Customer Name',
		},
		{
			field: 'from',
			headerName: 'From',
		},
		{
			field: 'recipients',
			headerName: 'To',
		},
		{
			field: 'sciSalesRep',
			headerName: 'Sales Rep',
		},
		{
			field: 'receivedDateTime',
			headerName: 'ReceivedDate',
			filterParams: dateFilterParams,
			minWidth: 190,
			sort: 'desc',
			cellRenderer: (p) => (
				<>
					{p.value
						? moment
								.utc(p.value)
								.tz('Asia/Singapore')
								.format('MMM-DD-YYYY hh:mm A')
						: '--'}
				</>
			),
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},

		{
			field: 'email',
			headerName: 'Email',
			floatingFilter: false,
			cellRenderer: (p) => {
				const trimmedString = p?.data?.webLink?.replace(/=+$/, '');
				return (
					<Link
						target="_blank"
						to={`${messageURl?.url}${trimmedString}%3D.eml`}
					>
						<ToolTip title="Preview mail">
							<MdMail style={{ fontSize: '16px' }} />
						</ToolTip>
					</Link>
				);
			},
			cellStyle: { padding: '4px 0 0 25px' },
			maxWidth: 80,
		},
	]);

	const handleSearchValue = () => {
		if (searchValue?.length > 2) {
			setCustomerProspectLoading(true);
			axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=findmail&keyword=${encodeURIComponent(
						Base64.encode(searchValue)
					)}`
				)
				.then((response) => {
					setCustomerProspectData(response.data);
					setCustomerProspectError(null);
				})
				.catch((err) => {
					setCustomerProspectError(err);
					setCustomerProspectData(null);
					setCustomerProspectLoading(false);
				})
				.finally(() => {
					setCustomerProspectLoading(false);
				});
		}
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);

	const onSelectionChanged = (p) => {
		setCurrent(1);
		setRowSelectionData(p.api.getSelectedRows());
	};

	return (
		<div style={{ marginTop: '10px' }}>
			{searchValue?.length < 3 && (
				<NotificationBar
					redBar
					message="A minimum of 3 characters are required to view the results."
				/>
			)}
			<div className={styles['input-searchButton']}>
				<Input
					placeholder="Search for Subject Line or Sender's Email Address"
					onChange={(e) => setSearchValue(e.target.value)}
					onPressEnter={handleSearchValue}
					disabled={customerProspectLoading}
				/>
				<Button
					onClick={handleSearchValue}
					disabled={customerProspectLoading || searchValue?.length < 3}
				>
					Search <HiOutlineSearch className={styles['search-icon']} />
				</Button>
			</div>

			{customerProspectLoading && (
				<div className={styles['loading-wrapper']}>
					<LoadingMessage height={60} width={60} message="Loading..." />
				</div>
			)}

			{customerProspectError && !customerProspectLoading && (
				<div className={styles['error-wrapper']}>
					<ErrorMessage
						style={{ width: '200px' }}
						messageStyle={{ fontSize: '14px' }}
						message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed."
					/>
				</div>
			)}

			{!isNotEmptyArray(customerProspectData) &&
				!customerProspectError &&
				!customerProspectLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData
							style={{ width: '200px' }}
							messageStyle={{ fontSize: '14px' }}
							message="Its all empty here. Search for Subject Line or Sender's Email Address"
						/>
					</div>
				)}

			{!customerProspectLoading &&
				!customerProspectError &&
				isNotEmptyArray(customerProspectData) && (
					<div className={`ag-theme-custom ${styles['table-wrapper']}`}>
						<AgGridReact
							columnDefs={columnDefs}
							rowData={customerProspectData}
							defaultColDef={defaultColDef}
							rowSelection={'single'}
							onSelectionChanged={onSelectionChanged}
							animateRows={true}
							pagination={true}
							paginationPageSize={10}
							paginationPageSizeSelector={false}
						/>
					</div>
				)}
		</div>
	);
};

export default AddMail;
