import { Tooltip } from 'antd';

import Excel from '../../images/exporttoexcel.svg';
import DeleteFileIcon from '../../images/delete-file-icon.svg';
import ImageIcon from '../../images/image.svg';
import PDFIcon from '../../images/pdf.svg';
import WordIcon from '../../images/word.svg';
import { FaRegFileCode } from 'react-icons/fa';

import styles from './uploadedFile.module.scss';

const UploadedFile = ({
	url,
	canDownload,
	onRemove,
	size,
	fileName,
	style,
}) => {
	const onDownload = () => {
		if (canDownload) {
			const aTag = document.createElement('a');
			aTag.href = url;
			aTag.download = fileName;
			aTag.target = '_blank';
			document.body.appendChild(aTag);
			aTag.click();
			document.body.removeChild(aTag);
		}
	};

	const getFileExtension = () => {
		const ext = url.substring(url.lastIndexOf('.'));
		if (
			ext === '.png' ||
			ext === '.jpeg' ||
			ext === '.jpg' ||
			ext === '.webp' ||
			ext === '.svg'
		) {
			return <img src={ImageIcon} alt="" key={url} />;
		} else if (ext === '.pdf') {
			return <img src={PDFIcon} alt="" key={url} />;
		} else if (ext === '.doc' || ext === '.docx') {
			return <img src={WordIcon} alt="" key={url} />;
		} else if (ext === '.xlsx' || ext === '.csv') {
			return <img src={Excel} alt="excel" />;
		} else if (ext === '.htm') {
			return <FaRegFileCode style={{ fontSize: '18px', color: '#3572EF' }} />;
		}
		return;
	};

	const getIcon = getFileExtension();

	return (
		<div className={styles['wrapper']} style={style}>
			<div
				className={styles['file']}
				style={{
					cursor: canDownload ? 'pointer' : 'default',
				}}
				onClick={onDownload}
			>
				<div className={styles['file-icon']}>{getIcon}</div>
				<Tooltip title={fileName} placement="bottom">
					<p className={styles['file-name']}>{fileName}</p>
				</Tooltip>
			</div>
			{onRemove && (
				<img
					className={styles['delete-icon']}
					src={DeleteFileIcon}
					onClick={onRemove}
					alt="delete"
				/>
			)}
		</div>
	);
};

export default UploadedFile;
