import React, { useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { MdMail } from 'react-icons/md';

import useFetch from '../../hooks';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import ToolTip from '../../components/tooltip';
import InnerHeader from '../../components/innerHeader';

import styles from './autoQc.module.scss';

const AutoQC = () => {
	const gridRef = useRef();

	const { apiData, isLoading, serverError } = useFetch(
		'https://internal.supremecomponents.com/api/qc_json_file.json'
	);
	const [gridApi, setGridApi] = useState();
	// eslint-disable-next-line
	const [columnDefs, setColumnDefs] = useState([
		{
			field: 'sno',
			headerName: 'S NO.',
			maxWidth: 75,
			cellStyle: { paddingLeft: '20px' },
			floatingFilter: false,
			cellRenderer: (p) => <span>{p.rowIndex + 1}</span>,
		},
		{
			field: 'MAIL_LINK',
			headerName: 'Preview Mail',
			maxWidth: 110,
			floatingFilter: false,
			cellRenderer: (p) => (
				<Link target="_blank" to={p?.value}>
					<ToolTip title="Preview mail">
						<MdMail style={{ fontSize: '16px' }} />
					</ToolTip>
				</Link>
			),
		},
		{
			field: 'DATE_TIME',
			headerName: 'Date Time Received',
			cellRenderer: (p) => (
				<>{p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : '--'}</>
			),
		},
		{ field: 'SUBJECT', headerName: 'Subject' },
		{
			field: 'Account',
			headerName: 'CRM Account Name',
		},
		{
			field: 'FROM_EMAIL',
			headerName: 'From Email',
		},
		{
			field: 'TO_EMAIL',
			headerName: 'To Email',
		},
		{
			field: 'MPN_COLUMN_NAME',
			headerName: 'MPN Column Name',
		},
		{
			field: 'BRAND_COLUMN_NAME',
			headerName: 'Brand Column Name',
		},
		{
			field: 'QTY_COLUMN_NAME',
			headerName: 'QTY Column Name',
		},
		{
			field: 'MPN',
			headerName: 'MPN',
		},
		{
			field: 'BRAND',
			headerName: 'Brand',
		},
		{
			field: 'QTY',
			headerName: 'QTY',
			maxWidth: 80,
		},
	]);

	const defaultColDef = useMemo(
		() => ({
			enableRowGroup: true,
			enablePivot: true,
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);

	const onGridReady = (p) => {
		setGridApi(p.api);
	};

	const excelStyles = [
		{
			id: 'header',
			font: {
				bold: true,
			},
		},
	];

	const exportToExcel = () => {
		const autoQcExcelParams = {
			sheetName: 'Auto QC',
			fileName: 'Auto QC.xlsx',
			processCellCallback: (p) => {
				if (p.column.colId === 'sno') {
					return p.node.rowIndex + 1;
				}
				if (p.column.colId === 'DATE_TIME') {
					return moment(p.value).format('MMM-DD-YYYY hh:mm A');
				}

				return p.value;
			},
		};

		gridApi.exportDataAsExcel(autoQcExcelParams);
	};

	const autoGroupColumnDef = useMemo(() => {
		return {
			minWidth: 200,
			filter: 'agGroupColumnFilter',
		};
	}, []);

	return (
		<div className={styles['wrapper']}>
			{isLoading && (
				<div className={styles['loading-wrapper']}>
					<LoadingMessage message="That’s a huge dataset we’ve got here. Give us a moment as we retrieve everything and organize them for you." />
				</div>
			)}

			{serverError && !isLoading && (
				<div className={styles['error-wrapper']}>
					<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
				</div>
			)}

			{!isLoading && !serverError && (
				<>
					<InnerHeader exportToExcel={exportToExcel} />

					<div className={`ag-theme-custom ${styles['table-wrapper']} `}>
						<AgGridReact
							ref={gridRef}
							rowData={apiData}
							onGridReady={onGridReady}
							columnDefs={columnDefs}
							defaultColDef={defaultColDef}
							autoGroupColumnDef={autoGroupColumnDef}
							groupDisplayType={'multipleColumns'}
							animateRows={true}
							rowGroupPanelShow="always"
							pagination={true}
							excelStyles={excelStyles}
							enableRangeSelection={true}
							paginationPageSizeSelector={false}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default AutoQC;
