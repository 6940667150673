import { useContext, useState } from 'react';
import { message } from 'antd';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import axios from 'axios';
import { IoDuplicateSharp } from 'react-icons/io5';
import { QuestionCircleOutlined } from '@ant-design/icons';

import Popconfirm from '../../../../components/popConfirm';
import ToolTip from '../../../../components/tooltip';
import EdenContext from '../../../../context/edenContext';
import { filterUniqueIdsAndRecent } from '../../../../utils';

import styles from './mpnCostBookAction.module.scss';

const MPNCostBookAction = (p) => {
	const {
		userMail,
		setMpnCostBookList,
		mpnCostBookList,
		setMpnCostBookRowData,
		mpnCostBookRowData,
		costBookType,
		userType,
		MCBlastUserActiviry,
	} = useContext(EdenContext);
	const [deleteQueuePopup, setDeleteQueuePopup] = useState(false);

	const deleteQueue = () => {
		setMpnCostBookRowData(
			filterUniqueIdsAndRecent(
				mpnCostBookRowData?.filter((item) => item.id !== p?.data?.id)
			)
		);
		setMpnCostBookList(
			filterUniqueIdsAndRecent(
				mpnCostBookList?.filter((item) => item.id !== p?.data?.id)
			)
		);
		message.success('MPN deleted successfully');
		setDeleteQueuePopup(false);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=deletempncostbook&id=${p?.data?.id}&userEmail=${userMail}`
			)

			.catch(() => {
				message.error('Something went wrong please try again later!');
			});
	};

	const closeDeleteQueuePopup = () => {
		setDeleteQueuePopup(false);
	};

	const openDeletePopUp = () => {
		setDeleteQueuePopup(true);
	};

	const duplicateRow = () => {
		const fieldValues = {
			userEmail: userMail,
			...p?.data,
			othSrRead: userType === 'sr' || userType === 'sm' ? true : p?.othSrRead,
			parentId: p.data.parentId,
			bomLineNo: p.data?.smartId?.split('.')?.[3],
			foundMpnInCrm: '',
			foundBrandInCrm: '',
			foundMPN: '',
			foundBrand: '',
			foundMOQ: 0,
			foundSPQ: 0,
			foundUsdCost: 0,
			foundExtPrice: 0,
			foundDC: '',
			foundPackaging: '',
			foundLT: '',
			sdSource: '',
			sdMail: '',
			sdSubject: '',
			sdSourceDate: null,
			sdSupplier: '',
			sdSupplierName: '',
			sdSupplierEmail: '',
			othDaysOpen: 0,
			othRemarks: '',
			othOrderReceived: null,
		};

		delete fieldValues?.id;
		delete fieldValues?.sdSourceDate;

		if (p?.data.srId) {
			axios
				.post(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=savempncostbook`,
					JSON.stringify(fieldValues)
				)
				.then((response) => {
					axios
						.get(
							`https://internal.supremecomponents.com/api/externalgateway.php?route=getmpncostbookbyid&id=${response?.data}&isbuy=${costBookType}&userEmail=${userMail}&lastUserActivity=${MCBlastUserActiviry}`
						)
						.then((response) => {
							setMpnCostBookList(
								filterUniqueIdsAndRecent([
									...mpnCostBookList,
									response?.data?.[0],
								])
							);
							message.success('MPN added successfully');
						})
						.catch((err) => {
							message.error('Something went wrong please try again later!');
						});
				})
				.catch((err) => {
					message.error('Something went wrong please try again later!');
				});
		} else {
			message.error('Something went wrong please try again later!');
		}
	};

	// const copyRow = () => {
	// 	if (p?.api) {
	// 		p?.api?.getSelectedRows();
	// 		p?.api?.copySelectedRowsToClipboard({
	// 			includeHeaders: true,
	// 		});
	// 		p?.api.deselectAll();
	// 	}
	// 	message.open({
	// 		type: 'success',
	// 		content: 'Copied to Clipboard',
	// 	});
	// };

	return (
		<div className={styles['wrapper']} style={{ display: 'flex', gap: '10px' }}>
			<ToolTip title="Copy Row">
				{/* <MdOutlineContentCopy
					onClick={copyRow}
					style={{ fontSize: '16px', cursor: 'pointer' }}
				/> */}
			</ToolTip>
			{p?.data?.othOrderReceived === true ? (
				''
			) : (
				<Popconfirm
					placement="bottomLeft"
					okButtonColor="red"
					open={deleteQueuePopup}
					title="Delete"
					description="You cannot undo this action. Do you confirm the deletion of the selected row?"
					okText="Yes"
					cancelText="Cancel"
					onConfirm={deleteQueue}
					onCancel={closeDeleteQueuePopup}
					icon={
						<QuestionCircleOutlined
							style={{
								color: 'red',
							}}
						/>
					}
				>
					<ToolTip title="Delete">
						<RiDeleteBin6Fill
							style={{ fontSize: '18px', cursor: 'pointer', color: '#C00000' }}
							onClick={openDeletePopUp}
						/>
					</ToolTip>
				</Popconfirm>
			)}
			{p.data.smartId?.split('.')[p.data.smartId?.split('.')?.length - 1] ===
				'0' && (
				<ToolTip title="Duplicate Row">
					<IoDuplicateSharp
						style={{ fontSize: '16px', cursor: 'pointer' }}
						onClick={duplicateRow}
					/>
				</ToolTip>
			)}
		</div>
	);
};

export default MPNCostBookAction;
