import { AdminHouseAccountProvider } from './adminHouseAccountContext';
import { AssignToHouseProvider } from './assignToHouseContext';
import { CustomerOutreachProvider } from './customerOutreachContext';
import { CustomerPricesProvider } from './customerPricesContext';
import { FreightProvider } from './freightContext';
import { HelpDeskTrackerProvider } from './helpdeskTrackerContext';
import { HouseAccountProvider } from './houseAccountContext';
import { KeyForAttachProvider } from './keyForAttachContext';
import { LinkedCleanserProvider } from './linkedCleanserContext';
import { ManagementReportProvider } from './mangementReportContext1';
import { MassMailProvider } from './massMailContext';
import { MpnQueueProvider } from './mpnQueueContext';

const contextProviders = [
	MpnQueueProvider,
	FreightProvider,
	KeyForAttachProvider,
	AdminHouseAccountProvider,
	HouseAccountProvider,
	AssignToHouseProvider,
	ManagementReportProvider,
	CustomerOutreachProvider,
	LinkedCleanserProvider,
	CustomerPricesProvider,
	MassMailProvider,
	HelpDeskTrackerProvider,
];

export default contextProviders;
