import { useContext, useEffect, useMemo, useState } from 'react';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { IoIosSearch } from 'react-icons/io';
import { CiCircleQuestion } from 'react-icons/ci';
import { Badge, Divider } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import axios from 'axios';

import BreadCrumb from '../../../components/breadcrumb';
import SectionInfo from '../../../components/sectionInfo';
import EdenContext from '../../../context/edenContext';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';
import AccessDenied from '../../../components/accessDenied';
import ToolTip from '../../../components/tooltip';
import Modal from '../../../components/modal';
import Input from '../../../components/input';

import styles from './apiHealthChecker.module.scss';

const APIHealthChecker = () => {
	const { userType } = useContext(EdenContext);
	const [modal, setModal] = useState(null);
	const [originalData, setOriginalData] = useState([]);
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [columnDefs] = useState([
		{ field: 'api_name', headerName: 'API Name' },
		{
			field: 'request_date_time',
			headerName: 'Request Date Time​',
			cellRenderer: (p) => (
				<> {p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : '--'}</>
			),
		},
		{
			field: 'is_alive',
			headerName: 'Status',
			cellRenderer: (p) =>
				p.value === 1 ? (
					<Badge status="processing" text="Operational" color="#10a37f" />
				) : (
					<Badge status="processing" text="Broken" color="#ef4146" />
				),
		},
		{ field: 'error', headerName: 'Error' },
	]);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	);

	function groupBy(arr, key) {
		return arr.reduce((acc, item) => {
			const groupKey = typeof key === 'function' ? key(item) : item[key];
			if (!acc[groupKey]) {
				acc[groupKey] = [];
			}
			acc[groupKey].push(item);
			return acc;
		}, {});
	}

	function calculateDaysDifference(date1, date2) {
		const diffInTime = Math.abs(new Date(date2) - new Date(date1));
		return Math.ceil(diffInTime / (1000 * 60 * 60 * 24));
	}

	function calculateUptimePercentage(logs) {
		const totalLogs = logs.length;
		const uptimeLogs = logs.filter((log) => log.is_alive === 1).length;
		return (uptimeLogs / totalLogs) * 100;
	}

	function formatData(input) {
		// Group by api_name
		const groupedByApiName = groupBy(input, 'api_name');

		// Process each api_name group
		const result = Object.entries(groupedByApiName).map(([api_name, logs]) => {
			// Sort logs by request_date_time in ascending order
			logs.sort(
				(a, b) => new Date(a.request_date_time) - new Date(b.request_date_time)
			);

			// Group logs by date
			const groupedByDate = groupBy(
				logs,
				(log) => log.request_date_time.split(' ')[0]
			);

			// Format the logs with nested grouping
			const formattedLogs = Object.entries(groupedByDate).map(
				([date, dateLogs]) => {
					// Sort dateLogs by request_date_time in ascending order
					dateLogs.sort(
						(a, b) =>
							new Date(b.request_date_time) - new Date(a.request_date_time)
					);

					return {
						...dateLogs[0], // Most recent log for this date
						groupBydate: dateLogs,
					};
				}
			);

			const mostRecentLog = logs[logs.length - 1];
			const oldestLog = logs[0];
			const daysDifference = calculateDaysDifference(
				oldestLog.request_date_time,
				mostRecentLog.request_date_time
			);

			const uptimePercentage = calculateUptimePercentage(logs);

			return {
				...mostRecentLog, // Last object data as logs are sorted in ascending order
				logs: formattedLogs,
				daysDifference: daysDifference,
				uptimePercentage: uptimePercentage.toFixed(2), // Calculated uptime percentage
			};
		});

		return result;
	}

	const getData = () => {
		setIsLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=getapihealth`
			)
			.then((response) => {
				setData(formatData(response.data));
				setOriginalData(formatData(response.data));
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
				setOriginalData([]);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getData(); //eslint-disable-next-line
	}, []);

	const handleSearch = (query) => {
		setSearchQuery(query);
		const filteredData = originalData.filter((item) =>
			// Adjust this condition to match the structure of your data
			item.api_name.toLowerCase().includes(query.toLowerCase())
		);
		setData(filteredData);
	};

	return (
		<>
			{userType === 'admin' ? (
				<div className={styles['wrapper']}>
					<BreadCrumb
						route1="Admin"
						route2="API Health Checker"
						icon={<MdOutlineAdminPanelSettings style={{ fontSize: '20px' }} />}
					/>
					<SectionInfo
						removeshortcutkeys
						title="API Health Checker"
						info="This section shows information about API Health."
					/>
					<div className={styles['api-health-checker']}>
						{isLoading && (
							<div className={styles['loading-wrapper']}>
								<LoadingMessage message="Loading..." />
							</div>
						)}

						{error && !isLoading && (
							<div className={styles['error-wrapper']}>
								<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
							</div>
						)}

						{!isNotEmptyArray(data) && !error && !isLoading && (
							<div className={styles['no-data-wrapper']}>
								<NoData message="Its all empty here." />
							</div>
						)}

						{!isLoading && !error && isNotEmptyArray(data) && (
							<div className={styles['data-wrapper']}>
								<Input
									placeholder="Search your API Name"
									value={searchQuery}
									onChange={(e) => handleSearch(e.target.value)}
									style={{ marginBottom: '10px', height: '40px' }}
									prefix={<IoIosSearch size={20} />}
								/>
								<div className={styles['api-status-wrapper']}>
									{data.map((el, index) => (
										<div key={index} className={styles['api-status-name']}>
											<div className={styles['name-tooltip-status']}>
												<div className={styles['right-side']}>
													<p className={styles['name']}>{el?.api_name}</p>
													<ToolTip title={el?.api_name} placement="top">
														<CiCircleQuestion
															className={styles['tooltip-icon']}
														/>
													</ToolTip>
												</div>
												<p
													className={`${
														el?.is_alive === 1
															? styles['status']
															: styles['status-error']
													}`}
												>
													{el?.is_alive === 1 ? 'Operational' : 'Broken'}
												</p>
											</div>
											<div className={styles['logs-wrapper']}>
												{el?.logs?.map((el, index) => (
													<ToolTip
														key={index}
														title={
															<div style={{ color: '#000' }}>
																{moment(el?.request_date_time).format(
																	'MMM-DD-YYYY hh:mm A'
																)}
															</div>
														}
														color={'#fff'}
													>
														<div
															onDoubleClick={() => setModal(el)}
															className={`${
																el?.is_alive === 1
																	? styles['log']
																	: styles['log-error']
															}`}
														></div>
													</ToolTip>
												))}
											</div>
											<div className={styles['log-time']}>
												<div className={styles['end-time']}>
													{el?.daysDifference}{' '}
													{el.daysDifference > 1 ? 'days ago' : 'day ago'}
												</div>

												<Divider plain className={styles['divider']}>
													{el?.uptimePercentage} %
												</Divider>
												<div className={styles['current-time']}>Today</div>
											</div>
										</div>
									))}
								</div>
								{!!modal && (
									<Modal
										title={
											<>
												<p>{modal?.api_name}</p>
												<Divider
													style={{
														margin: '5px 0',
														borderBlockStart: '1px solid rgb(216, 216, 216)',
													}}
												/>
											</>
										}
										open={modal}
										onCancel={() => setModal(null)}
										destroyOnClose
										width={700}
									>
										<div className={styles['table-wrapper']}>
											<div className={`ag-theme-custom ${styles['table']} `}>
												<AgGridReact
													rowData={modal?.groupBydate}
													columnDefs={columnDefs}
													defaultColDef={defaultColDef}
													animateRows={true}
													pagination={true}
													paginationPageSizeSelector={false}
												/>
											</div>
										</div>
									</Modal>
								)}
							</div>
						)}
					</div>
				</div>
			) : (
				<AccessDenied message="You are not an Admin" />
			)}
		</>
	);
};

export default APIHealthChecker;
