import { useIdleTimer } from 'react-idle-timer';

import brandMap from './brandMap.json';

export const capitalizeFirstLetter = (str) => {
	const capitalized =
		str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
	return capitalized;
};

export const currencyFormatter = (currency, sign) => {
	try {
		const sansDec = currency?.toFixed(2);
		const formatted = sansDec?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return `${sign}${formatted}`;
	} catch (error) {
		return currency;
	}
};

export const debounceMethod = (func) => {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => func.apply(this, args), 50);
	};
};

export function isArray(val) {
	return Object.prototype.toString.call(val) === '[object Array]';
}

export function isNotEmptyArray(val) {
	return isArray(val) && val.length !== 0;
}

export const filterUniqueIdsAndRecent = (arr) => {
	const uniqueIdsMap = new Map();

	arr?.forEach((obj) => {
		const currentId = obj.id;
		const existingObj = uniqueIdsMap.get(currentId);

		if (
			!existingObj ||
			new Date(obj.dateModified) > new Date(existingObj.dateModified)
		) {
			uniqueIdsMap.set(currentId, obj);
		}
	});

	return Array.from(uniqueIdsMap.values());
};

export const useIdleTimeout = ({ onIdle, idleTime }) => {
	const idleTimer = useIdleTimer({
		timeout: 1000 * idleTime,
		onIdle: onIdle,
		// throttle: 500,
	});

	return {
		idleTimer,
	};
};

export function getPrimaryBrand(searchedBrand) {
	const lowerSearchedBrand = searchedBrand?.toLowerCase();

	for (let i = 0; i < brandMap.data.length; i++) {
		const brandsArray = brandMap.data[i].brands
			.split('|')
			.filter(Boolean)
			.map((brand) => brand.trim().toLowerCase());
		if (brandsArray.includes(lowerSearchedBrand)) {
			return brandMap.data[i].brands.split('|').filter(Boolean)[0];
		}
	}
	return null;
}

// Function to get unique objects based on a specific property (e.g., MAIL_ID)
export function getUniqueObjectsByProperty(array, propertyName) {
	return Object.values(
		array.reduce((uniqueMap, obj) => {
			uniqueMap[obj[propertyName]] = obj;
			return uniqueMap;
		}, {})
	);
}

//Proper case title
export function titleCase(str) {
	if (str?.length > 0) {
		const words = str?.toLowerCase()?.split(' ');
		for (let i = 0; i < words?.length; i++) {
			words[i] = words[i][0]?.toUpperCase() + words[i]?.slice(1);
		}
		return words?.join(' ');
	}
	return '';
}

// Comma seperate values
export function formatNumberWithCommas(value) {
	if (value == null) return '';
	const parts = value.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return parts.join('.');
}
