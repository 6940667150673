import { useEffect, useMemo, useState } from 'react';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import axios from 'axios';

import BreadCrumb from '../../../components/breadcrumb';
import InnerHeader from '../../../components/innerHeader';
import SectionInfo from '../../../components/sectionInfo';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';
import { dateFilterParams, numberFilterParams } from '../../../filters';

import styles from './globalChatgptReport.module.scss';

const GlobalChatGptReport = () => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const [columnDefs] = useState([
		{ field: 'PROJECT_NAME', headerName: 'Project Name' },
		{ field: 'API_KEY', headerName: 'API Key' },
		{
			field: 'DATE_TIME',
			headerName: 'Date & Time',
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			cellRenderer: (p) => (
				<>{p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : '--'}</>
			),
		},
		{ field: 'DEVELOPER', headerName: 'Developer' },
		{
			field: 'INPUT_TOKEN',
			headerName: 'Input Token',
			cellRenderer: (p) => parseFloat(p.value)?.toLocaleString('en-US'),
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
		},
		{
			field: 'OUTPUT_TOKEN',
			headerName: 'Output Token',
			cellRenderer: (p) => parseFloat(p.value)?.toLocaleString('en-US'),
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
		},
		{
			field: 'TOTAL_TOKENS',
			headerName: 'Total Token',
			cellRenderer: (p) => parseFloat(p.value)?.toLocaleString('en-US'),
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
		},
		{
			field: 'INPUT_TOKEN_COST',
			headerName: 'Input Token Cost',
			cellRenderer: (p) => parseFloat(p.value),
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
		},
		{
			field: 'OUTPUT_TOKEN_COST',
			headerName: 'Output Token Cost',
			cellRenderer: (p) => parseFloat(p.value),
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
		},
		{
			field: 'TOTAL_COST',
			headerName: 'Total Cost',
			cellRenderer: (p) => parseFloat(p.value),
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
		},
	]);

	const getData = () => {
		setIsLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_openai_global_usage`
			)
			.then((response) => {
				setData(response.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getData(); //eslint-disable-next-line
	}, []);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);
	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="Global ChatGPT Response"
				icon={<MdOutlineAdminPanelSettings style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo removeshortcutkeys title="Global ChatGPT Response" info="" />
			<InnerHeader updatePage onUpdate={getData} updateText="Refresh" />

			<div className={styles['global-chatgpt-report']}>
				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}
				{!isNotEmptyArray(data) && !error && !isLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here." />
					</div>
				)}
				{!isLoading && !error && isNotEmptyArray(data) && (
					<div className={styles['table-wrapper']}>
						<div className={`ag-theme-custom ${styles['table']} `}>
							<AgGridReact
								rowData={data}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								animateRows={true}
								pagination={true}
								paginationPageSizeSelector={false}
								enableRangeSelection={true}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default GlobalChatGptReport;
