import { Select as AntdSelect, Tag } from 'antd';
import { IoIosArrowDown } from 'react-icons/io';

import styles from './select.module.scss';

const Select = ({
	style,
	options,
	placeholder,
	onChange,
	defaultValue,
	multiple,
	showSearch,
	disabled,
	title,
	allowClear,
	onSearch,
	value,
	loading,
	type,
	maxTagCount,
	optionLabelProp,
	optionFilterProp,
	filterOption,
	onClear,
	size,
	popupMatchSelectWidth,
	autoFocus,
	defaultOpen,
	onBlur,
	onKeyDown,
	onInputKeyDown,
	onKeyUp,
	status,
}) => {
	const tagRender = (props) => {
		const { label, closable, onClose } = props;

		const onPreventMouseDown = (event) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				color="#1677FF"
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				style={{
					marginRight: 3,
				}}
			>
				{label}
			</Tag>
		);
	};

	return (
		<>
			{type === 'bordered' ? (
				<AntdSelect
					style={style}
					options={options}
					tagRender={tagRender}
					placeholder={placeholder}
					className={styles['border-select']}
					popupClassName={styles['border-menu']}
					onChange={onChange}
					defaultValue={defaultValue}
					mode={multiple ? 'multiple' : ''}
					showSearch={showSearch}
					disabled={disabled}
					allowClear={allowClear}
					onSearch={onSearch}
					value={value}
					loading={loading}
					maxTagCount={maxTagCount}
					suffixIcon={<IoIosArrowDown className={styles['suffixicon']} />}
					optionLabelProp={optionLabelProp}
					optionFilterProp={optionFilterProp}
					filterOption={filterOption}
					onClear={onClear}
					size={size}
					popupMatchSelectWidth={popupMatchSelectWidth}
					autoFocus={autoFocus}
					defaultOpen={defaultOpen}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					onInputKeyDown={onInputKeyDown}
					onKeyUp={onKeyUp}
					status={status}
				/>
			) : (
				<div className={styles['wrapper']}>
					{title && <p className={styles['title']}>{title}</p>}
					<AntdSelect
						style={style}
						options={options}
						tagRender={tagRender}
						placeholder={placeholder}
						className={styles['select']}
						popupClassName={styles['menu']}
						onChange={onChange}
						defaultValue={defaultValue}
						mode={multiple ? 'multiple' : ''}
						showSearch={showSearch}
						disabled={disabled}
						allowClear={allowClear}
						maxTagCount={maxTagCount}
						onSearch={onSearch}
						value={value}
						loading={loading}
						suffixIcon={<IoIosArrowDown className={styles['suffixicon']} />}
						optionLabelProp={optionLabelProp}
						optionFilterProp={optionFilterProp}
						filterOption={filterOption}
						onClear={onClear}
						size={size}
						popupMatchSelectWidth={popupMatchSelectWidth}
						autoFocus={autoFocus}
						defaultOpen={defaultOpen}
						onBlur={onBlur}
						onKeyDown={onKeyDown}
						onInputKeyDown={onInputKeyDown}
						onKeyUp={onKeyUp}
						status={status}
					/>
				</div>
			)}
		</>
	);
};

export default Select;
