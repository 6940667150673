import { Radio as AntdRadio } from 'antd';

import styles from './radio.module.scss';

export const RadioTwo = ({ value, children, style, disabled, onChange }) => {
	return (
		<AntdRadio
			value={value}
			className={styles['radio-two']}
			style={style}
			disabled={disabled}
			onChange={onChange}
		>
			{children}
		</AntdRadio>
	);
};
