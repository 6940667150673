import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';

import { MailOutlined } from '@ant-design/icons';
import { TbLayoutBottombarExpand, TbLayoutNavbarExpand } from 'react-icons/tb';

import EdenContext from '../../../context/edenContext';
import BreadCrumb from '../../../components/breadcrumb';
import InnerHeader from '../../../components/innerHeader';
import Cascader from '../../../components/cascader';
import ToolTip from '../../../components/tooltip';
import AccountSummaryCard from '../../../components/accountSummaryCard';
import { COUNTRY_CODES, customerContactColumnsV2 } from '../../../columns';
import AccountDetailsImage from '../../../images/accountDetails.svg';
import ConversationStatisticsImage from '../../../images/conversationStatistics.svg';
import IncomingOutgoingImage from '../../../images/incomingOutgoingEmails.svg';
import useFetch from '../../../hooks';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import NoData from '../../../components/nodata';
import Button from '../../../components/button';
import Select from '../../../components/select';

import styles from './cusContEngagementV2.module.scss';

const CustomerContEngagementV2 = () => {
	const {
		userType,
		userMail,
		userInfo,
		cascaderOptionEE,
		setCascaderOptionEE,
		converStatTabEE,
		setConverStatTabEE,
		incomOutEmailTabEE,
		setIncomeOutEmailTabEE,
		converStatTableEE,
		setConverStatTableEE,
		incomOutEmailTableEE,
		setIncomeOutEmailTableEE,
		dateRange,
		setDateRange,
	} = useContext(EdenContext);

	const [apiDesignation, setApiDesignation] = useState(userType);
	const gridRef = useRef(); // eslint-disable-next-line
	const [columnDefs, setColumnDefs] = useState(customerContactColumnsV2);
	const [rowData, setRowData] = useState(null); // eslint-disable-next-line
	const [accountDetailsTab, setAccountDetailTab] = useState(true);
	const [gridApi, setGridApi] = useState(null);
	const [gridColumnApi, setGridColumnApi] = useState(null);
	const [expandAll, setExpandAll] = useState(false);

	let userEmails;
	if (userType === 'admin') {
		userEmails = '';
	}
	if (userType === 'ceo') {
		const userEmailsArray = userInfo?.EmailFilters?.ViewAsAdmin;
		userEmails = userEmailsArray.join(';');
	}
	if (userType === 'sm') {
		const userEmailsArray = userInfo?.EmailFilters?.ViewAsSM;
		userEmails = userEmailsArray.join(';');
	}
	if (userType === 'sr') {
		// const userEmailsArray = userInfo?.EmailFilters?.ViewAsSR;
		userEmails = userMail;
	}

	const { isLoading, serverError, apiData } = useFetch(
		apiDesignation === 'admin'
			? `https://internal.supremecomponents.com/api/externalgateway.php?route=getEdenEE&wk=${dateRange}&email=${userEmails}`
			: `https://internal.supremecomponents.com/api/externalgateway.php?route=getEdenEE&wk=${dateRange}&email=${
					cascaderOptionEE ? cascaderOptionEE : userEmails
			  }`
	);

	const data = apiData;

	// Cascader options
	const filterAndMapSalesReps = (salesReps) => {
		return salesReps
			.filter((rep) => rep?.isActive)
			.map((rep) => ({
				value: rep?.SalesRepEmail,
				label: rep?.SalesRepName,
				designation: rep?.Designation,
				disabled: rep?.isActive === false,
				children: rep?.SalesTeam ? filterAndMapSalesReps(rep.SalesTeam) : [],
			}));
	};

	let cascaderOptions;
	if (userType === 'admin' || userType === 'ceo') {
		cascaderOptions = filterAndMapSalesReps(userInfo?.ViewAsAdmin || []);
	}

	if (userType === 'sm') {
		cascaderOptions = filterAndMapSalesReps(userInfo?.ViewAsSM || []);
		// cascaderOptions = userInfo?.ViewAsSM?.map((el) => ({
		// 	value: el?.SalesRepEmail,
		// 	label: el?.SalesRepName,
		// 	designation: el?.Designation,
		// 	disabled: el?.isActive === false,
		// 	children: el?.SalesTeam?.map((el) => ({
		// 		value: el?.SalesRepEmail,
		// 		label: el?.SalesRepName,
		// 		designation: el?.Designation,
		// 		disabled: el?.isActive === false,
		// 	})),
		// }));
	}

	// For cascader
	const onChange = (value, selectedOptions) => {
		const designation =
			selectedOptions[selectedOptions?.length - 1]?.designation?.toLowerCase();
		setApiDesignation(designation);
		if (designation === 'admin' || designation === 'ceo') {
			setCascaderOptionEE(userEmails);
		} else {
			let selectedUser = selectedOptions[
				selectedOptions?.length - 1
			]?.children?.map((el) => el.value);

			let mail =
				selectedOptions[selectedOptions?.length - 1].children?.length > 0
					? selectedUser.join(';')
					: value[value?.length - 1];

			setCascaderOptionEE(mail);
		}

		const salesRepname =
			selectedOptions[selectedOptions.length - 1].label.toLowerCase();
		const containSelf = salesRepname?.includes('self');

		if (containSelf === true) {
			setCascaderOptionEE(value[value?.length - 1]);
		}
	};

	// on clear cascader
	const clearCascader = () => {
		setCascaderOptionEE(userEmails);
	};

	// Table default value
	const defaultColDef = useMemo(
		() => ({
			enableRowGroup: true,
			enablePivot: true,
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);

	// Table auto group col def
	const autoGroupColumnDef = useMemo(
		() => ({
			headerName: 'Account',
			field: 'Account',
			cellRendererParams: {
				suppressCount: true,
			},
			valueGetter: (params) => {
				return params.data.Account;
			},
			pinned: 'left',
			lockPinned: true,
			headerToolTip: 'Customer account from the CRM.',
		}),
		[]
	);

	//Export to excel
	const exportToExcel = () => {
		const formatEmailEngagementColumns = ['FyBilled'];

		const emailEngagementExcelParams = {
			sheetName: 'Email Engagement',
			fileName: 'Email Engagement.xlsx',
			processCellCallback: function (p) {
				if (formatEmailEngagementColumns.includes(p.column.getColDef().field)) {
					return p.value ? `$ ${p.value?.toLocaleString('en-US')}` : `$ -`;
				}
				return p.value;
			},
		};
		gridApi.exportDataAsExcel(emailEngagementExcelParams);
	};

	//Excel sheet style
	const excelStyles = useMemo(
		() => [
			{
				id: 'header',
				font: {
					bold: true,
				},
			},
		],
		[]
	);

	// Table get data path
	const getDataPath = useMemo(() => {
		return (data) => {
			return data.hierachy;
		};
	}, []);

	// Table data function
	const flattenChildrenRecursively = (
		data,
		parent = null,
		childHierachy = null
	) => {
		let newData = [];

		if (data) {
			data.forEach((initialRow, parentIndex) => {
				let parentHierachy = [];
				initialRow.hierachy = parentHierachy;

				if (parent) {
					initialRow.parent = parent;
					parentHierachy = [...childHierachy];
					initialRow.hierachy = parentHierachy;
				}
				parentHierachy.push(parentIndex);

				newData.push(initialRow);

				if (initialRow.Contacts) {
					newData = [
						...newData,
						...flattenChildrenRecursively(
							initialRow.Contacts,
							initialRow,
							parentHierachy
						),
					];
				}
			});
		}
		return newData;
	};

	function onGridReady(params) {
		setGridApi(params.api);
		setGridColumnApi(params.columnApi);

		const dataWithFlags = data?.Data?.filter((d) => {
			return COUNTRY_CODES[d?.AcctCountry] === undefined
				? d
				: COUNTRY_CODES[d?.AcctCountry];
		});

		setRowData(flattenChildrenRecursively(dataWithFlags));
	}

	if (gridRef?.current) {
		const columnApi = gridRef?.current?.columnApi;
		const targetColumn = columnApi?.getColumn('AcctSalesRep');
		if (targetColumn) {
			if (userType === 'sr') {
				targetColumn?.setVisible(false);
				targetColumn?.setColDef({
					...targetColumn.getColDef(),
					suppressColumnsToolPanel: true,
				});
			} else {
				targetColumn?.setVisible(true);
				targetColumn?.setColDef({
					...targetColumn?.getColDef(),
					suppressColumnsToolPanel: false,
				});
			}
			columnApi?.setColumnVisible('AcctSalesRep', targetColumn?.isVisible());
		}
	}

	const converStat = () => {
		gridColumnApi.setColumnsVisible(
			[
				'CS_Total1wayInbounds',
				'CS_TotalIncomingEmails',
				'CS_Total1wayInboundsRFQ',
			],
			converStatTableEE
		);
		setConverStatTabEE(!converStatTabEE);
		setConverStatTableEE(!converStatTableEE);
		gridApi.sizeColumnsToFit();
	};

	const incomeOutgoingStat = () => {
		gridColumnApi.setColumnsVisible(
			[
				'LI_ReceivedNoDaysAgo',
				'LI_ExternalSender',
				'LI_NoDaysAgoToMe',
				'LI_NoDaysAgoToMyColleague',
				'LI_WhichColleague',
				'LO_LastSentOutNoDaysAgo',
				'LO_WhoSent',
			],
			incomOutEmailTableEE
		);
		setIncomeOutEmailTabEE(!incomOutEmailTabEE);
		setIncomeOutEmailTableEE(!incomOutEmailTableEE);
		gridApi.sizeColumnsToFit();
	};

	// Engagement numbers info
	const engageNumberInfo = [
		{
			title: '#of Accounts',
			value: data?.NoOfAccounts,
			toolTipInfo: () => {
				if (userType === 'admin' || userType === 'ceo') {
					return 'Total no. of accounts that are in the CRM';
				}
				if (userType === 'sm') {
					return 'No. of accounts that are assigned to you and your team in the CRM';
				}
				if (userType === 'sr') {
					return 'No. of accounts that are assigned to you in the CRM';
				}
			},
		},
		{
			title: '#of 1-way inbound',
			value: data?.NoOf1wayInbound,
			toolTipInfo: 'Number of incoming customer emails without a response.',
		},
		{
			title: 'Last Incoming Customer Email (Days)',
			value: data?.LastIncCustEmailDays,
			toolTipInfo:
				'No. of days since you received last email from your customer.',
		},

		{
			title: 'Last Outgoing Customer Email (Days)',
			value: data?.LastOutCustEmailDays,
			toolTipInfo: 'No. of days since you send email to your customer',
		},
	];

	const context = useMemo(() => {
		return {
			COUNTRY_CODES: COUNTRY_CODES,
		};
	}, []);

	useEffect(() => {
		if (gridRef.current) {
			if (expandAll) {
				gridRef.current?.api?.expandAll();
			} else {
				gridRef.current?.api?.collapseAll();
			}
		}
	}, [expandAll]);

	const toggleExpandCollapse = () => {
		setExpandAll((prevState) => !prevState);
	};

	return (
		<>
			<div className={styles['customer-contact-engagement']}>
				<BreadCrumb
					route1="Email Engagement"
					route2="Email Engagement"
					icon={<MailOutlined style={{ paddingRight: '5px' }} />}
				/>
				<InnerHeader exportToExcel={exportToExcel} lastReport={data?.Updated}>
					<div className={styles['reports']}>
						<p className={styles['last-report']}>
							Data Retention period:{' '}
							{data
								? moment(data?.ReportTo).diff(moment(data?.ReportFrom), 'days')
								: '--'}{' '}
							days
						</p>
						<p className={styles['last-report']}>
							Data maintained since:{' '}
							{data ? moment(data?.ReportFrom).format('MMM-DD-YYYY') : '--'}
						</p>
					</div>
				</InnerHeader>
				<div className={styles['wrapper']}>
					<div className={styles['dropdown-wrapper']}>
						{userType !== 'sr' && (
							<Cascader
								placeholder="SR, SM"
								onChange={onChange}
								options={cascaderOptions}
								onClear={clearCascader}
								disabled={isLoading}
								changeOnSelect={true}
								title="Drill down by:"
							/>
						)}
						<Select
							title="Date Range:"
							disabled={isLoading}
							style={{
								width: 100,
							}}
							defaultValue={2}
							placeholder="Date Range"
							options={[
								{ value: 1, label: '1 Week' },
								{ value: 2, label: '2 Weeks' },
								{ value: 3, label: '3 Weeks' },
								{ value: 4, label: '4 Weeks' },
								{ value: '', label: '3 Months' },
							]}
							onChange={(value) => setDateRange(value)}
						/>
					</div>
					{!isLoading && !serverError && data?.Data?.length > 0 && (
						<>
							<AccountSummaryCard>
								<div className={styles['numbers-info']}>
									{engageNumberInfo.map((el, index) => (
										<div className={styles['info-wrapper']} key={index}>
											<div className={styles['value-tooltip']}>
												<ToolTip
													trigger="click"
													placement="bottom"
													title={el.toolTipInfo}
												>
													<p className={styles['title']}>{el.title}:</p>
												</ToolTip>
												<p
													className={`${styles['value']} ${styles['value-red']} `}
												>
													{el.value}
												</p>
											</div>
										</div>
									))}
								</div>
							</AccountSummaryCard>

							<div className={styles['tabs-wrapper']}>
								<ToolTip
									title="Show account-level information"
									mouseEnterDelay={2}
								>
									<div
										className={`${styles['tabs']} ${
											accountDetailsTab ? styles['tab-active'] : ''
										}`}
									>
										<img
											src={AccountDetailsImage}
											className={styles['tab-icon']}
											alt=""
										/>
										<p className={styles['tab-text']}>Account Details</p>
									</div>
								</ToolTip>
								<ToolTip
									title="Click this tab to toggle display/hide your conversation statistics"
									mouseEnterDelay={2}
								>
									<div
										className={`${styles['tabs']} ${
											converStatTabEE ? styles['tab-active'] : ''
										}`}
										onClick={converStat}
									>
										<img
											src={ConversationStatisticsImage}
											className={styles['tab-icon']}
											alt=""
										/>
										<p className={styles['tab-text']}>
											Conversation Statistics
										</p>
									</div>
								</ToolTip>

								<ToolTip
									title="Click this tab to toggle display/hide your incoming and outgoing email info"
									mouseEnterDelay={2}
								>
									<div
										className={`${styles['tabs']} ${
											incomOutEmailTabEE ? styles['tab-active'] : ''
										}`}
										onClick={incomeOutgoingStat}
									>
										<img
											src={IncomingOutgoingImage}
											className={styles['tab-icon']}
											alt=""
										/>
										<p className={styles['tab-text']}>
											Incoming/Outgoing Emails
										</p>
									</div>
								</ToolTip>
							</div>
							<div className={styles['button-table-wrapper']}>
								<ToolTip
									title={expandAll ? 'Collapse all rows' : 'Expand all rows'}
									placement="right"
								>
									<div className={styles['button']}>
										<Button
											size="small"
											type="xs"
											onClick={toggleExpandCollapse}
										>
											{expandAll ? (
												<TbLayoutNavbarExpand style={{ fontSize: '16px' }} />
											) : (
												<TbLayoutBottombarExpand style={{ fontSize: '16px' }} />
											)}
										</Button>
									</div>
								</ToolTip>
								<div className={`ag-theme-custom ${styles['table-wrapper']}`}>
									<AgGridReact
										ref={gridRef}
										rowData={rowData}
										onGridReady={onGridReady}
										columnDefs={columnDefs}
										context={context}
										defaultColDef={defaultColDef}
										animateRows={true}
										pagination={true}
										floatingFilter={true}
										autoGroupColumnDef={autoGroupColumnDef}
										treeData={true}
										getDataPath={getDataPath}
										enableRangeSelection={true}
										tooltipShowDelay={1500}
										groupHeaderHeight={30}
										excelStyles={excelStyles}
										paginationPageSize={50}
										paginationPageSizeSelector={false}
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			{isLoading && (
				<div className={styles['loading-wrapper']}>
					<LoadingMessage
						message="We are pulling data from the Outlook exchange server and compiling key
					email engagement metrics to help you make smart business decisions. Hang
					on!"
					/>
				</div>
			)}
			{serverError && !isLoading && (
				<div className={styles['error-wrapper']}>
					<ErrorMessage message="Error" />
				</div>
			)}
			{!isLoading && !serverError && data?.Data?.length === 0 && (
				<div className={styles['no-data-wrapper']}>
					<NoData message="Oops! Seems like none of your assigned accounts from the CRM have a transaction. What could be worse? You have not been assigned any accounts at all." />
				</div>
			)}
		</>
	);
};

export default CustomerContEngagementV2;
