import { InfoCircleOutlined } from '@ant-design/icons';

import ToolTip from '../tooltip';

import styles from './section.module.scss';

const SectionInfo = ({ title, info, tooltipTitle, removeshortcutkeys }) => {
	return (
		<div className={styles['wrapper']}>
			<div className={styles['left-side']}>
				<div className={styles['title-info']}>
					<h1 className={styles['title']}>{title}</h1>
					{tooltipTitle && (
						<ToolTip
							overlayInnerStyle={{ width: '500px' }}
							title={tooltipTitle}
							placement="right"
						>
							<InfoCircleOutlined className={styles['icon']} />
						</ToolTip>
					)}
				</div>
				<p className={styles['info']}>{info}</p>
			</div>
			{removeshortcutkeys ? null : (
				<div className={styles['right-side']}>
					<p className={styles['title']}>
						To efficiently navigate any tables in Eden, use the following
						shortcut keys:
					</p>
					<ul className={styles['ul']}>
						<li className={styles['li']}>
							<b>Shift + Mouse Wheel</b>: Scroll horizontally
						</li>
						<li className={styles['li']}>
							<b>Shift + Arrow Down/Up</b>: Scroll dropdowns within cells
						</li>
						<li className={styles['li']}>
							<b>Enter (with cell focused)</b>: Activated cell for entry
						</li>
						<li className={styles['li']}>
							<b>Tab</b>: Move to next cell
						</li>
						<li className={styles['li']}>
							<b>Shift + Tab</b>: Move to previous cell
						</li>
						<li className={styles['li']}>
							<b>Ctrl + Shift + L</b>: Remove all filters
						</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export default SectionInfo;
