import { useContext, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Modal, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { TfiHeadphoneAlt } from 'react-icons/tfi';

import EdenContext from '../../context/edenContext';
import { avatarBgColor } from '../../constant';
import WhiteLogo from '../../images/whiteLogo.svg';
import Select from '../select';

import styles from './header.module.scss';

const Header = () => {
	const { accounts } = useMsal();
	const userData = accounts?.[0];
	const navigate = useNavigate();
	const {
		me,
		userMail,
		userTypes,
		setUserType,
		userType,
		logout,
		setCascaderDesignation,
		setCascaderOption,
		setCascaderDesignationMpn,
		setCascaderOptionMpn,
		setCascaderDesignationCCE,
		setCascaderOptionCCE,
		setConverStatTabEE,
		setIncomeOutEmailTabEE,
		setCascaderOptionEE,
		setConverStatTableEE,
		setIncomeOutEmailTableEE,
	} = useContext(EdenContext);

	const [userDataModal, setUserDataModal] = useState(false);
	const [userColor, setUserColor] = useState(userType);

	const handleUserChange = (user) => {
		setCascaderDesignation('');
		setCascaderOption(me?.mail);
		setCascaderDesignationMpn('');
		setCascaderOptionMpn(me?.mail);
		setCascaderDesignationCCE('');
		setCascaderOptionCCE(me?.mail);
		setUserColor(user);
		setUserType(user);
		setConverStatTabEE(false);
		setIncomeOutEmailTabEE(false);
		setConverStatTableEE(true);
		setIncomeOutEmailTableEE(true);
		setCascaderOptionEE('');
	};

	const helpTitle = (
		<p className={styles['help-title']}>Need help? Raise a ticket.</p>
	);

	return (
		<div
			className={`${styles['header-wrapper']}
			 ${
					userType === 'admin'
						? styles['header-admin']
						: userType === 'ceo'
						? styles['header-ceo']
						: userType === 'sm'
						? styles['header-sm']
						: userType === 'sr'
						? styles['header-sr']
						: userType === 'ss'
						? styles['header-ss']
						: ''
				}
			 `}
		>
			<div className={styles['logo-wrapper']}>
				<img src={WhiteLogo} alt="logo" className={styles['logo']} />
				<p className={styles['name']}>EDEN</p>
				<p className={styles['hello']}>
					Hello, {userData?.name || me?.givenName || me?.displayName}
				</p>
			</div>
			{/* <div className={styles['dropdown-wrapper']}></div> */}
			<div className={styles['right-side-wrapper']}>
				<div className={styles['user-type']}>
					<div className={styles['view-type']}>
						<EyeOutlined />{' '}
						<p className={styles['user-type']}>{userType?.toUpperCase()}</p>
					</div>
					{userTypes?.map((el) => (
						<p
							className={`${styles['user']} ${
								userColor === el?.type
									? styles['user-active']
									: styles[`user-${el?.color}`]
							}`}
							key={el?.id}
							onClick={() => handleUserChange(el?.type)}
						>
							{el?.type.toUpperCase()}
						</p>
					))}
				</div>
				{/* <Tooltip
					className={styles['help']}
					placement="topRight"
					title={helpTitle}
				>
					<QuestionCircleOutlined />
				</Tooltip> */}
				<Tooltip
					className={styles['help']}
					placement="topRight"
					title={helpTitle}
				>
					<TfiHeadphoneAlt
						style={{ fontSize: '18px' }}
						onClick={() => navigate('/help-desk')}
					/>
				</Tooltip>
				<div className={styles['user-type-dropdown']}>
					<Select
						options={userTypes?.map((el) => ({
							label: el?.type?.toUpperCase(),
							value: el?.type,
						}))}
						size="small"
						style={{ width: '100px' }}
						onChange={(value) => handleUserChange(value)}
						defaultValue={userType}
					/>
				</div>
				<Avatar
					className={styles['avatar']}
					onClick={() => setUserDataModal(true)}
					style={{
						backgroundColor:
							avatarBgColor[
								userData?.name?.charAt(0) ||
									me?.givenName?.charAt(0) ||
									me?.displayName?.charAt(0)
							] || avatarBgColor.default,
					}}
				>
					{userData?.name?.charAt(0) ||
						me?.givenName?.charAt(0) ||
						me?.displayName?.charAt(0)}
				</Avatar>
			</div>
			<Modal
				style={{
					position: 'absolute',
					top: 50,
					right: '5px',
				}}
				open={userDataModal}
				onCancel={() => setUserDataModal(false)}
				footer={null}
				closable={false}
				width={360}
				wrapClassName={styles['userdata-wrapper']}
			>
				<div className={styles['userdata']}>
					<div className={styles['header']}>
						<p className={styles['eden']}>eden</p>
						<button className={styles['sign-out']} onClick={logout}>
							Sign Out
						</button>
					</div>
					<div className={styles['user-info']}>
						<p className={styles['big-avatar']}>
							{userData?.name?.charAt(0) ||
								me?.givenName?.charAt(0) ||
								me?.displayName?.charAt(0)}
							{me?.surname?.charAt(0)}
						</p>
						<div className={styles['info']}>
							<p className={styles['name']}>
								{userData?.name || me?.givenName || me?.displayName}&nbsp;
								{me?.surname}
							</p>
							<p className={styles['email']}>
								{userData?.username || me?.mail}
							</p>
							<Link
								to={`https://myaccount.microsoft.com/?ref=MeControl&amp;login_hint=${userMail}`}
								target="_blank"
								className={styles['view-account']}
							>
								View Account
							</Link>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default Header;
