import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React from 'react';
import ToolTip from '../tooltip';

import styles from './tableDataCountInput.module.scss';

const TableDataCountInput = ({ transactions, allowClear, onChange }) => {
	return (
		<div className={styles['wrapper']}>
			<div className={styles['count-input']}>
				<p className={styles['count']}>{transactions}</p>
				<ToolTip title="Quickly find anything in the report" placement="right">
					<Input
						placeholder="Quick Find"
						prefix={<SearchOutlined />}
						className={styles['quick-find']}
						allowClear={allowClear}
						onChange={onChange}
					/>
				</ToolTip>
			</div>
		</div>
	);
};

export default TableDataCountInput;
