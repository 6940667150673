import React from 'react';
import 'ag-grid-enterprise';
import { BrowserRouter } from 'react-router-dom';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-balham.css';
import './ag-grid-theme-builder.css';
import ReactDOM from 'react-dom/client';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { msalConfig } from './authConfig';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import './sass/base.scss';

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
	!msalInstance.getActiveAccount() &&
	msalInstance.getAllAccounts().length > 0
) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
		const account = event.payload.account;
		msalInstance.setActiveAccount(account);
		localStorage.setItem('account', JSON.stringify(account));
	}
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<MsalProvider instance={msalInstance}>
				<App />
			</MsalProvider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
