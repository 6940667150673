import axios from 'axios';
import { createContext, useCallback, useContext, useState } from 'react';

import EdenContext from '../edenContext';
import { message } from 'antd';

const LinkedCleanserContext = createContext();

export const LinkedCleanserProvider = ({ children }) => {
	const { userMail } = useContext(EdenContext);

	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const getData = () => {
		setIsLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_li_cleanser&email=${userMail}`
			)
			.then((response) => {
				setData(response.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const filterUniqueIdsAndRecent = (arr) => {
		const uniqueIdsMap = new Map();

		arr?.forEach((obj) => {
			const currentId = obj.id;
			if (currentId && !uniqueIdsMap.has(currentId)) {
				uniqueIdsMap.set(currentId, obj);
			}
		});

		return Array.from(uniqueIdsMap.values());
	};

	const onCellValueChanged = useCallback((e) => {
		const cellValue = e.data;

		axios({
			method: 'post',
			url: cellValue?.id
				? `https://internal.supremecomponents.com/api/externalgateway.php?route=put_li_cleanser&id=${cellValue?.id}&email=${userMail}&jobTitle=${cellValue?.jobtitle}`
				: `https://internal.supremecomponents.com/api/externalgateway.php?route=set_li_cleanser&email=${userMail}&jobTitle=${cellValue?.jobtitle}`,
		}).then((response) => {
			axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=get_li_cleanser_by_id&id=${
						response.data || cellValue?.id
					}&email=${userMail}`
				)
				.then((response) => {
					setData((prev) =>
						filterUniqueIdsAndRecent([...prev, response?.data?.[0]])
					);
				});
			message.success(
				cellValue?.id
					? 'Job Title updated successfully'
					: 'Job Title added successfully'
			);
		});

		//eslint-disable-next-line
	}, []);

	return (
		<LinkedCleanserContext.Provider
			value={{
				getData,
				data,
				setData,
				isLoading,
				error,
				onCellValueChanged,
				filterUniqueIdsAndRecent,
			}}
		>
			{children}
		</LinkedCleanserContext.Provider>
	);
};

export default LinkedCleanserContext;
