import { useContext, useState } from 'react';
import axios from 'axios';
import { Col, Divider, Form, Row, message } from 'antd';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { MdModeEdit } from 'react-icons/md';
import { QuestionCircleOutlined } from '@ant-design/icons';

import Popconfirm from '../../../../components/popConfirm';
import ToolTip from '../../../../components/tooltip';
import Modal from '../../../../components/modal';
import Select from '../../../../components/select';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import EdenContext from '../../../../context/edenContext';

import styles from '../chatGptKeywords.module.scss';

const KeywordActions = (p) => {
	const { chatGptAllkeywords, chatGptKeywords } = useContext(EdenContext);
	const [form] = Form.useForm();
	const [deleteKeywordPopup, setDeleteKeywordPopup] = useState(false);
	const [deleteKeywordLoading, setDeleteKeywordLoading] = useState(false);
	const [editKeywordModal, setEditKeywordModal] = useState(false);

	const openDeletePopUp = () => {
		setDeleteKeywordPopup(true);
	};

	const deleteKeyword = () => {
		setDeleteKeywordLoading(true);
		const formdata = new FormData();
		formdata.append('id', p?.data?.ID);
		axios
			.post(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=delete_chatgpt_keyword',
				formdata
			)
			.then(() => {
				setDeleteKeywordPopup(false);
				message.open({
					type: 'success',
					content: 'Keyword deleted successfully',
				});
				chatGptAllkeywords();
			})
			.catch(() => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				setDeleteKeywordPopup(false);
			})
			.finally(() => {
				setDeleteKeywordLoading(false);
			});
	};

	const closeDeleteKeywordPopup = () => {
		setDeleteKeywordPopup(false);
	};

	const closeRemoveKeywordModal = () => {
		setEditKeywordModal(false);
	};

	const addEditKeywords = (values) => {
		const formdata = new FormData();
		formdata.append('is_active', '1');
		formdata.append('type', values.TYPE);
		formdata.append('keyword', values.KEYWORD);
		formdata.append('id', p?.data?.ID);

		axios
			.post(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=edit_chatgpt_keyword',
				formdata
			)
			.then(() => {
				message.open({
					type: 'success',
					content: 'Keyword updated successfully',
				});
				setEditKeywordModal(false);
				chatGptAllkeywords();
			})
			.catch(() => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				setEditKeywordModal(false);
			});
	};

	const keywordType = [
		{
			value: 'MPN',
			label: 'MPN',
		},
		{
			value: 'Brand',
			label: 'Brand',
		},
		{
			value: 'Qty',
			label: 'Qty',
		},
	];

	const openEditKeywordModal = () => {
		setEditKeywordModal(true);
	};

	return (
		<div style={{ display: 'flex', gap: '10px' }}>
			<Popconfirm
				placement="bottom"
				okButtonColor="red"
				open={deleteKeywordPopup}
				title="Delete"
				description="Are you sure you want to delete this keyword?"
				okText="Yes"
				cancelText="Cancel"
				onConfirm={deleteKeyword}
				onCancel={closeDeleteKeywordPopup}
				okButtonLoading={deleteKeywordLoading}
				icon={
					<QuestionCircleOutlined
						style={{
							color: 'red',
						}}
					/>
				}
			>
				<ToolTip title="Delete">
					<RiDeleteBin6Fill
						style={{ fontSize: '18px', cursor: 'pointer', color: '#C00000' }}
						onClick={openDeletePopUp}
					/>
				</ToolTip>
			</Popconfirm>
			<ToolTip title="Edit">
				<MdModeEdit
					style={{ fontSize: '18px', cursor: 'pointer' }}
					onClick={openEditKeywordModal}
				/>
			</ToolTip>
			<Modal
				title={
					<>
						<p>Edit Keywords</p> <Divider style={{ margin: '5px 0' }} />
					</>
				}
				open={editKeywordModal}
				onCancel={closeRemoveKeywordModal}
				destroyOnClose
			>
				<Form
					name="add-chatgptkeywords"
					form={form}
					onFinish={addEditKeywords}
					validateTrigger={['onBlur', 'onChange']}
					labelCol={{ span: 24 }}
					requiredMark={false}
					initialValues={p?.data}
				>
					<Row gutter={16}>
						<Col sm={24} md={12}>
							<Form.Item
								label="Keyword Type"
								name="TYPE"
								rules={[
									{
										required: true,
										message: 'Please select Keyword Type!',
									},
								]}
							>
								<Select
									type="bordered"
									placeholder="Select Keyword Type"
									options={keywordType}
									allowClear={true}
								/>
							</Form.Item>
						</Col>
						<Col sm={24} md={12}>
							<Form.Item
								label="Keyword"
								name="KEYWORD"
								rules={[
									{
										required: true,
										message: 'Please enter Keyword!',
									},
									() => ({
										validator(_, value) {
											const existingKeywords = chatGptKeywords.map(
												(item) => item.KEYWORD
											);
											if (
												p?.data?.KEYWORD === value ||
												existingKeywords.includes(value)
											) {
												return Promise.reject(
													new Error('This keyword is already present!')
												);
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<Input placeholder="Please enter Keyword" />
							</Form.Item>
						</Col>

						<Col sm={24}>
							<Form.Item style={{ marginBottom: '0px' }}>
								<div className={styles['actions']}>
									<Button type="bordered" onClick={closeRemoveKeywordModal}>
										Cancel
									</Button>
									<Button htmlType="submit">Update</Button>
								</div>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</div>
	);
};

export default KeywordActions;
