import { useContext, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { Form, message } from 'antd';
import { IoMdAddCircle } from 'react-icons/io';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';

import EdenContext from '../../../context/edenContext';
import AccessDenied from '../../../components/accessDenied';
import BreadCrumb from '../../../components/breadcrumb';
import Button from '../../../components/button';
import { sourcingAssignmentsColumns } from '../../../columns';
import AddSSForm from './AddSSForm';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';
import SectionInfo from '../../../components/sectionInfo';

import styles from './sourcingAssignments.module.scss';

const SourcingAssignments = () => {
	const {
		userMail,
		userType,
		sourcingAssList,
		sourcingAssListLoading,
		sourcingAssListError,
		sourcingAssignment,
	} = useContext(EdenContext);
	const [form] = Form.useForm();
	const [addSSModal, setAddSSModal] = useState(false);
	const [ssvalues, setSSValues] = useState({});
	const [srvalues, setSRValues] = useState({});

	useEffect(() => {
		sourcingAssignment(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openAddSSModal = () => {
		setAddSSModal(true);
	};

	const closeAddSSModal = () => {
		form.resetFields();
		setAddSSModal(false);
		setSRValues({});
		setSSValues({});
	};

	const addSS = (values) => {
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=addacsourcingreq&loggedinuseremail=${userMail}&salesrepcrmid=${srvalues.id}&salesrepname=${values.sales_rep}&salesrepemail=${srvalues.email}&sscrmid=${ssvalues.id}&ssemail=${ssvalues.value}`
			)
			.then(() => {
				message.open({
					type: 'success',
					content: 'Sourcing Specialist Assigned successfully',
				});
				form.resetFields();
				setAddSSModal(false);
				sourcingAssignment();
			})
			.catch((err) => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				form.resetFields();
				setAddSSModal(false);
			});
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	);

	return (
		<>
			{userType === 'admin' ? (
				<div className={styles['wrapper']}>
					<BreadCrumb
						route1="Admin"
						route2="Sourcing Assignments"
						icon={<MdOutlineAdminPanelSettings style={{ fontSize: '20px' }} />}
					/>
					<SectionInfo
						removeshortcutkeys
						title="Sourcing Assignments"
						info="Please assign any Sourcing Specialists to Sales Reps in this section. This SS will be the default person used in the RFQ queue"
					/>
					<div className={styles['sourcing-assignments']}>
						<div className={styles['button-last-updated']}>
							<Button onClick={openAddSSModal}>
								<IoMdAddCircle style={{ fontSize: '16px' }} />
								Add Assignment
							</Button>
						</div>

						{sourcingAssListLoading && (
							<div className={styles['loading-wrapper']}>
								<LoadingMessage message="Loading..." />
							</div>
						)}

						{sourcingAssListError && !sourcingAssListLoading && (
							<div className={styles['error-wrapper']}>
								<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
							</div>
						)}

						{!isNotEmptyArray(sourcingAssList) &&
							!sourcingAssListError &&
							!sourcingAssListLoading && (
								<div className={styles['no-data-wrapper']}>
									<NoData message="Its all empty here." />
								</div>
							)}

						{!sourcingAssListLoading &&
							!sourcingAssListError &&
							isNotEmptyArray(sourcingAssList) && (
								<div className={styles['table-wrapper']}>
									<div className={`ag-theme-custom ${styles['table']} `}>
										<AgGridReact
											rowData={sourcingAssList}
											columnDefs={sourcingAssignmentsColumns}
											defaultColDef={defaultColDef}
											animateRows={true}
											pagination={true}
											paginationPageSizeSelector={false}
										/>
									</div>
								</div>
							)}
					</div>
					{addSSModal && (
						<AddSSForm
							open={addSSModal}
							onCancel={closeAddSSModal}
							form={form}
							onFinish={addSS}
							setSSValues={setSSValues}
							setSRValues={setSRValues}
							srvalues={srvalues}
						/>
					)}
				</div>
			) : (
				<AccessDenied message="You are not an Admin" />
			)}
		</>
	);
};

export default SourcingAssignments;
