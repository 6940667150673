import { useCallback, useContext, useMemo, useState } from 'react';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import { Avatar, Divider, message } from 'antd';
import { HiOutlineSearch } from 'react-icons/hi';
import { MdEdit, MdMail } from 'react-icons/md';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Base64 } from 'js-base64';

import EdenContext from '../../../../../context/edenContext';
import Modal from '../../../../../components/modal';
import NotificationBar from '../../../../../components/notificationBar';
import Input from '../../../../../components/input';
import Button from '../../../../../components/button';
import LoadingMessage from '../../../../../components/loaderMessage';
import ErrorMessage from '../../../../../components/errorMessage';
import {
	filterUniqueIdsAndRecent,
	isNotEmptyArray,
} from '../../../../../utils';
import NoData from '../../../../../components/nodata';
import ToolTip from '../../../../../components/tooltip';
import Drawer from '../../../../../components/drawer';
import { avatarBgColor } from '../../../../../constant';

import styles from './addCustomer.module.scss';

const dateFilterParams = {
	filters: [
		{
			filter: 'agDateColumnFilter',
			filterParams: {
				comparator: (a, b) => {
					return getDate(a).getTime() - getDate(b).getTime();
				},
			},
		},
	],
};

const getDate = (value) => {
	const date = moment.utc(value).tz('Asia/Singapore').format('DD/MM/YYYY');
	var dateParts = date.split('/');
	return new Date(
		Number(dateParts[2]),
		Number(dateParts[1]) - 1,
		Number(dateParts[0])
	);
};

const AddCustomer = (p) => {
	const {
		userType,
		userMail,
		setMpnCostBookList,
		mpnCostBookList,
		costBookType,
		MCBlastUserActiviry,
		messageURl,
	} = useContext(EdenContext);

	const [modalOpen, setModalOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [rowSelectionData, setRowSelectionData] = useState(null);
	const [customerProspectData, setCustomerProspectData] = useState([]);
	const [customerProspectLoading, setCustomerProspectLoading] = useState(false);
	const [customerProspectError, setCustomerProspectError] = useState(null);
	const [paramsData, setParamsData] = useState(null);
	const [addingMail, setAddingMail] = useState(false);
	const [loading, setLoading] = useState(false);
	const [drawerMail, setDrawerMail] = useState(false);
	const [drawerMailData, setDrawerMailData] = useState(null);
	const [drawerMailLoading, setDrawerMailLoading] = useState(false);
	const [cellData, setCellData] = useState(null);

	const closeDrawer = () => {
		setDrawerMail(!drawerMail);
		setDrawerMailData(null);
		setCellData(null);
	};

	const openDrawer = (cid) => {
		setDrawerMail(true);
		setDrawerMailLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_mail_html&cid=${cid}`
			)
			.then((response) => {
				setDrawerMailData(response.data);
			})
			.finally(() => {
				setDrawerMailLoading(false);
			});
	};

	const [customerProespectColumns] = useState([
		{
			checkboxSelection: true,
			field: 'subject',
			headerName: 'Subject Line',
		},
		{
			field: 'sciCustomer',
			headerName: 'Customer',
		},
		{
			field: 'contactName',
			headerName: 'Contact Name',
		},
		{
			field: 'from',
			headerName: 'From',
		},
		{
			field: 'recipients',
			headerName: 'To',
		},
		{
			field: 'sciSalesRep',
			headerName: 'Sales Rep',
		},
		{
			field: 'receivedDateTime',
			headerName: 'Received Date',
			sort: 'desc',
			cellRenderer: (p) => (
				<>
					{p.value
						? moment
								.utc(p.value)
								.tz('Asia/Singapore')
								.format('MMM-DD-YYYY hh:mm A')
						: '--'}
				</>
			),
			filterParams: dateFilterParams,
			minWidth: 190,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},
		{
			field: 'webLink',
			headerName: 'Email',
			cellRenderer: (p) => {
				const trimmedString = p?.value?.replace(/=+$/, '');
				return (
					<>
						{p.value ? (
							<ToolTip title="Preview mail">
								<MdMail
									onClick={(e) => {
										if (e.ctrlKey && e.button === 0) {
											window.open(
												`${messageURl?.url}${trimmedString}%3D.eml`,
												'_blank'
											);
										} else {
											openDrawer(p.value);
											setCellData(p);
										}
									}}
									style={{ fontSize: '16px', cursor: 'pointer' }}
								/>
							</ToolTip>
						) : (
							''
						)}
					</>
				);
			},
			cellStyle: { padding: '4px 0 0 25px' },
			maxWidth: 80,
		},
	]);

	const closeModal = () => {
		setModalOpen(false);
		setCustomerProspectData([]);
		setRowSelectionData([]);
		setParamsData(null);
		setSearchValue('');
	};

	const handleSearchValue = () => {
		if (searchValue?.length > 2) {
			setCustomerProspectLoading(true);
			axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=findmail&keyword=${encodeURIComponent(
						Base64.encode(searchValue)
					)}&acctype=5&pageno=1&rowsperpage=1000`
				)
				.then((response) => {
					setCustomerProspectData(response.data);
					setCustomerProspectError(null);
				})
				.catch((err) => {
					setCustomerProspectError(err);
					setCustomerProspectData(null);
					setCustomerProspectLoading(false);
				})
				.finally(() => {
					setCustomerProspectLoading(false);
				});
		}
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);

	const onSelectionChanged = (p) => {
		setRowSelectionData(p.api.getSelectedRows());
	};

	const addtoRfqQueue = () => {
		setLoading(true);
		const rowData = { ...rowSelectionData[0] };

		const formFields = {
			...paramsData,
			userEmail: userMail,
			cdMail: rowData?.webLink,
			cdCustomerSubject: rowData?.subject,
			cdCustomerId: rowData?.id,
			cdContactEmail: rowData?.from,
			cdContactName: rowData?.contactName,
			cdCustomerName: paramsData?.cdCustomerName
				? paramsData?.cdCustomerName
				: rowData?.sciCustomer,
			cdInquiryDateTime: rowData?.receivedDateTime,
			othOrderReceived:
				paramsData?.othOrderReceived === 'Yes' ||
				paramsData?.othOrderReceived === true
					? true
					: paramsData?.othOrderReceived === 'No' ||
					  paramsData?.othOrderReceived === false
					? false
					: null,
			othSrRead:
				userType === 'sr' || userType === 'sm' ? true : paramsData?.othSrRead,
		};

		setAddingMail(true);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=updatempncostbook`,
				JSON.stringify(formFields)
			)
			.then(() => {
				axios
					.get(
						`https://internal.supremecomponents.com/api/externalgateway.php?route=getmpncostbookbyid&id=${paramsData?.id}&isbuy=${costBookType}&userEmail=${userMail}&lastUserActivity=${MCBlastUserActiviry}`
					)
					.then((response) => {
						setMpnCostBookList(
							filterUniqueIdsAndRecent([
								...mpnCostBookList,
								response?.data?.[0],
							])
						);
						setParamsData(null);
						message.open({
							type: 'success',
							content: 'MPN updated successfully',
						});
						setLoading(false);
						setTimeout(() => {
							const rowIndex = p.rowIndex;
							const colKey = p.column.colId;
							p?.api?.setFocusedCell(rowIndex, colKey);
							const focusedCell = document.querySelector(
								'.ag-row-focus .ag-cell-focus'
							);
							if (focusedCell) {
								focusedCell.setAttribute('tabindex', '0');
							}
						}, 100);
					})
					.catch((err) => {
						message.open({
							type: 'error',
							content: 'Something went wrong please try again later!',
						});
					});
			})
			.then(() => {
				closeModal();
				setAddingMail(false);
			})
			.catch((err) => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				setAddingMail(false);
			});
	};

	const openModal = useCallback(() => {
		setModalOpen(true);
		setParamsData(p?.data);
	}, [p?.data]);

	return (
		<>
			{loading && <LoadingOutlined />}
			{!loading && p?.data?.cdCustomerSubject ? (
				<div className={styles['data-wrapper']}>
					<MdEdit
						onClick={openModal}
						className={styles['edit-icon']}
						onKeyDown={(e) => e.key === 'Enter' && openModal()}
						tabIndex={0}
					/>
					<ToolTip placement="bottomLeft" title={p?.data?.cdCustomerSubject}>
						<span className={styles['data']}>{p?.data?.cdCustomerSubject}</span>
					</ToolTip>
				</div>
			) : (
				<p
					className={styles['blue-underline']}
					onClick={p?.data?.srId && openModal}
					onKeyDown={(e) => e.key === 'Enter' && openModal()}
					tabIndex={0}
				>
					Insert
				</p>
			)}

			<Modal
				open={modalOpen}
				title={
					<>
						Add Customer RFQ Email to Cost Book{' '}
						<Divider style={{ margin: '10px 0' }} />
					</>
				}
				onCancel={closeModal}
				width={830}
				destroyOnClose
			>
				{searchValue?.length < 3 && (
					<NotificationBar
						redBar
						message="A minimum of 3 characters for MPN are required to view results"
					/>
				)}

				<NotificationBar
					wraningBar
					message="Note: Eden can only retrieve emails from the past 10 days."
				/>

				<div className={styles['input-searchButton']}>
					<Input
						placeholder="Search for Subject Line or Sender's Email Address"
						onChange={(e) => setSearchValue(e.target.value)}
						onPressEnter={handleSearchValue}
						disabled={customerProspectLoading}
					/>
					<Button
						onClick={handleSearchValue}
						disabled={customerProspectLoading || searchValue?.length < 3}
					>
						Search <HiOutlineSearch className={styles['search-icon']} />
					</Button>
				</div>

				{customerProspectLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage height={60} width={60} message="Loading..." />
					</div>
				)}

				{customerProspectError && !customerProspectLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage
							style={{ width: '200px' }}
							messageStyle={{ fontSize: '14px' }}
							message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed."
						/>
					</div>
				)}

				{!isNotEmptyArray(customerProspectData) &&
					!customerProspectError &&
					!customerProspectLoading && (
						<div className={styles['no-data-wrapper']}>
							<NoData
								style={{ width: '200px' }}
								messageStyle={{ fontSize: '14px' }}
								message="Its all empty here. Search for Customer."
							/>
						</div>
					)}

				{!customerProspectLoading &&
					!customerProspectError &&
					isNotEmptyArray(customerProspectData) && (
						<div className={`ag-theme-custom ${styles['table-wrapper']}`}>
							<AgGridReact
								columnDefs={customerProespectColumns}
								rowData={customerProspectData}
								defaultColDef={defaultColDef}
								rowSelection={'single'}
								onSelectionChanged={onSelectionChanged}
								animateRows={true}
								pagination={true}
								paginationPageSize={10}
								paginationPageSizeSelector={false}
							/>
						</div>
					)}

				<div className={styles['actions']}>
					<Button type="bordered" onClick={closeModal}>
						Cancel
					</Button>
					<Button
						disabled={
							customerProspectLoading ||
							addingMail ||
							!isNotEmptyArray(customerProspectData) ||
							!rowSelectionData
						}
						loading={addingMail}
						onClick={addtoRfqQueue}
					>
						Add Customer to MPN Cost Book
					</Button>
				</div>
			</Modal>

			{drawerMail && (
				<Drawer
					title={
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<p className={styles['mail-subject']}>
								<ToolTip placement="bottomLeft" title={drawerMailData?.subject}>
									{drawerMailData?.subject}
								</ToolTip>
							</p>
							<Link
								style={{ textDecoration: 'none' }}
								target="_blank"
								to={`${messageURl?.url}${cellData?.value?.replace(
									/=+$/,
									''
								)}%3D.eml`}
							>
								<Button type="bordered">Download Mail</Button>
							</Link>
						</div>
					}
					placement="right"
					onClose={closeDrawer}
					open={drawerMail}
					size={'large'}
				>
					{drawerMailLoading && <LoadingOutlined />}
					{drawerMailData && (
						<>
							<div className={styles['mail-details-wrapper']}>
								<div className={styles['mail-user-info']}>
									<Avatar
										className={styles['avatar']}
										style={{
											backgroundColor:
												avatarBgColor[drawerMailData?.fromName?.charAt(0)] ||
												avatarBgColor.default,
										}}
									>
										{drawerMailData?.fromName?.charAt(0)?.toUpperCase()}
									</Avatar>
									<div className={styles['users-info']}>
										<p className={styles['sender-name']}>
											{drawerMailData?.fromName}
										</p>
										{drawerMailData?.toRecipients && (
											<p className={styles['to']}>
												<span className={styles['title']}>To:</span>{' '}
												{drawerMailData?.toRecipients}
											</p>
										)}
										{drawerMailData?.ccRecipients && (
											<p className={styles['cc']}>
												<span className={styles['title']}>Cc:</span>{' '}
												{drawerMailData?.ccRecipients}
											</p>
										)}
										{drawerMailData?.bccRecipients && (
											<p className={styles['bcc']}>
												<span className={styles['title']}>Bcc:</span>{' '}
												{drawerMailData?.bccRecipients}
											</p>
										)}
									</div>
								</div>

								<p className={styles['date-info']}>
									{moment(drawerMailData?.receivedDateTime).format(
										'MMM-DD-YYYY hh:mm A'
									)}
								</p>
							</div>
							<div dangerouslySetInnerHTML={{ __html: drawerMailData?.html }} />
						</>
					)}
				</Drawer>
			)}
		</>
	);
};

export default AddCustomer;
