import { useContext, useState } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { RiDeleteBin6Fill } from 'react-icons/ri';

import Popconfirm from '../../../../components/popConfirm';
import EdenContext from '../../../../context/edenContext';
import ToolTip from '../../../../components/tooltip';
import { filterUniqueIdsAndRecent } from '../../../../utils';
import MpnQueueContext from '../../../../context/mpnQueueContext';

const MpnQueueAction = (p) => {
	const { userMail } = useContext(EdenContext);
	const { mpnQueueRowData, setMpnQueueRowData, setMpnQueueList, mpnQueueList } =
		useContext(MpnQueueContext);
	const [deleteQueuePopup, setDeleteQueuePopup] = useState(false);

	const deleteQueue = () => {
		setMpnQueueRowData(
			filterUniqueIdsAndRecent(
				mpnQueueRowData?.filter((item) => item.id !== p?.data?.id)
			)
		);
		setMpnQueueList(
			filterUniqueIdsAndRecent(
				mpnQueueList?.filter((item) => item.id !== p?.data?.id)
			)
		);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=deletempnque&id=${p?.data?.id}&email=${userMail}`
			)
			.then(() => {
				setDeleteQueuePopup(false);
				message.open({
					type: 'success',
					content: 'RFQ deleted successfully',
				});
			})
			.catch(() => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
			});
	};

	const closeDeleteQueuePopup = () => {
		setDeleteQueuePopup(false);
	};

	const openDeletePopUp = () => {
		setDeleteQueuePopup(true);
	};

	return (
		<div>
			<Popconfirm
				placement="bottomLeft"
				okButtonColor="red"
				open={deleteQueuePopup}
				title="Delete"
				description="You cannot undo this action. Do you confirm the deletion of the selected row?"
				okText="Yes"
				cancelText="Cancel"
				onConfirm={deleteQueue}
				onCancel={closeDeleteQueuePopup}
				icon={
					<QuestionCircleOutlined
						style={{
							color: 'red',
						}}
					/>
				}
			>
				<ToolTip title="Delete">
					<RiDeleteBin6Fill
						style={{
							fontSize: '18px',
							cursor: 'pointer',
							color: '#C00000',
							display: 'flex',
							alignItems: 'center',
						}}
						onClick={openDeletePopUp}
					/>
				</ToolTip>
			</Popconfirm>
		</div>
	);
};

export default MpnQueueAction;
