import { useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { message } from 'antd';

import InnerHeader from '../../components/innerHeader';
import NotificationBar from '../../components/notificationBar';
import useFetch from '../../hooks';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import { useQuery } from '../../hooks/use-query';

import styles from './outlookRFQ.module.scss';

const OutlookRFQ = () => {
	const { query } = useQuery();
	const id = query.get('datafile');
	const error = query.get('error');
	const [gridApi, setGridApi] = useState();
	// eslint-disable-next-line
	const [columnDefs, setColumnDefs] = useState([
		{ field: 'BRAND', headerName: 'Brand' },
		{ field: 'MPN', headerName: 'MPN' },
		{ field: 'QTY', headerName: 'Qty' },
	]);

	const { apiData, isLoading, serverError } = useFetch(`${id} `);

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	const exportToExcel = () => {
		const outlookRFQParams = {
			sheetName: 'RFQ',
			fileName: 'RFQ.xlsx',
		};
		gridApi.exportDataAsExcel(outlookRFQParams);
	};

	const copyToClipboard = () => {
		if (gridApi) {
			gridApi.selectAll();
			gridApi.copySelectedRowsToClipboard({
				includeHeaders: true,
			});
			gridApi.deselectAll();
		}
		message.open({
			type: 'success',
			content: 'Copied to Clipboard',
		});
	};

	const excelStyles = [
		{
			id: 'header',
			font: {
				bold: true,
			},
		},
	];

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	);

	return (
		<div className={styles['wrapper']}>
			{isLoading && (
				<div className={styles['loading-wrapper']}>
					<LoadingMessage message="Loading..." />
				</div>
			)}

			{serverError && !isLoading && !id && (
				<div className={styles['error-wrapper']}>
					<ErrorMessage
						message={
							<span>
								Oops… it appears Eden didn’t find any parts in your email
								&#128532;.
								<br /> If this is an error, please immediately contact{' '}
								<a
									style={{ color: '#1677ff' }}
									href="mailto:dev@supremecomponents.com?subject=Hi there is an error in Eden Analyser"
								>
									dev@supremecomponents.com
								</a>{' '}
								for support.
							</span>
						}
					/>
				</div>
			)}

			{!isLoading && !serverError && (
				<>
					<h1 className={styles['title']}>EDEN Analyser​</h1>
					<p className={styles['subtitle']}>
						Eden has found <b>{apiData?.length}</b> parts after reviewing your
						email. Please copy or export the results to Excel and run Chip
						Finder, or use the Mass Email system via Outlook.
					</p>
					<NotificationBar
						wraningBar
						message="Because Eden is AI driven, you may see a few imperfections in your dataset. But fret not, over time, Eden will learn these errors and the errors will go away! ​"
					/>
					<InnerHeader
						exportToExcel={exportToExcel}
						copyToClipboard={copyToClipboard}
					/>
					<div className={`ag-theme-custom ${styles['table-wrapper']} `}>
						<AgGridReact
							columnDefs={columnDefs}
							rowData={apiData}
							onGridReady={onGridReady}
							excelStyles={excelStyles}
							defaultColDef={defaultColDef}
							pagination={true}
							enableRangeSelection={true}
							copyHeadersToClipboard={true}
							animateRows={true}
							paginationPageSizeSelector={false}
						/>
					</div>
				</>
			)}

			{error === '1' && (
				<div className={styles['error-wrapper']}>
					<ErrorMessage
						title="Eden Couldn’t Find Valid Quote or Stock."
						message={
							<p>
								<p style={{ padding: '10px 0' }}>
									Looks like this email appears to be from a supplier. However,
									we couldn't find any stock or quotes hidden within.
								</p>
								If you believe this is an error, let us know at{' '}
								<a
									style={{ color: '#1677ff' }}
									href="mailto:dev@supremecomponents.com?subject=Hi there is an error in Eden Analyser"
								>
									dev@supremecomponents.com
								</a>
							</p>
						}
					/>
				</div>
			)}

			{error === '2' && (
				<div className={styles['error-wrapper']}>
					<ErrorMessage
						title="Customer Email but No Valid RFQ Found in this email."
						message={
							<p>
								<p style={{ padding: '10px 0' }}>
									Well, the search for a valid RFQ in this email turned up
									empty. It seems this message isn't hiding any treasure.
								</p>
								If you believe this is an error, please report it to{' '}
								<a
									style={{ color: '#1677ff' }}
									href="mailto:dev@supremecomponents.com?subject=Hi there is an error in Eden Analyser"
								>
									dev@supremecomponents.com
								</a>
							</p>
						}
					/>
				</div>
			)}

			{error === '3' && (
				<div className={styles['error-wrapper']}>
					<ErrorMessage
						title="Mysterious Email Alert."
						message={
							<p>
								<p style={{ padding: '10px 0' }}>
									This email doesn't quite fit the supplier or customer profile
									with valid RFQs, quotes, or stocks.
								</p>
								If you believe this is an error, please report it to{' '}
								<a
									style={{ color: '#1677ff' }}
									href="mailto:dev@supremecomponents.com?subject=Hi there is an error in Eden Analyser"
								>
									dev@supremecomponents.com
								</a>
							</p>
						}
					/>
				</div>
			)}

			{error === '4' && (
				<div className={styles['error-wrapper']}>
					<ErrorMessage
						title="Service Unavailable"
						message={
							<p>
								<p style={{ padding: '10px 0' }}>
									Oops! It appears our service is currently unavailable. Our
									team is working diligently to resolve the issue and get things
									back on track.
								</p>
								We apologize for any inconvenience this may cause. Please check
								back later and thank you for your patience.
							</p>
						}
					/>
				</div>
			)}
		</div>
	);
};

export default OutlookRFQ;
