import { Col, Divider, Form, message, Row, Upload } from 'antd';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { LuSendHorizonal } from 'react-icons/lu';
import { IoMdAttach } from 'react-icons/io';
import axios from 'axios';
import ReactQuill from 'react-quill';
import { LoadingOutlined } from '@ant-design/icons';
import JoditEditor from 'jodit-react';

import Input from '../../../components/input';
import Button from '../../../components/button';
import EdenContext from '../../../context/edenContext';
import UploadedFile from '../../../components/uploadedFile';
import MassMailContext from '../../../context/massMailContext';

import styles from './mailConfig.module.scss';

const MailConfig = ({
	selectedRows,
	gridApi,
	setSelectedRows,
	setSendMailCount,
}) => {
	const { userMail, userInfo, userType } = useContext(EdenContext);
	const { setMailConfigData } = useContext(MassMailContext);
	const [disabled, setDisabled] = useState(false);
	const [mailConfigDataLoading, setMailConfigDataLoading] = useState(false);
	const [file, setFile] = useState(null); //eslint-disable-next-line
	const [submitMailConfig, setSubmitMailConfig] = useState(false);
	const [editorContent, setEditorContent] = useState('Dear [FName],');
	const [form] = Form.useForm();
	const editor = useRef(null);

	const getUserMailConfig = () => {
		setMailConfigDataLoading(true);

		const formFields = { USER_EMAIL: userMail };
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=getmailconfig`,
				JSON.stringify(formFields)
			)
			.then((response) => {
				form.setFieldsValue({
					SIGNATURE: response.data?.SIGNATURE,
				});
				setMailConfigDataLoading(false);
			})
			.catch((err) => {
				setMailConfigDataLoading(false);
			});
	};

	useEffect(() => {
		getUserMailConfig(); //eslint-disable-next-line
	}, [userMail]);

	const buttons = [
		'bold',
		'strikethrough',
		'underline',
		'italic',
		'|',
		'align',
		'|',
		'ul',
		'ol',
		'outdent',
		'indent',
		'|',
		'font',
		'fontsize',
		'brush',
		'paragraph',
		'|',
		'link',
		'table',
		'|',
		'eraser',
	];

	const config = useMemo(
		() => ({
			readonly: false,
			spellcheck: true,
			language: 'en',
			toolbarButtonSize: 'medium',
			toolbarAdaptive: false,
			showCharsCounter: false,
			showWordsCounter: false,
			showXPathInStatusbar: false,
			askBeforePasteHTML: false,
			askBeforePasteFromWord: false,
			//defaultActionOnPaste: "insert_clear_html",
			buttons: buttons,
			uploader: {
				insertImageAsBase64URI: true,
			},
			width: '100%',
			disabled: selectedRows.length <= 0,
			clipboard: {
				match: 'wordHtml', // Options: 'word', 'wordHtml', 'html', 'plain' - match clipboard content type
				processPastedHTML: (html) => {
					// Example: Remove all styles and classes from pasted content
					const doc = new DOMParser().parseFromString(html, 'text/html');
					doc.body.querySelectorAll('*').forEach((el) => {
						el.removeAttribute('style');
						el.removeAttribute('class');
					});
					return doc.body.innerHTML;
				},
				// Additional clipboard settings can be added here
			},
		}), //eslint-disable-next-line
		[selectedRows]
	);

	const onFinish = async (values) => {
		setSubmitMailConfig(true);
		setDisabled(true);

		const formFields = {
			SIGNATURE: values?.SIGNATURE?.replace(/<p><br><\/p>/g, '')?.replace(
				/<p(?![^>]*style=["'][^"']*margin[^>]*)([^>]*)>/g,
				'<p$1 style="margin: 0;">'
			),
			USER_EMAIL: userMail,
		};

		axios.post(
			'https://internal.supremecomponents.com/api/externalgateway.php?route=savemailconfig',
			JSON.stringify(formFields)
		);

		try {
			const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
			const requests = selectedRows.map((el, index) => {
				return delay(index * 250).then(() => {
					const formData = new FormData();
					let newBody = editorContent;

					let signature = values?.SIGNATURE?.replace(
						/<p><br><\/p>/g,
						''
					)?.replace(
						/<p(?![^>]*style=["'][^"']*margin[^>]*)([^>]*)>/g,
						'<p$1 style="margin: 0;">'
					);

					newBody = newBody?.concat(`<br>${signature}`);
					newBody = newBody?.replace(
						'[FName],',
						`${el?.ContactFirstName},<br>`
					);
					newBody = newBody?.replace(/border="0"/g, 'border="1"');
					newBody = newBody.replace(
						/<table(?![^>]*\bborder=)/g,
						'<table border="1"'
					);
					if (values.TO === '[SUPPLIER_EMAIL]') {
						formData.append('receiverEmail', el?.ContactEmail);
					} else {
						formData.append('receiverEmail', values?.TO);
					}
					formData.append('cc', values?.CC || '');
					formData.append('bcc', values?.BCC || '');
					formData.append('firstname', el?.ContactFirstName);
					formData.append('subject', values.SUBJECT);
					formData.append('senderEmail', userMail);
					formData.append('account_id', el?.AcctId);
					formData.append('contact_id', el?.ContactId);
					formData.append('file', file);
					formData.append(
						'sales_rep_id',
						userInfo?.ViewAsLoggedinUser?.MasterId
					);
					formData.append('body', newBody);
					formData.append('HTTP_API_KEY', '218b84c69678e95cb98679051a6e5118');

					return axios.post(
						'https://internal.supremecomponents.com/api/sendmm.php',
						formData
					);
				});
			});

			const results = await Promise.all(requests);

			const successCount = results.filter(
				(result) => result.data.status === 'success'
			).length;
			setSendMailCount(successCount);
		} catch (error) {
			console.error('Error sending emails:', error);
		} finally {
			form.resetFields();
			setEditorContent('Dear [FName],');
			setFile(null);
			setSelectedRows([]);
			setSubmitMailConfig(false);
			setDisabled(false);
			gridApi.deselectAll();
			getUserMailConfig();
		}
	};

	const initialValues = { TO: '[SUPPLIER_EMAIL]', BODY: 'Dear [FName],' };

	const props = {
		name: 'file',
		action: '',
		fileList: [],
		showUploadList: false,
		maxCount: 1,
		beforeUpload: (file) => {
			const fileSize = file.size;

			// file size in bytes (1MB = 1,048,576 bytes)
			if (fileSize > 5242880) {
				message.error('The file cannot be more than 5MB');
			}
			if (fileSize <= 5242880) {
				setFile(file);
			}
		},
		customRequest: () => null,
	};

	const onValuesChange = (changedValues, allValues) => {
		setMailConfigData(allValues);
	};

	return (
		<div className={styles['wrapper']}>
			<Form
				name="mass-mail-form"
				form={form}
				onFinish={onFinish}
				validateTrigger={['onBlur', 'onChange']}
				layout="horizontal"
				requiredMark={false}
				initialValues={initialValues}
				disabled={mailConfigDataLoading || disabled || selectedRows.length <= 0}
				colon={false}
				className={styles['mass-mail-form']}
				onValuesChange={onValuesChange}
			>
				<Row gutter={8}>
					<Col span={2}>
						<Form.Item>
							<Button
								onClick={() => form.submit()}
								style={{
									display: 'flex',
									flexDirection: 'column',
									height: '80px',
									width: '100%',
								}}
							>
								{submitMailConfig || disabled ? (
									<LoadingOutlined style={{ fontSize: '20px' }} />
								) : (
									<LuSendHorizonal style={{ fontSize: '20px' }} />
								)}
								<p>Send</p>
							</Button>
						</Form.Item>
					</Col>
					<Col span={22}>
						<Form.Item
							name="TO"
							style={{ marginBottom: '10px' }}
							label={<span className={styles['to']}>To</span>}
							rules={[
								{
									required: true,
									message: 'Please enter To!',
								},
							]}
						>
							<Input
								variant="borderless"
								disabled={
									userType !== 'admin' ||
									mailConfigDataLoading ||
									disabled ||
									selectedRows.length <= 0
								}
								style={{ borderBottom: '1px solid rgb(216, 216, 216)' }}
							/>
						</Form.Item>
						<Form.Item
							name="CC"
							style={{ marginBottom: '10px' }}
							label={<span className={styles['to']}>Cc</span>}
						>
							<Input
								variant="borderless"
								style={{ borderBottom: '1px solid rgb(216, 216, 216)' }}
							/>
						</Form.Item>
						<Form.Item
							style={{ margin: '0 0 0 20px' }}
							name="SUBJECT"
							label={<span style={{ paddingTop: '4px' }}>Subject</span>}
							rules={[
								{
									required: true,
									message: 'Please enter Subject!',
								},
							]}
						>
							<Input variant="borderless" />
						</Form.Item>
					</Col>
				</Row>
				<Divider
					style={{
						margin: '5px 0 10px 0',
						borderBlockStart: '1px solid rgb(216, 216, 216)',
					}}
				/>
				<Col xs={24} style={{ paddingLeft: '17px' }}>
					<Form.Item
						label={<span style={{ paddingTop: '4px' }}>Attachment</span>}
						name="FILE"
						id="attachment"
						style={{ marginBottom: '10px' }}
					>
						{file ? (
							<UploadedFile
								url={file?.name}
								style={{ marginTop: '5px' }}
								fileName={file?.name}
								onRemove={() => {
									setFile(null);
									form.setFieldsValue({ FILE: '' });
								}}
								canDownload
							/>
						) : (
							<Upload {...props}>
								<Button type="bordered">
									<IoMdAttach className={styles['attach-icon']} /> Upload
								</Button>
							</Upload>
						)}
					</Form.Item>
				</Col>{' '}
				<Divider
					style={{
						margin: '5px 0 10px 0',
						borderBlockStart: '1px solid rgb(216, 216, 216)',
					}}
				/>
				<Col>
					<Form.Item name="BODY" style={{ marginBottom: 0 }}>
						<div
							style={{
								opacity:
									(mailConfigDataLoading ||
										disabled ||
										selectedRows.length <= 0) &&
									0.4,
								pointerEvents:
									(mailConfigDataLoading ||
										disabled ||
										selectedRows.length <= 0) &&
									'none',
							}}
							className={styles['jodit-container']}
						>
							<JoditEditor
								ref={editor}
								config={config}
								value={editorContent}
								onBlur={(content) => setEditorContent(content)}
							/>
						</div>
					</Form.Item>
				</Col>
				<Col xs={24}>
					<Form.Item
						style={{ marginBottom: '0' }}
						name="SIGNATURE"
						id="signature"
						rules={[
							{
								required: true,
								message: 'Please enter Signature!',
							},
						]}
					>
						<ReactQuill
							className={styles['signature-body']}
							style={{
								opacity:
									(mailConfigDataLoading ||
										disabled ||
										selectedRows.length <= 0) &&
									0.4,
								pointerEvents:
									(mailConfigDataLoading ||
										disabled ||
										selectedRows.length <= 0) &&
									'none',
							}}
							readOnly={
								mailConfigDataLoading || disabled || selectedRows.length <= 0
							}
							modules={{
								toolbar: false,
								clipboard: {
									matchVisual: true,
								},
							}}
							theme="snow"
							placeholder=""
							id="signature"
						/>
					</Form.Item>
				</Col>
			</Form>
		</div>
	);
};

export default MailConfig;
