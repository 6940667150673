import { Radio as AntdRadio } from 'antd';

import { RadioTwo } from '../../../../../components/radio';
import UploadFile from './UploadFile';
import CopyPaste from './CopyPaste';

import styles from './uploadCopyPasteParts.module.scss';

const UploadCopyPasteParts = ({
	isBomOrCopyPaste,
	setIsBomOrCopyPaste,
	excelData,
	setExcelData,
	excelFileName,
	setExcelFileName,
	selectedSheet,
	setSelectedSheet,
	sheetNames,
	setSheetNames,
	copyPasteForm,
	copyPasteData,
	setCopyPasteData,
	workbook,
	setWorkbook,
}) => {
	const handleRadioChange = (e) => {
		setIsBomOrCopyPaste(e.target.value);
	};

	return (
		<div className={styles['wrapper']}>
			<AntdRadio.Group
				defaultValue={isBomOrCopyPaste}
				onChange={handleRadioChange}
			>
				<RadioTwo value="upload-bom">Upload BOM</RadioTwo>
				<RadioTwo value="copy-paste">COPY/PASTE DATA</RadioTwo>
			</AntdRadio.Group>

			{isBomOrCopyPaste === 'upload-bom' && (
				<UploadFile
					excelData={excelData}
					setExcelData={setExcelData}
					excelFileName={excelFileName}
					setExcelFileName={setExcelFileName}
					selectedSheet={selectedSheet}
					setSelectedSheet={setSelectedSheet}
					sheetNames={sheetNames}
					setSheetNames={setSheetNames}
					workbook={workbook}
					setWorkbook={setWorkbook}
				/>
			)}
			{isBomOrCopyPaste === 'copy-paste' && (
				<CopyPaste
					copyPasteForm={copyPasteForm}
					copyPasteData={copyPasteData}
					setCopyPasteData={setCopyPasteData}
				/>
			)}
		</div>
	);
};

export default UploadCopyPasteParts;
