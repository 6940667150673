import { useState, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import Header from '../../header';
import Sidebar from '../../sidebar';
import EdenContext from '../../../context/edenContext';
import LoadingMessage from '../../loaderMessage';
import ErrorMessage from '../../errorMessage';
import NotAuthorized from '../../notAuthorized';
import Loader from '../../loader';
import { loginRequest } from '../../../authConfig';
import MicroSoftLogo from '../../../images/microsoft.svg';

import styles from './privateLayout.module.scss';

const PrivateRoutes = () => {
	const { instance, inProgress } = useMsal();

	const handleLogin = () => {
		instance.loginRedirect(loginRequest);
	};

	const {
		isAuthenticated,
		userInfo,
		userInfoLoading,
		userInfoError,
		userType,
	} = useContext(EdenContext);

	const [collapsed, setCollapsed] = useState(false);

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.key === 'b') {
				toggleCollapsed();
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		}; //eslint-disable-next-line
	}, [collapsed]);

	if (inProgress === 'login') {
		return (
			<div className={styles['loading-wrapper-1']}>
				<LoadingMessage message="Login is currently in progress!" />
			</div>
		);
	}

	return (
		<>
			{isAuthenticated ? (
				<>
					{userInfoError && !userInfoLoading && (
						<div className={styles['error-wrapper']}>
							<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
						</div>
					)}

					{userInfoLoading && (
						<div className={styles['loading-wrapper']}>
							<Loader />
						</div>
					)}

					{!userInfoLoading &&
						!userInfoError &&
						userInfo?.ViewAsLoggedinUser?.isActive === false && (
							<NotAuthorized />
						)}

					{!userInfoLoading &&
						!userInfoError &&
						userInfo?.ViewAsLoggedinUser?.isActive === true && (
							<>
								{!userInfoLoading && !userInfoError && (
									<div className={styles['layout-wrapper']}>
										<div className={styles['header']}>
											<Header />
										</div>
										<div className={styles['layout']}>
											<div
												className={`${styles['sidebar-content']} ${
													collapsed === true
														? styles['collapsed-sidebar']
														: userType === 'sm'
														? styles['sidebar-sm']
														: styles['sidebar']
												}`}
											>
												<Sidebar
													collapsed={collapsed}
													toggleCollapsed={toggleCollapsed}
													setCollapsed={setCollapsed}
												/>
											</div>
											<div
												className={`${styles['cont']} ${
													collapsed
														? styles['collapsed-content']
														: userType === 'sm'
														? styles['content-sm']
														: styles['content']
												}`}
											>
												<Outlet />
											</div>
										</div>
									</div>
								)}
							</>
						)}
				</>
			) : (
				<>
					<div className={styles['login-wrapper']}>
						<div className={styles['header']}>EDEN</div>
						<div className={styles['login']}>
							<div className={styles['sections']}>
								<div className={styles['left-side']}>
									<h1>Welcome to EDEN</h1>
								</div>
								<div className={styles['right-side']}>
									<div className={styles['login-button']} onClick={handleLogin}>
										<img
											className={styles['img']}
											src={MicroSoftLogo}
											alt="Login"
										/>
										<span className={styles['span']}>
											Sign in with Microsoft
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default PrivateRoutes;
