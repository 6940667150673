import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MdManageAccounts } from 'react-icons/md';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import axios from 'axios';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import Button from '../../components/button';
import NotificationBar from '../../components/notificationBar';
import HouseAccountContext from '../../context/houseAccountContext';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import { isNotEmptyArray } from '../../utils';
import NoData from '../../components/nodata';
import { CountryCellRenderer } from '../../columns/countryCellRenderer';
import { COUNTRY_CODES } from '../../columns';
import EdenContext from '../../context/edenContext';
import InnerHeader from '../../components/innerHeader';
import { dateFilterParams, dollarFilterParams } from '../../filters';
import MultipleTabsImage from '../../images/multipleTabs.svg';

import styles from './houseAccounts.module.scss';

const HouseAccounts = () => {
	const {
		data,
		isLoading,
		error,
		getData,
		getUserStatics,
		userStatics,
		dataWithFlags,
		forceLoadData,
	} = useContext(HouseAccountContext);
	const { userMail, userData, me, userInfo, userType, isMain } =
		useContext(EdenContext);

	const [selectedRowValue, setSelectedRowValue] = useState([]);
	const [allocatingAcc, setAllocatingAcc] = useState(false);
	const [accAllocated, setAccAllocated] = useState([]);
	const [allocationError, setAllocationError] = useState(false);
	const [showAccAllocatedBanner, setShowAccAllocatedBanner] = useState(false);
	const [gridApi, setGridApi] = useState(null);
	const agGridRef = useRef(null);

	useEffect(() => {
		getUserStatics();
		getData(); //eslint-disable-next-line
	}, []);

	const [columnDefs] = useState([
		{
			field: 'AcctCreatedDate',
			checkboxSelection: true,
			showDisabledCheckboxes: true,
			headerName: 'Created Date',
			minWidth: 120,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value && moment(p.value).format('MMM-DD-YYYY')}
				</span>
			),
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
		},
		{ field: 'Account', headerName: 'Account', minWidth: 300 },
		{ field: 'PrimaryCompanyType', headerName: 'Account Type' },
		{
			field: 'AcctCountry',
			headerName: 'Country',
			cellRenderer: CountryCellRenderer,
			filter: 'agSetColumnFilter',
			filterParams: {
				cellRenderer: CountryCellRenderer,
				cellRendererParams: { isFilterRenderer: true },
			},
		},
		{
			field: 'BillingRounded',
			headerName: 'Past 5-year Billings',
			sort: 'desc',
			sortingOrder: ['desc', 'asc'],
			filter: 'agSetColumnFilter',
			filterParams: dollarFilterParams,
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
		},
	]);

	const isRowSelectable = (params) => {
		const eligibleToAdd = Math.max(0, userStatics?.eligible_to_add);

		return (
			(selectedRowValue.length !== eligibleToAdd &&
				userStatics?.count < 25 &&
				eligibleToAdd > 0) ||
			params.isSelected()
		);
	};

	const onGridReady = (e) => {
		setGridApi(e.api);
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
		}),
		[]
	);

	const context = useMemo(() => {
		return {
			COUNTRY_CODES: COUNTRY_CODES,
		};
	}, []);

	const remainingAccounts = 25 - userStatics?.count;

	const onSelectionChanged = (e) => {
		setSelectedRowValue(e.api.getSelectedRows());
	};

	const allocateAccount = () => {
		setAllocatingAcc(true);
		setShowAccAllocatedBanner(false);
		setAccAllocated([]);
		const promises = selectedRowValue.map((el, index, arr) =>
			axios
				.post(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=allocate_account&email=${userMail}&account_id=${el?.AcctId}&master_id=${userInfo?.ViewAsLoggedinUser?.MasterId}`
				)
				.then((response) => {
					setAccAllocated(arr.length);
				})
				.catch((err) => {
					console.log(err);
					setAllocationError(true);
				})
		);
		Promise.all(promises)
			.then(() => {
				axios.post(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=send_allocation_notification&userEmail=${userMail}&userName=${
						userData?.name || me?.givenName || me?.displayName
					}&data=${JSON.stringify(selectedRowValue)}`
				);
			})
			.then(() => {
				setAllocatingAcc(false);
				setShowAccAllocatedBanner(true);
				setSelectedRowValue([]);
				getData();
				getUserStatics();
			})
			.catch((error) => {
				console.error('Error during promise execution:', error);
			});
	};

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	return (
		<div className={styles['wrapper']}>
			{isMain ? (
				<>
					<BreadCrumb
						route1="House Accounts"
						icon={<MdManageAccounts style={{ fontSize: '20px' }} />}
					/>
					<SectionInfo
						removeshortcutkeys
						title="House Accounts"
						info={
							<div>
								Please take note the following rules before assigning any House
								Accounts to yourself:
								<br />
								<ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
									<li>
										Each Sales Representative may manage up to a <b>maximum</b>{' '}
										of 25 accounts.
									</li>
									<li>
										Sales Representatives are permitted to select no more than{' '}
										<b>5 accounts per week</b> to avoid hoarding.
									</li>
									<li>
										A newly self-assigned account enters a 30-day probationary
										period.
									</li>
									<li>
										The account is considered successfully assigned if there is
										an incoming email from any CRM-listed contact within those
										30 days; else, the account will be released back to the
										House accounts list.
									</li>
									<li>
										Accounts that have been inactive for more than 6 months will
										released back to the House accounts list.
									</li>
								</ul>
							</div>
						}
					/>
					{userType === 'admin' && (
						<InnerHeader
							updateText="Force Reload data"
							updatePage
							onUpdate={forceLoadData}
							disableUpdate={isLoading}
						/>
					)}
					<div className={styles['house-accounts']}>
						{isLoading && (
							<div className={styles['loading-wrapper']}>
								<LoadingMessage message="Loading..." />
							</div>
						)}
						{error && !isLoading && (
							<div className={styles['error-wrapper']}>
								<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
							</div>
						)}

						{!isNotEmptyArray(data) && !error && !isLoading && (
							<div className={styles['no-data-wrapper']}>
								<NoData message="Its all empty here. Select a date range." />
							</div>
						)}

						{!isLoading && !error && isNotEmptyArray(data) && (
							<>
								<NotificationBar
									messageStyle={{ fontWeight: 400 }}
									message={
										<>
											You have <b>{userStatics?.count}</b> accounts allocated.
											You have up to{' '}
											<b>{remainingAccounts < 0 ? 0 : remainingAccounts}</b>{' '}
											accounts you can allocate from the following list. Good
											luck!
										</>
									}
								/>

								{userStatics?.eligible_to_add <= 0 && (
									<NotificationBar
										messageStyle={{ fontSize: '14px', fontWeight: 400 }}
										redBar
										message={
											<>
												In the last <b>7</b> days, you have already allocated{' '}
												<b>5</b> accounts to yourself. Please come back on{' '}
												<b>{userStatics?.quota_release_date}</b> to allocate
												more accounts.​
											</>
										}
									/>
								)}

								{allocationError && (
									<NotificationBar
										messageStyle={{ fontSize: '14px', fontWeight: 400 }}
										redBar
										message={
											<>
												There seems to be an error tagging your accounts in
												Focus. IT has been notified and HelpDesk has been
												notified to manually tag your accounts. Contact Help
												Desk today to get a status update.
											</>
										}
									/>
								)}

								{selectedRowValue.length === userStatics?.eligible_to_add &&
									selectedRowValue.length > 0 &&
									userStatics?.eligible_to_add < 5 && (
										<NotificationBar
											messageStyle={{ fontSize: '14px', fontWeight: 400 }}
											redBar
											message={
												<>
													You have <b>{userStatics?.count}</b> accounts
													allocated. This week you have already added{' '}
													<b>{5 - userStatics?.eligible_to_add}</b> accounts.
													You can only add up to{' '}
													<b>{userStatics?.eligible_to_add}</b> more accounts
													this week.{' '}
												</>
											}
										/>
									)}

								{showAccAllocatedBanner && !allocationError && (
									<NotificationBar
										greenBar
										messageStyle={{ fontSize: '14px', fontWeight: 400 }}
										message={
											<>
												Congratulations! <b>{accAllocated}</b> accounts have
												been allocated to you. You can have up to{' '}
												<b>{25 - userStatics?.count}</b> more account of the
												maximum <b>25</b> account allocation. ​
											</>
										}
									/>
								)}

								<Button
									onClick={allocateAccount}
									loading={allocatingAcc}
									disabled={
										allocatingAcc ||
										userStatics?.count >= 25 ||
										selectedRowValue.length <= 0 ||
										selectedRowValue.length > 5
									}
								>
									{allocatingAcc
										? 'Assigning to you... Please wait'
										: 'Assign to Me'}
								</Button>

								<div className={styles['table-wrapper']}>
									<div className={`ag-theme-custom ${styles['table']} `}>
										<AgGridReact
											ref={agGridRef}
											onGridReady={onGridReady}
											rowData={dataWithFlags}
											columnDefs={columnDefs}
											defaultColDef={defaultColDef}
											rowSelection={'multiple'}
											suppressRowClickSelection={true}
											rowMultiSelectWithClick={true}
											onSelectionChanged={onSelectionChanged}
											animateRows={true}
											pagination={true}
											paginationPageSizeSelector={false}
											suppressContextMenu={userType !== 'admin'}
											context={context}
											isRowSelectable={isRowSelectable}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</>
			) : (
				<div className={styles['multiple-tabs']}>
					<img
						className={styles['img']}
						src={MultipleTabsImage}
						alt="No data"
					/>
					<p className={styles['message']}>
						App already opened on another tab!
					</p>
				</div>
			)}
		</div>
	);
};

export default HouseAccounts;
