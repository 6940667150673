import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { FaYoutube } from 'react-icons/fa';
import { TbReport } from 'react-icons/tb';
import { AgGridReact } from 'ag-grid-react';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import ManagementReportContext from '../../context/mangementReportContext1';
import InnerHeader from '../../components/innerHeader';
import { isNotEmptyArray, titleCase } from '../../utils';
import Select from '../../components/select';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import NoData from '../../components/nodata';

import styles from './managementReport1.module.scss';

const ManagementReport = () => {
	const {
		fy,
		dataType,
		setDataType,
		setFy,
		columnDefs,
		isLoading,
		error,
		data,
	} = useContext(ManagementReportContext);

	const [gridApi, setGridApi] = useState(null);

	const ref = useRef(null);

	const onGridReady = (p) => {
		setGridApi(p.api);
		p.api.autoSizeAllColumns();

		const calculateFooterValue = () => {
			let totalValues = {
				CurrY: 0,
				SalesTarget: 0,
				PctToGoal: 0,
				Jan: 0,
				Feb: 0,
				Mar: 0,
				Apr: 0,
				May: 0,
				Jun: 0,
				Jul: 0,
				Aug: 0,
				Sep: 0,
				Oct: 0,
				Nov: 0,
				Dec: 0,
				'CurrY-1': 0,
				'CurrY-2': 0,
			};

			let countValues = {
				CurrY: 0,
				SalesTarget: 0,
				PctToGoal: 0,
				Jan: 0,
				Feb: 0,
				Mar: 0,
				Apr: 0,
				May: 0,
				Jun: 0,
				Jul: 0,
				Aug: 0,
				Sep: 0,
				Oct: 0,
				Nov: 0,
				Dec: 0,
				'CurrY-1': 0,
				'CurrY-2': 0,
			};

			p.api.forEachNodeAfterFilter((node) => {
				let data = node.data;

				Object.keys(totalValues).forEach((key) => {
					let value = parseFloat(data[key]);
					if (!isNaN(value)) {
						totalValues[key] += value;
						if (value !== 0) {
							countValues[key]++;
						}
					}
				});
			});

			let footerData = {
				billingFor: 'Total:',
				Division: 'Total:',
				Supplier: 'Total:',
				Customer: 'Total:',
				Country: 'Total:',
				SalesRep: 'Total:',
				Brand: 'Total:',
				'c1 Customer': 'Total:',
			};

			Object.keys(totalValues).forEach((key) => {
				let value;
				if (
					dataType === 'gpm-by-customer-by-month' ||
					dataType === 'gpm-by-salesrep-by-month' ||
					key === 'PctToGoal'
				) {
					value =
						countValues[key] > 0
							? parseFloat(totalValues[key] / countValues[key]).toFixed(2)
							: '';
				} else {
					value = totalValues[key] ? totalValues[key] : '';
				}

				// Convert PctToGoal to an integer
				if (key === 'PctToGoal' && value !== '') {
					value = parseFloat(value);
				}

				footerData[key] = value;
			});

			p.api.setPinnedBottomRowData([footerData]);
		};

		calculateFooterValue();

		p.api.addEventListener('filterChanged', calculateFooterValue);
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agSetColumnFilter',
			suppressMovable: true,
			cellStyle: { fontSize: '14px' },
		}),
		[]
	);

	// *- Generate Fiscal Years
	const generateFiscalYears = (startYear) => {
		const currentYear = new Date().getFullYear();
		const currentMonth = new Date().getMonth();
		const options = [];

		const fiscalStartYear = currentMonth >= 3 ? currentYear : currentYear - 1;

		for (let year = startYear; year <= fiscalStartYear; year++) {
			const fiscalYear = `FY${(year + 1).toString().slice(-2)}`;

			options.push({
				label: fiscalYear,
				value: year + 1,
			});
		}

		return options;
	};

	// *- Start fiscal year from 2022
	const fiscalYearOptions = generateFiscalYears(2022);

	const exportToExcel = () => {
		const managementReportExcelParams = {
			sheetName: `${fy} ${titleCase(dataType.split('-').join(' '))}`,
			fileName: `${fy} ${titleCase(dataType.split('-').join(' '))}.xlsx`,
			processCellCallback: (p) => {
				if (p.column.colId === 'billingFor') {
					return p.value;
				}
				return p.value;
			},
		};

		gridApi.exportDataAsExcel(managementReportExcelParams);
	};

	const excelStyles = useMemo(() => {
		return [
			{
				id: 'numberType',
				numberFormat: {
					format: '$ #,##0.00',
				},
				alignment: {
					horizontal: 'Distributed',
				},
			},
			{
				id: 'decimal',
				numberFormat: {
					format: '0.00',
				},
				alignment: {
					horizontal: 'Distributed',
				},
			},
			{
				id: 'header',
				font: {
					bold: true,
				},
			},
		];
	}, []);

	const getRowStyle = useCallback((params) => {
		if (params.node.rowPinned) {
			return { fontWeight: 600 };
		}
	}, []);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="Management Reports"
				icon={<TbReport style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="Management Reports"
				info={
					<>
						This section enables you to review all the key management reports
						needed to make sound business decisions. The following reports are
						provided to you for your review:
						<ul className={styles['ul-mr']}>
							<li
								className={styles['li-mr']}
								onClick={() => setDataType('division-billings-by-month')}
							>
								Division Billings by Month
							</li>
							<li
								className={styles['li-mr']}
								onClick={() => setDataType('supplier-billings-by-month')}
							>
								Supplier Billings by Month
							</li>
							<li
								className={styles['li-mr']}
								onClick={() => setDataType('customer-billings-by-month')}
							>
								Customer Billings by Month
							</li>
							<li
								className={styles['li-mr']}
								onClick={() => setDataType('country-billings-by-month')}
							>
								Country Billings by Month
							</li>
							<li
								className={styles['li-mr']}
								onClick={() => setDataType('salesrep-billings-by-month')}
							>
								Sales rep Billings by Month
							</li>
							<li
								className={styles['li-mr']}
								onClick={() =>
									setDataType('capitalizing-the-billings-by-month')
								}
							>
								Capitalizing the Billings by Month
							</li>
							<li
								className={styles['li-mr']}
								onClick={() => setDataType('gpm-by-salesrep-by-month')}
							>
								GPM by Sales rep by Month
							</li>
							<li
								className={styles['li-mr']}
								onClick={() => setDataType('gpm-by-customer-by-month')}
							>
								GPM by Customer by Month
							</li>
							<li
								className={styles['li-mr']}
								onClick={() => setDataType('gp$-by-customer-by-month')}
							>
								GP$ by Customer by Month
							</li>
						</ul>
					</>
				}
			/>
			<InnerHeader
				exportToExcel={exportToExcel}
				rightSideChildren={
					<a
						href="https://youtu.be/P0s50SVhkgU"
						target="_blank"
						rel="noreferrer"
						className={styles['youtube']}
					>
						<FaYoutube className={styles['youtube-icon']} />
						<span>Watch Demo</span>
					</a>
				}
			/>
			<div className={styles['management-report']}>
				<div className={styles['dropdown-wrapper']}>
					<Select
						title="Fiscal Year:"
						showSearch
						style={{ width: '150px' }}
						placeholder="Select Fiscal Year"
						options={fiscalYearOptions}
						onChange={(value) => setFy(value)}
						defaultValue={fy}
						disabled={isLoading}
					/>
					<Select
						disabled={isLoading}
						title="Report:"
						showSearch
						allowClear
						value={dataType}
						defaultValue={dataType}
						style={{ width: '250px' }}
						placeholder="Select Report Type"
						options={[
							{
								value: 'division-billings-by-month',
								label: 'Division Billings by Month',
							},
							{
								value: 'supplier-billings-by-month',
								label: 'Supplier Billings by Month',
							},
							{
								value: 'customer-billings-by-month',
								label: 'Customer Billings by Month',
							},
							{
								value: 'country-billings-by-month',
								label: 'Country Billings by Month',
							},
							{
								value: 'salesrep-billings-by-month',
								label: 'Sales rep Billings by Month',
							},
							{
								value: 'capitalizing-the-billings-by-month',
								label: 'Capitalizing the Billings by Month',
							},
							{
								value: 'gpm-by-salesrep-by-month',
								label: 'GPM by Sales rep by Month',
							},
							{
								value: 'gpm-by-customer-by-month',
								label: 'GPM by Customer by Month',
							},
							{
								value: 'gp$-by-customer-by-month',
								label: 'GP$ by Customer by Month',
							},
						]}
						onChange={(value) => setDataType(value)}
					/>
				</div>

				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(data) && !error && !isLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here. Please select Fiscal year and Report type." />
					</div>
				)}

				{!isLoading && !error && isNotEmptyArray(data) && (
					<div className={styles['table-wrapper']}>
						<div className={`ag-theme-custom ${styles['table']} `}>
							<AgGridReact
								onGridReady={onGridReady}
								ref={ref}
								rowData={data}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								animateRows={true}
								pagination={true}
								paginationPageSizeSelector={false}
								excelStyles={excelStyles}
								getRowStyle={getRowStyle}
								enableCellChangeFlash={true}
								enableRangeSelection={true}
								enableCharts={true}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ManagementReport;
