import { useContext, useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import ReactQuill from 'react-quill';
import { Col, Divider, Form, Row, Upload, message } from 'antd';
import { IoMdAttach } from 'react-icons/io';

import Drawer from '../../../components/drawer';
import Button from '../../../components/button';
import Input from '../../../components/input';
import EdenContext from '../../../context/edenContext';
import CustomerOutreachContext from '../../../context/customerOutreachContext';
import UploadedFile from '../../../components/uploadedFile';

import styles from './mailConfig.module.scss';

const MailConfig = ({
	drawer,
	setDrawer,
	selectedRows,
	setSelectedRows,
	gridApi,
}) => {
	const { userType, userMail, userInfo } = useContext(EdenContext);
	const { setSendMailCount } = useContext(CustomerOutreachContext);

	const [form] = Form.useForm();

	const [submitMailConfig, setSubmitMailConfig] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [mailConfigDataLoading, setMailConfigDataLoading] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [messageLoading, setMessageLoading] = useState(false);

	const loadEmailTemplate = () => {
		setDisabled(true);
		setMessageLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_suffled_msg_for_outreach`
			)
			.then((response) => {
				if (response.data?.status === 'success') {
					const data = response.data;
					const signaturePlaceholder = '<br />[SIGNATURE]';
					form.setFieldsValue({
						BODY: data?.body?.concat(signaturePlaceholder),
					});
					form.setFieldsValue({
						SUBJECT: data?.subject,
					});
				}
				if (
					response.data?.status === 'error' ||
					response.data?.template?.length === 0
				) {
					form.setFieldsValue({
						BODY: `Dear [FName],<br/><br/>I hope you're doing well. I'm reaching out to see if you have any RFQs for NPI, shortage, or lead time needs. Additionally, any POs in hand would be greatly appreciated!<br /><br />[SIGNATURE]`,
					});
					form.setFieldsValue({
						SUBJECT: 'Have any RFQs?',
					});
				}
			})
			.catch((err) => {
				form.setFieldsValue({
					BODY: `Dear [FName],<br/><br/>I hope you're doing well. I'm reaching out to see if you have any RFQs for NPI, shortage, or lead time needs. Additionally, any POs in hand would be greatly appreciated!<br /><br />[SIGNATURE]`,
				});
				form.setFieldsValue({
					SUBJECT: 'Have any RFQs?',
				});
			})
			.finally(() => {
				setDisabled(false);
				setMessageLoading(false);
			});
	};

	const closeDrawer = () => {
		setDrawer(false);
	};

	const onFinish = async (values) => {
		setSubmitMailConfig(true);
		setDisabled(true);

		const formFields = {
			SIGNATURE: values?.SIGNATURE?.replace(/<p><br><\/p>/g, '')?.replace(
				/<p(?![^>]*style=["'][^"']*margin[^>]*)([^>]*)>/g,
				'<p$1 style="margin: 0;">'
			),
			USER_EMAIL: userMail,
		};

		axios.post(
			'https://internal.supremecomponents.com/api/externalgateway.php?route=savemailconfig',
			JSON.stringify(formFields)
		);

		try {
			const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
			const requests = selectedRows.map((el, index) => {
				return delay(index * 250).then(() => {
					const formData = new FormData();
					let newBody = values.BODY;

					newBody = newBody?.replace(
						'[SIGNATURE]',
						values?.SIGNATURE?.replace(/<p><br><\/p>/g, '')?.replace(
							/<p(?![^>]*style=["'][^"']*margin[^>]*)([^>]*)>/g,
							'<p$1 style="margin: 0;">'
						)
					);
					newBody = newBody?.replace('[FName]', el?.ContactFirstName);
					newBody = newBody?.replace(/<p><br><\/p>/g, '');

					if (values.TO === '[CUSTOMER_EMAIL]') {
						formData.append('receiverEmail', el?.ContactEmail);
					} else {
						formData.append('receiverEmail', values?.TO);
					}

					formData.append('cc', values?.CC || '');
					formData.append('bcc', values?.BCC || '');
					formData.append('firstname', el?.ContactFirstName);
					formData.append('subject', values.SUBJECT);
					formData.append('senderEmail', userMail);
					formData.append('account_id', el?.AcctId);
					formData.append('contact_id', el?.ContactId);
					fileList.forEach((file) => {
						formData.append('file[]', file.originFileObj);
					});
					formData.append(
						'sales_rep_id',
						userInfo?.ViewAsLoggedinUser?.MasterId
					);
					formData.append('body', newBody);
					formData.append('HTTP_API_KEY', '218b84c69678e95cb98679051a6e5118');

					return axios.post(
						'https://internal.supremecomponents.com/api/sendoutreach.php',
						formData
					);
				});
			});

			const results = await Promise.all(requests);

			const successCount = results.filter(
				(result) => result.data.status === 'success'
			).length;
			setSendMailCount(successCount);
		} catch (error) {
			console.error('Error sending emails:', error);
		} finally {
			form.resetFields();
			setSelectedRows([]);
			setSubmitMailConfig(false);
			setDrawer(false);
			setDisabled(false);
			gridApi.deselectAll();
		}
	};

	const initialValues = { TO: '[CUSTOMER_EMAIL]', USER_EMAIL: userMail };

	const modules = {
		toolbar: [
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			['bold', 'italic', 'underline', 'strike'],
			[{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
			['clean'],
		],
		clipboard: {
			matchVisual: true,
		},
	};

	useEffect(() => {
		setMailConfigDataLoading(true);
		loadEmailTemplate();
		const formFields = { USER_EMAIL: userMail };
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=getmailconfig`,
				JSON.stringify(formFields)
			)
			.then((response) => {
				form.setFieldsValue({
					SIGNATURE: response.data?.SIGNATURE,
				});
				setMailConfigDataLoading(false);
			})
			.catch((err) => {
				setMailConfigDataLoading(false);
			}); //eslint-disable-next-line
	}, [userMail]);

	const props = {
		name: 'file',
		action: '',
		fileList: fileList,
		showUploadList: false,
		multiple: true,
		maxCount: 5,
		disabled: fileList.length >= 5,
		onChange: (info) => {
			let newFileList = [...info.fileList];

			// Remove files exceeding the size limit
			newFileList = newFileList.filter((file) => {
				if (file.size > 3145728) {
					message.error(`${file.name} is larger than 3MB and was removed`);
					return false;
				}
				return true;
			});

			setFileList(newFileList);
		},
		customRequest: () => null,
	};

	return (
		<div className={styles['wrapper']}>
			<Drawer
				title={
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<p>Mailing Configuration</p>
						<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
							{submitMailConfig && (
								<div>
									<LoadingOutlined /> Sending Mail...
								</div>
							)}
							<Button
								loading={messageLoading}
								disabled={submitMailConfig || messageLoading}
								onClick={loadEmailTemplate}
							>
								Add Auto-shuffle RFQ Message
							</Button>
						</div>
					</div>
				}
				placement="right"
				onClose={closeDrawer}
				open={drawer}
				width={600}
				footer={
					<div className={styles['drawer-footer']}>
						<Button disabled={submitMailConfig} onClick={() => form.submit()}>
							Send Mass Mail
						</Button>
						<Button
							disabled={submitMailConfig}
							type="bordered"
							onClick={closeDrawer}
						>
							Cancel
						</Button>
					</div>
				}
			>
				<Form
					name="mail-config"
					id="mail-config"
					onFinish={onFinish}
					validateTrigger={['onBlur', 'onChange']}
					labelCol={{ span: 4 }}
					wrapperCol={{ span: 20 }}
					layout="horizontal"
					requiredMark={false}
					form={form}
					disabled={mailConfigDataLoading || disabled}
					initialValues={initialValues}
				>
					<div className="font-weight-600 font-18">Mailing Draft</div>
					<div className="font-13 text-lighter pb-2">
						This shows the recipient and the structure of the emails that are
						being sent.
					</div>
					<Row gutter={20} style={{ marginBottom: '20px' }}>
						<Col xs={24}>
							<Form.Item
								label="To"
								name="TO"
								id="to"
								rules={[
									{
										required: true,
										message: 'Please enter To!',
									},
								]}
							>
								<Input
									disabled={userType !== 'admin' || disabled}
									placeholder="Please enter To"
								/>
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item label="CC" name="CC" id="cc">
								<Input placeholder="Please enter CC" />
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item label="BCC" name="BCC" id="bcc">
								<Input placeholder="Please enter BCC" />
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
								label="Subject"
								name="SUBJECT"
								rules={[
									{
										required: true,
										message: 'Please enter Subject!',
									},
								]}
							>
								<Input id="subject" placeholder="Please enter Subject" />
							</Form.Item>
						</Col>

						<Col xs={24}>
							<Form.Item
								label="Body"
								name="BODY"
								id="body"
								rules={[
									{
										required: true,
										message: 'Please enter Body!',
									},
								]}
							>
								<ReactQuill
									style={{
										opacity: disabled && 0.4,
										pointerEvents: disabled && 'none',
									}}
									readOnly={disabled}
									modules={modules}
									theme="snow"
									placeholder="Please enter Email Body"
								/>
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
								label="Attachment"
								name="FILE"
								id="attachment"
								style={{ marginBottom: '0' }}
							>
								<Upload {...props}>
									<Button type="bordered" disabled={fileList.length >= 5}>
										<IoMdAttach className={styles['attach-icon']} /> Upload
									</Button>
								</Upload>
								{fileList.length > 0 && (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: 10,
											flexWrap: 'wrap',
										}}
									>
										{fileList.map((file) => (
											<UploadedFile
												key={file?.uid}
												url={file?.name}
												style={{ marginTop: '5px' }}
												fileName={file?.name}
												onRemove={() => {
													setFileList(
														fileList.filter((el) => el.uid !== file.uid)
													);
													// form.setFieldsValue({ FILE: '' });
												}}
												canDownload
											/>
										))}
									</div>
								)}
							</Form.Item>
						</Col>
					</Row>
					<Divider
						style={{
							borderBlockStart: '1px solid rgb(216, 216, 216)',
							margin: '10px 0',
						}}
					/>
					<div className="font-weight-600 font-18">Your Personal Details</div>
					<div className="font-13 text-lighter pb-2">
						This shows the information that will be visible to the supplier(s)
						when they receive the email.
					</div>
					<Row gutter={10}>
						<Col xs={24}>
							<Form.Item
								label="Email"
								name="USER_EMAIL"
								rules={[
									{
										required: true,
										message: 'Please enter Email!',
									},
								]}
							>
								<Input disabled placeholder="Please enter Email" />
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
								style={{ marginBottom: '0' }}
								label="Signature"
								name="SIGNATURE"
								id="signature"
								rules={[
									{
										required: true,
										message: 'Please enter Signature!',
									},
								]}
							>
								<ReactQuill
									readOnly={disabled}
									style={{
										opacity: disabled && 0.4,
										pointerEvents: disabled && 'none',
									}}
									modules={modules}
									theme="snow"
									placeholder="Please enter Email Signature"
									id="signature"
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Drawer>
		</div>
	);
};

export default MailConfig;
