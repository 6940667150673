import React, { useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CgClose } from 'react-icons/cg';

import styles from './notificationBar.module.scss';

const NotificationBar = ({
	message,
	wraningBar,
	redBar,
	greenBar,
	hideClose,
	style,
	messageStyle,
	onClose,
}) => {
	const [closeNotification, setCloseNotification] = useState(true);
	return (
		<>
			{closeNotification && (
				<div
					style={style}
					className={`${styles['notification-bar']} ${
						wraningBar
							? styles['bg-yellow']
							: redBar
							? styles['bg-red']
							: greenBar
							? styles['bg-green']
							: styles['light-blue']
					}`}
				>
					<div className={styles['content']}>
						<div className={styles['notification']}>
							<InfoCircleOutlined />
							<span className={styles['text']} style={messageStyle}>
								{message}
							</span>
						</div>
						{hideClose ? (
							''
						) : (
							<CgClose
								className={styles['close']}
								onClick={() => {
									setCloseNotification(false);
									onClose();
								}}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default NotificationBar;
