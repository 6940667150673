import React, { useContext, useState } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { Avatar, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { MdMail } from 'react-icons/md';
import { LoadingOutlined } from '@ant-design/icons';

import ToolTip from '../tooltip';
import EdenContext from '../../context/edenContext';
import Button from '../button';
import { avatarBgColor } from '../../constant';

import styles from './mailPreview.module.scss';

const MailPreview = ({ value, mailPreview, data, color, previewTooltip }) => {
	const { messageURl } = useContext(EdenContext);
	const [drawerMail, setDrawerMail] = useState(false);
	const [drawerMailData, setDrawerMailData] = useState(null);
	const [drawerMailLoading, setDrawerMailLoading] = useState(false);

	/**
	 * Preview mail
	 */
	const openDrawer = (cid) => {
		setDrawerMail(true);
		setDrawerMailLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=${
					mailPreview === 'V2' ? 'get_mail_html_for_register' : 'get_mail_html'
				}&cid=${cid}`
			)
			.then((response) => {
				setDrawerMailData(response.data);
			})
			.finally(() => {
				setDrawerMailLoading(false);
			});
	};

	const closeDrawer = () => {
		setDrawerMail(!drawerMail);
		setDrawerMailData(null);
	};

	const trimmedString = value?.replace(/=+$/, '');

	return (
		<>
			{value ? (
				<ToolTip
					title={`${previewTooltip ? previewTooltip : 'Preview mail'}`}
					placement={previewTooltip && 'bottomLeft'}
				>
					<MdMail
						onClick={(e) => {
							if (e.ctrlKey && e.button === 0) {
								window.open(
									`${messageURl?.url}${trimmedString}%3D.eml`,
									'_blank'
								);
							} else {
								openDrawer(value);
							}
						}}
						style={{
							fontSize: '16px',
							cursor: 'pointer',
							color: color ? color : 'black',
						}}
					/>
				</ToolTip>
			) : (
				''
			)}

			{/* Mail Preview */}
			{drawerMail && (
				<Drawer
					title={
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<p className={styles['mail-subject']}>
								<ToolTip placement="bottomLeft" title={drawerMailData?.subject}>
									{drawerMailData?.subject}
								</ToolTip>
							</p>
							<Link
								style={{ textDecoration: 'none' }}
								target="_blank"
								to={`${messageURl?.url}${trimmedString}%3D.eml`}
							>
								<Button type="bordered">Download Mail</Button>
							</Link>
						</div>
					}
					placement="right"
					onClose={closeDrawer}
					open={drawerMail}
					size={'large'}
				>
					{drawerMailLoading && <LoadingOutlined />}
					{drawerMailData && (
						<>
							<div className={styles['mail-details-wrapper']}>
								<div className={styles['mail-user-info']}>
									<Avatar
										className={styles['avatar']}
										style={{
											backgroundColor:
												avatarBgColor[drawerMailData?.fromName?.charAt(0)] ||
												avatarBgColor.default,
										}}
									>
										{drawerMailData?.fromName?.charAt(0)?.toUpperCase()}
									</Avatar>
									<div className={styles['users-info']}>
										<p className={styles['sender-name']}>
											{drawerMailData?.fromName}
										</p>
										{drawerMailData?.toRecipients && (
											<p className={styles['to']}>
												<span className={styles['title']}>To:</span>{' '}
												{drawerMailData?.toRecipients}
											</p>
										)}
										{drawerMailData?.ccRecipients && (
											<p className={styles['cc']}>
												<span className={styles['title']}>Cc:</span>{' '}
												{drawerMailData?.ccRecipients}
											</p>
										)}
										{drawerMailData?.bccRecipients && (
											<p className={styles['bcc']}>
												<span className={styles['title']}>Bcc:</span>{' '}
												{drawerMailData?.bccRecipients}
											</p>
										)}
									</div>
								</div>

								<p className={styles['date-info']}>
									{moment
										.utc(drawerMailData?.receivedDateTime)
										.tz('Asia/Singapore')
										.format('MMM-DD-YYYY hh:mm A')}
								</p>
							</div>
							<div dangerouslySetInnerHTML={{ __html: drawerMailData?.html }} />
						</>
					)}
				</Drawer>
			)}
		</>
	);
};

export default MailPreview;
