import PropTypes from 'prop-types';
import { Button as AntdButton } from 'antd';

import styles from './button.module.scss';

const Button = ({
	children,
	type,
	size,
	disabled,
	loading,
	onClick,
	htmlType,
	color,
	style,
	id,
}) => {
	return (
		<>
			{type === 'bordered' ? (
				<AntdButton
					size={size}
					disabled={disabled}
					loading={loading}
					onClick={onClick}
					htmlType={htmlType}
					style={style}
					id={id}
					className={`${styles['border-button']} ${
						styles[`border-btn-${color}`]
					}`}
				>
					{children}
				</AntdButton>
			) : type === 'xs' ? (
				<AntdButton
					size={size}
					disabled={disabled}
					loading={loading}
					onClick={onClick}
					htmlType={htmlType}
					id={id}
					style={style}
					className={`${styles['button-xs']} ${styles[`btn-${color}`]}`}
				>
					{children}
				</AntdButton>
			) : (
				<AntdButton
					size={size}
					disabled={disabled}
					loading={loading}
					onClick={onClick}
					htmlType={htmlType}
					id={id}
					style={style}
					className={`${styles['button']} ${styles[`btn-${color}`]}`}
				>
					{children}
				</AntdButton>
			)}
		</>
	);
};

Button.defaultProps = {
	color: 'dark',
};

Button.propTypes = {
	children: PropTypes.node.isRequired,
	color: PropTypes.string,
};

export default Button;
