export const avatarBgColor = {
	A: '#1ABC9C',
	B: '#16A085',
	C: '#F1C40F',
	D: '#F39C12',
	E: '#2ECC71',
	F: '#27AE60',
	G: '#EF8D4D',
	H: '#D35400',
	I: '#3498DB',
	J: '#2980B9',
	K: '#E74C3C',
	L: '#C0392B',
	M: '#9B59B6',
	N: '#8E44AD',
	O: '#BDC3C7',
	P: '#34495E',
	Q: '#34495E',
	R: '#95A5A6',
	S: '#7F8C8D',
	T: '#EC87BF',
	U: '#D870AD',
	V: '#F69785',
	W: '#9BA37E',
	X: '#C9A669',
	Y: '#B49255',
	Z: '#001433',
	default: '#A94136',
};

export const rfqbyAccountTable1 = [
	{
		id: 1,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail1',
		RFQID: 'VISAT/A001234565',
		SalesRep: 'SalesRep1',
		CustomerAccount: 'VISATRONIC',
		ContactName: 'Simon',
		EmailAddress: 'simon.muratore@gmail.com',
		CCedContacts: 'N/A',
		type: 'Trader/Broke',
		RFQDateTime: '21-04-23 11:24',
		noofline: 1,
		SubjectLine: 'RFQ #124',
		RFQType: 'Unstructured',
		RFQFoundIn: 'Subject Line',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		cfcount: '2',
		TargetPrice: '',
		ChipFinderStatus: 100,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 2,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail2',
		RFQID: 'VISAT/A001234565',
		SalesRep: 'SalesRep2',
		CustomerAccount: 'VISATRONIC',
		ContactName: 'Simon',
		EmailAddress: 'simon.muratore@gmail.com',
		CCedContacts: 'N/A',
		type: 'Trader/Broke',
		RFQDateTime: '21-04-23 11:24',
		noofline: 3,
		SubjectLine: 'RFQ #124',
		RFQType: 'Unstructured',
		RFQFoundIn: 'Body',
		MPN: '1N4148W-TP',
		MFRBrand: 'MICROCHIP',
		QtyReq: '2,400',
		TargetPrice: '2.30',
		cfcount: '1',
		ChipFinderStatus: 50,
		// details: [
		// 	{
		// 		id: 1,
		// 		actionCenter: '',
		// 		salesrepemail: '',
		// 		RFQID: '',
		// 		SalesRep: '',
		// 		CustomerAccount: '',
		// 		ContactName: '',
		// 		EmailAddress: '',
		// 		CCedContacts: '',
		// 		type: '',
		// 		RFQDateTime: '',
		// 		noofline: '',
		// 		SubjectLine: '',
		// 		RFQType: '',
		// 		RFQFoundIn: '',
		// 		MPN: 'ATXMEGA128A1-AU',
		// 		MFRBrand: 'MICROCHIP',
		// 		QtyReq: '100',
		// 		TargetPrice: '8.20',
		// 		ChipFinderStatus: '',
		// 	},
		// 	{
		// 		id: 2,
		// 		actionCenter: '',
		// 		salesrepemail: '',
		// 		RFQID: '',
		// 		SalesRep: '',
		// 		CustomerAccount: '',
		// 		ContactName: '',
		// 		EmailAddress: '',
		// 		CCedContacts: '',
		// 		type: '',
		// 		RFQDateTime: '',
		// 		noofline: '',
		// 		SubjectLine: '',
		// 		RFQType: '',
		// 		RFQFoundIn: '',
		// 		MPN: 'BAT54SLT1G',
		// 		MFRBrand: 'ONSEMI',
		// 		QtyReq: '5000',
		// 		TargetPrice: '0..0165',
		// 		ChipFinderStatus: '',
		// 	},
		// ],
	},
	{
		id: 3,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail3',
		RFQID: 'VISAT/A001234565',
		SalesRep: 'SalesRep3',
		CustomerAccount: 'VISATRONIC',
		ContactName: 'Simon',
		EmailAddress: 'simon.muratore@gmail.com',
		CCedContacts: 'N/A',
		type: 'Trader/Broke',
		RFQDateTime: '21-04-23 11:24',
		noofline: 4,
		SubjectLine: 'RFQ #124',
		RFQType: 'Unstructured',
		RFQFoundIn: 'Attachment',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		TargetPrice: '',
		cfcount: '2',
		ChipFinderStatus: 70,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 4,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail4',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep4',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Simon',
		EmailAddress: 'simon.muratore@gmail.com',
		CCedContacts: 'N/A',
		type: 'Trader/Broke',
		RFQDateTime: '21-04-23 11:24',
		noofline: 6,
		SubjectLine: 'Micro Quote',
		RFQType: 'Unstructured',
		RFQFoundIn: 'Subject Line',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		TargetPrice: '',
		cfcount: '2',
		ChipFinderStatus: 60,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 5,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail5',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep5',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Ricardo',
		EmailAddress: 'ricardo.briseno@gmail.com',
		CCedContacts: 'ana.romero@gmail.com',
		type: 'EMS',
		RFQDateTime: '21-04-23 11:24',
		noofline: 4,
		SubjectLine: 'Micro Quote',
		RFQType: 'Unstructured',
		RFQFoundIn: 'Subject Line',
		MPN: 'ATMEGA328P-AU',
		MFRBrand: 'ONSEMI',
		QtyReq: '12,500',
		TargetPrice: '0.0165',
		cfcount: '1',
		ChipFinderStatus: 40,
		// details: [
		// 	{
		// 		id: 1,
		// 		actionCenter: '',
		// 		salesrepemail: '',
		// 		RFQID: '',
		// 		SalesRep: '',
		// 		CustomerAccount: '',
		// 		ContactName: '',
		// 		EmailAddress: '',
		// 		CCedContacts: '',
		// 		type: '',
		// 		RFQDateTime: '',
		// 		noofline: '',
		// 		SubjectLine: '',
		// 		RFQType: '',
		// 		RFQFoundIn: '',
		// 		MPN: 'ATXMEGA128A1-AU',
		// 		MFRBrand: 'MICROCHIP',
		// 		QtyReq: '100',
		// 		TargetPrice: '8.20',
		// 		ChipFinderStatus: '',
		// 	},
		// 	{
		// 		id: 2,
		// 		actionCenter: '',
		// 		salesrepemail: '',
		// 		RFQID: '',
		// 		SalesRep: '',
		// 		CustomerAccount: '',
		// 		ContactName: '',
		// 		EmailAddress: '',
		// 		CCedContacts: '',
		// 		type: '',
		// 		RFQDateTime: '',
		// 		noofline: '',
		// 		SubjectLine: '',
		// 		RFQType: '',
		// 		RFQFoundIn: '',
		// 		MPN: 'BAT54SLT1G',
		// 		MFRBrand: 'ONSEMI',
		// 		QtyReq: '5000',
		// 		TargetPrice: '0..0165',
		// 		ChipFinderStatus: '',
		// 	},
		// ],
	},
	{
		id: 6,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail6',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep6',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Ricardo',
		EmailAddress: 'ricardo.briseno@gmail.com',
		CCedContacts: 'ana.romero@gmail.com',
		type: 'EMS',
		RFQDateTime: '21-04-23 11:24',
		noofline: 8,
		SubjectLine: 'Micro Quote',
		RFQType: 'Structured',
		RFQFoundIn: 'Body',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		TargetPrice: '',
		cfcount: '2',
		ChipFinderStatus: 90,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 7,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail7',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep7',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Ricardo',
		EmailAddress: 'ricardo.briseno@gmail.com',
		CCedContacts: 'ana.romero@gmail.com',
		type: 'EMS',
		RFQDateTime: '21-04-23 11:24',
		noofline: 9,
		SubjectLine: 'Micro Quote',
		RFQType: 'Structured',
		RFQFoundIn: 'Attachment',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		TargetPrice: '',
		cfcount: '2',
		ChipFinderStatus: 100,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 8,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail8',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep8',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Ricardo',
		EmailAddress: 'ricardo.briseno@gmail.com',
		CCedContacts: 'ana.romero@gmail.com',
		type: 'EMS',
		RFQDateTime: '21-04-23 11:24',
		noofline: 6,
		SubjectLine: 'Micro Quote',
		RFQType: 'Structured',
		RFQFoundIn: 'Attachment',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		TargetPrice: '',
		cfcount: '2',
		ChipFinderStatus: 70,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 9,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail9',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep9',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Ricardo',
		EmailAddress: 'ricardo.briseno@gmail.com',
		CCedContacts: 'ana.romero@gmail.com',
		type: 'EMS',
		RFQDateTime: '21-04-23 11:24',
		noofline: 5,
		SubjectLine: 'Micro Quote',
		RFQType: 'Structured',
		RFQFoundIn: 'Subject line',
		MPN: 'ATMEGA328P-AU',
		MFRBrand: 'MICTOCHIP',
		QtyReq: '12,500',
		TargetPrice: '0.0165',
		cfcount: '1',
		ChipFinderStatus: 20,
		// details: [
		// 	{
		// 		id: 1,
		// 		actionCenter: '',
		// 		salesrepemail: '',
		// 		RFQID: '',
		// 		SalesRep: '',
		// 		CustomerAccount: '',
		// 		ContactName: '',
		// 		EmailAddress: '',
		// 		CCedContacts: '',
		// 		type: '',
		// 		RFQDateTime: '',
		// 		noofline: '',
		// 		SubjectLine: '',
		// 		RFQType: '',
		// 		RFQFoundIn: '',
		// 		MPN: 'ATXMEGA128A1-AU',
		// 		MFRBrand: 'MICROCHIP',
		// 		QtyReq: '100',
		// 		TargetPrice: '8.20',
		// 		ChipFinderStatus: '',
		// 	},
		// 	{
		// 		id: 2,
		// 		actionCenter: '',
		// 		salesrepemail: '',
		// 		RFQID: '',
		// 		SalesRep: '',
		// 		CustomerAccount: '',
		// 		ContactName: '',
		// 		EmailAddress: '',
		// 		CCedContacts: '',
		// 		type: '',
		// 		RFQDateTime: '',
		// 		noofline: '',
		// 		SubjectLine: '',
		// 		RFQType: '',
		// 		RFQFoundIn: '',
		// 		MPN: 'BAT54SLT1G',
		// 		MFRBrand: 'ONSEMI',
		// 		QtyReq: '5000',
		// 		TargetPrice: '0..0165',
		// 		ChipFinderStatus: '',
		// 	},
		// ],
	},
	{
		id: 10,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail10',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep10',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Ricardo',
		EmailAddress: 'ricardo.briseno@gmail.com',
		CCedContacts: 'ana.romero@gmail.com',
		type: 'EMS',
		RFQDateTime: '21-04-23 11:24',
		noofline: 4,
		SubjectLine: 'Micro Quote',
		RFQType: 'Structured',
		RFQFoundIn: 'Body',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		TargetPrice: '',
		cfcount: '2',
		ChipFinderStatus: 100,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 1,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail1',
		RFQID: 'VISAT/A001234565',
		SalesRep: 'SalesRep1',
		CustomerAccount: 'VISATRONIC',
		ContactName: 'Simon',
		EmailAddress: 'simon.muratore@gmail.com',
		CCedContacts: 'N/A',
		type: 'Trader/Broke',
		RFQDateTime: '21-04-23 11:24',
		noofline: 1,
		SubjectLine: 'RFQ #124',
		RFQType: 'Unstructured',
		RFQFoundIn: 'Subject Line',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		cfcount: '2',
		TargetPrice: '',
		ChipFinderStatus: 100,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 2,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail2',
		RFQID: 'VISAT/A001234565',
		SalesRep: 'SalesRep2',
		CustomerAccount: 'VISATRONIC',
		ContactName: 'Simon',
		EmailAddress: 'simon.muratore@gmail.com',
		CCedContacts: 'N/A',
		type: 'Trader/Broke',
		RFQDateTime: '21-04-23 11:24',
		noofline: 3,
		SubjectLine: 'RFQ #124',
		RFQType: 'Unstructured',
		RFQFoundIn: 'Body',
		MPN: '1N4148W-TP',
		MFRBrand: 'MICROCHIP',
		QtyReq: '2,400',
		TargetPrice: '2.30',
		cfcount: '1',
		ChipFinderStatus: 50,
		// details: [
		// 	{
		// 		id: 1,
		// 		actionCenter: '',
		// 		salesrepemail: '',
		// 		RFQID: '',
		// 		SalesRep: '',
		// 		CustomerAccount: '',
		// 		ContactName: '',
		// 		EmailAddress: '',
		// 		CCedContacts: '',
		// 		type: '',
		// 		RFQDateTime: '',
		// 		noofline: '',
		// 		SubjectLine: '',
		// 		RFQType: '',
		// 		RFQFoundIn: '',
		// 		MPN: 'ATXMEGA128A1-AU',
		// 		MFRBrand: 'MICROCHIP',
		// 		QtyReq: '100',
		// 		TargetPrice: '8.20',
		// 		ChipFinderStatus: '',
		// 	},
		// 	{
		// 		id: 2,
		// 		actionCenter: '',
		// 		salesrepemail: '',
		// 		RFQID: '',
		// 		SalesRep: '',
		// 		CustomerAccount: '',
		// 		ContactName: '',
		// 		EmailAddress: '',
		// 		CCedContacts: '',
		// 		type: '',
		// 		RFQDateTime: '',
		// 		noofline: '',
		// 		SubjectLine: '',
		// 		RFQType: '',
		// 		RFQFoundIn: '',
		// 		MPN: 'BAT54SLT1G',
		// 		MFRBrand: 'ONSEMI',
		// 		QtyReq: '5000',
		// 		TargetPrice: '0..0165',
		// 		ChipFinderStatus: '',
		// 	},
		// ],
	},
	{
		id: 3,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail3',
		RFQID: 'VISAT/A001234565',
		SalesRep: 'SalesRep3',
		CustomerAccount: 'VISATRONIC',
		ContactName: 'Simon',
		EmailAddress: 'simon.muratore@gmail.com',
		CCedContacts: 'N/A',
		type: 'Trader/Broke',
		RFQDateTime: '21-04-23 11:24',
		noofline: 4,
		SubjectLine: 'RFQ #124',
		RFQType: 'Unstructured',
		RFQFoundIn: 'Attachment',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		TargetPrice: '',
		cfcount: '2',
		ChipFinderStatus: 70,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 4,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail4',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep4',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Simon',
		EmailAddress: 'simon.muratore@gmail.com',
		CCedContacts: 'N/A',
		type: 'Trader/Broke',
		RFQDateTime: '21-04-23 11:24',
		noofline: 6,
		SubjectLine: 'Micro Quote',
		RFQType: 'Unstructured',
		RFQFoundIn: 'Subject Line',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		TargetPrice: '',
		cfcount: '2',
		ChipFinderStatus: 60,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 5,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail5',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep5',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Ricardo',
		EmailAddress: 'ricardo.briseno@gmail.com',
		CCedContacts: 'ana.romero@gmail.com',
		type: 'EMS',
		RFQDateTime: '21-04-23 11:24',
		noofline: 4,
		SubjectLine: 'Micro Quote',
		RFQType: 'Unstructured',
		RFQFoundIn: 'Subject Line',
		MPN: 'ATMEGA328P-AU',
		MFRBrand: 'ONSEMI',
		QtyReq: '12,500',
		TargetPrice: '0.0165',
		cfcount: '1',
		ChipFinderStatus: 40,
		// details: [
		// 	{
		// 		id: 1,
		// 		actionCenter: '',
		// 		salesrepemail: '',
		// 		RFQID: '',
		// 		SalesRep: '',
		// 		CustomerAccount: '',
		// 		ContactName: '',
		// 		EmailAddress: '',
		// 		CCedContacts: '',
		// 		type: '',
		// 		RFQDateTime: '',
		// 		noofline: '',
		// 		SubjectLine: '',
		// 		RFQType: '',
		// 		RFQFoundIn: '',
		// 		MPN: 'ATXMEGA128A1-AU',
		// 		MFRBrand: 'MICROCHIP',
		// 		QtyReq: '100',
		// 		TargetPrice: '8.20',
		// 		ChipFinderStatus: '',
		// 	},
		// 	{
		// 		id: 2,
		// 		actionCenter: '',
		// 		salesrepemail: '',
		// 		RFQID: '',
		// 		SalesRep: '',
		// 		CustomerAccount: '',
		// 		ContactName: '',
		// 		EmailAddress: '',
		// 		CCedContacts: '',
		// 		type: '',
		// 		RFQDateTime: '',
		// 		noofline: '',
		// 		SubjectLine: '',
		// 		RFQType: '',
		// 		RFQFoundIn: '',
		// 		MPN: 'BAT54SLT1G',
		// 		MFRBrand: 'ONSEMI',
		// 		QtyReq: '5000',
		// 		TargetPrice: '0..0165',
		// 		ChipFinderStatus: '',
		// 	},
		// ],
	},
	{
		id: 6,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail6',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep6',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Ricardo',
		EmailAddress: 'ricardo.briseno@gmail.com',
		CCedContacts: 'ana.romero@gmail.com',
		type: 'EMS',
		RFQDateTime: '21-04-23 11:24',
		noofline: 8,
		SubjectLine: 'Micro Quote',
		RFQType: 'Structured',
		RFQFoundIn: 'Body',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		TargetPrice: '',
		cfcount: '2',
		ChipFinderStatus: 90,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 7,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail7',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep7',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Ricardo',
		EmailAddress: 'ricardo.briseno@gmail.com',
		CCedContacts: 'ana.romero@gmail.com',
		type: 'EMS',
		RFQDateTime: '21-04-23 11:24',
		noofline: 9,
		SubjectLine: 'Micro Quote',
		RFQType: 'Structured',
		RFQFoundIn: 'Attachment',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		TargetPrice: '',
		cfcount: '2',
		ChipFinderStatus: 100,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 8,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail8',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep8',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Ricardo',
		EmailAddress: 'ricardo.briseno@gmail.com',
		CCedContacts: 'ana.romero@gmail.com',
		type: 'EMS',
		RFQDateTime: '21-04-23 11:24',
		noofline: 6,
		SubjectLine: 'Micro Quote',
		RFQType: 'Structured',
		RFQFoundIn: 'Attachment',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		TargetPrice: '',
		cfcount: '2',
		ChipFinderStatus: 70,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
	{
		id: 9,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail9',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep9',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Ricardo',
		EmailAddress: 'ricardo.briseno@gmail.com',
		CCedContacts: 'ana.romero@gmail.com',
		type: 'EMS',
		RFQDateTime: '21-04-23 11:24',
		noofline: 5,
		SubjectLine: 'Micro Quote',
		RFQType: 'Structured',
		RFQFoundIn: 'Subject line',
		MPN: 'ATMEGA328P-AU',
		MFRBrand: 'MICTOCHIP',
		QtyReq: '12,500',
		TargetPrice: '0.0165',
		cfcount: '1',
		ChipFinderStatus: 20,
		// details: [
		// 	{
		// 		id: 1,
		// 		actionCenter: '',
		// 		salesrepemail: '',
		// 		RFQID: '',
		// 		SalesRep: '',
		// 		CustomerAccount: '',
		// 		ContactName: '',
		// 		EmailAddress: '',
		// 		CCedContacts: '',
		// 		type: '',
		// 		RFQDateTime: '',
		// 		noofline: '',
		// 		SubjectLine: '',
		// 		RFQType: '',
		// 		RFQFoundIn: '',
		// 		MPN: 'ATXMEGA128A1-AU',
		// 		MFRBrand: 'MICROCHIP',
		// 		QtyReq: '100',
		// 		TargetPrice: '8.20',
		// 		ChipFinderStatus: '',
		// 	},
		// 	{
		// 		id: 2,
		// 		actionCenter: '',
		// 		salesrepemail: '',
		// 		RFQID: '',
		// 		SalesRep: '',
		// 		CustomerAccount: '',
		// 		ContactName: '',
		// 		EmailAddress: '',
		// 		CCedContacts: '',
		// 		type: '',
		// 		RFQDateTime: '',
		// 		noofline: '',
		// 		SubjectLine: '',
		// 		RFQType: '',
		// 		RFQFoundIn: '',
		// 		MPN: 'BAT54SLT1G',
		// 		MFRBrand: 'ONSEMI',
		// 		QtyReq: '5000',
		// 		TargetPrice: '0..0165',
		// 		ChipFinderStatus: '',
		// 	},
		// ],
	},
	{
		id: 10,
		actionCenter: 'actionCenter',
		salesrepemail: 'salesrepemail10',
		RFQID: 'ONCOR/A001234565',
		SalesRep: 'SalesRep10',
		CustomerAccount: 'ONCORE MA',
		ContactName: 'Ricardo',
		EmailAddress: 'ricardo.briseno@gmail.com',
		CCedContacts: 'ana.romero@gmail.com',
		type: 'EMS',
		RFQDateTime: '21-04-23 11:24',
		noofline: 4,
		SubjectLine: 'Micro Quote',
		RFQType: 'Structured',
		RFQFoundIn: 'Body',
		MPN: '',
		MFRBrand: '',
		QtyReq: '',
		TargetPrice: '',
		cfcount: '2',
		ChipFinderStatus: 100,
		details: [
			{
				id: 1,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'ATXMEGA128A1-AU',
				MFRBrand: 'MICROCHIP',
				QtyReq: '100',
				TargetPrice: '8.20',
				ChipFinderStatus: '',
			},
			{
				id: 2,
				actionCenter: '',
				salesrepemail: '',
				RFQID: '',
				SalesRep: '',
				CustomerAccount: '',
				ContactName: '',
				EmailAddress: '',
				CCedContacts: '',
				type: '',
				RFQDateTime: '',
				noofline: '',
				SubjectLine: '',
				RFQType: '',
				RFQFoundIn: '',
				MPN: 'BAT54SLT1G',
				MFRBrand: 'ONSEMI',
				QtyReq: '5000',
				TargetPrice: '0..0165',
				ChipFinderStatus: '',
			},
		],
	},
];

export const rfqbyAccountTable2 = [
	{
		sno: 1,
		receiveddatatime: '21-04-23 11:24',
		emailSender: 'aamirahmed.s@flex.com',
		ccedContacts: '',
		crmAccount: 'FLEXTRONICS',
		subjectLine: 'RFQ-12.02.23',
		previewEmail: 'previewEmail1',
		markAsRfq: '',
	},
	{
		sno: 2,
		receiveddatatime: '12-2-2023 14:12',
		emailSender: 'lyndon.sevilla@schneider-electric.com',
		ccedContacts: 'abigael.solivar@schneider-electric.com',
		crmAccount: 'AMERICAN POWER CONVERSION (A.P.C) B.V.',
		subjectLine: 'Quote for 2n2222A',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 3,
		receiveddatatime: '14-2-2023 23:48',
		emailSender: 'prathap@amararaja.co.in',
		ccedContacts: '',
		crmAccount: 'AMARA RAJA ELECTRONICS LIMITED',
		subjectLine: 'Shipment status update',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 4,
		receiveddatatime: '17-2-2023 7:00',
		emailSender: 'elisabetta.scandroglio@elemaster.it',
		ccedContacts: '',
		crmAccount: 'ELEMASTER SPA',
		subjectLine: 'Provide LOA',
		previewEmail: '',
		markAsRfq: '',
	},

	{
		sno: 1,
		receiveddatatime: '21-04-23 11:24',
		emailSender: 'aamirahmed.s@flex.com',
		ccedContacts: '',
		crmAccount: 'FLEXTRONICS',
		subjectLine: 'RFQ-12.02.23',
		previewEmail: 'previewEmail1',
		markAsRfq: '',
	},
	{
		sno: 2,
		receiveddatatime: '12-2-2023 14:12',
		emailSender: 'lyndon.sevilla@schneider-electric.com',
		ccedContacts: 'abigael.solivar@schneider-electric.com',
		crmAccount: 'AMERICAN POWER CONVERSION (A.P.C) B.V.',
		subjectLine: 'Quote for 2n2222A',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 3,
		receiveddatatime: '14-2-2023 23:48',
		emailSender: 'prathap@amararaja.co.in',
		ccedContacts: '',
		crmAccount: 'AMARA RAJA ELECTRONICS LIMITED',
		subjectLine: 'Shipment status update',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 4,
		receiveddatatime: '17-2-2023 7:00',
		emailSender: 'elisabetta.scandroglio@elemaster.it',
		ccedContacts: '',
		crmAccount: 'ELEMASTER SPA',
		subjectLine: 'Provide LOA',
		previewEmail: '',
		markAsRfq: '',
	},

	{
		sno: 1,
		receiveddatatime: '21-04-23 11:24',
		emailSender: 'aamirahmed.s@flex.com',
		ccedContacts: '',
		crmAccount: 'FLEXTRONICS',
		subjectLine: 'RFQ-12.02.23',
		previewEmail: 'previewEmail1',
		markAsRfq: '',
	},
	{
		sno: 2,
		receiveddatatime: '12-2-2023 14:12',
		emailSender: 'lyndon.sevilla@schneider-electric.com',
		ccedContacts: 'abigael.solivar@schneider-electric.com',
		crmAccount: 'AMERICAN POWER CONVERSION (A.P.C) B.V.',
		subjectLine: 'Quote for 2n2222A',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 3,
		receiveddatatime: '14-2-2023 23:48',
		emailSender: 'prathap@amararaja.co.in',
		ccedContacts: '',
		crmAccount: 'AMARA RAJA ELECTRONICS LIMITED',
		subjectLine: 'Shipment status update',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 4,
		receiveddatatime: '17-2-2023 7:00',
		emailSender: 'elisabetta.scandroglio@elemaster.it',
		ccedContacts: '',
		crmAccount: 'ELEMASTER SPA',
		subjectLine: 'Provide LOA',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 1,
		receiveddatatime: '21-04-23 11:24',
		emailSender: 'aamirahmed.s@flex.com',
		ccedContacts: '',
		crmAccount: 'FLEXTRONICS',
		subjectLine: 'RFQ-12.02.23',
		previewEmail: 'previewEmail1',
		markAsRfq: '',
	},
	{
		sno: 2,
		receiveddatatime: '12-2-2023 14:12',
		emailSender: 'lyndon.sevilla@schneider-electric.com',
		ccedContacts: 'abigael.solivar@schneider-electric.com',
		crmAccount: 'AMERICAN POWER CONVERSION (A.P.C) B.V.',
		subjectLine: 'Quote for 2n2222A',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 3,
		receiveddatatime: '14-2-2023 23:48',
		emailSender: 'prathap@amararaja.co.in',
		ccedContacts: '',
		crmAccount: 'AMARA RAJA ELECTRONICS LIMITED',
		subjectLine: 'Shipment status update',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 4,
		receiveddatatime: '17-2-2023 7:00',
		emailSender: 'elisabetta.scandroglio@elemaster.it',
		ccedContacts: '',
		crmAccount: 'ELEMASTER SPA',
		subjectLine: 'Provide LOA',
		previewEmail: '',
		markAsRfq: '',
	},

	{
		sno: 1,
		receiveddatatime: '21-04-23 11:24',
		emailSender: 'aamirahmed.s@flex.com',
		ccedContacts: '',
		crmAccount: 'FLEXTRONICS',
		subjectLine: 'RFQ-12.02.23',
		previewEmail: 'previewEmail1',
		markAsRfq: '',
	},
	{
		sno: 2,
		receiveddatatime: '12-2-2023 14:12',
		emailSender: 'lyndon.sevilla@schneider-electric.com',
		ccedContacts: 'abigael.solivar@schneider-electric.com',
		crmAccount: 'AMERICAN POWER CONVERSION (A.P.C) B.V.',
		subjectLine: 'Quote for 2n2222A',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 3,
		receiveddatatime: '14-2-2023 23:48',
		emailSender: 'prathap@amararaja.co.in',
		ccedContacts: '',
		crmAccount: 'AMARA RAJA ELECTRONICS LIMITED',
		subjectLine: 'Shipment status update',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 4,
		receiveddatatime: '17-2-2023 7:00',
		emailSender: 'elisabetta.scandroglio@elemaster.it',
		ccedContacts: '',
		crmAccount: 'ELEMASTER SPA',
		subjectLine: 'Provide LOA',
		previewEmail: '',
		markAsRfq: '',
	},

	{
		sno: 1,
		receiveddatatime: '21-04-23 11:24',
		emailSender: 'aamirahmed.s@flex.com',
		ccedContacts: '',
		crmAccount: 'FLEXTRONICS',
		subjectLine: 'RFQ-12.02.23',
		previewEmail: 'previewEmail1',
		markAsRfq: '',
	},
	{
		sno: 2,
		receiveddatatime: '12-2-2023 14:12',
		emailSender: 'lyndon.sevilla@schneider-electric.com',
		ccedContacts: 'abigael.solivar@schneider-electric.com',
		crmAccount: 'AMERICAN POWER CONVERSION (A.P.C) B.V.',
		subjectLine: 'Quote for 2n2222A',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 3,
		receiveddatatime: '14-2-2023 23:48',
		emailSender: 'prathap@amararaja.co.in',
		ccedContacts: '',
		crmAccount: 'AMARA RAJA ELECTRONICS LIMITED',
		subjectLine: 'Shipment status update',
		previewEmail: '',
		markAsRfq: '',
	},
	{
		sno: 4,
		receiveddatatime: '17-2-2023 7:00',
		emailSender: 'elisabetta.scandroglio@elemaster.it',
		ccedContacts: '',
		crmAccount: 'ELEMASTER SPA',
		subjectLine: 'Provide LOA',
		previewEmail: '',
		markAsRfq: '',
	},
];

export const backorderSitesData = [
	{
		id: 1,
		type: 'Distributor',
		name: 'Element 14',
		hyperlink: 'https://sg.element14.com/',
	},
	{
		id: 2,
		type: 'Distributor',
		name: 'Digikey',
		hyperlink: 'www.digikey.com',
	},
	{
		id: 3,
		type: 'Distributor',
		name: 'Mouser',
		hyperlink: 'www.mouser.sg',
	},
	{
		id: 4,
		type: 'Distributor',
		name: 'TME',
		hyperlink: 'https://www.tme.eu/',
	},
	{
		id: 5,
		type: 'Distributor',
		name: 'Avnet USA',
		hyperlink: 'http://www.avnet.com',
	},
	{
		id: 6,
		type: 'Distributor',
		name: 'Avnet Europe',
		hyperlink: 'https://www.avnet.com/wps/portal/emea/',
	},
	{
		id: 7,
		type: 'Distributor',
		name: 'Avnet Asia',
		hyperlink: 'https://www.avnet.com/wps/portal/apac',
	},
	{
		id: 8,
		type: 'Distributor',
		name: 'Heilind Electronics',
		hyperlink: 'www.heilind.com',
	},
	{
		id: 9,
		type: 'Distributor',
		name: 'Heilind Europe',
		hyperlink: 'https://www.heilind.de/',
	},
	{
		id: 10,
		type: 'Distributor',
		name: 'Heilind Asia',
		hyperlink: 'www.heilindasia.com',
	},
	{
		id: 11,
		type: 'Distributor',
		name: 'Future Electronics',
		hyperlink: 'https://www.futureelectronics.com/',
	},
	{
		id: 12,
		type: 'Distributor',
		name: 'TTI USA',
		hyperlink: 'http://www.ttiofusa.com/',
	},
	{
		id: 13,
		type: 'Distributor',
		name: 'TTI Europe',
		hyperlink: 'https://www.ttieurope.com/',
	},
	{
		id: 14,
		type: 'Distributor',
		name: 'TTI Asia',
		hyperlink: 'https://www.ttiasia.com/',
	},
	{
		id: 15,
		type: 'Distributor',
		name: 'Newark',
		hyperlink: 'http://www.newark.com',
	},
	{
		id: 16,
		type: 'Distributor',
		name: 'Farnell',
		hyperlink: 'https://uk.farnell.com',
	},
	{
		id: 17,
		type: 'Distributor',
		name: 'Anglia',
		hyperlink: 'https://www.anglia.com.sg',
	},
	{
		id: 18,
		type: 'Distributor',
		name: 'Allied Electronics',
		hyperlink: 'http://www.ex-en.alliedelec.com',
	},
	{
		id: 19,
		type: 'Distributor',
		name: 'RS Components',
		hyperlink: 'http://www.sg.rs-online.com',
	},
	{
		id: 20,
		type: 'Distributor',
		name: 'Rochester Electronics',
		hyperlink: 'https://www.rocelec.com/',
	},
	{
		id: 21,
		type: 'Distributor',
		name: 'Corestaff',
		hyperlink: 'https://corestaff.co.jp/cms/en/index.html',
	},
	{
		id: 22,
		type: 'Distributor',
		name: 'Master Electronics',
		hyperlink: 'https://www.masterelectronics.com/en/',
	},
	{
		id: 23,
		type: 'IDM',
		name: 'Microchip',
		hyperlink:
			'https://www.microchipdirect.com/product/MIC5801YWM-TR?productLoaded=true',
	},
	{
		id: 24,
		type: 'IDM',
		name: 'Maxim',
		hyperlink:
			'https://www.maximintegrated.com/en/products/power/switching-regulators/MAX15303.html#order-quality',
	},
	{
		id: 25,
		type: 'IDM',
		name: 'STM',
		hyperlink: 'https://estore.st.com/en/catalogsearch/result/?q=ULN2803A',
	},
	{
		id: 26,
		type: 'IDM',
		name: 'Renesas',
		hyperlink:
			'https://www.renesas.com/sg/en/buy-sample/check-inventory/result?partno=R5F21256SNFP%23V2',
	},
	{
		id: 27,
		type: 'IDM',
		name: 'Samtec',
		hyperlink:
			'https://wwws.samtec.com//my-samtec/pricing.aspx?partnumber=slw-106-01-l-s',
	},
	{
		id: 28,
		type: 'IDM',
		name: 'CoilCraft',
		hyperlink: 'https://www.coilcraft.com/',
	},
];

export const mpnCostBookData = [
	{
		id: 1,
		queueid: 'Rohit',
		sr: 'Rohit',
		ss: 'Mikee',
		Mail: 'mikee@abc.com',
		inquiry_date: '20-06-2023',
		customer: 'John Doe',
		customer_email: 'john.doe@example.com',
		contact: '+1 (123) 456-7890',
		cpn: 'CPN12345',
		mpn_req: 'MPN67890',
		brand_req: 'XYZ Brand',
		qty_req: '100',
		fc_ext: 'Factory XYZ',
		mpn_found: 'MPN67890',
		brand_found: 'XYZ Brand',
		moq: '50',
		spq: '10',
		usd_cost: '5.00',
		ext_price: '500.00',
		dc: 'Discount applied',
		packaging: 'Standard packaging',
		lt_w_units: '5 days',
		cf_mm_online: 'Yes',
		mail: 'Sent',
		subject: 'Product Inquiry',
		source_date: '18-06-2023',
		supplier: 'Supplier Inc.',
		supplier_email: 'supplier@example.com',
		supplier_name: 'Supplier Inc.',
		days_open: '2',
		remarks: 'This is a sample inquiry.',
		order_received: 'Yes',
	},
	{
		id: 2,
		queueid: 'Alice',
		sr: 'Alice',
		ss: 'Bob',
		Mail: 'alice@xyz.com',
		inquiry_date: '22-06-2023',
		customer: 'Eve Smith',
		customer_email: 'eve.smith@example.com',
		contact: '+1 (987) 654-3210',
		cpn: 'CPN54321',
		mpn_req: 'MPN12345',
		brand_req: 'ABC Brand',
		qty_req: '200',
		fc_ext: 'Factory ABC',
		mpn_found: 'MPN12345',
		brand_found: 'ABC Brand',
		moq: '75',
		spq: '20',
		usd_cost: '6.50',
		ext_price: '1300.00',
		dc: 'No discount',
		packaging: 'Custom packaging',
		lt_w_units: '7 days',
		cf_mm_online: 'No',
		mail: 'Received',
		subject: 'Product Inquiry',
		source_date: '20-06-2023',
		supplier: 'Supplier Corp.',
		supplier_email: 'supplier@corp.com',
		supplier_name: 'Supplier Corp.',
		days_open: '3',
		remarks: 'This is another sample inquiry.',
		order_received: 'No',
	},
	{
		id: 3,
		queueid: 'David',
		sr: 'David',
		ss: 'Emily',
		Mail: 'david@example.com',
		inquiry_date: '24-06-2023',
		customer: 'Olivia Brown',
		customer_email: 'olivia.brown@example.com',
		contact: '+1 (555) 123-4567',
		cpn: 'CPN67890',
		mpn_req: 'MPN54321',
		brand_req: 'XYZ Brand',
		qty_req: '150',
		fc_ext: 'Factory XYZ',
		mpn_found: 'MPN54321',
		brand_found: 'XYZ Brand',
		moq: '60',
		spq: '15',
		usd_cost: '4.75',
		ext_price: '712.50',
		dc: '10% discount',
		packaging: 'Premium packaging',
		lt_w_units: '6 days',
		cf_mm_online: 'Yes',
		mail: 'Sent',
		subject: 'Product Inquiry',
		source_date: '22-06-2023',
		supplier: 'Suppliers R Us',
		supplier_email: 'suppliers@r.us',
		supplier_name: 'Suppliers R Us',
		days_open: '2',
		remarks: 'This is a third sample inquiry.',
		order_received: 'Yes',
	},
	{
		id: 4,
		queueid: 'Elena',
		sr: 'Elena',
		ss: 'Frank',
		Mail: 'elena@test.com',
		inquiry_date: '26-06-2023',
		customer: 'Michael Johnson',
		customer_email: 'michael.johnson@example.com',
		contact: '+1 (789) 456-1230',
		cpn: 'CPN98765',
		mpn_req: 'MPN67890',
		brand_req: 'Brand X',
		qty_req: '300',
		fc_ext: 'Factory X',
		mpn_found: 'MPN67890',
		brand_found: 'Brand X',
		moq: '100',
		spq: '25',
		usd_cost: '8.00',
		ext_price: '2400.00',
		dc: '5% discount',
		packaging: 'Eco-friendly packaging',
		lt_w_units: '8 days',
		cf_mm_online: 'Yes',
		mail: 'Received',
		subject: 'Product Inquiry',
		source_date: '24-06-2023',
		supplier: 'Best Suppliers Inc.',
		supplier_email: 'best@suppliers.com',
		supplier_name: 'Best Suppliers Inc.',
		days_open: '2',
		remarks: 'This is yet another sample inquiry.',
		order_received: 'Yes',
	},
	{
		id: 5,
		queueid: 'Grace',
		sr: 'Grace',
		ss: 'Henry',
		Mail: 'grace@example.com',
		inquiry_date: '28-06-2023',
		customer: 'Sophia Davis',
		customer_email: 'sophia.davis@example.com',
		contact: '+1 (111) 222-3333',
		cpn: 'CPN55555',
		mpn_req: 'MPN44444',
		brand_req: 'Brand Z',
		qty_req: '50',
		fc_ext: 'Factory Z',
		mpn_found: 'MPN44444',
		brand_found: 'Brand Z',
		moq: '25',
		spq: '5',
		usd_cost: '2.50',
		ext_price: '125.00',
		dc: '15% discount',
		packaging: 'Basic packaging',
		lt_w_units: '4 days',
		cf_mm_online: 'No',
		mail: 'Sent',
		subject: 'Product Inquiry',
		source_date: '26-06-2023',
		supplier: 'Suppliers Unlimited',
		supplier_email: 'suppliers@unlimited.com',
		supplier_name: 'Suppliers Unlimited',
		days_open: '2',
		remarks: 'This is a fourth sample inquiry.',
		order_received: 'No',
	},
	{
		id: 6,
		queueid: 'Ivy',
		sr: 'Ivy',
		ss: 'Jack',
		Mail: 'ivy@test.com',
		inquiry_date: '30-06-2023',
		customer: 'Liam Wilson',
		customer_email: 'liam.wilson@example.com',
		contact: '+1 (222) 333-4444',
		cpn: 'CPN77777',
		mpn_req: 'MPN66666',
		brand_req: 'Brand Y',
		qty_req: '75',
		fc_ext: 'Factory Y',
		mpn_found: 'MPN66666',
		brand_found: 'Brand Y',
		moq: '30',
		spq: '10',
		usd_cost: '3.75',
		ext_price: '281.25',
		dc: 'No discount',
		packaging: 'Standard packaging',
		lt_w_units: '5 days',
		cf_mm_online: 'Yes',
		mail: 'Received',
		subject: 'Product Inquiry',
		source_date: '28-06-2023',
		supplier: 'Global Suppliers Ltd.',
		supplier_email: 'global@suppliersltd.com',
		supplier_name: 'Global Suppliers Ltd.',
		days_open: '2',
		remarks: 'This is a fifth sample inquiry.',
		order_received: 'Yes',
	},
	{
		id: 7,
		queueid: 'James',
		sr: 'James',
		ss: 'Kate',
		Mail: 'james@example.com',
		inquiry_date: '02-07-2023',
		customer: 'Oliver White',
		customer_email: 'oliver.white@example.com',
		contact: '+1 (444) 555-6666',
		cpn: 'CPN22222',
		mpn_req: 'MPN11111',
		brand_req: 'Brand W',
		qty_req: '120',
		fc_ext: 'Factory W',
		mpn_found: 'MPN11111',
		brand_found: 'Brand W',
		moq: '40',
		spq: '8',
		usd_cost: '4.25',
		ext_price: '510.00',
		dc: '5% discount',
		packaging: 'Premium packaging',
		lt_w_units: '7 days',
		cf_mm_online: 'No',
		mail: 'Sent',
		subject: 'Product Inquiry',
		source_date: '30-06-2023',
		supplier: 'Suppliers Now',
		supplier_email: 'suppliers@now.com',
		supplier_name: 'Suppliers Now',
		days_open: '2',
		remarks: 'This is a sixth sample inquiry.',
		order_received: 'No',
	},
	{
		id: 8,
		queueid: 'Karen',
		sr: 'Karen',
		ss: 'Leo',
		Mail: 'karen@test.com',
		inquiry_date: '04-07-2023',
		customer: 'Lucas Taylor',
		customer_email: 'lucas.taylor@example.com',
		contact: '+1 (777) 888-9999',
		cpn: 'CPN33333',
		mpn_req: 'MPN22222',
		brand_req: 'Brand V',
		qty_req: '90',
		fc_ext: 'Factory V',
		mpn_found: 'MPN22222',
		brand_found: 'Brand V',
		moq: '35',
		spq: '7',
		usd_cost: '3.00',
		ext_price: '270.00',
		dc: '10% discount',
		packaging: 'Eco-friendly packaging',
		lt_w_units: '6 days',
		cf_mm_online: 'Yes',
		mail: 'Received',
		subject: 'Product Inquiry',
		source_date: '02-07-2023',
		supplier: 'Fast Suppliers Inc.',
		supplier_email: 'fast@suppliersinc.com',
		supplier_name: 'Fast Suppliers Inc.',
		days_open: '2',
		remarks: 'This is a seventh sample inquiry.',
		order_received: 'Yes',
	},
	{
		id: 9,
		queueid: 'Liam',
		sr: 'Liam',
		ss: 'Mia',
		Mail: 'liam@example.com',
		inquiry_date: '06-07-2023',
		customer: 'Harper Brown',
		customer_email: 'harper.brown@example.com',
		contact: '+1 (999) 888-7777',
		cpn: 'CPN44444',
		mpn_req: 'MPN33333',
		brand_req: 'Brand U',
		qty_req: '180',
		fc_ext: 'Factory U',
		mpn_found: 'MPN33333',
		brand_found: 'Brand U',
		moq: '60',
		spq: '12',
		usd_cost: '4.50',
		ext_price: '810.00',
		dc: 'No discount',
		packaging: 'Standard packaging',
		lt_w_units: '5 days',
		cf_mm_online: 'Yes',
		mail: 'Sent',
		subject: 'Product Inquiry',
		source_date: '04-07-2023',
		supplier: 'Suppliers Express',
		supplier_email: 'suppliers@express.com',
		supplier_name: 'Suppliers Express',
		days_open: '2',
		remarks: 'This is an eighth sample inquiry.',
		order_received: 'No',
	},
	{
		id: 10,
		queueid: 'Mason',
		sr: 'Mason',
		ss: 'Nora',
		Mail: 'mason@test.com',
		inquiry_date: '08-07-2023',
		customer: 'Mia Johnson',
		customer_email: 'mia.johnson@example.com',
		contact: '+1 (666) 777-8888',
		cpn: 'CPN66666',
		mpn_req: 'MPN55555',
		brand_req: 'Brand T',
		qty_req: '250',
		fc_ext: 'Factory T',
		mpn_found: 'MPN55555',
		brand_found: 'Brand T',
		moq: '80',
		spq: '16',
		usd_cost: '5.25',
		ext_price: '1312.50',
		dc: '15% discount',
		packaging: 'Premium packaging',
		lt_w_units: '8 days',
		cf_mm_online: 'No',
		mail: 'Received',
		subject: 'Product Inquiry',
		source_date: '06-07-2023',
		supplier: 'Suppliers Direct',
		supplier_email: 'suppliers@direct.com',
		supplier_name: 'Suppliers Direct',
		days_open: '2',
		remarks: 'This is a ninth sample inquiry.',
		order_received: 'No',
	},
];

export const chipsfinderData = [
	{
		SCIrepresentative: 'Swraj',
		Filename: 'abc.xlsx',
		uplodaddatatime: '25-07-2023',
		bomid: '12345667789',
		oflines: '50',
		status: 54,
		estcompdatatime: '25-07-2023 10:00PM',
		action: true,
	},
	{
		SCIrepresentative: 'Rohit',
		Filename: 'xyz.xlsx',
		uplodaddatatime: '22-07-2023',
		bomid: '0987654321',
		oflines: '100',
		status: 74,
		estcompdatatime: '22-07-2023 7:00PM',
	},

	{
		SCIrepresentative: 'Rohit',
		Filename: 'data1.xlsx',
		uplodaddatatime: '23-07-2023',
		bomid: '1234567890',
		oflines: '50',
		status: 92,
		estcompdatatime: '23-07-2023 8:30AM',
	},

	{
		SCIrepresentative: 'Samantha',
		Filename: 'data2.xlsx',
		uplodaddatatime: '24-07-2023',
		bomid: '2345678901',
		oflines: '200',
		status: 56,
		estcompdatatime: '24-07-2023 6:45PM',
		action: true,
	},
	{
		SCIrepresentative: 'Michael',
		Filename: 'data3.xlsx',
		uplodaddatatime: '25-07-2023',
		bomid: '3456789012',
		oflines: '75',
		status: 82,
		estcompdatatime: '25-07-2023 11:15AM',
	},
	{
		SCIrepresentative: 'Emma',
		Filename: 'data4.xlsx',
		uplodaddatatime: '26-07-2023',
		bomid: '4567890123',
		oflines: '125',
		status: 37,
		estcompdatatime: '26-07-2023 2:00PM',
	},
	{
		SCIrepresentative: 'John',
		Filename: 'data5.xlsx',
		uplodaddatatime: '27-07-2023',
		bomid: '5678901234',
		oflines: '300',
		status: 100,
		estcompdatatime: '27-07-2023 9:00AM',
	},
	{
		SCIrepresentative: 'Olivia',
		Filename: 'data6.xlsx',
		uplodaddatatime: '28-07-2023',
		bomid: '6789012345',
		oflines: '50',
		status: 15,
		estcompdatatime: '28-07-2023 5:30PM',
		action: true,
	},
	{
		SCIrepresentative: 'William',
		Filename: 'data7.xlsx',
		uplodaddatatime: '29-07-2023',
		bomid: '7890123456',
		oflines: '175',
		status: 64,
		estcompdatatime: '29-07-2023 3:45PM',
	},
	{
		SCIrepresentative: 'Sophia',
		Filename: 'data8.xlsx',
		uplodaddatatime: '30-07-2023',
		bomid: '8901234567',
		oflines: '90',
		status: 88,
		estcompdatatime: '30-07-2023 12:00PM',
	},
	{
		SCIrepresentative: 'James',
		Filename: 'data9.xlsx',
		uplodaddatatime: '31-07-2023',
		bomid: '9012345678',
		oflines: '120',
		status: 42,
		estcompdatatime: '31-07-2023 10:30AM',
	},
	{
		SCIrepresentative: 'Ava',
		Filename: 'data10.xlsx',
		uplodaddatatime: '01-08-2023',
		bomid: '0123456789',
		oflines: '250',
		status: 77,
		estcompdatatime: '01-08-2023 4:15PM',
	},
	{
		SCIrepresentative: 'Alexander',
		Filename: 'data11.xlsx',
		uplodaddatatime: '02-08-2023',
		bomid: '5432109876',
		oflines: '80',
		status: 91,
		estcompdatatime: '02-08-2023 1:30PM',
	},
	{
		SCIrepresentative: 'Mia',
		Filename: 'data12.xlsx',
		uplodaddatatime: '03-08-2023',
		bomid: '6543210987',
		oflines: '100',
		status: 26,
		estcompdatatime: '03-08-2023 9:45AM',
		action: true,
	},
	{
		SCIrepresentative: 'Ethan',
		Filename: 'data13.xlsx',
		uplodaddatatime: '04-08-2023',
		bomid: '7654321098',
		oflines: '150',
		status: 53,
		estcompdatatime: '04-08-2023 5:00PM',
	},
	{
		SCIrepresentative: 'Abigail',
		Filename: 'data14.xlsx',
		uplodaddatatime: '05-08-2023',
		bomid: '8765432109',
		oflines: '70',
		status: 79,
		estcompdatatime: '05-08-2023 2:15PM',
	},
	{
		SCIrepresentative: 'Daniel',
		Filename: 'data15.xlsx',
		uplodaddatatime: '06-08-2023',
		bomid: '9876543210',
		oflines: '200',
		status: 40,
		estcompdatatime: '06-08-2023 10:00AM',
		action: true,
	},
	{
		SCIrepresentative: 'Charlotte',
		Filename: 'data16.xlsx',
		uplodaddatatime: '07-08-2023',
		bomid: '0987654321',
		oflines: '90',
		status: 68,
		estcompdatatime: '07-08-2023 7:30PM',
	},
	{
		SCIrepresentative: 'Lucas',
		Filename: 'data17.xlsx',
		uplodaddatatime: '08-08-2023',
		bomid: '1098765432',
		oflines: '110',
		status: 31,
		estcompdatatime: '08-08-2023 4:45PM',
	},
	{
		SCIrepresentative: 'Isabella',
		Filename: 'data18.xlsx',
		uplodaddatatime: '09-08-2023',
		bomid: '2109876543',
		oflines: '250',
		status: 87,
		estcompdatatime: '09-08-2023 1:00PM',
		action: true,
	},
	{
		SCIrepresentative: 'Jackson',
		Filename: 'data19.xlsx',
		uplodaddatatime: '10-08-2023',
		bomid: '3210987654',
		oflines: '120',
		status: 44,
		estcompdatatime: '10-08-2023 10:15AM',
	},
	{
		SCIrepresentative: 'Harper',
		Filename: 'data20.xlsx',
		uplodaddatatime: '11-08-2023',
		bomid: '4321098765',
		oflines: '180',
		status: 73,
		estcompdatatime: '11-08-2023 3:00PM',
		action: true,
	},
	{
		SCIrepresentative: 'Charlotte',
		Filename: 'data16.xlsx',
		uplodaddatatime: '07-08-2023',
		bomid: '0987654321',
		oflines: '90',
		status: 68,
		estcompdatatime: '07-08-2023 7:30PM',
	},
	{
		SCIrepresentative: 'Lucas',
		Filename: 'data17.xlsx',
		uplodaddatatime: '08-08-2023',
		bomid: '1098765432',
		oflines: '110',
		status: 31,
		estcompdatatime: '08-08-2023 4:45PM',
	},
	{
		SCIrepresentative: 'Isabella',
		Filename: 'data18.xlsx',
		uplodaddatatime: '09-08-2023',
		bomid: '2109876543',
		oflines: '250',
		status: 87,
		estcompdatatime: '09-08-2023 1:00PM',
		action: true,
	},
	{
		SCIrepresentative: 'Jackson',
		Filename: 'data19.xlsx',
		uplodaddatatime: '10-08-2023',
		bomid: '3210987654',
		oflines: '120',
		status: 44,
		estcompdatatime: '10-08-2023 10:15AM',
	},
	{
		SCIrepresentative: 'Harper',
		Filename: 'data20.xlsx',
		uplodaddatatime: '11-08-2023',
		bomid: '4321098765',
		oflines: '180',
		status: 73,
		estcompdatatime: '11-08-2023 3:00PM',
		action: true,
	},
	{
		SCIrepresentative: 'Jackson',
		Filename: 'data19.xlsx',
		uplodaddatatime: '10-08-2023',
		bomid: '3210987654',
		oflines: '120',
		status: 44,
		estcompdatatime: '10-08-2023 10:15AM',
	},
	{
		SCIrepresentative: 'Harper',
		Filename: 'data20.xlsx',
		uplodaddatatime: '11-08-2023',
		bomid: '4321098765',
		oflines: '180',
		status: 73,
		estcompdatatime: '11-08-2023 3:00PM',
		action: true,
	},
];

export const sourcingAssignmentData = [
	{
		id: 1,
		sales_rep: 'Jaffry',
		email: 'jaffry@supremecomponents.com',
		DATE_ADD: '2023-09-01 06:49:24',
		ss: 'mikee@supremecomponents.com',
	},
	{
		id: 1,
		sales_rep: 'Jaffry',
		email: 'jaffry@supremecomponents.com',
		DATE_ADD: '2023-09-01 06:49:24',
		ss: 'mikee@supremecomponents.com',
	},
	{
		id: 1,
		sales_rep: 'Jaffry',
		email: 'jaffry@supremecomponents.com',
		DATE_ADD: '2023-09-01 06:49:24',
		ss: 'mikee@supremecomponents.com',
	},
	{
		id: 1,
		sales_rep: 'Jaffry',
		email: 'jaffry@supremecomponents.com',
		DATE_ADD: '2023-09-01 06:49:24',
		ss: 'mikee@supremecomponents.com',
	},
	{
		id: 1,
		sales_rep: 'Jaffry',
		email: 'jaffry@supremecomponents.com',
		DATE_ADD: '2023-09-01 06:49:24',
		ss: 'mikee@supremecomponents.com',
	},
	{
		id: 1,
		sales_rep: 'Jaffry',
		email: 'jaffry@supremecomponents.com',
		DATE_ADD: '2023-09-01 06:49:24',
		ss: 'mikee@supremecomponents.com',
	},
	{
		id: 1,
		sales_rep: 'Jaffry',
		email: 'jaffry@supremecomponents.com',
		DATE_ADD: '2023-09-01 06:49:24',
		ss: 'mikee@supremecomponents.com',
	},
	{
		id: 1,
		sales_rep: 'Jaffry',
		email: 'jaffry@supremecomponents.com',
		DATE_ADD: '2023-09-01 06:49:24',
		ss: 'mikee@supremecomponents.com',
	},
	{
		id: 1,
		sales_rep: 'Jaffry',
		email: 'jaffry@supremecomponents.com',
		DATE_ADD: '2023-09-01 06:49:24',
		ss: 'mikee@supremecomponents.com',
	},
	{
		id: 1,
		sales_rep: 'Jaffry',
		email: 'jaffry@supremecomponents.com',
		DATE_ADD: '2023-09-01 06:49:24',
		ss: 'mikee@supremecomponents.com',
	},
	{
		id: 1,
		sales_rep: 'Jaffry',
		email: 'jaffry@supremecomponents.com',
		DATE_ADD: '2023-09-01 06:49:24',
		ss: 'mikee@supremecomponents.com',
	},
];

export const customerProespectData = [
	{
		subject_line: 'Regarding Your Recent Order',
		customer: 'John Doe',
		from: 'Company XYZ Support',
		sci_rep: 'SR-12345',
		date: '2023-09-11',
		time: '15:30:00',
		email: 'johndoe@example.com',
	},
	{
		subject_line: 'Product Inquiry',
		customer: 'Alice Johnson',
		from: 'Sales Department',
		sci_rep: 'SR-67890',
		date: '2023-09-12',
		time: '10:15:00',
		email: 'alice@example.com',
	},
	{
		subject_line: 'Feedback on Recent Service',
		customer: 'Mark Smith',
		from: 'Customer Support',
		sci_rep: 'SR-54321',
		date: '2023-09-13',
		time: '14:45:00',
		email: 'mark@example.com',
	},
	{
		subject_line: 'Invoice Payment Reminder',
		customer: 'Sarah Brown',
		from: 'Billing Department',
		sci_rep: 'SR-98765',
		date: '2023-09-14',
		time: '09:30:00',
		email: 'sarah@example.com',
	},
	{
		subject_line: 'Technical Support Request',
		customer: 'Robert Davis',
		from: 'Tech Support',
		sci_rep: 'SR-23456',
		date: '2023-09-15',
		time: '16:20:00',
		email: 'robert@example.com',
	},
	{
		subject_line: 'Order Confirmation',
		customer: 'Emily Wilson',
		from: 'Sales Department',
		sci_rep: 'SR-13579',
		date: '2023-09-16',
		time: '11:10:00',
		email: 'emily@example.com',
	},
	{
		subject_line: 'Appointment Reminder',
		customer: 'James Anderson',
		from: 'Health Clinic',
		sci_rep: 'SR-87654',
		date: '2023-09-17',
		time: '13:55:00',
		email: 'james@example.com',
	},
	{
		subject_line: 'Product Warranty Information',
		customer: 'Linda Martinez',
		from: 'Customer Support',
		sci_rep: 'SR-65432',
		date: '2023-09-18',
		time: '08:40:00',
		email: 'linda@example.com',
	},
	{
		subject_line: 'New Product Announcement',
		customer: 'Jennifer Clark',
		from: 'Marketing Department',
		sci_rep: 'SR-78901',
		date: '2023-09-20',
		time: '12:25:00',
		email: 'jennifer@example.com',
	},
	{
		subject_line: 'Account Verification Request',
		customer: 'David Taylor',
		from: 'Security Team',
		sci_rep: 'SR-45678',
		date: '2023-09-21',
		time: '14:15:00',
		email: 'david@example.com',
	},
];
