import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// A custom hook that builds on useLocation to parse
// the query string for you.

export const useQuery = () => {
	const location = useLocation();

	return useMemo(() => {
		return { query: new URLSearchParams(location.search), location };
	}, [location]);
};
