import { useCallback, useState } from 'react';
import { FaBook } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { IoIosSearch } from 'react-icons/io';
import { motion, AnimatePresence } from 'framer-motion';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import { debounceMethod } from '../../utils';
import WelcomeVideoImage from '../../images/Welcome video.png';
import ItSecurityImage from '../../images/ItSecurityVideo.jpg';
import OutlookImage from '../../images/outlook-image.jpg';
import RFQImage from '../../images/rfq.jpg';
import Input from '../../components/input';

import styles from './learnCentre.module.scss';

const LearnCentre = () => {
	const cardVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0 },
	};
	const content = [
		{
			id: 1,
			image: ItSecurityImage,
			name: 'IT Security Presentation',
			links: [
				{
					name: 'Video',
					link: 'https://supremecomponentsi.sharepoint.com/:v:/g/EX2L7el0ctZBn_QOh_Q2croBmF-WxPT5k4StbZt0SyqPrA?e=mexgU7',
				},
				{
					name: 'PPT',
					link: 'https://supremecomponentsi.sharepoint.com/:p:/g/EYwZKRqdPxZInveEjLEjHy8B9ZaA6PdTGddzX-Qhx9Y86g?e=UFV36Z',
				},
			],
		},
		{
			id: 2,
			image: WelcomeVideoImage,
			name: 'Welcome to SCI',
			links: [
				{
					name: 'Video',
					link: 'https://supremecomponentsi.sharepoint.com/:v:/g/EUSADh0_EsJOs-WINYlftOUBePZ4b-nPVsoKorl1MtOcTQ?e=4vW9HC',
				},
			],
		},
		{
			id: 3,
			image: OutlookImage,
			name: 'How to Download Email?',
			links: [
				{
					name: 'Video',
					link: 'https://supremecomponentsi.sharepoint.com/:v:/g/EUQT2hbJPyRJh_Vnr5crMlQBI17eh7_0NDT3mNJ_4RaMjQ?e=BtaZBr',
				},
			],
		},
		{
			id: 4,
			image: RFQImage,
			name: 'RFQ Template',
			links: [
				{
					name: 'Excel',
					link: 'https://supremecomponentsi.sharepoint.com/:x:/g/ER5KIw4JVE5DpjbGnm9MESwB_LBDxzuNHq_ckeiYTGBUsA?e=JMdUan',
				},
			],
		},
	];
	const [searchQuery, setSearchQuery] = useState('');
	const [data, setData] = useState(content);

	const handleSearch = (query) => {
		setSearchQuery(query);

		if (!query) {
			setData(content);
		} else {
			const filteredData = content.filter((item) =>
				item.name.toLowerCase().includes(query.toLowerCase())
			);
			setData(filteredData);
		}
	};

	// eslint-disable-next-line
	const debouncedSearch = useCallback(debounceMethod(handleSearch), []);

	// const [columnDefs] = useState([
	// 	{
	// 		field: 'useful_links',
	// 		headerName: 'Useful Links',
	// 		cellRenderer: (p) => (
	// 			<Link
	// 				to={p.value}
	// 				target="_blank"
	// 				style={{ color: 'blue', textDecoration: 'none' }}
	// 			>
	// 				{p.data.name}
	// 			</Link>
	// 		),
	// 	},
	// 	{
	// 		field: 'useful_videos',
	// 		headerName: 'Useful Videos',
	// 		cellRenderer: (p) => (
	// 			<Link
	// 				to={p.value}
	// 				target="_blank"
	// 				style={{ color: 'blue', textDecoration: 'none' }}
	// 			>
	// 				{p.data.name}
	// 			</Link>
	// 		),
	// 	},
	// ]);
	// const defaultColDef = useMemo(
	// 	() => ({
	// 		sortable: true,
	// 		resizable: true,
	// 		flex: 2,
	// 		floatingFilter: true,
	// 		minWidth: 100,
	// 		filter: 'agMultiColumnFilter',
	// 		suppressMovable: true,
	// 		suppressHeaderMenuButton: true,
	// 		floatingFilterComponentParams: {
	// 			suppressFilterButton: true,
	// 		},
	// 	}),
	// 	[]
	// );

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="Learn Centre"
				icon={<FaBook style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="Learn Centre"
				info="Unlock valuable links and informative videos to support your learning and decision-making process."
			/>

			<div className={styles['learn-centre']}>
				<Input
					placeholder="What are you looking for?"
					value={searchQuery}
					onChange={(e) => debouncedSearch(e.target.value)}
					style={{
						marginBottom: '20px',
						height: '40px',
						width: '1110px',
						borderRadius: '8px',
					}}
					prefix={<IoIosSearch size={20} />}
				/>
				<div className={styles['cards-wrapper']}>
					<AnimatePresence>
						{data.map((el, index) => (
							<motion.div
								key={el.id}
								initial="hidden"
								animate="visible"
								exit="hidden"
								variants={cardVariants}
								transition={{ duration: 0.3, delay: index * 0.1 }}
								style={{ maxWidth: '355px' }}
							>
								<Card data={el} />
							</motion.div>
						))}
					</AnimatePresence>
				</div>
				{/* <div className={styles['table-wrapper']}>
					<div className={`ag-theme-custom ${styles['table']} `}>
						<AgGridReact
							rowData={data}
							columnDefs={columnDefs}
							defaultColDef={defaultColDef}
							animateRows={true}
							pagination={true}
							paginationPageSizeSelector={false}
						/>
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default LearnCentre;

const Card = (data) => {
	return (
		<div className={styles['card-wrapper']}>
			<img className={styles['image']} src={data.data.image} alt="" />
			<div className={styles['contents']}>
				<p className={styles['title']}>{data.data.name}</p>
				<div className={styles['links']}>
					{data.data.links.map((el) => (
						<Link target="_blank" to={el.link} className={styles['link']}>
							{el.name}
							<FiExternalLink />
						</Link>
					))}
				</div>
			</div>
		</div>
	);
};
