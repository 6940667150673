import { createContext, useContext, useState } from 'react';
import axios from 'axios';

import EdenContext from '../edenContext';
import { COUNTRY_CODES } from '../../columns';

const HouseAccountContext = createContext();

export const HouseAccountProvider = ({ children }) => {
	const { userMail } = useContext(EdenContext);

	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [userStatics, setUserStatics] = useState(null);

	const processRowData = (data) => {
		const uniqueRoundedValues = new Set();
		return data.map((row) => {
			const roundedValue = Math.round(row.Billing);
			uniqueRoundedValues.add(roundedValue);
			return { ...row, BillingRounded: roundedValue };
		});
	};

	const getData = () => {
		setIsLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_all_admin_accounts`
			)
			.then((response) => {
				setData(processRowData(response.data));
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const forceLoadData = () => {
		setIsLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=force_refresh_accounts`
			)
			.then((response) => {
				if (response.data?.status === 'success') {
					getData();
				}
			});
	};

	const getUserStatics = () => {
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_allocated_account_count&email=${userMail}`
			)
			.then((response) => setUserStatics(response.data));
	};

	const dataWithFlags = data?.filter((d) => {
		return COUNTRY_CODES[d?.AcctCountry] === undefined
			? d
			: COUNTRY_CODES[d?.AcctCountry];
	});

	return (
		<HouseAccountContext.Provider
			value={{
				data,
				isLoading,
				error,
				getData,
				getUserStatics,
				userStatics,
				dataWithFlags,
				forceLoadData,
			}}
		>
			{children}
		</HouseAccountContext.Provider>
	);
};

export default HouseAccountContext;
