import { useContext, useState } from 'react';
import { IoMailOpen } from 'react-icons/io5';
import { Avatar, Drawer } from 'antd';
import moment from 'moment';

import MassMailContext from '../../../context/massMailContext';
import EdenContext from '../../../context/edenContext';
import { avatarBgColor } from '../../../constant';
import ToolTip from '../../../components/tooltip';
import { titleCase } from '../../../utils';
import UploadedFile from '../../../components/uploadedFile';

import styles from './mailPreview.module.scss';

const MailPreview = ({ data, node }) => {
	const { mailConfigData } = useContext(MassMailContext);
	const { userInfo } = useContext(EdenContext);
	const [drawerMail, setDrawerMail] = useState(false);

	const closeDrawer = () => {
		setDrawerMail(!drawerMail);
	};

	return (
		<div className={styles['wrapper']}>
			{node.group ? (
				''
			) : (
				<ToolTip title={'Preview mail'}>
					<IoMailOpen
						onClick={() => {
							setDrawerMail(true);
						}}
						style={{
							fontSize: '16px',
							cursor: 'pointer',
							color: 'black',
							display: 'flex',
							alignItems: 'center',
						}}
					/>
				</ToolTip>
			)}

			{/* Mail Preview */}
			{drawerMail && (
				<Drawer
					title={
						<p className={styles['mail-subject']}>
							<ToolTip placement="bottomLeft" title={mailConfigData?.SUBJECT}>
								{mailConfigData?.SUBJECT}
							</ToolTip>
						</p>
					}
					placement="right"
					onClose={closeDrawer}
					open={drawerMail}
					size={'large'}
				>
					{mailConfigData && (
						<>
							<div className={styles['mail-details-wrapper']}>
								<div className={styles['mail-user-info']}>
									<Avatar
										className={styles['avatar']}
										style={{
											backgroundColor:
												avatarBgColor[
													userInfo?.ViewAsLoggedinUser?.SalesRepName?.charAt(0)
												] || avatarBgColor.default,
										}}
									>
										{userInfo?.ViewAsLoggedinUser?.SalesRepName?.charAt(
											0
										)?.toUpperCase()}
									</Avatar>
									<div className={styles['users-info']}>
										<p className={styles['sender-name']}>
											{titleCase(userInfo?.ViewAsLoggedinUser?.SalesRepName)}
										</p>
										{data?.ContactEmail && (
											<p className={styles['to']}>
												<span className={styles['title']}>To:</span>{' '}
												{data?.ContactEmail}
											</p>
										)}
										{mailConfigData?.CC && (
											<p className={styles['cc']}>
												<span className={styles['title']}>Cc:</span>{' '}
												{mailConfigData?.CC}
											</p>
										)}
										{mailConfigData?.bccRecipients && (
											<p className={styles['bcc']}>
												<span className={styles['title']}>Bcc:</span>{' '}
												{mailConfigData?.bccRecipients}
											</p>
										)}
									</div>
								</div>
								<p className={styles['date-info']}>
									{moment().format('MMM-DD-YYYY hh:mm A')}
								</p>
							</div>
							{mailConfigData?.FILE && (
								<UploadedFile
									url={mailConfigData?.FILE?.file?.name}
									style={{ margin: '15px 0' }}
									fileName={mailConfigData?.FILE?.file?.name}
									canDownload
								/>
							)}
							<div
								dangerouslySetInnerHTML={{
									__html: mailConfigData?.body?.replace(
										'[FName]',
										data?.ContactFirstName
									),
								}}
							/>
							<br />
							<div
								dangerouslySetInnerHTML={{ __html: mailConfigData?.SIGNATURE }}
							/>
						</>
					)}
				</Drawer>
			)}
		</div>
	);
};

export default MailPreview;
