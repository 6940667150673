import { createContext, useState } from 'react';

const MassMailContext = createContext();

export const MassMailProvider = ({ children }) => {
	const [mailConfigData, setMailConfigData] = useState(null);
	return (
		<MassMailContext.Provider value={{ mailConfigData, setMailConfigData }}>
			{children}
		</MassMailContext.Provider>
	);
};

export default MassMailContext;
