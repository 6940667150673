import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import axios from 'axios';
import { Form, message } from 'antd';
import { IoMdAddCircle } from 'react-icons/io';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';

import EdenContext from '../../../context/edenContext';
import AccessDenied from '../../../components/accessDenied';
import BreadCrumb from '../../../components/breadcrumb';
import Button from '../../../components/button';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';
import AddExcluForm from './AddExcluForm';
import BobcatExclusionAction from './BobcatExclusionListAction';
import NotificationBar from '../../../components/notificationBar';
import InnerHeader from '../../../components/innerHeader';

import styles from './bobcatExclusionList.module.scss';

const BobcatExclusionList = () => {
	const {
		userType,
		bobcatExcluList,
		bobcatExcluLoading,
		bobcatExcluError,
		getBobcatExcluList,
	} = useContext(EdenContext);
	const [form] = Form.useForm();
	const [addBobcatExcluModal, setAddBobcatExcluModal] = useState(false);

	const [columnDefs] = useState([
		{
			field: 'sno',
			headerName: 'S.No.',
			maxWidth: 60,
			cellStyle: { paddingLeft: '20px' },
			floatingFilter: false,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>{p.rowIndex + 1}</span>
			),
		},
		{
			field: 'type',
			headerName: 'Type',
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value ? p.value : '--'}
				</span>
			),
		},
		{
			field: 'parameter',
			headerName: 'Parameter',
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value ? p.value : '--'}
				</span>
			),
		},
		{
			field: 'date_added',
			headerName: 'Date',
			sort: 'desc',
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : '--'}
				</span>
			),
		},
		{
			field: 'action',
			headerName: 'Actions',
			floatingFilter: false,
			cellRenderer: memo(BobcatExclusionAction),
			maxWidth: 90,
			cellStyle: { padding: '4px 0 0 10px' },
		},
	]);

	useEffect(() => {
		getBobcatExcluList(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openBobcatExcluModal = () => {
		setAddBobcatExcluModal(true);
	};

	const closeBobcatExcluModal = () => {
		form.resetFields();
		setAddBobcatExcluModal(false);
	};

	const addBobcatExclu = (values) => {
		const formData = new FormData();
		formData.append('type', values.type);
		formData.append('parameter', values.parameter);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=bobcat_add_excl_email`,
				formData
			)
			.then(() => {
				message.open({
					type: 'success',
					content: 'Exclusion added successfully',
				});
				form.resetFields();
				setAddBobcatExcluModal(false);
				getBobcatExcluList();
			})
			.catch((err) => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				form.resetFields();
				setAddBobcatExcluModal(false);
			});
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
			suppressHeaderMenuButton: true,
			// floatingFilterComponentParams: {
			// 	suppressFilterButton: true,
			// },
		}),
		[]
	);

	const lastUpdated = bobcatExcluList
		? bobcatExcluList[bobcatExcluList?.length - 1]?.dateAdded
		: '--';

	return (
		<>
			{userType === 'admin' ? (
				<div className={styles['wrapper']}>
					<BreadCrumb
						route1="Admin"
						route2="Exclusion Manager"
						icon={<MdOutlineAdminPanelSettings style={{ fontSize: '20px' }} />}
					/>
					<InnerHeader
						lastReport={
							bobcatExcluList
								? moment(lastUpdated)?.format('MMM-DD-YYYY hh:mm A')
								: '--'
						}
						updatePage
						onUpdate={getBobcatExcluList}
						updateText="Refresh List"
					/>
					<div className={styles['sourcing-assignments']}>
						<NotificationBar
							greenBar
							message="Below mentioned keywords and emails will be excluded in pre-processing for Bobcat/Eagle."
						/>
						<div className={styles['button-last-updated']}>
							<Button onClick={openBobcatExcluModal}>
								<IoMdAddCircle style={{ fontSize: '16px' }} />
								Add New
							</Button>
						</div>
						{bobcatExcluLoading && (
							<div className={styles['loading-wrapper']}>
								<LoadingMessage message="Loading..." />
							</div>
						)}
						{bobcatExcluError && !bobcatExcluLoading && (
							<div className={styles['error-wrapper']}>
								<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
							</div>
						)}
						{!isNotEmptyArray(bobcatExcluList) &&
							!bobcatExcluError &&
							!bobcatExcluLoading && (
								<div className={styles['no-data-wrapper']}>
									<NoData message="Its all empty here." />
								</div>
							)}
						{!bobcatExcluLoading &&
							!bobcatExcluError &&
							isNotEmptyArray(bobcatExcluList) && (
								<div className={styles['table-wrapper']}>
									<div className={`ag-theme-custom ${styles['table']} `}>
										<AgGridReact
											rowData={bobcatExcluList}
											columnDefs={columnDefs}
											defaultColDef={defaultColDef}
											animateRows={true}
											pagination={true}
											paginationPageSize={50}
											paginationPageSizeSelector={false}
										/>
									</div>
								</div>
							)}
					</div>
					{addBobcatExcluModal && (
						<AddExcluForm
							open={addBobcatExcluModal}
							onCancel={closeBobcatExcluModal}
							form={form}
							onFinish={addBobcatExclu}
						/>
					)}
				</div>
			) : (
				<AccessDenied message="You are not an Admin" />
			)}
		</>
	);
};

export default BobcatExclusionList;
