import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { AgGridReact } from 'ag-grid-react';
import { Checkbox, Divider, message, Radio as AntdRadio, Avatar } from 'antd';
import { HiOutlineSearch } from 'react-icons/hi';
import { MdEdit, MdMail } from 'react-icons/md';
import { Base64 } from 'js-base64';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import EdenContext from '../../../../../context/edenContext';
import Modal from '../../../../../components/modal';
import NotificationBar from '../../../../../components/notificationBar';
import Input from '../../../../../components/input';
import Button from '../../../../../components/button';
import LoadingMessage from '../../../../../components/loaderMessage';
import ErrorMessage from '../../../../../components/errorMessage';
import {
	filterUniqueIdsAndRecent,
	isNotEmptyArray,
} from '../../../../../utils';
import NoData from '../../../../../components/nodata';
import ToolTip from '../../../../../components/tooltip';
import Steps from '../../../../../components/steps';
import { RadioTwo } from '../../../../../components/radio';
import Drawer from '../../../../../components/drawer';
import { avatarBgColor } from '../../../../../constant';

import styles from './addSupplier.module.scss';

const dateFilterParams = {
	filters: [
		{
			filter: 'agDateColumnFilter',
			filterParams: {
				comparator: (a, b) => {
					return getDate(a).getTime() - getDate(b).getTime();
				},
			},
		},
	],
};

const getDate = (value) => {
	const date = moment.utc(value).tz('Asia/Singapore').format('DD/MM/YYYY');
	var dateParts = date.split('/');
	return new Date(
		Number(dateParts[2]),
		Number(dateParts[1]) - 1,
		Number(dateParts[0])
	);
};

const AddSupplier = (p) => {
	const {
		userType,
		userMail,
		userData,
		me,
		costBookType,
		setMpnCostBookList,
		mpnCostBookList,
		MCBlastUserActiviry,
	} = useContext(EdenContext);

	const [modalOpen, setModalOpen] = useState(false);
	const [paramsData, setParamsData] = useState(null);
	const [addingMail, setAddingMail] = useState(false);
	const [loading, setLoading] = useState(false);
	const [current, setCurrent] = useState(0);

	// Add Spplier email states
	const [searchValue, setSearchValue] = useState('');
	const [rowSelectionData, setRowSelectionData] = useState(null);
	const [customerProspectData, setCustomerProspectData] = useState([]);
	const [customerProspectLoading, setCustomerProspectLoading] = useState(false);
	const [customerProspectError, setCustomerProspectError] = useState(null);

	// Bobcat states
	const [bobcatResults, setBobcatResults] = useState(null);
	const [bobcatResultsLoading, setBobcatResultsLoading] = useState(false);
	const [bobcatResultsError, setBobcatResultsError] = useState(null);
	const [bobcatRowSelectionData, setBobcatRowSelectionData] = useState(null);
	const [addingBobCatResults, setAddingBobcatResults] = useState(false);

	const onChange = (value) => {
		if (rowSelectionData?.length > 0) {
			setCurrent(value);
		} else {
			message.error('Please select your email');
		}
	};

	const steps = [
		{
			title: 'Select Supplier Email​',
			content: (
				<AddMail
					customerProspectLoading={customerProspectLoading}
					setCustomerProspectLoading={setCustomerProspectLoading}
					customerProspectError={customerProspectError}
					setCustomerProspectError={setCustomerProspectError}
					rowSelectionData={rowSelectionData}
					setRowSelectionData={setRowSelectionData}
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					customerProspectData={customerProspectData}
					setCustomerProspectData={setCustomerProspectData}
					setCurrent={setCurrent}
					paramsData={paramsData}
				/>
			),
		},
		{
			title: 'Add Parts to Cost Book​',
			content: (
				<BobcatResults
					rowSelectionData={rowSelectionData}
					bobcatResults={bobcatResults}
					setBobcatResults={setBobcatResults}
					bobcatResultsLoading={bobcatResultsLoading}
					setBobcatResultsLoading={setBobcatResultsLoading}
					bobcatResultsError={bobcatResultsError}
					setBobcatResultsError={setBobcatResultsError}
					paramsData={paramsData}
					setCurrent={setCurrent}
					setBobcatRowSelectionData={setBobcatRowSelectionData}
				/>
			),
		},
	];

	const closeModal = () => {
		setModalOpen(false);
		setCustomerProspectData([]);
		setRowSelectionData([]);
		setParamsData(null);
		setSearchValue('');
		setCustomerProspectLoading(false);
		setCurrent(0);
	};

	const sendAcknowledgeMail = () => {
		const formdata = new FormData();
		formdata.append(
			'receiverEmail',
			rowSelectionData[0]?.from || rowSelectionData[0]?.FROM_EMAIL
		);
		formdata.append('senderEmail', userMail);
		formdata.append(
			'body',
			`<p>Dear, ${
				rowSelectionData[0]?.ACCOUNT_NAME?.toUpperCase() ||
				rowSelectionData[0]?.contactName?.toUpperCase() ||
				''
			}</p>
			Thank you for your email.
			<p></p>
			${userData?.name || me?.givenName || me?.displayName}`
		);
		formdata.append('cc', '');
		formdata.append('bcc', '');
		formdata.append(
			'subject',
			rowSelectionData[0]?.subject ||
				rowSelectionData[0]?.SUBJECT ||
				'Thank you for the Stock details.'
		);
		formdata.append('HTTP_API_KEY', '218b84c69678e95cb98679051a6e5118');
		formdata.append(
			'emailsId',
			rowSelectionData[0]?.ID || rowSelectionData[0]?.id
		);

		axios.post(
			`https://internal.supremecomponents.com/api/sendemail.php`,
			formdata
		);
	};

	const addtoRfqQueue = () => {
		setLoading(true);
		const rowData = { ...rowSelectionData[0] };

		const formFields = {
			...paramsData,
			userEmail: userMail,
			sdMail: rowData?.webLink || rowData?.MAIL_ID,
			sdSubject: rowData?.subject || rowData?.SUBJECT,
			sdSourceDate:
				rowData?.receivedDateTime ||
				new Date(rowData?.DATE_TIME)?.toISOString(),
			sdSupplierName: rowData?.contactName || rowData?.ACCOUNT_NAME,
			sdSupplier: paramsData?.sdSupplier
				? paramsData?.sdSupplier
				: rowData?.sciCustomer || rowData?.ACCOUNT,
			sdSupplierEmail: rowData?.from || rowData?.FROM_EMAIL,
			sdSource: 'Mass Email',
			othOrderReceived:
				paramsData?.othOrderReceived === 'Yes' ||
				paramsData?.othOrderReceived === true
					? true
					: paramsData?.othOrderReceived === 'No' ||
					  paramsData?.othOrderReceived === false
					? false
					: null,
			othSrRead:
				userType === 'sr' || userType === 'sm' ? true : paramsData?.othSrRead,
		};

		setAddingMail(true);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=updatempncostbook`,
				JSON.stringify(formFields)
			)
			.then(() => {
				axios
					.get(
						`https://internal.supremecomponents.com/api/externalgateway.php?route=getmpncostbookbyid&id=${paramsData?.id}&isbuy=${costBookType}&userEmail=${userMail}&lastUserActivity=${MCBlastUserActiviry}`
					)
					.then((response) => {
						if (rowData?.isAck === '0') {
							sendAcknowledgeMail();
						}
						setMpnCostBookList(
							filterUniqueIdsAndRecent([
								...mpnCostBookList,
								response?.data?.[0],
							])
						);
						setParamsData(null);
						message.success('MPN updated successfully');
						setLoading(false);
						setTimeout(() => {
							const rowIndex = p.rowIndex;
							const colKey = p.column.colId;
							p?.api?.setFocusedCell(rowIndex, colKey);
							const focusedCell = document.querySelector(
								'.ag-row-focus .ag-cell-focus'
							);
							if (focusedCell) {
								focusedCell.setAttribute('tabindex', '0');
							}
						}, 100);
					})
					.catch((err) => {
						message.error('Something went wrong please try again later!');
					});
			})
			.then(() => {
				closeModal();
				setAddingMail(false);
			})
			.catch((err) => {
				message.error('Something went wrong please try again later!');
				setAddingMail(false);
				setLoading(false);
			});
	};

	const addBobcatdatatoRfqQueue = () => {
		setLoading(true);
		const rowData = { ...rowSelectionData[0] };
		const bobcatRowData = bobcatRowSelectionData[0];

		const formFields = {
			...paramsData,
			userEmail: userMail,
			sdMail: rowData?.webLink || rowData?.MAIL_ID,
			sdSubject: rowData?.subject || rowData?.SUBJECT,
			sdSourceDate:
				rowData?.receivedDateTime ||
				new Date(rowData?.DATE_TIME)?.toISOString(),
			sdSupplierName: rowData?.contactName || rowData?.ACCOUNT_NAME,
			sdSupplier: paramsData?.sdSupplier
				? paramsData?.sdSupplier
				: rowData?.sciCustomer || rowData?.ACCOUNT,
			sdSupplierEmail: rowData?.from || rowData?.FROM_EMAIL,
			sdSource: 'Mass Email',
			foundMPN: bobcatRowData?.MPN ? bobcatRowData?.MPN : '',
			foundBrand: bobcatRowData?.BRAND ? bobcatRowData?.BRAND : '',
			foundMOQ: parseInt(bobcatRowData?.QTY) || 0,
			foundUsdCost: parseFloat(bobcatRowData?.PRC) || 0,
			foundDC: bobcatRowData?.DC ? bobcatRowData?.DC : '',
			foundLT: bobcatRowData?.LT ? bobcatRowData?.LT : '',
			othOrderReceived:
				paramsData?.othOrderReceived === 'Yes' ||
				paramsData?.othOrderReceived === true
					? true
					: paramsData?.othOrderReceived === 'No' ||
					  paramsData?.othOrderReceived === false
					? false
					: null,
			othSrRead:
				userType === 'sr' || userType === 'sm' ? true : paramsData?.othSrRead,
		};

		setAddingBobcatResults(true);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=updatempncostbook`,
				JSON.stringify(formFields)
			)
			.then(() => {
				axios
					.get(
						`https://internal.supremecomponents.com/api/externalgateway.php?route=getmpncostbookbyid&id=${paramsData?.id}&isbuy=${costBookType}&userEmail=${userMail}&lastUserActivity=${MCBlastUserActiviry}`
					)
					.then((response) => {
						if (rowData?.isAck === '0') {
							sendAcknowledgeMail();
						}
						setMpnCostBookList(
							filterUniqueIdsAndRecent([
								...mpnCostBookList,
								response?.data?.[0],
							])
						);
						setParamsData(null);
						message.success('MPN updated successfully');
						setLoading(false);
						setTimeout(() => {
							const rowIndex = p.rowIndex;
							const colKey = p.column.colId;
							p?.api?.setFocusedCell(rowIndex, colKey);
							const focusedCell = document.querySelector(
								'.ag-row-focus .ag-cell-focus'
							);
							if (focusedCell) {
								focusedCell.setAttribute('tabindex', '0');
							}
						}, 100);
					})
					.catch((err) => {
						message.error('Something went wrong please try again later!');
					});
			})
			.then(() => {
				closeModal();
				setAddingBobcatResults(false);
			})
			.catch((err) => {
				message.error('Something went wrong please try again later!');
				setAddingBobcatResults(false);
				setLoading(false);
			});
	};

	const openModal = useCallback(() => {
		setModalOpen(true);
		setParamsData(p?.data);
	}, [p?.data]);

	return (
		<>
			{loading && <LoadingOutlined />}
			{!loading && p?.data?.sdSubject ? (
				<div className={styles['data-wrapper']}>
					<MdEdit
						onClick={openModal}
						className={styles['edit-icon']}
						onKeyDown={(e) => e.key === 'Enter' && openModal()}
						tabIndex={0}
					/>
					<ToolTip placement="bottomLeft" title={p?.data?.sdSubject}>
						<span className={styles['data']}>{p?.data?.sdSubject}</span>
					</ToolTip>
				</div>
			) : (
				<p
					className={styles['blue-underline']}
					onClick={p?.data?.srId && openModal}
					onKeyDown={(e) => e.key === 'Enter' && openModal()}
					tabIndex={0}
				>
					Insert
				</p>
			)}

			{modalOpen && (
				<Modal
					open={modalOpen}
					title={
						<>
							Add Supplier RFQ Response Email to Cost Book{' '}
							<Divider style={{ margin: '10px 0' }} />
						</>
					}
					onCancel={closeModal}
					width={830}
					destroyOnClose
				>
					<Steps
						size="small"
						current={current}
						onChange={onChange}
						type="navigation"
						style={{ width: '600px' }}
						items={steps.map((item) => ({
							key: item.title,
							title: item.title,
						}))}
					/>

					<div className={styles['steps-content']}>
						{steps[current].content}
					</div>

					<div className={styles['actions']}>
						<Button type="bordered" onClick={closeModal}>
							Cancel
						</Button>
						<Button
							disabled={
								customerProspectLoading ||
								addingMail ||
								!isNotEmptyArray(customerProspectData) ||
								!rowSelectionData ||
								bobcatResultsLoading ||
								addingBobCatResults
							}
							loading={addingMail}
							onClick={addtoRfqQueue}
						>
							Add Supplier Only
						</Button>
						<Button
							disabled={
								customerProspectLoading ||
								addingMail ||
								!isNotEmptyArray(customerProspectData) ||
								!rowSelectionData ||
								!bobcatRowSelectionData ||
								bobcatResultsLoading
							}
							loading={addingBobCatResults}
							onClick={addBobcatdatatoRfqQueue}
						>
							Add Supplier Cost To Book
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
};

export default AddSupplier;

const AddMail = ({
	customerProspectLoading,
	setCustomerProspectLoading,
	customerProspectError,
	setCustomerProspectError,
	rowSelectionData,
	setRowSelectionData,
	searchValue,
	setSearchValue,
	customerProspectData,
	setCustomerProspectData,
	setCurrent,
	paramsData,
}) => {
	const { messageURl } = useContext(EdenContext);

	const [searchBy, setSearchBy] = useState('part-number');

	// Drawer states
	const [drawerMail, setDrawerMail] = useState(false);
	const [drawerMailData, setDrawerMailData] = useState(null);
	const [drawerMailLoading, setDrawerMailLoading] = useState(false);
	const [cellData, setCellData] = useState(null);

	const closeDrawer = () => {
		setDrawerMail(!drawerMail);
		setDrawerMailData(null);
		setCellData(null);
	};

	const openDrawer = (cid) => {
		setDrawerMail(true);
		setDrawerMailLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_mail_html&cid=${cid}`
			)
			.then((response) => {
				setDrawerMailData(response.data);
			})
			.finally(() => {
				setDrawerMailLoading(false);
			});
	};

	const [columnDefs] = useState([
		{
			checkboxSelection: true,
			field: 'subject',
			headerName: 'Subject Line',
		},
		{
			field: 'sciCustomer',
			headerName: 'Supplier',
		},
		{
			field: 'contactName',
			headerName: 'Supplier Name',
		},
		{
			field: 'from',
			headerName: 'From',
		},
		{
			field: 'recipients',
			headerName: 'To',
		},
		// {
		// 	field: 'sciSalesRep',
		// 	headerName: 'Sales Rep',
		// },
		{
			field: 'receivedDateTime',
			headerName: 'Received Date',
			cellRenderer: (p) => {
				return (
					<>
						{p.value
							? moment
									.utc(p.value)
									.tz('Asia/Singapore')
									.format('MMM-DD-YYYY hh:mm A')
							: '--'}
					</>
				);
			},
			filterParams: dateFilterParams,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			minWidth: 190,
		},

		{
			field: 'webLink',
			headerName: 'Email',
			cellRenderer: (p) => {
				const trimmedString = p?.value?.replace(/=+$/, '');
				return (
					<>
						{p.value ? (
							<ToolTip title="Preview mail">
								<MdMail
									onClick={(e) => {
										if (e.ctrlKey && e.button === 0) {
											window.open(
												`${messageURl?.url}${trimmedString}%3D.eml`,
												'_blank'
											);
										} else {
											openDrawer(p.value);
											setCellData(p);
										}
									}}
									style={{ fontSize: '16px', cursor: 'pointer' }}
								/>
							</ToolTip>
						) : (
							''
						)}
					</>
				);
			},
			cellStyle: { padding: '4px 0 0 25px' },
			maxWidth: 80,
		},
		{
			field: 'isAck',
			headerName: 'Is Acknowledged?',
			cellRenderer: (p) =>
				p.value === '1' ? 'Yes' : p.value === '0' ? 'No' : '',
		},
	]);

	const [partNumberColumnDefs] = useState([
		{
			checkboxSelection: true,
			field: 'SUBJECT',
			headerName: 'Subject Line',
		},
		{
			field: 'ACCOUNT',
			headerName: 'Supplier',
		},
		{
			field: 'ACCOUNT_NAME',
			headerName: 'Supplier Name',
		},
		{
			field: 'FROM_EMAIL',
			headerName: 'From',
		},
		{
			field: 'TO_EMAIL',
			headerName: 'To',
		},
		// {
		// 	field: 'sciSalesRep',
		// 	headerName: 'Sales Rep',
		// },
		{
			field: 'DATE_TIME',
			headerName: 'Received Date',
			cellRenderer: (p) => {
				return (
					<>
						{moment
							.utc(p.value)
							.tz('Asia/Singapore')
							.format('MMM-DD-YYYY hh:mm A')}
					</>
				);
			},
			filterParams: dateFilterParams,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			minWidth: 190,
		},

		{
			field: 'MAIL_ID',
			headerName: 'Email',
			cellRenderer: (p) => {
				const trimmedString = p?.value?.replace(/=+$/, '');
				return (
					<>
						{p.value ? (
							<ToolTip title="Preview mail">
								<MdMail
									onClick={(e) => {
										if (e.ctrlKey && e.button === 0) {
											window.open(
												`${messageURl?.url}${trimmedString}%3D.eml`,
												'_blank'
											);
										} else {
											openDrawer(p.value);
											setCellData(p);
										}
									}}
									style={{ fontSize: '16px', cursor: 'pointer' }}
								/>
							</ToolTip>
						) : (
							''
						)}
					</>
				);
			},
			cellStyle: { padding: '4px 0 0 25px' },
			maxWidth: 80,
		},
		{
			field: 'isAck',
			headerName: 'Is Acknowledged?',
			cellRenderer: (p) =>
				p.value === '1' ? 'Yes' : p.value === '0' ? 'No' : '',
		},
	]);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);

	const handleSearchBy = (e) => {
		setSearchBy(e.target.value);
		setCustomerProspectData([]);
	};

	const onSelectionChanged = (p) => {
		setRowSelectionData(p.api.getSelectedRows());
	};

	useEffect(() => {
		if (paramsData?.reqMPN?.length > 0) {
			setCustomerProspectLoading(true);
			axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=get_supplier_prices&keyword=${paramsData?.reqMPN}`
				)
				.then((response) => {
					setCustomerProspectData(response.data);
					setCustomerProspectError(null);
				})
				.catch((err) => {
					setCustomerProspectError(err);
					setCustomerProspectData(null);
					setCustomerProspectLoading(false);
				})
				.finally(() => {
					setCustomerProspectLoading(false);
				});
		} //eslint-disable-next-line
	}, []);

	const handleSearchValue = () => {
		if (searchValue?.length > 2) {
			setCustomerProspectLoading(true);
			axios
				.get(
					searchBy === 'part-number'
						? `https://internal.supremecomponents.com/api/externalgateway.php?route=get_supplier_prices&keyword=${searchValue}`
						: searchBy === 'subject-mail'
						? `https://internal.supremecomponents.com/api/externalgateway.php?route=findmail&keyword=${encodeURIComponent(
								Base64.encode(searchValue)
						  )}`
						: null
				)
				.then((response) => {
					setCustomerProspectData(response.data);
					setCustomerProspectError(null);
				})
				.catch((err) => {
					setCustomerProspectError(err);
					setCustomerProspectData(null);
					setCustomerProspectLoading(false);
				})
				.finally(() => {
					setCustomerProspectLoading(false);
				});
		}
	};

	const extractPartsBobcat = () => {
		setCurrent(1);
	};

	return (
		<>
			{searchValue?.length < 3 && (
				<NotificationBar
					redBar
					message="A minimum of 3 characters are required to view the results."
				/>
			)}

			<NotificationBar
				wraningBar
				message={
					<ul style={{ listStyle: 'disc', paddingLeft: '10px' }}>
						<li>Note: Eden can only retrieve emails from the past 10 days.</li>
						<li>Select your supplier from the list of emails below.</li>
						<li>
							<b>New!</b> Try our new Bobcat GPT tool to try and extract your
							parts automatically from the email you select!
						</li>
					</ul>
				}
			/>

			<AntdRadio.Group
				defaultValue={searchBy}
				onChange={handleSearchBy}
				style={{ marginBottom: '10px' }}
			>
				<RadioTwo value={'part-number'}>Search using Part number</RadioTwo>
				<RadioTwo value={'subject-mail'}>
					Search using Subject or Email
				</RadioTwo>
			</AntdRadio.Group>

			{rowSelectionData?.length > 0 && (
				<Checkbox
					onChange={extractPartsBobcat}
					style={{ marginBottom: '10px' }}
				>
					Extract from Bobcat GPT and add parts to cost book​
				</Checkbox>
			)}

			<div className={styles['input-searchButton']}>
				<Input
					placeholder={
						searchBy === 'part-number'
							? 'Search for Part number'
							: searchBy === 'subject-mail'
							? "Search for Subject Line or Sender's Email Addresss"
							: null
					}
					onChange={(e) => setSearchValue(e.target.value)}
					onPressEnter={handleSearchValue}
					disabled={customerProspectLoading}
				/>
				<Button
					onClick={handleSearchValue}
					disabled={customerProspectLoading || searchValue?.length < 3}
				>
					Search <HiOutlineSearch className={styles['search-icon']} />
				</Button>
			</div>

			{customerProspectLoading && (
				<div className={styles['loading-wrapper']}>
					<LoadingMessage height={60} width={60} message="Loading..." />
				</div>
			)}

			{customerProspectError && !customerProspectLoading && (
				<div className={styles['error-wrapper']}>
					<ErrorMessage
						style={{ width: '200px' }}
						messageStyle={{ fontSize: '14px' }}
						message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed."
					/>
				</div>
			)}

			{!isNotEmptyArray(customerProspectData) &&
				!customerProspectError &&
				!customerProspectLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData
							style={{ width: '200px' }}
							messageStyle={{ fontSize: '14px' }}
							message={`Its all empty here. Search for ${
								searchBy === 'part-number'
									? 'Part number'
									: searchBy === 'subject-mail'
									? "Subject Line or Sender's Email Addresss"
									: null
							}.`}
						/>
					</div>
				)}

			{!customerProspectLoading &&
				!customerProspectError &&
				isNotEmptyArray(customerProspectData) && (
					<div className={`ag-theme-custom ${styles['table-wrapper']}`}>
						<AgGridReact
							columnDefs={
								searchBy === 'part-number'
									? partNumberColumnDefs
									: searchBy === 'subject-mail'
									? columnDefs
									: null
							}
							rowData={customerProspectData}
							defaultColDef={defaultColDef}
							rowSelection={'single'}
							onSelectionChanged={onSelectionChanged}
							animateRows={true}
							pagination={true}
							paginationPageSize={10}
							paginationPageSizeSelector={false}
						/>
					</div>
				)}

			{drawerMail && (
				<Drawer
					title={
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<p className={styles['mail-subject']}>
								<ToolTip placement="bottomLeft" title={drawerMailData?.subject}>
									{drawerMailData?.subject}
								</ToolTip>
							</p>
							<Link
								style={{ textDecoration: 'none' }}
								target="_blank"
								to={`${messageURl?.url}${cellData?.value?.replace(
									/=+$/,
									''
								)}%3D.eml`}
							>
								<Button type="bordered">Download Mail</Button>
							</Link>
						</div>
					}
					placement="right"
					onClose={closeDrawer}
					open={drawerMail}
					size={'large'}
				>
					{drawerMailLoading && <LoadingOutlined />}
					{drawerMailData && (
						<>
							<div className={styles['mail-details-wrapper']}>
								<div className={styles['mail-user-info']}>
									<Avatar
										className={styles['avatar']}
										style={{
											backgroundColor:
												avatarBgColor[drawerMailData?.fromName?.charAt(0)] ||
												avatarBgColor.default,
										}}
									>
										{drawerMailData?.fromName?.charAt(0)?.toUpperCase()}
									</Avatar>
									<div className={styles['users-info']}>
										<p className={styles['sender-name']}>
											{drawerMailData?.fromName}
										</p>
										{drawerMailData?.toRecipients && (
											<p className={styles['to']}>
												<span className={styles['title']}>To:</span>{' '}
												{drawerMailData?.toRecipients}
											</p>
										)}
										{drawerMailData?.ccRecipients && (
											<p className={styles['cc']}>
												<span className={styles['title']}>Cc:</span>{' '}
												{drawerMailData?.ccRecipients}
											</p>
										)}
										{drawerMailData?.bccRecipients && (
											<p className={styles['bcc']}>
												<span className={styles['title']}>Bcc:</span>{' '}
												{drawerMailData?.bccRecipients}
											</p>
										)}
									</div>
								</div>

								<p className={styles['date-info']}>
									{moment(drawerMailData?.receivedDateTime).format(
										'MMM-DD-YYYY hh:mm A'
									)}
								</p>
							</div>
							<div dangerouslySetInnerHTML={{ __html: drawerMailData?.html }} />
						</>
					)}
				</Drawer>
			)}
		</>
	);
};

const BobcatResults = ({
	rowSelectionData,
	bobcatResults,
	setBobcatResults,
	bobcatResultsLoading,
	setBobcatResultsLoading,
	bobcatResultsError,
	setBobcatResultsError,
	paramsData,
	setCurrent,
	setBobcatRowSelectionData,
}) => {
	const apiParams = rowSelectionData[0];

	const [filteredResults, setFilteredResults] = useState(null);

	const findPart = (array, targetMPN) => {
		const matchingParts = array.filter((part) => part.MPN === targetMPN);

		if (matchingParts.length === 1) {
			return {
				message: () => (
					<p>
						<b>Congratulations</b>, Eden found the exact matched part. Please
						confirm that this is the part.
					</p>
				),
				matchingParts,
			};
		} else if (matchingParts.length > 1) {
			return {
				message: () => (
					<p>
						<b>Congratulations</b>, Eden found the exact matched parts, but
						multiple entries for the same matched part were found. Please select
						the correct matched part. However, the system found{' '}
						<b>{array.length}</b> other part(s) in the email. Please select the
						part that best matches your customer’s MPN request:​
					</p>
				),
				matchingParts,
			};
		} else {
			return {
				message: () => (
					<p>
						Sorry 😞, but Eden was unable to find any exact matching parts from
						Bobcat against your MPN requested. However, the system found{' '}
						<b>{array.length}</b> other part(s) in the email. Please select the
						part that best matches your customer’s MPN request:
					</p>
				),
				matchingParts: array,
			};
		}
	};

	const fetchBobcatResults = () => {
		setBobcatResultsLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=edenservice&cid=${
					apiParams?.cid || apiParams?.CID
				}&mail_id=${apiParams?.mail_id || apiParams?.MAIL_ID}&mode=json`
			)
			.then((response) => {
				setBobcatResults(response.data);
				setFilteredResults(
					response.data?.output &&
						findPart(JSON.parse(response.data?.output), paramsData?.reqMPN)
				);
				setBobcatResultsError(null);
			})
			.catch((err) => {
				setBobcatResultsError(err);
				setBobcatResults(null);
				setBobcatResultsLoading(false);
			})
			.finally(() => {
				setBobcatResultsLoading(false);
			});
	};

	useEffect(() => {
		fetchBobcatResults(); //eslint-disable-next-line
	}, []);

	const [columnDefs] = useState([
		{
			checkboxSelection: true,
			field: 'MPN',
			headerName: 'MPN',
			cellRenderer: (p) => {
				const removeSearchPart =
					p?.value
						?.toLowerCase()
						?.replace(paramsData?.reqMPN?.toLowerCase(), '') || '';

				return (
					<>
						{p.value?.includes(paramsData?.reqMPN) ? (
							<>
								<strong style={{ color: 'green' }}>
									{paramsData?.reqMPN?.toUpperCase()}
								</strong>
								<span>{removeSearchPart?.toUpperCase()}</span>
							</>
						) : (
							p.value
						)}
					</>
				);
			},
		},
		{ field: 'BRAND', headerName: 'Brand' },
		{ field: 'QTY', headerName: 'MOQ' },
		{ field: 'PRC', headerName: 'USD Cost' },
		{ field: 'DC', headerName: 'DC' },
		{ field: 'packaging', headerName: 'Packaging' },
		{ field: 'LT', headerName: 'LT (w/units)' },
	]);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);

	const onSelectionChanged = (p) => {
		setBobcatRowSelectionData(p.api.getSelectedRows());
	};

	return (
		<>
			{!bobcatResultsLoading &&
				!bobcatResultsError &&
				bobcatResults?.status === 'success' &&
				isNotEmptyArray(JSON.parse(bobcatResults?.output)) && (
					<>
						<p style={{ marginBottom: '5px' }}>{filteredResults?.message()}</p>
						<p style={{ marginBottom: '20px' }}>
							<b>MPN Req:</b> {paramsData?.reqMPN}
						</p>
					</>
				)}

			{bobcatResultsLoading && (
				<div className={styles['loading-wrapper']}>
					<LoadingMessage height={60} width={60} message="Loading..." />
				</div>
			)}

			{bobcatResultsError && !bobcatResultsLoading && (
				<div className={styles['error-wrapper']}>
					<ErrorMessage
						style={{ width: '200px' }}
						messageStyle={{ fontSize: '14px' }}
						message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed."
					/>
				</div>
			)}

			{!bobcatResultsLoading &&
				!bobcatResultsError &&
				bobcatResults?.status === 'error' && (
					<div className={styles['error-wrapper']}>
						<p
							style={{
								fontSize: '16px',
								fontWeight: 600,
								marginTop: '20px',
								width: '500px',
								lineHeight: '20px',
							}}
						>
							Sorry 😞, but Eden was unable to find any parts from the Supplier
							email that you selected.​ Either{' '}
							<b style={{ cursor: 'pointer' }} onClick={() => setCurrent(0)}>
								go back
							</b>{' '}
							and change the email, or proceed without adding any parts.
						</p>
					</div>
				)}
			{!bobcatResultsLoading &&
				!bobcatResultsError &&
				bobcatResults?.status === 'success' &&
				bobcatResults?.output === '[]' && (
					<div className={styles['error-wrapper']}>
						<p
							style={{
								fontSize: '16px',
								fontWeight: 600,
								marginTop: '20px',
								width: '500px',
								lineHeight: '20px',
							}}
						>
							Sorry 😞, but Eden was unable to find any parts from the Supplier
							email that you selected.​ Either{' '}
							<b style={{ cursor: 'pointer' }} onClick={() => setCurrent(0)}>
								go back
							</b>{' '}
							and change the email, or proceed without adding any parts.
						</p>
					</div>
				)}

			{!bobcatResultsLoading &&
				!bobcatResultsError &&
				bobcatResults?.status === 'success' &&
				isNotEmptyArray(JSON.parse(bobcatResults?.output)) && (
					<div className={`ag-theme-custom ${styles['table-wrapper']}`}>
						<AgGridReact
							columnDefs={columnDefs}
							rowData={filteredResults?.matchingParts}
							defaultColDef={defaultColDef}
							rowSelection={'single'}
							onSelectionChanged={onSelectionChanged}
							animateRows={true}
							pagination={true}
							paginationPageSize={10}
							paginationPageSizeSelector={false}
						/>
					</div>
				)}
		</>
	);
};
