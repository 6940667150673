import { LuMailSearch } from 'react-icons/lu';
import { Divider } from 'antd';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';

import styles from './bounceBackTracker.module.scss';

const BounceBackTrackerV2 = () => {
	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="Bounceback Tracker"
				icon={<LuMailSearch style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="Bounceback Tracker"
				info="The Bounceback Tracking software is designed to help you track all “bounced” and “automatic reply” emails that were sent to a set of prospective or paying customers or suppliers. By using this tool, you will greatly improve your productivity."
			/>
			<div className={styles['bounceback-tracker']}>
				<Divider
					style={{
						margin: '10px 0',
						borderBlockStart: '1px solid rgb(216, 216, 216)',
					}}
				/>
				Download this tool here:{' '}
				<a
					href="https://supremecomponentsi.sharepoint.com/:f:/g/EpIjRuTS3mpGsVcp3Cwhk7EBEwwVJMEdYo2mPT3usECscA?e=79Itkp"
					target="_blank"
					rel="noreferrer"
					style={{
						color: 'blue',
						// borderBottom: '1px solid blue',
						cursor: 'pointer',
					}}
				>
					Click here to download
				</a>{' '}
				<br /> <br />
				<span style={{ fontWeight: 600 }}>Installation Guide:</span>
				<br /> <br />
				<ul
					style={{
						listStyleType: 'auto',
						paddingLeft: '20px',
						lineHeight: '20px',
					}}
				>
					<li>
						Click on the blue download link labeled "
						<a
							href="https://supremecomponentsi.sharepoint.com/:f:/g/EpIjRuTS3mpGsVcp3Cwhk7EBEwwVJMEdYo2mPT3usECscA?e=79Itkp"
							target="_blank"
							rel="noreferrer"
							style={{
								color: 'blue',
								// borderBottom: '1px solid blue',
								cursor: 'pointer',
							}}
						>
							Click here to download
						</a>
						".
					</li>
					<li>
						You will be redirected to a SharePoint page. Download the ZIP file
						named "EmailBouncebackTracker-v1.2.1.7".
					</li>
					<li>Click on the three dots (options) and select "Download".</li>
					<li>
						Once the download is complete, go to the folder where the file was
						saved.
					</li>
					<li>Right-click on the ZIP file and select "Extract".</li>
					<li>Navigate to the extracted folder.</li>
					<li>
						Right-click on the executable file "EmailBouncebackTracker.exe" or
						"EmailBouncebackTracker" and select "Open".
					</li>
					<li>
						The installation is now complete. Refer to the attached video for
						instructions on how to use this tool.
					</li>
				</ul>
				<br />
				<span style={{ fontWeight: 600 }}>How-to Use Video</span> <br /> <br />
				<div className={styles['video-container']}>
					<iframe
						src="https://www.youtube.com/embed/R_AWrgVwgWg"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						title="Embedded YouTube Video"
					></iframe>
				</div>
			</div>
		</div>
	);
};

export default BounceBackTrackerV2;
