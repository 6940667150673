import { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Form, message } from 'antd';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { IoMdAddCircle } from 'react-icons/io';
import { AgGridReact } from 'ag-grid-react';

import EdenContext from '../../../context/edenContext';
import BreadCrumb from '../../../components/breadcrumb';
import NotificationBar from '../../../components/notificationBar';
import Button from '../../../components/button';
import AccessDenied from '../../../components/accessDenied';
import { epAccTagsColumns } from '../../../columns';
import AddTagForm from './AddTagForm';
import LoadingMessage from '../../../components/loaderMessage';
import NoData from '../../../components/nodata';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';

import styles from './epAccTags.module.scss';

const EPAccTags = () => {
	const { userType, epTagsList, epTagsListLoading, epTagsListError, epTags } =
		useContext(EdenContext);
	const [form] = Form.useForm();
	const [addTagsModal, setAddTagsModal] = useState(false);

	useEffect(() => {
		epTags(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const Message = () => {
		return (
			<>
				<p>
					This section enables the administrator to add distinct tags to CRM
					accounts and/or contacts. These tags help automatically identify MPN,
					Brand, and Quantity in attachments or bodies of customer/prospect
					emails during the pre-processing stage for commonly use email formats.
					If a specific keyword and it’s associated MPN, Brand, and QTY is
					found, the system shall bypass ChatGPT and save the data for
					post-processing. 
				</p>
				<br />
				<p>A few important regarding the table:</p>
				<br />
				<ul style={{ listStyleType: 'disc', paddingLeft: '16px' }}>
					<li>
						If the Keyword Tags were not found for that contact, Eagle will send
						the commonly used email to ChatGPT
					</li>
					<li>
						If you select “No” under Apply Rule to All Contacts within Account,
						the system will allow you to only add the same account/email once
						more, but for the tag not used.
					</li>
				</ul>
			</>
		);
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agTextColumnFilter',
			suppressMovable: true,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	);

	const openAddTagsModal = () => {
		setAddTagsModal(true);
	};

	const closeAddTagsModal = () => {
		setAddTagsModal(false);
	};

	const addTags = (values) => {
		const formData = new FormData();
		formData.append('crm_account', values.crm_account);
		formData.append(
			'applied_at_account_level',
			values.applied_at_account_level
		);
		formData.append(
			'contact_email',
			JSON.stringify(values.contact_email) || ''
		);
		formData.append('rfq_location', values.rfq_location);
		formData.append(
			'mpn_header_tags',
			JSON.stringify(values.mpn_header_tags) || ''
		);
		formData.append(
			'brand_header_name_tags',
			JSON.stringify(values.brand_header_name_tags) || ''
		);
		formData.append(
			'qty_header_tags',
			JSON.stringify(values.qty_header_tags) || ''
		);
		formData.append(
			'dc_header_tags',
			JSON.stringify(values.dc_header_tags) || ''
		);
		formData.append(
			'lt_header_tags',
			JSON.stringify(values.lt_header_tags) || ''
		);
		formData.append(
			'prc_header_tags',
			JSON.stringify(values.prc_header_tags) || ''
		);
		formData.append(
			'moq_header_tags',
			JSON.stringify(values.moq_header_tags) || ''
		);
		formData.append(
			'spq_header_tags',
			JSON.stringify(values.spq_header_tags) || ''
		);
		formData.append(
			'pkg_header_tags',
			JSON.stringify(values.pkg_header_tags) || ''
		);
		formData.append('is_active', '1');

		axios
			.post(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=add_tags',
				formData
			)
			.then(() => {
				form.resetFields();
				message.open({
					type: 'success',
					content: 'Keyword added successfully',
				});
				setAddTagsModal(false);
				epTags();
			})
			.catch(() => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				form.resetFields();
				setAddTagsModal(false);
			});
	};

	const lastUpdated = epTagsList
		? epTagsList[epTagsList?.length - 1]?.date_added
		: '--';

	return (
		<>
			{userType === 'admin' ? (
				<div className={styles['wrapper']}>
					<BreadCrumb
						route1="Admin"
						route2="Tagging System"
						icon={<MdOutlineAdminPanelSettings style={{ fontSize: '20px' }} />}
					/>
					<div className={styles['ep-acc-tags']}>
						<NotificationBar message={<Message />} wraningBar />

						<div className={styles['button-last-updated']}>
							<Button onClick={openAddTagsModal}>
								<IoMdAddCircle style={{ fontSize: '16px' }} />
								Add Tags
							</Button>
							<p className={styles['last-report']}>
								Last Updated on :{' '}
								{epTagsList
									? moment(lastUpdated)?.format('MMM-DD-YYYY hh:mm A')
									: '--'}
							</p>
						</div>

						{epTagsListLoading && (
							<div className={styles['loading-wrapper']}>
								<LoadingMessage message="Loading..." />
							</div>
						)}

						{epTagsListError && !epTagsListLoading && (
							<div className={styles['error-wrapper']}>
								<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
							</div>
						)}

						{!isNotEmptyArray(epTagsList) &&
							!epTagsListError &&
							!epTagsListLoading && (
								<div className={styles['no-data-wrapper']}>
									<NoData message="Its all empty here." />
								</div>
							)}

						{!epTagsListLoading &&
							!epTagsListError &&
							isNotEmptyArray(epTagsList) && (
								<div className={styles['table-wrapper']}>
									<div className={`ag-theme-custom ${styles['table']} `}>
										<AgGridReact
											rowData={epTagsList}
											columnDefs={epAccTagsColumns}
											defaultColDef={defaultColDef}
											animateRows={true}
											pagination={true}
											paginationPageSizeSelector={false}
										/>
									</div>
								</div>
							)}
					</div>
					<AddTagForm
						open={addTagsModal}
						form={form}
						onCancel={closeAddTagsModal}
						onFinish={addTags}
						initialValues={{
							applied_at_account_level: 'Yes',
						}}
					/>
				</div>
			) : (
				<AccessDenied message="You are not an Admin" />
			)}
		</>
	);
};

export default EPAccTags;
