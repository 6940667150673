import { useState, useContext, useEffect } from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { HomeOutlined, MenuOutlined } from '@ant-design/icons';
import {
	MdOutlineAdminPanelSettings,
	MdEmail,
	// MdOutlinePriceChange,
} from 'react-icons/md';
import { TbReport, TbReportMoney, TbSpeakerphone } from 'react-icons/tb';
import { IoMdPricetags } from 'react-icons/io';
import { HiOutlineQueueList } from 'react-icons/hi2';
import { AiOutlineMoneyCollect } from 'react-icons/ai';
import { FcInTransit } from 'react-icons/fc';
import { LuMailSearch } from 'react-icons/lu';
import { RiContactsFill } from 'react-icons/ri';
import { FaMailBulk } from 'react-icons/fa';
import { SiBrandfolder } from 'react-icons/si';
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import { FaBook } from 'react-icons/fa';

import EdenContext from '../../context/edenContext';
// import netCompIcon from '../../images/netcompICON.ico';
import HouseAccount from '../../images/HouseAccount.png';

import styles from './sidebar.module.scss';

const Sidebar = ({ collapsed, toggleCollapsed, setCollapsed }) => {
	const { userType, userMail } = useContext(EdenContext);

	const location = useLocation();
	const navigate = useNavigate();
	const [openKeys, setOpenKeys] = useState([]);

	const [leaveTime, setLeaveTime] = useState(null);

	useEffect(() => {
		setLeaveTime(new Date());
	}, [location.pathname]);

	function getItem(label, key, icon, children, disabled) {
		return {
			key,
			icon,
			children,
			label,
			disabled,
		};
	}

	const items = [
		getItem('Home', '/', <HomeOutlined />),
		getItem(
			'Help Desk',
			'/help-desk',
			<TfiHeadphoneAlt style={{ fontSize: '18px' }} />
		),
		getItem(
			'Bobcat: Supplier Prices',
			'/supplier-prices',
			<AiOutlineMoneyCollect style={{ fontSize: '18px' }} />
		),
		getItem(
			'Bounceback Tracker',
			'/bounceback-tracker',
			<LuMailSearch style={{ fontSize: '18px' }} />
		),
		getItem(
			'Customer Outreach​',
			'/customer-outreach',
			<TbSpeakerphone style={{ fontSize: '18px' }} />
		),
		getItem(
			'Customer Report',
			'/customer-report',
			<TbReportMoney style={{ fontSize: '18px' }} />
		),
		getItem(
			'Eagle: Customer RFQs',
			'/customer-rfqs',
			<span style={{ fontWeight: 'bold', fontSize: '10px' }}>RFQ</span>
		),
		getItem(
			'Email Engagement',
			'/email-engagement',
			<MdEmail style={{ fontSize: '18px' }} />
		),
		getItem(
			'Freight/MOV by Supplier',
			'/freight-by-supplier',
			<FcInTransit style={{ fontSize: '20px' }} />
		),
		getItem('Learn Centre', '/learn-centre', <FaBook />),
		getItem(
			'House Accounts',
			'/house-accounts',
			<img src={HouseAccount} alt="House Account" style={{ width: '20px' }} />
		),
		(userType === 'admin' || userType === 'ceo') &&
			getItem(
				'Management Reports​',
				'/management-report',
				<TbReport style={{ fontSize: '18px' }} />
			),
		getItem(
			'Mass Mail​',
			'/mass-mail',
			<FaMailBulk style={{ fontSize: '18px' }} />
		),
		getItem(
			'MPN Queue',
			'/mpn-queue',
			<HiOutlineQueueList style={{ fontSize: '18px' }} />
		),
		getItem(
			'MPN Report',
			'/mpn-report',
			<IoMdPricetags style={{ fontSize: '18px' }} />
		),
		getItem(
			'My Assigned Accounts​',
			'/assign-to-house',
			<img src={HouseAccount} alt="House Account" style={{ width: '20px' }} />
		),
		getItem(
			'My Customer Contacts',
			'/customer-contacts',
			<RiContactsFill style={{ fontSize: '18px' }} />
		),
		// getItem(
		// 	'Net Components',
		// 	'/netcomponents',
		// 	<img src={netCompIcon} alt="Net Component" />
		// ),
		getItem(
			'Preferred suppliers by Brand',
			'/preferred-suppliers-by-brand',
			<SiBrandfolder />
		),

		// getItem(
		// 	'MPN Cost Book',
		// 	'/mpn-cost-book',
		// 	<MdOutlinePriceChange style={{ fontSize: '18px' }} />
		// ),
		userType === 'admin'
			? getItem(
					'Admin',
					'/admin',
					<MdOutlineAdminPanelSettings style={{ fontSize: '20px' }} />,
					[
						getItem('Account Allocation Log', '/admin/account-allocation-log'),
						getItem('API Health Checker', '/admin/api-health-checker'),
						getItem('API Token Limits', '/admin/api-token-limits'),
						getItem('ChatGPT Usage Report', '/admin/chatgpt-usage-report'),
						getItem('ChipsFinder Sources', '/admin/chipfinder-sources'),
						getItem('Exclusion Manager', '/admin/bobcat-eagle-exclusions-list'),
						getItem('Global ChatGPT Report', '/admin/global-chatgpt-report'),
						getItem('Header Identifiers', '/admin/chatgpt-keywords'),
						getItem('House Account - Admin', '/admin/house-account'),
						getItem(
							'House Account Exclusion',
							'/admin/house-account-exclusion'
						),
						getItem('LinkedIn Cleanser', '/admin/linkedin-cleanser'),
						getItem('Sales Report Tracker', '/admin/sales-report-tracker'),
						getItem('Sourcing Assignments', '/admin/sourcing-assignments'),
						getItem('SR Accounts Summary', '/admin/sr-accounts-summary'),
						getItem('Tagging System', '/admin/eagle-processor-account-tags'),
					]
			  )
			: null,
	];

	const rootSubmenuKeys = [
		'/',
		'/sales-performance',
		'/email-engagement',
		'/rfq-opportunities',
		'/my-sources',
		'/my-quotes',
	];

	const onOpenChange = (keys) => {
		const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			setOpenKeys(keys);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};

	const onClick = ({ key }) => {
		navigate(key);
		setCollapsed(true);

		const leDate = new Date(leaveTime?.toISOString());
		const entDate = new Date(new Date().toISOString());
		const diffInMilliseconds = Math.abs(leDate - entDate);
		const url = window.location.pathname;
		const sectionName = url
			?.substring(url?.lastIndexOf('/'))
			.replace(/[/-]/g, ' ')
			.toUpperCase()
			.trim();

		const data = {
			user_email: userMail,
			section_name: sectionName,
			section_enter_time: leaveTime?.toISOString(),
			section_leave_time: new Date().toISOString(),
			total_duration_seconds: diffInMilliseconds / 1000,
			event_datetime: '',
			comment: '',
		};

		axios
			.post(
				`https://internal.supremecomponents.com/api/track.php`,
				JSON.stringify(data)
			)
			.then((response) => {});
	};

	return (
		<div className={styles['sidebar-wrapper']}>
			<div onClick={toggleCollapsed} className={styles['collapse-btn']}>
				<MenuOutlined />
			</div>
			<Menu
				className={` ${styles['side']} ${
					collapsed === true
						? styles['sidebar-collapsed']
						: userType === 'sm'
						? styles['sidebar-sm']
						: styles['sidebar']
				}`}
				mode="inline"
				openKeys={openKeys}
				inlineCollapsed={collapsed}
				items={items}
				triggerSubMenuAction="click"
				onClick={onClick}
				onOpenChange={onOpenChange}
				selectedKeys={[window.location.pathname]}
			/>
		</div>
	);
};

export default Sidebar;
