import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Avatar, message } from 'antd';
import axios from 'axios';
import { LoadingOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import { HiOutlineSearch } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { MdMail } from 'react-icons/md';
import moment from 'moment-timezone';

import BreadCrumb from '../../../components/breadcrumb';
import SectionInfo from '../../../components/sectionInfo';
import InnerHeader from '../../../components/innerHeader';
import NotificationBar from '../../../components/notificationBar';
import Input from '../../../components/input';
import Button from '../../../components/button';
import NoData from '../../../components/nodata';
import ErrorMessage from '../../../components/errorMessage';
import LoadingMessage from '../../../components/loaderMessage';
import { isNotEmptyArray, titleCase } from '../../../utils';
import ToolTip from '../../../components/tooltip';
import EdenContext from '../../../context/edenContext';
import Select from '../../../components/select';
import { useQuery } from '../../../hooks/use-query';
import Drawer from '../../../components/drawer';
import { avatarBgColor } from '../../../constant';

import styles from './supplierPrices.module.scss';

const dateFilterParams = {
	filters: [
		{
			filter: 'agDateColumnFilter',
			filterParams: {
				suppressMiniFilter: true,
				comparator: (a, b) => {
					return getDate(a).getTime() - getDate(b).getTime();
				},
			},
		},
	],
};

const getDate = (value) => {
	const date = moment.utc(value).tz('Asia/Singapore').format('DD/MM/YYYY');
	var dateParts = date.split('/');
	return new Date(
		Number(dateParts[2]),
		Number(dateParts[1]) - 1,
		Number(dateParts[0])
	);
};

const SupplierPrices = () => {
	const gridRef = useRef();
	const { query } = useQuery();
	const mpn = query.get('mpn');
	const mailid = query.get('id');

	const { messageURl, userType } = useContext(EdenContext);
	const [searchValue, setSearchValue] = useState('');
	const [gridApi, setGridApi] = useState();
	const [rowdata, setRowData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [selectedRows, setSelectedRows] = useState([]);
	const [currentTime, setCurrentTime] = useState(moment().format());
	const [dateRange, setDateRange] = useState('1W');
	const [count, setCount] = useState(0);
	const [drawerMail, setDrawerMail] = useState(false);
	const [drawerMailData, setDrawerMailData] = useState(null);
	const [drawerMailLoading, setDrawerMailLoading] = useState(false);
	const [cellData, setCellData] = useState(null);

	const closeDrawer = () => {
		setDrawerMail(!drawerMail);
		setDrawerMailData(null);
		setCellData(null);
	};

	const openDrawer = (cid) => {
		setDrawerMail(true);
		setDrawerMailLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_mail_html&cid=${cid}`
			)
			.then((response) => {
				setDrawerMailData(response.data);
			})
			.finally(() => {
				setDrawerMailLoading(false);
			});
	};

	const getPrompt = () => {
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_prompt&cid=${cellData?.data?.CID}`
			)
			.then((response) => {
				navigator.clipboard
					.writeText(response.data)
					.then(() => {
						message.success('Copied to clipboard!');
					})
					.catch((err) => {
						message.error('Failed to copy to clipboard.');
					});
			});
	};

	const fetchSupplierPricesToday = () => {
		setLoading(true);
		setSelectedRows([]);
		setCount(0);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_supplier_prices_today&interval=${dateRange}`
			)
			.then((response) => {
				setRowData(response?.data);
				setCurrentTime(moment().format());
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setRowData(null);
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSearch = () => {
		if (searchValue?.length > 3) {
			setLoading(true);
			setSelectedRows([]);
			setCount(0);
			axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=get_supplier_prices&keyword=${searchValue}`
				)
				.then((response) => {
					setRowData(response?.data);
					setCurrentTime(moment().format());
					setError(null);
				})
				.catch((err) => {
					setError(err);
					setRowData(null);
					setLoading(false);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const fetchData = () => {
		if (!loading) {
			axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=get_supplier_prices_today&interval=${dateRange}`
				)
				.then((response) => {
					const difference = response.data?.length - rowdata?.length;
					if (difference > 0) {
						setCount(difference);
					}
				});
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			fetchData();
		}, 15 * 1000);

		return () => clearInterval(interval); //eslint-disable-next-line
	}, [dateRange, rowdata]);

	useEffect(() => {
		if (searchValue?.length <= 0) {
			fetchSupplierPricesToday();
		} //eslint-disable-next-line
	}, [searchValue, dateRange]);

	const [columnDefs] = useState([
		{
			headerName: 'Action Center',
			children: [
				{
					field: 'INTERNET_MESSAGE_ID',
					filter: 'agSetColumnFilter',
					hide: true,
				},
				{
					field: 'MAIL_ID',
					headerName: 'Mail',
					floatingFilter: mailid ? true : false,
					filter: mailid && 'agSetColumnFilter',
					suppressHeaderMenuButton: true,
					floatingFilterComponentParams: {
						suppressFilterButton: true,
					},
					checkboxSelection: true,
					headerCheckboxSelection: true,
					headerCheckboxSelectionFilteredOnly: true,
					maxWidth: 120,
					cellRenderer: (p) => {
						const trimmedString = p?.data?.MAIL_ID?.replace(/=+$/, '');
						return (
							<ToolTip title="Preview mail">
								<MdMail
									onClick={(e) => {
										if (e.ctrlKey && e.button === 0) {
											window.open(
												`${messageURl?.url}${trimmedString}%3D.eml`,
												'_blank'
											);
										} else {
											openDrawer(p.data?.CID);
											setCellData(p);
										}
									}}
									style={{ fontSize: '16px', cursor: 'pointer' }}
								/>
							</ToolTip>
						);
					},
				},
			],
		},
		{
			headerName: 'Quote Details',
			headerClass: styles['other-header'],
			children: [
				{
					field: 'DATE_TIME',
					headerName: 'Incoming Date and Time',
					sort: 'desc',
					minWidth: 180,
					cellRenderer: (p) => (
						<span className={styles['font-weight']}>
							{p.value
								? moment
										.utc(p.value)
										.tz('Asia/Singapore')
										.format('MMM-DD-YYYY hh:mm A')
								: ''}
						</span>
					),
					filterParams: dateFilterParams,
					suppressHeaderMenuButton: true,
					floatingFilterComponentParams: {
						suppressFilterButton: true,
					},
				},
				{
					field: 'DATE_ADDED',
					headerName: 'Date and Time Added',
					cellRenderer: (p) => (
						<span className={styles['font-weight']}>
							{p.value
								? moment
										.utc(p.value)
										.tz('Asia/Singapore')
										.format('MMM-DD-YYYY hh:mm A')
								: ''}
						</span>
					),
					filterParams: dateFilterParams,
					suppressHeaderMenuButton: true,
					floatingFilterComponentParams: {
						suppressFilterButton: true,
					},
				},
				{
					field: 'Source',
					headerName: 'Source',
					maxWidth: 100,
					cellRenderer: (p) => 'Email',
				},
			],
		},
		{ field: 'ID', headerName: 'ID', sort: 'desc', hide: true },
		{
			headerName: 'MPN Source Details',
			headerClass: styles['msd-header'],
			children: [
				{
					field: 'MPN',
					headerName: 'MPN Found',
					headerClass: styles['msd-header-child'],
					filter: mpn ? 'agSetColumnFilter' : 'agMultiColumnFilter',
					cellRenderer: (p) => {
						const inputValue = (
							document.getElementById('searchValue')?.value || ''
						).toLowerCase();
						const cellValue = (p.value || '').toLowerCase();

						const index = cellValue.indexOf(inputValue);
						if (index !== -1) {
							const beforeHighlight = cellValue.substring(0, index);
							const highlighted = cellValue.substring(
								index,
								index + inputValue.length
							);
							const afterHighlight = cellValue.substring(
								index + inputValue.length
							);

							return (
								<span className={styles['font-size']}>
									{beforeHighlight.toUpperCase()}
									<strong style={{ color: '#0070C0' }}>
										{highlighted.toUpperCase()}
									</strong>
									{afterHighlight.toUpperCase()}
								</span>
							);
						} else {
							return (
								<span className={styles['font-size']}>
									{cellValue.toUpperCase()}
								</span>
							);
						}
					},
				},
				{
					field: 'BRAND',
					headerName: 'Brand Found',
					headerClass: styles['msd-header-child'],
					cellRenderer: (p) => (
						<span className={styles['font-weight']}>
							{p.value ? p.value?.toUpperCase() : ''}
						</span>
					),
				},
				{
					field: 'QTY',
					headerName: 'QTY',
					maxWidth: 120,
					headerClass: styles['msd-header-child'],
					cellRenderer: (p) => {
						const number = parseInt(p.value);
						const trimmedString = p?.data?.MAIL_ID?.replace(/=+$/, '');
						return (
							<>
								{isNaN(number) ? (
									<Link
										style={{
											display: 'flex',
											alignItems: 'center',
											color: '#0070C0',
										}}
										target="_blank"
										to={`${messageURl?.url}${trimmedString}%3D.eml`}
									>
										<ToolTip title="Preview mail">Check email</ToolTip>
									</Link>
								) : (
									number.toLocaleString('en-US')
								)}
							</>
						);
					},
				},
				{
					field: 'PRC',
					headerName: 'USD Cost',
					maxWidth: 120,
					headerClass: styles['msd-header-child'],
					cellRenderer: (p) => {
						const number = parseFloat(p.value);
						return (
							<>
								{p.value
									? !isNaN(number)
										? '$' +
										  number.toLocaleString('en-US', {
												minimumFractionDigits: 5,
										  })
										: ''
									: ''}
							</>
						);
					},
				},
				{
					field: 'ext_price',
					maxWidth: 120,
					headerName: 'Ext. Price',
					headerClass: styles['msd-header-child'],
					cellRenderer: (p) => {
						const extPrice = parseFloat(p.data?.QTY) * parseFloat(p.data?.PRC);
						return (
							<>
								{typeof extPrice === 'number' && !isNaN(extPrice)
									? '$' + extPrice.toLocaleString('en-US')
									: ''}
							</>
						);
					},
				},
				{
					field: 'DC',
					headerName: 'DC',
					maxWidth: 120,
					headerClass: styles['msd-header-child'],
				},
				{
					field: 'PKG',
					headerName: 'Packaging',
					headerClass: styles['msd-header-child'],
				},
				{
					field: 'LT',
					headerName: 'LT (w/units)',
					maxWidth: 120,
					headerClass: styles['msd-header-child'],
				},
				{
					field: 'SUPPLIER_STRENGTH',
					maxWidth: 130,
					headerName: 'Brand Strength',
					headerClass: styles['msd-header-child'],
				},
				{
					field: 'REMARKS',
					headerName: 'Remarks',
					headerClass: styles['msd-header-child'],
				},
			],
		},
		{
			headerName: 'Supplier Details',
			headerClass: styles['sd-header'],
			children: [
				{
					field: 'SUBJECT',
					headerName: 'Subject',
					headerClass: styles['sd-header-child'],
					cellRenderer: (p) => (
						<ToolTip placement="bottomLeft" title={p?.value}>
							<span className={styles['data']}>{p?.value}</span>
						</ToolTip>
					),
				},
				{
					field: 'ACCOUNT',
					headerName: 'Supplier',
					headerClass: styles['sd-header-child'],
					cellRenderer: (p) => (
						<ToolTip placement="bottomLeft" title={p?.value}>
							<span className={styles['data']}>{p?.value}</span>
						</ToolTip>
					),
				},
				{
					field: 'FROM_EMAIL',
					headerName: 'Supplier Email',
					headerClass: styles['sd-header-child'],
				},
				{
					field: 'TO_EMAIL',
					headerName: 'To',
					headerClass: styles['sd-header-child'],
					cellRenderer: (p) => (
						<span className={styles['font-weight']}>
							{p.value ? p.value?.toLowerCase() : ''}
						</span>
					),
				},
				{
					field: 'SALES_REP_NAME',
					headerName: 'Sales Rep',
					cellRenderer: (p) => (
						<span className={styles['font-weight']}>
							{p.value ? titleCase(p.value) : ''}
						</span>
					),
					headerClass: styles['sd-header-child'],
				},
				{
					field: 'ACCOUNT_NAME',
					headerName: 'Supplier Contact Name',
					headerClass: styles['sd-header-child'],
				},
			],
		},
		{
			headerName: 'Outbound Details',
			children: [
				{ headerName: 'MPN', field: 'mpn', hide: true },
				{ headerName: 'Brand', field: 'brand', hide: true },
				{ headerName: 'QTY', field: 'qty', hide: true },
			],
		},
	]);

	useEffect(() => {
		if (mpn && gridApi && !searchValue) {
			const getMpnColumn = gridApi?.getFilterInstance('MPN');
			getMpnColumn?.setModel({ values: [mpn] });
			gridApi?.onFilterChanged();
		}
	}, [mpn, gridApi, searchValue]);

	useEffect(() => {
		if (mailid && gridApi && !searchValue) {
			const getMpnColumn = gridApi?.getFilterInstance('INTERNET_MESSAGE_ID');
			getMpnColumn?.setModel({ values: [mailid] });
			gridApi?.onFilterChanged();

			setTimeout(() => {
				gridApi.getSelectedRows()?.length > 0
					? gridApi.getSelectedRows()
					: gridApi?.selectAll();
				gridApi.copySelectedRowsToClipboard({
					includeHeaders: true,
					columnKeys: [
						'MPN',
						'BRAND',
						'QTY',
						'PRC',
						'ext_price',
						'DC',
						'LT',
						'SUPPLIER_STRENGTH',
						'REMARKS',
					],
				});
				gridApi.deselectAll();
				message.open({
					type: 'success',
					content: `Results copied to your clipboard!`,
				});
			}, 1000);
		}
	}, [mailid, gridApi, searchValue]);

	const onGridReady = (p) => {
		setGridApi(p.api);
	};

	// Default column options
	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			floatingFilter: true,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);

	// export excel sheet style
	const excelStyles = [
		{
			id: 'header',
			font: {
				bold: true,
			},
		},
	];

	const exportToExcel = () => {
		const supplierPricesExcelParams = {
			sheetName: 'Supplier Prices',
			fileName: 'Supplier Prices.xlsx',
			columnKeys: [
				'MAIL_ID',
				'DATE_TIME',
				'DATE_ADDED',
				'Source',
				'MPN',
				'BRAND',
				'QTY',
				'PRC',
				'ext_price',
				'DC',
				'PKG',
				'LT',
				'SUPPLIER_STRENGTH',
				'REMARKS',
				'SUBJECT',
				'ACCOUNT',
				'FROM_EMAIL',
				'TO_EMAIL',
				'SALES_REP_NAME',
				'ACCOUNT_NAME',
			],
			processCellCallback: (p) => {
				if (p.column.colId === 'DATE_TIME') {
					if (p?.value) {
						return moment
							.utc(p.value)
							.tz('Asia/Singapore')
							.format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				if (p.column.colId === 'DATE_ADDED') {
					if (p?.value) {
						return moment
							.utc(p.value)
							.tz('Asia/Singapore')
							.format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				if (p.column.colId === 'Source') {
					return 'Email';
				}
				if (p.column.colId === 'MAIL_ID') {
					if (p?.value) {
						const trimmedString = p?.value?.replace(/=+$/, '');
						return `${messageURl?.url}${trimmedString}%3D.eml`;
					}
					return '';
				}
				if (p.column.colId === 'QTY') {
					const number = parseInt(p.value);
					if (p.value && typeof number === 'number' && !isNaN(number)) {
						return number?.toLocaleString('en-US');
					}
					return p.value;
				}
				if (p.column.colId === 'PRC') {
					const number = parseFloat(p.value);
					if (p?.value && typeof number === 'number' && !isNaN(number)) {
						return `$ ${number?.toLocaleString('en-US', {
							minimumFractionDigits: 5,
						})}`;
					}
					return p.value;
				}
				if (p.column.colId === 'ext_price') {
					const extPrice =
						parseFloat(p.node.data.QTY) * parseFloat(p.node.data.PRC);
					if (
						p.node.data.QTY &&
						p.node.data.PRC &&
						typeof extPrice === 'number' &&
						!isNaN(extPrice)
					) {
						return `$ ${extPrice?.toLocaleString('en-US')}`;
					}
				}
				return p.value;
			},
		};
		gridApi.exportDataAsExcel(supplierPricesExcelParams);
	};

	const onSelectionChanged = (e) => {
		setSelectedRows(e.api.getSelectedRows());
	};

	const processCellForClipboard = (p) => {
		if (p.column.colId === 'DATE_TIME') {
			if (p?.value) {
				return moment
					.utc(p.value)
					.tz('Asia/Singapore')
					.format('MMM-DD-YYYY hh:mm A');
			}
			return '';
		}
		if (p.column.colId === 'Source') {
			return 'Email';
		}
		if (p.column.colId === 'MAIL_ID') {
			if (p?.value) {
				const trimmedString = p?.value?.replace(/=+$/, '');
				return `${messageURl?.url}${trimmedString}%3D.eml`;
			}
			return '';
		}
		if (p.column.colId === 'QTY') {
			const number = parseInt(p.value);
			if (p.value && typeof number === 'number' && !isNaN(number)) {
				return number?.toLocaleString('en-US');
			}
			return p.value;
		}
		if (p.column.colId === 'PRC') {
			const number = parseFloat(p.value);
			if (p?.value && typeof number === 'number' && !isNaN(number)) {
				return `$ ${number?.toLocaleString('en-US', {
					minimumFractionDigits: 5,
				})}`;
			}
			return p.value;
		}
		if (p.column.colId === 'SUPPLIER_STRENGTH') {
			return `  ${p.value}`;
		}
		if (p.column.colId === 'ext_price') {
			const extPrice =
				parseFloat(p.node.data.QTY) * parseFloat(p.node.data.PRC);
			if (
				p.node.data.QTY &&
				p.node.data.PRC &&
				typeof extPrice === 'number' &&
				!isNaN(extPrice)
			) {
				return `$ ${extPrice?.toLocaleString('en-US')}`;
			}
		}
		return p.value;
	};

	const copyToClipboard = () => {
		if (gridApi) {
			gridApi.getSelectedRows()?.length > 0
				? gridApi.getSelectedRows()
				: gridApi?.selectAll();
			gridApi.copySelectedRowsToClipboard({
				includeHeaders: true,
				columnKeys: [
					'MAIL_ID',
					'DATE_TIME',
					'DATE_ADDED',
					'Source',
					'MPN',
					'BRAND',
					'QTY',
					'PRC',
					'ext_price',
					'DC',
					'PKG',
					'LT',
					'SUPPLIER_STRENGTH',
					'REMARKS',
					'SUBJECT',
					'ACCOUNT',
					'FROM_EMAIL',
					'TO_EMAIL',
					'SALES_REP_NAME',
					'ACCOUNT_NAME',
				],
			});
			gridApi.deselectAll();
		}
		message.open({
			type: 'success',
			content: `${selectedRows?.length} Rows copied to your clipboard!`,
		});
	};

	const copyResultsToClipboard = () => {
		if (gridApi) {
			gridApi.getSelectedRows()?.length > 0
				? gridApi.getSelectedRows()
				: gridApi?.selectAll();
			gridApi.copySelectedRowsToClipboard({
				includeHeaders: true,
				columnKeys: [
					'MPN',
					'BRAND',
					'QTY',
					'PRC',
					'ext_price',
					'DC',
					'PKG',
					'LT',
					'SUPPLIER_STRENGTH',
					'REMARKS',
				],
			});
			gridApi.deselectAll();
		}
		message.open({
			type: 'success',
			content: `Results copied to your clipboard!`,
		});
	};

	const autoSizeStrategy = useMemo(() => {
		return {
			type: 'fitGridWidth',
			defaultMinWidth: 100,
			columnLimits: [
				{
					colId: 'Source',
					minWidth: 100,
				},
				{
					colId: 'MAIL_ID',
					maxWidth: 100,
				},
			],
		};
	}, []);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="Sourcing"
				route2="Supplier Prices"
				icon={<MoneyCollectOutlined style={{ paddingRight: '5px' }} />}
			/>
			<SectionInfo
				title="Supplier Prices"
				info="This section displays all supplier prices received via mass emails.​"
				tooltipTitle={
					<ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
						<li>The sender should be valid CRM Supplier</li>
						<li>
							Generic domain email are not eligible Example: google.com,
							yahoo.com
						</li>
						<li>
							Subject will be excluded which contains excluded keyword and will
							ignore excluded subject
						</li>
						<li>Any attachment other than excel is ignored</li>
						<li>Ignore any mail legal disclaimers, confidentiality notes</li>
						<li>If any mail coming from excluded mail then its ignored</li>
						<li>Internal mails are also ignored</li>
					</ul>
				}
			/>

			<InnerHeader
				exportToExcel={exportToExcel}
				copyToClipboard={
					mailid && gridApi && !searchValue && copyResultsToClipboard
				}
				copyToClipboardText="Copy results to clipboard"
				updatePage
				onUpdate={searchValue ? handleSearch : fetchSupplierPricesToday}
				updateText="Check for new Prices"
				lastReport={currentTime}
				lastReportText="Last updated on"
			></InnerHeader>
			<div className={styles['bobcat']}>
				{count > 0 && searchValue.length < 0 && (
					<NotificationBar
						message={
							<p>
								{count}&nbsp;new Supplier Prices received.&nbsp;
								<b
									onClick={fetchSupplierPricesToday}
									style={{ cursor: 'pointer', color: '#0070C0' }}
								>
									Click here
								</b>{' '}
								to check new Supplier Prices.
							</p>
						}
						greenBar
					/>
				)}

				<div className={styles['dropdown-wrapper']}>
					<div className={styles['input-button-wrapper']}>
						<Input
							style={{ width: '250px' }}
							placeholder="Global Search within Grid Results"
							onChange={(e) => gridApi?.setQuickFilter(e.target.value)}
							allowClear={true}
							disabled={loading}
						/>
						<Input
							style={{ width: '250px' }}
							placeholder="Search by MPN"
							onChange={(e) => setSearchValue(e.target.value)}
							onPressEnter={handleSearch}
							id="searchValue"
							allowClear={true}
							onClear={fetchSupplierPricesToday}
							disabled={loading}
						/>
						<Button onClick={handleSearch} disabled={searchValue?.length <= 3}>
							Search <HiOutlineSearch className={styles['search-icon']} />
						</Button>

						<Select
							title="Date Range:"
							disabled={loading}
							style={{
								width: 100,
							}}
							defaultValue={'1W'}
							placeholder="Date Range"
							options={[
								{ value: '1W', label: '1 Week' },
								{ value: '2W', label: '2 Weeks' },
								{ value: '3W', label: '3 Weeks' },
								{ value: '4W', label: '4 Weeks' },
								{ value: '', label: 'All' },
							]}
							onChange={(value) => setDateRange(value)}
						/>
					</div>
					{selectedRows?.length > 0 && (
						<Button onClick={copyToClipboard}>Copy Row(s)</Button>
					)}
					{selectedRows?.length === 1 && (
						<Button disabled>Copy to Cost Book</Button>
					)}
				</div>

				{!searchValue?.length <= 3 && !rowdata && !error && !loading && (
					<div className={styles['no-data-wrapper']}>
						<NoData search message="Please Search an MPN to Continue" />
					</div>
				)}

				{loading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Fetching the Results..." />
					</div>
				)}

				{error && !loading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(rowdata) &&
					rowdata !== null &&
					!error &&
					!loading && (
						<div className={styles['no-data-wrapper']}>
							<NoData
								message={
									<>
										<p style={{ fontSize: '20px', marginBottom: '5px' }}>
											Oops... we didn't find anything that matched this search.
										</p>
										<p style={{ fontSize: '12px' }}>
											Try shaving off characters in your search descriptor to
											expand your search results.
										</p>
									</>
								}
							/>
						</div>
					)}

				{!loading && !error && isNotEmptyArray(rowdata) && (
					<div className={styles['table-wrapper']}>
						<div className={`ag-theme-custom ${styles['table']} `}>
							<AgGridReact
								ref={gridRef}
								onGridReady={onGridReady}
								rowSelection={'multiple'}
								rowData={rowdata}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								onSelectionChanged={onSelectionChanged}
								animateRows={true}
								pagination={true}
								paginationPageSize={50}
								enableRangeSelection={true}
								excelStyles={excelStyles}
								rowMultiSelectWithClick={true}
								suppressRowHoverHighlight={true}
								rowClass={styles['row-style']}
								autoSizeStrategy={autoSizeStrategy}
								suppressRowClickSelection={true}
								processCellForClipboard={processCellForClipboard}
								paginationPageSizeSelector={false}
							/>
						</div>
					</div>
				)}
				{drawerMail && (
					<Drawer
						title={
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<p className={styles['mail-subject']}>
									<ToolTip
										placement="bottomLeft"
										title={drawerMailData?.subject}
									>
										{drawerMailData?.subject}
									</ToolTip>
								</p>
								<div
									style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
								>
									{userType === 'admin' && (
										<Button onClick={getPrompt}>Copy Prompt</Button>
									)}
									<Link
										style={{ textDecoration: 'none' }}
										target="_blank"
										to={`${messageURl?.url}${cellData?.value?.replace(
											/=+$/,
											''
										)}%3D.eml`}
									>
										<Button type="bordered">Download Mail</Button>
									</Link>
								</div>
							</div>
						}
						placement="right"
						onClose={closeDrawer}
						open={drawerMail}
						size={'large'}
					>
						{drawerMailLoading && <LoadingOutlined />}
						{drawerMailData && (
							<>
								<div className={styles['mail-details-wrapper']}>
									<div className={styles['mail-user-info']}>
										<Avatar
											className={styles['avatar']}
											style={{
												backgroundColor:
													avatarBgColor[drawerMailData?.fromName?.charAt(0)] ||
													avatarBgColor.default,
											}}
										>
											{drawerMailData?.fromName?.charAt(0)?.toUpperCase()}
										</Avatar>
										<div className={styles['users-info']}>
											<p className={styles['sender-name']}>
												{drawerMailData?.fromName}
											</p>
											{drawerMailData?.toRecipients && (
												<p className={styles['to']}>
													<span className={styles['title']}>To:</span>{' '}
													{drawerMailData?.toRecipients}
												</p>
											)}
											{drawerMailData?.ccRecipients && (
												<p className={styles['cc']}>
													<span className={styles['title']}>Cc:</span>{' '}
													{drawerMailData?.ccRecipients}
												</p>
											)}
											{drawerMailData?.bccRecipients && (
												<p className={styles['bcc']}>
													<span className={styles['title']}>Bcc:</span>{' '}
													{drawerMailData?.bccRecipients}
												</p>
											)}
										</div>
									</div>

									<p className={styles['date-info']}>
										{moment(drawerMailData?.receivedDateTime).format(
											'MMM-DD-YYYY hh:mm A'
										)}
									</p>
								</div>
								<div
									dangerouslySetInnerHTML={{ __html: drawerMailData?.html }}
								/>
							</>
						)}
					</Drawer>
				)}
			</div>
		</div>
	);
};

export default SupplierPrices;
