import { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Collapse, Divider, Form, notification, Row } from 'antd';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
	CaretRightOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import { AgGridReact } from 'ag-grid-react';

import Input from '../../../components/input';
import EdenContext from '../../../context/edenContext';
import Drawer from '../../../components/drawer';
import Button from '../../../components/button';
import ToolTip from '../../../components/tooltip';

import styles from './mailConfig.module.scss';

const MailConfig = ({ drawer, setDrawer, selectedRows, gridApi }) => {
	const { userType, userMail } = useContext(EdenContext);
	const [form] = Form.useForm();
	const targetPrice = Form.useWatch('TARGET_PRICE', form);

	const [submitMailConfig, setSubmitMailConfig] = useState(false);
	const [mailConfigDataLoading, setMailConfigDataLoading] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [selectedRowsMailing, setSelectedRowsMailing] = useState([]);
	const [rowData, setRowData] = useState([]);
	const [columnDefs] = useState([
		{
			field: 'supplier',
			headerName: 'Supplier',
			checkboxSelection: true,
			headerCheckboxSelection: true,
		},
		{
			field: 'data_source',
			headerName: 'CRM?',
			cellRenderer: (p) => <>{p.value === 'crm' ? 'Yes' : 'No'}</>,
		},
		{ field: 'email', headerName: 'Email' },
		{ field: 'name', headerName: 'Name' },
		{ field: 'last_email_date', headerName: 'Last Email on' },
		{ field: 'last_email_subject', headerName: 'Subject' },
	]);

	const defaultColDef = useMemo(
		() => ({
			resizable: true,
			flex: 2,
			minWidth: 100,
			suppressHeaderMenuButton: true,
		}),
		[]
	);

	// 2 is for new contact
	const onGridReady = (p) => {
		p.api?.forEachNode((node) => {
			if (
				node.data?.eden_nc_is_selected === '1' ||
				node.data?.eden_nc_is_selected === 2
			) {
				node.setSelected(true);
			}
		});
	};

	const onRowSelected = (event) => {
		const data = event.node.data;
		const formdata = new FormData();

		formdata.append('is_selected', event.node.isSelected() === true ? 1 : 0);
		formdata.append('user_email', userMail);
		formdata.append('supplier_name', data?.supplier);
		formdata.append('brand_name', data?.mfr);
		formdata.append('crm_name', data?.crm_account_name);
		formdata.append('contact_email', data?.email);
		formdata.append('id', data?.eden_nc_selection_id);

		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=updatencselections`,
				formdata
			)
			.then((response) => {
				// console.log(response);
			});
	};

	const onSelectionChanged = (event) => {
		setSelectedRowsMailing(event.api.getSelectedRows());
	};

	const getMailConfigData = () => {
		setMailConfigDataLoading(true);
		const formFields = { USER_EMAIL: userMail };

		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=getmailconfig`,
				JSON.stringify(formFields)
			)
			.then((response) => {
				form.setFieldsValue({
					SIGNATURE: response.data?.SIGNATURE,
				});
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setMailConfigDataLoading(false);
			});
	};

	useEffect(() => {
		selectedRows?.forEach((item) => {
			item?.supplier_email?.forEach((emailObj) => {
				emailObj.crm_account_name = item?.crm_account_name;
				emailObj.supplier = item?.supplier;
				emailObj.mfr = item?.mfr;
				emailObj.part_number = item?.part_number;
			});
		});

		const allSupplierEmails = selectedRows?.reduce((result, item) => {
			result?.push(...item?.supplier_email);
			return result;
		}, []);

		setRowData(allSupplierEmails);

		getMailConfigData();
		const selectedTemplate = getRandomTemplate();
		form.setFieldsValue({
			SUBJECT: selectedTemplate.subject,
			BODY: selectedTemplate.body,
		});
		//eslint-disable-next-line
	}, []);

	const emailTemplates = [
		{
			subject: 'Quote Request for [BRAND]',
			body: `Dear Sir/ Ma'am, <br />Good day!<br /><br/>Could you please provide your best price, stock availability, and D/C for below requirement(s):<br /><br />[TABLE]<br /><br />I am looking for [QTY] pieces. My Target Price is $[TP].<br /><br />If you can’t meet the stock, please quote the lead time quote as well.<br /><br />[SIGNATURE]`,
		},
		{
			subject: 'RFQ STOCK NEEDED',
			body: `Dear Sir/ Ma'am,<br /><br />Good day! Pls. quote for the below part if you can support:<br /><br />[TABLE]<br /><br />Our requirement is for [QTY] pieces. We are aiming for a target price of $[TP].<br />Kindly advise on SPQ, Packaging, and Date code.<br /><br />If you can’t meet the stock, please quote the lead time quote as well.<br /><br />[SIGNATURE]`,
		},
		{
			subject: 'Pls Quote for [BRAND]',
			body: `Dear Sir/ Ma'am,<br /><br />Please send your quote for the below parts along with the D/C, packaging, SPQ, and stock:<br /><br />[TABLE]<br /><br />I am looking for [QTY] pieces. My Target Price is $[TP].<br /><br />If you can’t meet the stock, please quote the lead time quote as well.<br /><br />[SIGNATURE]`,
		},
		{
			subject: 'RFQ for [BRAND]',
			body: `Dear Sir/ Ma'am,<br /><br />Can you please quote for the below part:<br /><br />[TABLE]<br /><br />Also, please advise on D/C, packaging, SPQ, and stock:<br />I need [QTY] pieces. My Target Price is $[TP].<br /><br />If you can’t meet the stock, please quote the lead time quote as well.<br /><br />[SIGNATURE]`,
		},
		{
			subject: 'Please Offer your Quotes for [BRAND]',
			body: `Dear Sir/ Ma'am,<br /><br />Please quote for the below part along with D/C, packaging, SPQ, and stock:<br /><br />[TABLE]<br /><br />I require [QTY] pieces. My Target Price is $[TP].<br /><br />If you can’t meet the stock, please quote the lead time quote as well.<br /><br />[SIGNATURE]`,
		},
		{
			subject: 'Request for a Quote for [BRAND]',
			body: `Dear Sir/ Ma'am,<br /><br />Please quote your best prices, D/C, packaging, SPQ, and stock availability for:<br /><br />[TABLE]<br /><br />We need [QTY] pieces. Our target price is $[TP].<br /><br />If you can’t meet the stock, please quote the lead time quote as well.<br /><br />[SIGNATURE]`,
		},
	];

	const getRandomTemplate = () => {
		const randomIndex = Math.floor(Math.random() * emailTemplates.length);
		return emailTemplates[randomIndex];
	};

	const loadEmailTemplate = () => {
		const selectedTemplate = getRandomTemplate();
		form.setFieldsValue({
			SUBJECT: selectedTemplate.subject,
			BODY: selectedTemplate.body,
		});
	};

	const submitMailandProcess = () => {
		form.submit();
	};

	const closeDrawer = () => {
		setDrawer(false);
		form.resetFields();
	};

	const createTable = (MPN, BRAND, QTY, targetPrice) => {
		let tableHeaders = '';
		let tableData = '<tr>';
		if (MPN !== '') {
			tableHeaders +=
				"<th style='background-color: #333; color: white; padding: 10px; text-align: left;'>MPN</th>";
			tableData +=
				"<td style='border: 1px solid #333; padding: 10px; background-color: #f9f9f9; color: #333;'>" +
				MPN +
				'</td>';
		}
		if (BRAND !== '') {
			tableHeaders +=
				"<th style='background-color: #333; color: white; padding: 10px; text-align: left;'>BRAND</th>";
			tableData +=
				"<td style='border: 1px solid #333; padding: 10px; background-color: #f9f9f9; color: #333;'>" +
				BRAND +
				'</td>';
		}
		if (QTY !== '') {
			tableHeaders +=
				"<th style='background-color: #333; color: white; padding: 10px; text-align: left;'>QTY</th>";
			tableData +=
				"<td style='border: 1px solid #333; padding: 10px; background-color: #f9f9f9; color: #333;'>" +
				QTY +
				'</td>';
		}
		if (targetPrice !== '') {
			tableHeaders +=
				"<th style='background-color: #333; color: white; padding: 10px; text-align: left;'>Target Price</th>";
			tableData +=
				"<td style='border: 1px solid #333; padding: 10px; background-color: #f9f9f9; color: #333;'>" +
				targetPrice +
				'</td>';
		}
		tableData += '</tr>';
		let table =
			"<table style='border-collapse: collapse; margin-top:15px;'>" +
			tableHeaders +
			tableData +
			'</table>';
		return table;
	};

	const onFinishMailConfig = async (values) => {
		setSubmitMailConfig(true);
		setDisabled(true);
		const formFields = {
			SIGNATURE: values?.SIGNATURE?.replace(/<p><br><\/p>/g, '')?.replace(
				/<p(?![^>]*style=["'][^"']*margin[^>]*)([^>]*)>/g,
				'<p$1 style="margin: 0;">'
			),
			USER_EMAIL: userMail,
		};
		axios.post(
			'https://internal.supremecomponents.com/api/externalgateway.php?route=savemailconfig',
			JSON.stringify(formFields)
		);

		try {
			const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
			const requests = selectedRowsMailing.map((el, index) => {
				return delay(index * 250).then(() => {
					const formData = new FormData();

					let newBody = values?.BODY;
					let subject = values?.SUBJECT;

					if (
						targetPrice <= 0 ||
						targetPrice === undefined ||
						targetPrice === 'undefined'
					) {
						newBody = values?.BODY?.replace('My Target Price is $[TP].', '');
						newBody = newBody?.replace('Our target price is $[TP].', '');
						newBody = newBody?.replace(
							'We are aiming for a target price of $[TP].',
							''
						);
					}
					subject = subject?.replace('[BRAND]', el?.mfr);
					newBody = newBody?.replace('[QTY]', values?.REQUIRED_QTY);
					newBody = newBody?.replace('[TP]', values?.TARGET_PRICE);
					newBody = newBody?.replace(
						'[SIGNATURE]',
						values?.SIGNATURE?.replace(/<p><br><\/p>/g, '')?.replace(
							/<p(?![^>]*style=["'][^"']*margin[^>]*)([^>]*)>/g,
							'<p$1 style="margin: 0;">'
						)
					);

					let tableHTML = createTable(
						el?.part_number,
						el?.mfr,
						values?.REQUIRED_QTY,
						values?.TARGET_PRICE ? values?.TARGET_PRICE : ''
					);

					newBody = newBody?.replace('[TABLE]', tableHTML);
					newBody = newBody?.replace(/<p><br><\/p>/g, '');

					if (values.TO === '[SUPPLIER_EMAIL]') {
						formData.append('receiverEmail', el?.email);
					} else {
						formData.append('receiverEmail', values?.TO);
					}
					formData.append('cc', values?.CC || '');
					formData.append('bcc', values?.BCC || '');
					formData.append('senderEmail', userMail);
					formData.append('subject', subject);
					formData.append('body', newBody);
					formData.append('HTTP_API_KEY', '218b84c69678e95cb98679051a6e5118');
					formData.append('timestamp', Date.now());

					return axios.post(
						'https://internal.supremecomponents.com/api/sendemail.php',
						formData
					);
				});
			});

			const results = await Promise.all(requests);

			const successCount = results.filter(
				(result) => result.data.status === 'success'
			).length;

			notification.open({
				message: 'Emails are Being Dispatched',
				description: `Eden is dispatching emails to ${successCount} contacts of the suppliers you selected. You can confirm the transmission by reviewing the emails in your Outlook 'Sent Items' folder.`,
				duration: 10,
				type: 'success',
				style: {
					backgroundColor: '#f6ffed',
					border: '1px solid #b7eb8f',
					borderRadius: '2px',
				},
			});
		} catch (err) {
			console.error('Error sending emails:', err);
		} finally {
			setSubmitMailConfig(false);
			setDrawer(false);
			gridApi.deselectAll();
			form.resetFields();
			setDisabled(false);
		}
	};

	const modules = {
		toolbar: false,
		clipboard: {
			matchVisual: true,
		},
	};

	const { Panel } = Collapse;

	const initialValues = { USER_EMAIL: userMail, TO: '[SUPPLIER_EMAIL]' };

	return (
		<>
			<Drawer
				title={
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<p>Mailing Configuration</p>
						<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
							{submitMailConfig && (
								<div>
									<LoadingOutlined /> Sending Mail...
								</div>
							)}
							<Button disabled={submitMailConfig} onClick={loadEmailTemplate}>
								Load New Template
							</Button>
						</div>
					</div>
				}
				placement="right"
				onClose={closeDrawer}
				open={drawer}
				width={600}
				footer={
					<div className={styles['drawer-footer']}>
						<Button
							disabled={
								disabled || submitMailConfig || selectedRowsMailing?.length <= 0
							}
							onClick={submitMailandProcess}
						>
							Send Mass Mail
						</Button>
						<Button
							disabled={submitMailConfig}
							type="bordered"
							onClick={closeDrawer}
						>
							Cancel
						</Button>
					</div>
				}
			>
				<>
					<Collapse
						accordion
						defaultActiveKey={['1']}
						ghost
						className={styles['collapse-wrapper']}
						expandIconPosition="end"
						expandIcon={({ isActive }) => (
							<CaretRightOutlined rotate={isActive ? 90 : 0} />
						)}
					>
						<Panel
							header={
								<>
									<div className="font-weight-600 font-18">Contact Center</div>
									<div className="font-13 text-lighter pb-2">
										Please select the contacts you would like send the mass mail
										to.{' '}
										<ToolTip title="Your contact selection preference will be saved in the system.">
											<InfoCircleOutlined style={{ fontSize: '16px' }} />
										</ToolTip>
									</div>
								</>
							}
							key="1"
							className={styles['panel-wrapper']}
						>
							<div
								className={`ag-theme-custom ${styles['drawer-table-wrapper']}`}
							>
								<AgGridReact
									columnDefs={columnDefs}
									rowData={rowData}
									rowSelection={'multiple'}
									defaultColDef={defaultColDef}
									onGridReady={onGridReady}
									pagination={true}
									paginationPageSize={10}
									rowMultiSelectWithClick={true}
									suppressRowHoverHighlight={true}
									onRowSelected={onRowSelected}
									onSelectionChanged={onSelectionChanged}
									paginationPageSizeSelector={false}
								/>
							</div>
						</Panel>
					</Collapse>
					<Form
						name="mail-config"
						id="mail-config"
						onFinish={onFinishMailConfig}
						validateTrigger={['onBlur', 'onChange']}
						labelCol={{ span: 24 }}
						requiredMark={false}
						form={form}
						disabled={submitMailConfig || mailConfigDataLoading || disabled}
						initialValues={initialValues}
					>
						<Divider
							style={{
								borderBlockStart: '1px solid rgb(216, 216, 216)',
								margin: '10px 0',
							}}
						/>
						<div className="font-weight-600 font-18">Mailing Draft</div>
						<div className="font-13 text-lighter pb-2">
							This shows the recipient and the structure of the emails that are
							being sent.
						</div>
						<Row gutter={20} style={{ marginBottom: '20px' }}>
							<Col xs={24}>
								<Form.Item
									label="To"
									name="TO"
									id="to"
									rules={[
										{
											required: true,
											message: 'Please enter To!',
										},
									]}
								>
									<Input
										disabled={userType !== 'admin'}
										placeholder="Please enter To"
									/>
								</Form.Item>
							</Col>
							<Col xs={24}>
								<Form.Item label="CC" name="CC" id="cc">
									<Input placeholder="Please enter CC" />
								</Form.Item>
							</Col>
							<Col xs={24}>
								<Form.Item label="BCC" name="BCC" id="bcc">
									<Input placeholder="Please enter BCC" />
								</Form.Item>
							</Col>
							<Col xs={24}>
								<Form.Item
									label="Subject"
									name="SUBJECT"
									rules={[
										{
											required: true,
											message: 'Please enter Subject!',
										},
									]}
								>
									<Input id="subject" placeholder="Please enter Subject" />
								</Form.Item>
							</Col>
							<Col xs={12}>
								<Form.Item
									label="Required QTY"
									name="REQUIRED_QTY"
									id="required_qty"
									rules={[
										{
											required: true,
											message: 'Please enter QTY',
										},
										() => ({
											validator(_, value) {
												if (value <= 0 || value > 10000000) {
													return Promise.reject(
														new Error('Please Enter a Valid QTY')
													);
												}
												return Promise.resolve();
											},
										}),
									]}
								>
									<Input type="number" placeholder="Required QTY" />
								</Form.Item>
							</Col>
							<Col xs={12}>
								<Form.Item
									label="Target Price"
									name="TARGET_PRICE"
									id="target_price"
									rules={[
										{
											required: targetPrice < 0 ? true : false,
											message: '',
										},
										() => ({
											validator(_, value) {
												if (value < 0 || value === 0) {
													return Promise.reject(
														new Error('Please Enter a Valid Price')
													);
												}
												return Promise.resolve();
											},
										}),
									]}
								>
									<Input type="number" placeholder="Target Price (Optional)" />
								</Form.Item>
							</Col>

							<Col xs={24}>
								<Form.Item
									label="Body"
									name="BODY"
									id="body"
									style={{ marginBottom: '0' }}
									rules={[
										{
											required: true,
											message: 'Please enter Body!',
										},
									]}
								>
									<ReactQuill
										modules={modules}
										theme="snow"
										placeholder="Please enter Email Body"
									/>
								</Form.Item>
							</Col>
						</Row>
						<Divider
							style={{
								borderBlockStart: '1px solid rgb(216, 216, 216)',
								margin: '10px 0',
							}}
						/>
						<div className="font-weight-600 font-18">Your Personal Details</div>
						<div className="font-13 text-lighter pb-2">
							This shows the information that will be visible to the supplier(s)
							when they receive the email.
						</div>
						<Row gutter={10}>
							<Col xs={24}>
								<Form.Item
									label="Email"
									name="USER_EMAIL"
									rules={[
										{
											required: true,
											message: 'Please enter Email!',
										},
									]}
								>
									<Input disabled placeholder="Please enter Email" />
								</Form.Item>
							</Col>
							<Col xs={24}>
								<Form.Item
									style={{ marginBottom: '0' }}
									label="Signature"
									name="SIGNATURE"
									id="signature"
									rules={[
										{
											required: true,
											message: 'Please enter Signature!',
										},
									]}
								>
									<ReactQuill
										modules={modules}
										theme="snow"
										placeholder="Please enter Email Signature"
										id="signature"
									/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</>
			</Drawer>
		</>
	);
};

export default MailConfig;
