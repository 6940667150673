import { Drawer as AntdDrawer } from 'antd';
import { GrFormClose } from 'react-icons/gr';

import styles from './drawer.module.scss';

const Drawer = ({
	footer,
	placement,
	size,
	title,
	open,
	width,
	onClose,
	children,
	style,
	bodyStyle,
}) => {
	return (
		<div className={styles['wrapper']}>
			<AntdDrawer
				className={styles['drawer']}
				closeIcon={<GrFormClose className={styles['close-icon']} />}
				footer={footer}
				keyboard={true}
				placement={placement}
				size={size}
				title={title}
				open={open}
				width={width}
				onClose={onClose}
				style={style}
				bodyStyle={bodyStyle}
				headerStyle={{ display: 'flex', alignItems: 'center' }}
			>
				{children}
			</AntdDrawer>
		</div>
	);
};

export default Drawer;
