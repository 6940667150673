import {
	memo,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import axios from 'axios';
import { Divider, message, Radio as AntdRadio, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { AgGridReact } from 'ag-grid-react';
import {
	LoadingOutlined,
	MoneyCollectOutlined,
	QuestionCircleOutlined,
} from '@ant-design/icons';
import { IoMdShareAlt } from 'react-icons/io';
import { IoMdAddCircle } from 'react-icons/io';
import { MdMail } from 'react-icons/md';

import BreadCrumb from '../../../components/breadcrumb';
import NotificationBar from '../../../components/notificationBar';
import InnerHeader from '../../../components/innerHeader';
import SectionInfo from '../../../components/sectionInfo';
import CheckBox from '../../../components/checkBox';
import Button from '../../../components/button';
import ViewBackorderSites from './ViewBackorderSites';
import TableDataCountInput from '../../../components/tableDataCountInput';
import ToolTip from '../../../components/tooltip';
import EdenContext from '../../../context/edenContext';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import {
	filterUniqueIdsAndRecent,
	// getPrimaryBrand,
	isNotEmptyArray,
	titleCase,
} from '../../../utils';
import NoData from '../../../components/nodata';
import MPNCostBookAction from './MPNCostBookAction';
// import AddSalesRep from './TableComp/AddSalesRep';
import AddCustomer from './TableComp/AddCustomer';
import AddSupplier from './TableComp/AddSupplier/AddSupplier';
import NumericCellEditor from './TableComp/NumericCellEditor';
// import AddMpn from './TableComp/AddMpn';
// import AddBrand from './TableComp/AddBrand';
// import AddCustomerName from './TableComp/AddCustomerName';
import { RadioTwo } from '../../../components/radio';
// import AddSupplierName from './TableComp/AddSupplierName';
import FcExtPrice from './TableComp/FcExtPrice';
// import AddSS from './TableComp/AddSS';
import BulkUploadPasteModal from './BulkUploadPasteModal';
import Select from '../../../components/select';
import Popconfirm from '../../../components/popConfirm';
import Drawer from '../../../components/drawer';
import { avatarBgColor } from '../../../constant';

import styles from './mpnCostBook.module.scss';

// const SSFilterRenderer = (props) => {
// 	return (
// 		<span>
// 			{props.value === 1061
// 				? 'Mikee'
// 				: props.value === 1076
// 				? 'Cindy'
// 				: props.value === 1
// 				? 'Self'
// 				: props.value === '(Select All)'
// 				? '(Select All)'
// 				: props.value === 0
// 				? '(Blank)'
// 				: ''}
// 		</span>
// 	);
// };

const orderRecFilterRenderer = (props) => {
	return (
		<span>
			{props.value === true
				? 'Yes'
				: props.value === false
				? 'No'
				: props.value === '(Select All)'
				? '(Select All)'
				: props.value === null
				? '(Blank)'
				: ''}
		</span>
	);
};

const suppressNavigation = (params) => {
	var KEY_A = 'A';
	// var KEY_C = 'C';
	// var KEY_V = 'V';
	var KEY_LEFT = 'ArrowLeft';
	var KEY_UP = 'ArrowUp';
	var KEY_RIGHT = 'ArrowRight';
	var KEY_DOWN = 'ArrowDown';
	var event = params.event;
	var key = event.key;
	var keysToSuppress = [];

	if (event.ctrlKey || event.metaKey) {
		keysToSuppress.push(KEY_A);
		// keysToSuppress.push(KEY_V);
		// keysToSuppress.push(KEY_C);
		keysToSuppress.push(KEY_LEFT);
		keysToSuppress.push(KEY_UP);
		keysToSuppress.push(KEY_RIGHT);
		keysToSuppress.push(KEY_DOWN);
	}

	var suppress = keysToSuppress.some(function (suppressedKey) {
		return suppressedKey === key || key.toUpperCase() === suppressedKey;
	});
	return suppress;
};

const suppressUpDownNavigation = (params) => {
	var key = params.event.key;
	return key === 'ArrowUp' || key === 'ArrowDown';
};

const currencyFormatter = (params) => {
	if (params.value == null) {
		return '';
	}

	const valueWithoutDollarSign =
		typeof params.value === 'string'
			? params.value.replace('$', '')
			: params.value;

	return '$' + parseFloat(valueWithoutDollarSign).toLocaleString('en-US');
};

const currencyParser = (params) => {
	let value = params.newValue;
	if (value == null || value === '') {
		return null;
	}

	value = typeof value === 'string' ? value.replace('$', '') : value;

	let parsedValue = parseFloat(value);

	if (!isNaN(parsedValue)) {
		return parsedValue;
	}

	return String(value);
};

const MOQValueParser = (params) => {
	if (typeof params.newValue === 'string') {
		const parsedValue = parseFloat(params.newValue.replace(/,/g, ''));

		if (!isNaN(parsedValue)) {
			return parsedValue;
		}
	}

	return params.newValue;
};

function numberWithCommasFormatter(params) {
	if (params.value !== null && params.value !== undefined) {
		const stringValue = params.value.toString().replace(/,/g, '');

		return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	} else {
		return '';
	}
}

const dateFilterParams = {
	filters: [
		{
			filter: 'agDateColumnFilter',
			filterParams: {
				comparator: (a, b) => {
					return getDate(a).getTime() - getDate(b).getTime();
				},
			},
		},
	],
};

const getDate = (value) => {
	const date = moment.utc(value).tz('Asia/Singapore').format('DD/MM/YYYY');
	var dateParts = date.split('/');
	return new Date(
		Number(dateParts[2]),
		Number(dateParts[1]) - 1,
		Number(dateParts[0])
	);
};

const MPNCostBook = () => {
	const {
		userType,
		userMail,
		userInfo,
		mpnCostBookList,
		mpnCostBookLoading,
		mpnCostBookError,
		costBookType,
		setCostBookType,
		setMpnCostBookList,
		sourcingMpnQueueCostBook,
		messageURl,
		mpnCostBookRowData,
		setMpnCostBookRowData,
		setMpnDateRange,
		mpnDateRange,
		srData,
		ssData,
		crmAccounts,
		crmSuppliers,
		mpnBrand,
	} = useContext(EdenContext);

	const gridRef = useRef();

	const [gridApi, setGridApi] = useState();
	const [backorderSitesModal, setBackorderSitesModal] = useState(false);
	const [costView, setCostView] = useState('View Full Cost Book');
	const [isAutoReceiveMail, setIsAutoReceiveMail] = useState();
	const [selectedRowValue, setSelectedRowValue] = useState([]);
	const [showBorders, setShowBorders] = useState(false);
	const [deleteQueuePopup, setDeleteQueuePopup] = useState(false);
	const [lowestPrice, setLowestPrice] = useState(false);
	const [lowestPriceData, setLowestPriceData] = useState(null);
	const [drawerMail, setDrawerMail] = useState(false);
	const [drawerMailData, setDrawerMailData] = useState(null);
	const [drawerMailLoading, setDrawerMailLoading] = useState(false);
	const [cellData, setCellData] = useState(null);

	const closeDrawer = () => {
		setDrawerMail(!drawerMail);
		setDrawerMailData(null);
		setCellData(null);
	};

	const openDrawer = (cid) => {
		setDrawerMail(true);
		setDrawerMailLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_mail_html&cid=${cid}`
			)
			.then((response) => {
				setDrawerMailData(response.data);
			})
			.finally(() => {
				setDrawerMailLoading(false);
			});
	};

	const ssDetails = [
		...ssData,
		{
			salesRepCrmId: 1,
			salesRepEmail: 'Self',
			salesRepName: 'Self',
		},
	];

	const filterCRMAccount =
		Array.isArray(crmAccounts) &&
		crmAccounts?.filter((el) => el?.INACTIVE !== 'Yes');

	// check buy or bid
	const checkBuyorBid = (e) => {
		setMpnCostBookList([]);
		setMpnCostBookRowData([]);
		setCostBookType(e.target.value);
		setLowestPrice(false);
	};

	useEffect(() => {
		sourcingMpnQueueCostBook();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [costBookType, mpnDateRange]);

	// Columns
	const mpnCostBookColumns = [
		{ field: 'currentTime', sort: 'desc', hide: true },
		{
			headerName: '',
			children: [
				{
					field: 'action_center',
					headerName: 'Action Center',
					maxWidth: 150,
					floatingFilter: false,
					cellRenderer: memo(MPNCostBookAction),
					pinned: 'left',
					lockPinned: true,
					checkboxSelection: true,
					headerCheckboxSelection: true,
					headerCheckboxSelectionFilteredOnly: true,
				},
				{
					field: 'id',
					headerName: 'ID',
					cellStyle: {
						paddingLeft: '20px',
						borderRight: showBorders && '1px solid #EAECEE',
					},
					minWidth: 100,
					cellRenderer: (p) => (
						<span className={styles['font-weight']}>{p.value}</span>
					),
				},
				{
					field: 'smartId',
					headerName: 'Queue ID',
					sort: 'asc',
					comparator: (valueA, valueB) => {
						const getTypeAndNumbers = (str) => {
							const match = str.match(/(?:Bid|Buy)\.(\d+)\.(\d+)\.(\d+)/);
							if (match) {
								const type = match[0].startsWith('D.Buy') ? 'Buy' : 'Bid';
								const numbers = match.slice(1).map(Number);
								return { type, numbers };
							}
							return { type: '', numbers: [0, 0, 0] }; // default to sorting by 0 if format doesn't match
						};

						const {
							type: typeA,
							numbers: [num1A, num2A, num3A],
						} = getTypeAndNumbers(valueA);
						const {
							type: typeB,
							numbers: [num1B, num2B, num3B],
						} = getTypeAndNumbers(valueB);

						// Prioritize "Buy" over "Bid"
						if (typeA === 'Buy' && typeB === 'Bid') return -1;
						if (typeA === 'Bid' && typeB === 'Buy') return 1;

						// Compare numbers after "Bid" or "Buy" and subsequent numbers for sorting in descending order
						if (num1A !== num1B) return num1B - num1A;
						if (num2A !== num2B) return num2B - num2A;
						return num3B - num3A;
					},
					cellStyle: {
						paddingLeft: '20px',
						borderRight: showBorders && '1px solid #EAECEE',
					},
					minWidth: 140,
					cellRenderer: (p) => (
						<span className={styles['font-weight']}>{p.value}</span>
					),
				},
				{
					field: 'lastModifiedAgo',
					headerName: 'Last Modified',
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				{
					field: 'lastActivity',
					headerName: 'Last Activity',
					cellRenderer: (p) => (
						<span className={styles['font-weight']}>
							{p.value
								? moment
										.utc(p.value)
										.tz('Asia/Singapore')
										.format('MMM-DD-YYYY hh:mm A')
								: ''}
						</span>
					),
					filterParams: dateFilterParams,
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					minWidth: 190,
					suppressHeaderMenuButton: true,
					floatingFilterComponentParams: {
						suppressFilterButton: true,
					},
				},
				{
					field: 'sr',
					headerName: 'Sales Rep',
					cellEditor: 'agRichSelectCellEditor',
					editable: true,
					cellRenderer: (p) => (p.value ? titleCase(p.value) : ''),
					cellEditorParams: {
						values: srData?.map((el) => el?.salesRepName),
						formatValue: (value) => titleCase(value),
						searchType: 'match',
						allowTyping: true,
						filterList: true,
						highlightMatch: true,
						valueListMaxHeight: 220,
						valueListMaxWidth: 160,
					},
					cellClassRules: {
						'cell-error-bg': (p) => !p.value,
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				{
					field: 'ss',
					headerName: 'SS',
					cellEditor: 'agRichSelectCellEditor',
					cellRenderer: (p) => (p.value ? titleCase(p.value) : ''),
					editable: (p) => (p?.data?.srId ? true : false),
					cellEditorParams: {
						values: ssDetails?.map((el) => el?.salesRepName),
						formatValue: (value) => titleCase(value),
						searchType: 'match',
						allowTyping: true,
						filterList: true,
						highlightMatch: true,
						valueListMaxHeight: 220,
						valueListMaxWidth: 160,
					},
					cellClassRules: {
						'cell-error-bg': (p) => !p.value,
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				{ field: 'ssId', hide: true },
			],
		},
		{
			headerName: 'Customer Details',
			headerClass: styles['cd-header'],
			children: [
				{
					field: 'cdMail',
					headerName: 'Mail',
					headerClass: styles['cd-header-child'],
					cellStyle: {
						padding: '4px 0 0 25px',
						borderRight: showBorders && '1px solid #EAECEE',
					},
					maxWidth: 80,
					floatingFilter: false,
					cellClassRules: {
						'cell-error-bg': (p) => !p.value,
					},
					cellRenderer: (p) => {
						const trimmedString = p?.value?.replace(/=+$/, '');
						return (
							<>
								{p.value ? (
									<ToolTip title="Preview mail">
										<MdMail
											onClick={(e) => {
												if (e.ctrlKey && e.button === 0) {
													window.open(
														`${messageURl?.url}${trimmedString}%3D.eml`,
														'_blank'
													);
												} else {
													openDrawer(p.value);
													setCellData(p);
												}
											}}
											style={{ fontSize: '16px', cursor: 'pointer' }}
										/>
									</ToolTip>
								) : (
									''
								)}
							</>
						);
					},
				},
				{
					field: 'cdInquiryDateTime',
					headerName: 'Inquiry Date',
					headerClass: styles['cd-header-child'],
					cellRenderer: (p) => {
						return (
							<>
								{p.value
									? moment
											.utc(p.value)
											.tz('Asia/Singapore')
											.format('MMM-DD-YYYY hh:mm A')
									: ''}
							</>
						);
					},
					minWidth: 170,
					cellClassRules: {
						'cell-error-bg': (p) => !p.value,
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					filterParams: dateFilterParams,
					suppressHeaderMenuButton: true,
					floatingFilterComponentParams: {
						suppressFilterButton: true,
					},
				},
				{
					field: 'cdCustomerName',
					headerName: 'Customer',
					headerClass: styles['cd-header-child'],
					cellEditor: 'agRichSelectCellEditor',
					editable: (p) => (p?.data?.srId ? true : false),
					cellEditorParams: {
						values:
							Array.isArray(filterCRMAccount) &&
							filterCRMAccount?.map((el) => el?.ACCOUNT_NAME),
						searchType: 'match',
						allowTyping: true,
						filterList: true,
						highlightMatch: true,
						valueListMaxHeight: 220,
						valueListMaxWidth: 360,
					},
					cellClassRules: {
						'cell-error-bg': (p) => !p.value,
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				{
					field: 'cdCustomerSubject',
					headerClass: styles['cd-header-child'],
					headerName: 'Subject',
					editable: (p) => (p?.data?.srId ? true : false),
					cellRenderer: memo(AddCustomer),
					cellClassRules: {
						'cell-error-bg': (p) => !p.value,
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				{
					field: 'cdContactEmail',
					headerClass: styles['cd-header-child'],
					headerName: 'Customer Email',
					editable: (p) => (p?.data?.srId ? true : false),
					cellRenderer: (p) => <>{p?.value ? p?.value : ''}</>,
					cellClassRules: {
						'cell-error-bg': (p) => !p.value,
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				{
					field: 'cdContactName',
					headerClass: styles['cd-header-child'],
					headerName: 'Contact',
					cellRenderer: (p) => <>{p?.value ? p?.value : ''}</>,
					editable: (p) =>
						p.data.cdCustomerName && p.data.cdContactEmail ? true : false,
					cellClassRules: {
						'cell-error-bg': (p) => !p.value,
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
			],
		},
		{
			headerName: 'MPN Requested',
			headerClass: styles['mr-header'],
			children: [
				{
					field: 'reqCPN',
					headerName: 'CPN',
					headerClass: styles['mr-header-child'],
					cellRenderer: (p) => <>{p?.value ? p?.value : ''}</>,
					editable: (p) => (p?.data?.srId ? true : false),
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				{
					field: 'reqMPN',
					headerName: 'MPN Req.',
					cellDataType: 'string',
					headerClass: styles['mr-header-child'],
					minWidth: 160,
					editable: (p) => (p?.data?.srId ? true : false),
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: {
						values: mpnBrand?.MPN?.map((el) => el?.CLEAN_MPN),
						searchType: 'match',
						allowTyping: true,
						filterList: true,
						highlightMatch: true,
						valueListMaxHeight: 220,
						valueListMaxWidth: 220,
					},
					cellClassRules: {
						'cell-error-bg': (p) => !p.value,
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				{
					field: 'reqBrand',
					headerName: 'Brand Req.',
					headerClass: styles['mr-header-child'],
					cellDataType: 'string',
					minWidth: 160,
					editable: (p) => (p?.data?.srId ? true : false),
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: {
						values: mpnBrand?.BRAND?.map((el) => el?.CLEAN_BRAND),
						searchType: 'match',
						allowTyping: true,
						filterList: true,
						highlightMatch: true,
						valueListMaxHeight: 220,
						valueListMaxWidth: 220,
					},
					cellClassRules: {
						'cell-error-bg': (p) => !p.value,
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				{
					field: 'reqQtyReq',
					headerName: 'QTY Req.',
					headerClass: styles['mr-header-child'],
					editable: (p) => (p?.data?.srId ? true : false),
					cellDataType: 'number',
					valueParser: MOQValueParser,
					valueFormatter: numberWithCommasFormatter,
					cellClassRules: {
						'cell-error-bg': (p) => !p.value,
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				// {
				// 	field: 'noofQuoteFound',
				// 	headerName: '# Quotes Found',
				// 	headerClass: styles['mr-header-child'],
				// },
				{
					field: 'reqExtPrice',
					headerName: 'FC Ext $',
					headerClass: styles['mr-header-child'],
					editable: (p) => (p?.data?.srId ? true : false),
					cellRenderer: memo(FcExtPrice),
					valueFormatter: numberWithCommasFormatter,
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
			],
		},
		{
			headerName: 'MPN Source Details',
			headerClass: styles['msd-header'],
			children: [
				{
					field: 'foundMPN',
					headerName: 'MPN Found',
					minWidth: 160,
					headerClass: styles['msd-header-child'],
					editable: (p) => (p?.data?.srId ? true : false),
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: {
						values: (p) => [
							p.data?.reqMPN,
							...mpnBrand?.MPN?.map((el) => el?.CLEAN_MPN),
						],
						searchType: 'match',
						allowTyping: true,
						filterList: true,
						highlightMatch: true,
						valueListMaxHeight: 220,
						valueListMaxWidth: 300,
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellClassRules: {
						'cell-error-bg': (p) =>
							!p.value &&
							(p?.data?.foundBrand ||
								p?.data?.foundMOQ ||
								p?.data?.foundSPQ ||
								p?.data?.foundUsdCost ||
								p?.data?.foundDC ||
								p?.data?.foundPackaging ||
								p?.data?.foundLT ||
								p?.data?.sdSource ||
								p?.data?.sdMail ||
								p?.data?.sdSourceDate ||
								p?.data?.sdSupplier ||
								p?.data?.sdSubject ||
								p?.data?.sdSupplierEmail ||
								p?.data?.sdSupplierName),
					},
				},
				{
					field: 'foundBrand',
					headerName: 'Brand Found',
					minWidth: 160,
					headerClass: styles['msd-header-child'],
					editable: (p) => (p?.data?.srId ? true : false),
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: {
						values: (p) => [
							p.data?.reqBrand,
							...mpnBrand?.BRAND?.map((el) => el?.CLEAN_BRAND),
						],
						searchType: 'match',
						allowTyping: true,
						filterList: true,
						highlightMatch: true,
						valueListMaxHeight: 220,
						valueListMaxWidth: 220,
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellClassRules: {
						'cell-error-bg': (p) =>
							!p?.value &&
							(p?.data?.foundMPN ||
								p?.data?.foundMOQ ||
								p?.data?.foundSPQ ||
								p?.data?.foundUsdCost ||
								p?.data?.foundDC ||
								p?.data?.foundPackaging ||
								p?.data?.foundLT ||
								p?.data?.sdSource ||
								p?.data?.sdMail ||
								p?.data?.sdSourceDate ||
								p?.data?.sdSupplier ||
								p?.data?.sdSubject ||
								p?.data?.sdSupplierEmail ||
								p?.data?.sdSupplierName),
					},
				},
				{
					field: 'foundMOQ',
					headerName: 'MOQ',
					headerClass: styles['msd-header-child'],
					editable: (p) => (p?.data?.srId ? true : false),
					cellDataType: 'number',
					valueParser: MOQValueParser,
					valueFormatter: numberWithCommasFormatter,
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellClassRules: {
						'cell-error-bg': (p) =>
							!p?.value &&
							(p?.data?.foundMPN ||
								p?.data?.foundBrand ||
								p?.data?.foundSPQ ||
								p?.data?.foundUsdCost ||
								p?.data?.foundDC ||
								p?.data?.foundPackaging ||
								p?.data?.foundLT ||
								p?.data?.sdSource ||
								p?.data?.sdMail ||
								p?.data?.sdSourceDate ||
								p?.data?.sdSupplier ||
								p?.data?.sdSubject ||
								p?.data?.sdSupplierEmail ||
								p?.data?.sdSupplierName),
					},
				},
				{
					field: 'foundSPQ',
					headerName: 'SPQ',
					hide: true,
					headerClass: styles['msd-header-child'],
					cellRenderer: (p) => (
						<>{p?.value ? p?.value?.toLocaleString('en-US') : ''}</>
					),
					editable: (p) => (p?.data?.srId ? true : false),
					cellEditorSelector: (params) => {
						return {
							component: NumericCellEditor,
						};
					},
					valueParser: (params) => Number(params.newValue),
					valueFormatter: numberWithCommasFormatter,
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				{
					field: 'foundUsdCost',
					headerName: 'USD Cost',
					sortIndex: 2,
					headerClass: styles['msd-header-child'],
					editable: (p) => (p?.data?.srId ? true : false),
					valueParser: currencyParser,
					valueFormatter: currencyFormatter,
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellClassRules: {
						'cell-error-bg': (p) =>
							!p?.value &&
							(p?.data?.foundMPN ||
								p?.data?.foundBrand ||
								p?.data?.foundMOQ ||
								p?.data?.foundDC ||
								p?.data?.foundPackaging ||
								p?.data?.foundLT ||
								p?.data?.sdSource ||
								p?.data?.sdMail ||
								p?.data?.sdSourceDate ||
								p?.data?.sdSupplier ||
								p?.data?.sdSubject ||
								p?.data?.sdSupplierEmail ||
								p?.data?.sdSupplierName),
					},
				},
				{
					field: 'foundExtPrice',
					headerName: 'Ext. Price',
					headerClass: styles['msd-header-child'],
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellRenderer: (p) => {
						const value = p?.data?.foundUsdCost * p?.data?.foundMOQ;
						return (
							<>
								{p?.data?.foundUsdCost || p?.data?.foundMOQ
									? '$' + value?.toLocaleString('en-US')
									: ''}
							</>
						);
					},
				},
				{
					field: 'foundDC',
					headerName: 'DC',
					headerClass: styles['msd-header-child'],
					cellRenderer: (p) => <>{p?.value ? p?.value : ''}</>,
					editable: (p) => (p?.data?.srId ? true : false),
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				{
					field: 'foundPackaging',
					headerName: 'Packaging',
					headerClass: styles['msd-header-child'],
					cellRenderer: (p) => <>{p?.value ? p?.value : ''}</>,
					editable: (p) => (p?.data?.srId ? true : false),
					cellEditor: 'agRichSelectCellEditor',
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellEditorParams: {
						searchType: 'match',
						allowTyping: true,
						filterList: true,
						highlightMatch: true,
						valueListMaxHeight: 220,
						valueListMaxWidth: 220,
						values: [
							'Anti-static Bag (ESD Bag)',
							'Ball Grid Array (BGA)',
							'Blister Pack',
							'Bulk',
							'Ceramic Packages',
							'Chip Scale Package (CSP)',
							'Chip-on-Board (COB)',
							'Cylindrical Packages',
							'Cut Tape',
							'DIP (Dual In-line Package) Tray',
							'Land Grid Array (LGA)',
							'Lead Frame Packages',
							'	Metal Can',
							'Plastic Dual In-line Package (PDIP)',
							'Quad Flat Package (QFP)',
							'Tape and Reel (T&R)',
							'Tray',
							'Tube',
							'Wafer-Level Packaging',
							'Custom Packaging',
						],
					},
				},
				{
					field: 'foundLT',
					headerName: 'LT (w/units)',
					headerClass: styles['msd-header-child'],
					cellRenderer: (p) => <>{p?.value ? p?.value : ''}</>,
					editable: (p) => (p?.data?.srId ? true : false),
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellClassRules: {
						'cell-error-bg': (p) =>
							!p?.value &&
							(p?.data?.foundMPN ||
								p?.data?.foundBrand ||
								p?.data?.foundMOQ ||
								p?.data?.foundUsdCost ||
								p?.data?.foundDC ||
								p?.data?.foundPackaging ||
								p?.data?.sdSource ||
								p?.data?.sdMail ||
								p?.data?.sdSourceDate ||
								p?.data?.sdSupplier ||
								p?.data?.sdSubject ||
								p?.data?.sdSupplierEmail ||
								p?.data?.sdSupplierName),
					},
				},
			],
		},
		{
			headerName: 'Supplier Details',
			headerClass: styles['sd-header'],
			children: [
				{
					field: 'sdSource',
					headerName: 'CF/MM/Online',
					headerClass: styles['sd-header-child'],
					cellRenderer: (p) => <>{p?.value ? p?.value : ''}</>,
					editable: (p) => (p?.data?.srId ? true : false),
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: {
						searchType: 'match',
						allowTyping: true,
						filterList: true,
						highlightMatch: true,
						valueListMaxHeight: 220,
						valueListMaxWidth: 220,
						values: [
							'ChipFinder',
							'Mass Email',
							'Online',
							'Messaging',
							'Price Book',
						],
					},
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellClassRules: {
						'cell-error-bg': (p) =>
							!p?.value &&
							(p?.data?.foundMPN ||
								p?.data?.foundBrand ||
								p?.data?.foundMOQ ||
								p?.data?.foundUsdCost ||
								p?.data?.foundDC ||
								p?.data?.foundPackaging ||
								p?.data?.foundLT ||
								p?.data?.sdMail ||
								p?.data?.sdSourceDate ||
								p?.data?.sdSupplier ||
								p?.data?.sdSubject ||
								p?.data?.sdSupplierEmail ||
								p?.data?.sdSupplierName),
					},
				},
				{
					field: 'sdMail',
					headerName: 'Mail',
					headerClass: styles['sd-header-child'],
					cellStyle: {
						padding: '4px 0 0 25px',
						borderRight: showBorders && '1px solid #EAECEE',
					},
					maxWidth: 80,
					floatingFilter: false,
					cellRenderer: (p) => {
						const trimmedString = p?.value?.replace(/=+$/, '');
						return (
							<>
								{p.value ? (
									<ToolTip title="Preview mail">
										<MdMail
											onClick={(e) => {
												if (e.ctrlKey && e.button === 0) {
													window.open(
														`${messageURl?.url}${trimmedString}%3D.eml`,
														'_blank'
													);
												} else {
													openDrawer(p.value);
													setCellData(p);
												}
											}}
											style={{ fontSize: '16px', cursor: 'pointer' }}
										/>
									</ToolTip>
								) : (
									''
								)}
							</>
						);
					},
				},
				{
					field: 'sdSourceDate',
					headerName: 'Source Date',
					headerClass: styles['sd-header-child'],
					cellRenderer: (p) => {
						return (
							<>
								{p.value
									? moment
											.utc(p.value)
											.tz('Asia/Singapore')
											.format('MMM-DD-YYYY hh:mm A')
									: ''}
							</>
						);
					},
					filterParams: dateFilterParams,
					suppressHeaderMenuButton: true,
					floatingFilterComponentParams: {
						suppressFilterButton: true,
					},
					minWidth: 170,
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellClassRules: {
						'cell-error-bg': (p) =>
							!p?.value &&
							(p?.data?.foundMPN ||
								p?.data?.foundBrand ||
								p?.data?.foundMOQ ||
								p?.data?.foundUsdCost ||
								p?.data?.foundDC ||
								p?.data?.foundPackaging ||
								p?.data?.foundLT ||
								p?.data?.sdSource ||
								p?.data?.sdMail ||
								p?.data?.sdSupplier ||
								p?.data?.sdSubject ||
								p?.data?.sdSupplierEmail ||
								p?.data?.sdSupplierName),
					},
				},
				{
					field: 'sdSubject',
					headerName: 'Subject',
					headerClass: styles['sd-header-child'],
					editable: (p) => (p?.data?.srId ? true : false),
					cellRenderer: memo(AddSupplier),
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellClassRules: {
						'cell-error-bg': (p) =>
							!p?.value &&
							(p?.data?.foundMPN ||
								p?.data?.foundBrand ||
								p?.data?.foundMOQ ||
								p?.data?.foundUsdCost ||
								p?.data?.foundDC ||
								p?.data?.foundPackaging ||
								p?.data?.foundLT ||
								p?.data?.sdSource ||
								p?.data?.sdMail ||
								p?.data?.sdSourceDate ||
								p?.data?.sdSupplier ||
								p?.data?.sdSupplierEmail ||
								p?.data?.sdSupplierName),
					},
				},
				{
					field: 'sdSupplier',
					headerName: 'Supplier',
					headerClass: styles['sd-header-child'],
					cellEditor: 'agRichSelectCellEditor',
					editable: (p) => (p?.data?.srId ? true : false),
					cellEditorParams: {
						values: crmSuppliers?.map((el) => el),
						searchType: 'match',
						allowTyping: true,
						filterList: true,
						highlightMatch: true,
						valueListMaxHeight: 220,
						valueListMaxWidth: 360,
					},
					minWidth: 190,
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellClassRules: {
						'cell-error-bg': (p) =>
							!p?.value &&
							(p?.data?.foundMPN ||
								p?.data?.foundBrand ||
								p?.data?.foundMOQ ||
								p?.data?.foundUsdCost ||
								p?.data?.foundDC ||
								p?.data?.foundPackaging ||
								p?.data?.foundLT ||
								p?.data?.sdSource ||
								p?.data?.sdMail ||
								p?.data?.sdSourceDate ||
								p?.data?.sdSubject ||
								p?.data?.sdSupplierEmail ||
								p?.data?.sdSupplierName),
					},
				},
				{
					field: 'sdSupplierEmail',
					headerName: 'Supplier Email',
					headerClass: styles['sd-header-child'],
					editable: (p) => (p?.data?.srId && p.data.sdSupplier ? true : false),
					cellRenderer: (p) => <>{p?.value ? p?.value : ''}</>,
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellClassRules: {
						'cell-error-bg': (p) =>
							!p?.value &&
							(p?.data?.foundMPN ||
								p?.data?.foundBrand ||
								p?.data?.foundMOQ ||
								p?.data?.foundUsdCost ||
								p?.data?.foundDC ||
								p?.data?.foundPackaging ||
								p?.data?.foundLT ||
								p?.data?.sdSource ||
								p?.data?.sdMail ||
								p?.data?.sdSourceDate ||
								p?.data?.sdSupplier ||
								p?.data?.sdSubject ||
								p?.data?.sdSupplierName),
					},
				},
				{
					field: 'sdSupplierName',
					headerName: 'Supplier Contact Name',
					headerClass: styles['sd-header-child'],
					editable: (p) => (p?.data?.srId ? true : false),
					cellRenderer: (p) => <>{p?.value ? p?.value : ''}</>,
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellClassRules: {
						'cell-error-bg': (p) =>
							!p?.value &&
							(p?.data?.foundMPN ||
								p?.data?.foundBrand ||
								p?.data?.foundMOQ ||
								p?.data?.foundUsdCost ||
								p?.data?.foundDC ||
								p?.data?.foundPackaging ||
								p?.data?.foundLT ||
								p?.data?.sdSource ||
								p?.data?.sdMail ||
								p?.data?.sdSourceDate ||
								p?.data?.sdSupplier ||
								p?.data?.sdSubject ||
								p?.data?.sdSupplierEmail),
					},
				},
			],
		},
		{
			headerName: 'Other',
			headerClass: styles['other-header'],
			children: [
				{
					field: 'othDaysOpen',
					headerName: 'Days Open',
					filter: false,
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellRenderer: (p) => {
						const openDay =
							p?.data?.cdInquiryDateTime &&
							moment(moment().format('MMM-DD-YYYY hh:mm A')).diff(
								moment(p?.data?.cdInquiryDateTime),
								'days'
							);

						return (
							<>
								{p?.data?.cdInquiryDateTime
									? p?.data?.foundMPN &&
									  p?.data?.foundBrand &&
									  p?.data?.foundMOQ &&
									  p?.data?.foundSPQ &&
									  p?.data?.foundUsdCost &&
									  p?.data?.foundDC &&
									  p?.data?.foundPackaging &&
									  p?.data?.foundLT &&
									  p?.data?.sdSource &&
									  p?.data?.sdSupplier &&
									  p?.data?.sdMail &&
									  p?.data?.sdSubject &&
									  p?.data?.sdSourceDate &&
									  p?.data?.sdSupplierEmail &&
									  p?.data?.sdSupplierName
										? ''
										: openDay === 0
										? 'Today'
										: openDay === 1
										? `${openDay} day`
										: `${openDay} days`
									: ''}
							</>
						);
					},
				},
				{
					field: 'othOrderReceived',
					headerName: 'Order Received?',
					editable: (p) => (p?.data?.srId ? true : false),
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: {
						searchType: 'match',
						allowTyping: true,
						filterList: true,
						highlightMatch: true,
						valueListMaxHeight: 220,
						valueListMaxWidth: 220,
						values: ['Yes', 'No'],
					},
					cellEditorPopupPosition: 'under',
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
				},
				{
					field: 'othSrRead',
					headerName: 'SR Read?',
					cellRenderer: (p) => <>{p?.value === true ? 'Yes' : 'No'}</>,
					hide: userType === 'sr' || userType === 'sm' ? true : false,
					filter: 'agSetColumnFilter',
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					filterParams: {
						suppressMiniFilter: true,
						cellRenderer: orderRecFilterRenderer,
						cellRendererParams: { isFilterRenderer: true },
					},
				},
				{
					field: 'othRemarks',
					headerName: 'Remarks',
					editable: (p) => (p?.data?.srId ? true : false),
					cellStyle: {
						borderRight: showBorders && '1px solid #EAECEE',
					},
					cellRenderer: (p) => (
						<>
							{p?.value ? (
								<ToolTip title={p?.value} placement="left">
									{p?.value}
								</ToolTip>
							) : (
								''
							)}
						</>
					),
					cellEditor: 'agLargeTextCellEditor',
					cellEditorPopup: true,
					cellEditorParams: {
						maxLength: 256,
						rows: 5,
						cols: 50,
					},
				},
			],
		},
	];

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	const Notification = () => {
		return (
			<>
				{userType !== 'ss' && (
					<ul style={{ listStyle: 'disc', paddingLeft: '15px' }}>
						<li>
							There are{' '}
							<span style={{ color: '#006249' }}>
								{(costBookType === true &&
									mpnCostBookRowData?.filter((el) => el?.newUpdate === true)
										?.length) ||
									0}
							</span>{' '}
							buy parts that have been updated. Click on “Buy Cost Book” and
							then the “View Updated MPN Lines” tab to view the parts.
						</li>
						<li>
							There are{' '}
							<span style={{ color: '#006249' }}>
								{(costBookType === false &&
									mpnCostBookRowData?.filter((el) => el?.newUpdate === true)
										?.length) ||
									0}
							</span>{' '}
							bid parts that have been updated. Click on “Bid Cost Book” and
							then the “View Updated MPN Lines” tab to view the parts.
						</li>
					</ul>
				)}
				{/* {userType === 'ss' && (
					<p>
						There are{' '}
						<span style={{ color: '#006249' }}>
							{costBookType === true
								? buyBookData?.length
								: bidBookData?.length}
						</span>{' '}
						new items in the queue. Please review.
					</p>
				)} */}
			</>
		);
	};

	// Add black row to cost book
	const addCostBook = () => {
		const hasBlankRow = mpnCostBookRowData.some(
			(row) => row.currentTime && row.othSrRead === false
		);

		if (!hasBlankRow) {
			const newRow = { currentTime: Date.now(), othSrRead: false };
			setMpnCostBookRowData([newRow, ...mpnCostBookRowData]);
		} else {
			message.error('You can only add one blank row.');
		}
	};

	// On cell value changed
	const onCellValueChanged = useCallback(
		async (event) => {
			const cellValue = event.data;

			if (cellValue.sr?.length > 0) {
				cellValue.srId =
					srData?.filter((el) => el?.salesRepName === cellValue.sr)?.[0]
						?.salesRepCrmId || cellValue.srId;
			}

			const formFields = {
				userEmail: userMail,
				id: cellValue.id,
				queueId: cellValue.queueId || '',
				srId:
					srData?.filter((el) => el?.salesRepName === cellValue.sr)?.[0]
						?.salesRepCrmId || cellValue.srId,
				sr: cellValue.sr || '',
				ssId:
					ssDetails?.filter((el) => el?.salesRepName === cellValue.ss)?.[0]
						?.salesRepCrmId || cellValue.ssId,
				ss: cellValue.ss || '',
				isBuy: cellValue.isBuy || costBookType || null,
				cdMail: cellValue.cdMail || '',
				cdInquiryDateTime: cellValue.cdInquiryDateTime || null,
				cdCustomerId: cellValue.cdCustomerId || null,
				cdCustomerName: cellValue.cdCustomerName || '',
				cdContactEmail: cellValue.cdContactEmail || '',
				cdContactName: cellValue.cdContactName || '',
				cdCustomerSubject: cellValue.cdCustomerSubject || '',
				reqCPN: cellValue.reqCPN || '',
				reqMPN: cellValue.reqMPN || '',
				reqBrand: cellValue.reqBrand
					? cellValue.reqBrand
					: mpnBrand?.MPN_BRAND?.filter(
							(el) => el.split('|')[0] === cellValue?.reqMPN
					  )?.[0]?.split('|')?.[1] || '',
				reqQtyReq: parseInt(cellValue?.reqQtyReq) || null,
				reqExtPrice: parseFloat(cellValue?.reqExtPrice) || null,
				reqMpnInCrm: cellValue.reqMpnInCrm || '',
				reqBrandInCrm: cellValue.reqBrandInCrm || '',
				foundMpnInCrm: cellValue.foundMpnInCrm || '',
				foundBrandInCrm: cellValue.foundBrandInCrm || '',
				foundMPN: cellValue.foundMPN || '',
				foundBrand: cellValue.foundBrand
					? cellValue.foundBrand
					: mpnBrand?.MPN_BRAND?.filter(
							(el) => el.split('|')[0] === cellValue?.foundMPN
					  )?.[0]?.split('|')?.[1] || '',
				foundMOQ: cellValue?.foundMOQ ? parseFloat(cellValue?.foundMOQ) : null,
				foundSPQ: cellValue?.foundSPQ ? parseInt(cellValue?.foundSPQ) : null,
				foundUsdCost: cellValue?.foundUsdCost
					? parseFloat(cellValue?.foundUsdCost)
					: 0,
				foundExtPrice: cellValue?.foundExtPrice
					? parseFloat(cellValue?.foundExtPrice)
					: null,
				foundDC: cellValue.foundDC || '',
				foundPackaging: cellValue.foundPackaging || '',
				foundLT: cellValue.foundLT || '',
				sdSource: cellValue.sdSource || '',
				sdMail: cellValue.sdMail || '',
				sdSubject: cellValue.sdSubject || '',
				sdSourceDate: cellValue.sdSourceDate || null,
				sdSupplier: cellValue.sdSupplier || '',
				sdSupplierName: cellValue.sdSupplierName || '',
				sdSupplierEmail: cellValue.sdSupplierEmail || '',
				othDaysOpen: cellValue.othDaysOpen || null,
				othRemarks: cellValue.othRemarks || '',
				othOrderReceived:
					cellValue.othOrderReceived ||
					cellValue?.othOrderReceived === 'Yes' ||
					cellValue?.othOrderReceived === true
						? true
						: cellValue?.othOrderReceived === 'No' ||
						  cellValue?.othOrderReceived === false
						? false
						: null,
				othSrRead:
					userType === 'sr' || userType === 'sm'
						? true
						: cellValue?.othSrRead || null,
			};

			event.colDef.field === 'sr' && !event.oldValue && delete formFields.id;

			const rowNode = event.api.getRowNode(event.data.id);

			if (event.colDef.field === 'reqMPN') {
				rowNode.setDataValue(
					'reqBrand',
					mpnBrand?.MPN_BRAND?.filter((el) =>
						el?.includes(cellValue?.reqMPN)
					)?.[0]?.split('|')?.[1]
				);
			}

			if (event.colDef.field === 'ss') {
				rowNode.setDataValue(
					'ssId',
					ssDetails?.filter((el) => el?.salesRepName === cellValue.ss)?.[0]
						?.salesRepCrmId
				);
			}

			if (event.colDef.field === 'foundMPN') {
				rowNode.setDataValue(
					'foundBrand',
					mpnBrand?.MPN_BRAND?.filter((el) =>
						el?.includes(cellValue?.foundMPN)
					)?.[0]?.split('|')?.[1]
				);
			}

			if (
				event.colDef.field === 'foundMOQ' ||
				event.colDef.field === 'foundUsdCost'
			) {
				rowNode.setDataValue(
					'foundExtPrice',
					cellValue.foundUsdCost * cellValue.foundMOQ
				);
			}

			if (
				isNaN(formFields.foundUsdCost) === false ||
				formFields.foundUsdCost === null
			) {
				axios({
					method: 'post',
					url: cellValue?.id
						? `https://internal.supremecomponents.com/api/externalgateway.php?route=updatempncostbook`
						: `https://internal.supremecomponents.com/api/externalgateway.php?route=savempncostbook`,
					data: JSON.stringify(formFields),
				})
					.then((response) => {
						if (event.colDef.field === 'sr' && !event.oldValue) {
							let responseid = response?.data;
							const url = window.location.pathname;
							const sectionName = url
								?.substring(url?.lastIndexOf('/'))
								.replace(/[/-]/g, ' ')
								.toUpperCase()
								.trim();

							let lastUserActivity = '';

							axios
								.get(
									`https://internal.supremecomponents.com/api/gettracking.php?user_email=${userMail}&section_name=${sectionName}`
								)
								.then((response) => {
									lastUserActivity = new Date(
										response.data[0]?.SECTION_LEAVE_TIME
									).toISOString();
								})
								.then(() => {
									axios
										.get(
											`https://internal.supremecomponents.com/api/externalgateway.php?route=getmpncostbookbyid&id=${
												responseid || cellValue?.id
											}&isbuy=${costBookType}&userEmail=${userMail}&lastUserActivity=${lastUserActivity}`
										)
										.then((response) => {
											setMpnCostBookList((prev) =>
												filterUniqueIdsAndRecent([...prev, response?.data?.[0]])
											);
											const column = event.column.colDef.field;
											setTimeout(() => {
												event.api.refreshCells({
													force: true,
													columns: [column],
													rowNodes: [event.node],
												});
												event?.api?.tabToNextCell();
											}, 500);
										});
								});
						}
					})
					.catch((err) => {
						message.error('Something went wrong please try again later.');
					});
			}
		},
		// eslint-disable-next-line
		[costBookType]
	);

	const getRowId = useMemo(() => {
		return (params) => {
			return params.data.id;
		};
	}, []);

	// Export to excel
	const exportToExcel = () => {
		const mpnCostBookExcelParams = {
			sheetName: 'MPN Cost Book',
			fileName: 'MPN Cost Book.xlsx',
			columnKeys: [
				'id',
				'smartId',
				'lastModifiedAgo',
				'lastActivity',
				'sr',
				'ss',
				'cdMail',
				'cdInquiryDateTime',
				'cdCustomerName',
				'cdCustomerSubject',
				'cdContactEmail',
				'cdContactName',
				'reqCPN',
				'reqMPN',
				'reqBrand',
				'reqQtyReq',
				'reqExtPrice',
				'foundMPN',
				'foundBrand',
				'foundMOQ',
				'foundUsdCost',
				'foundExtPrice',
				'foundDC',
				'foundPackaging',
				'foundLT',
				'sdSource',
				'sdMail',
				'sdSourceDate',
				'sdSubject',
				'sdSupplier',
				'sdSupplierEmail',
				'sdSupplierName',
				'othDaysOpen',
				'othOrderReceived',
				'othSrRead',
				'othRemarks',
			],
			processCellCallback: (p) => {
				if (p.column.colId === 'lastActivity') {
					if (p?.value) {
						return moment(p.value).format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				if (p.column.getColDef().field === 'ssId') {
					if (p?.value === 1061) {
						return 'Mikee';
					}
					if (p?.value === 1076) {
						return 'Cindy';
					}
					if (p?.value === 1) {
						return 'Self';
					}
					return '';
				}
				if (p.column.colId === 'cdMail') {
					if (p?.value) {
						const trimmedString = p?.value?.replace(/=+$/, '');
						return `${messageURl?.url}${trimmedString}%3D.eml`;
					}
					return '';
				}
				if (p.column.colId === 'cdInquiryDateTime') {
					if (p?.value) {
						return moment(p.value).format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				if (p.column.colId === 'sdMail') {
					if (p?.value) {
						const trimmedString = p?.value?.replace(/=+$/, '');
						return `${messageURl?.url}${trimmedString}%3D.eml`;
					}
					return '';
				}
				if (p.column.colId === 'sdSourceDate') {
					if (p?.value) {
						return moment(p.value).format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				if (p.column.colId === 'othDaysOpen') {
					const openDay =
						p?.node?.data?.cdInquiryDateTime &&
						moment(moment().format('MMM-DD-YYYY hh:mm A')).diff(
							moment(p?.node?.data?.cdInquiryDateTime),
							'days'
						);
					return p?.node?.data?.cdInquiryDateTime
						? p?.node?.data?.foundMPN &&
						  p?.node?.data?.foundBrand &&
						  p?.node?.data?.foundMOQ &&
						  p?.node?.data?.foundSPQ &&
						  p?.node?.data?.foundUsdCost &&
						  p?.node?.data?.foundDC &&
						  p?.node?.data?.foundPackaging &&
						  p?.node?.data?.foundLT &&
						  p?.node?.data?.sdSource &&
						  p?.node?.data?.sdSupplier &&
						  p?.node?.data?.sdMail &&
						  p?.node?.data?.sdSubject &&
						  p?.node?.data?.sdSourceDate &&
						  p?.node?.data?.sdSupplierEmail &&
						  p?.node?.data?.sdSupplierName
							? ''
							: openDay === 0
							? 'Today'
							: openDay === 1
							? `${openDay} day`
							: `${openDay} days`
						: '';
				}
				if (p.column.colId === 'othSrRead') {
					if (p?.value === true) {
						return 'Yes';
					}
					if (p?.value === false) {
						return 'No';
					}
					return '';
				}

				return p.value;
			},
		};
		gridApi.exportDataAsExcel(mpnCostBookExcelParams);
	};

	// automatically receive mails
	useEffect(() => {
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=getuserpref&id=${userInfo?.ViewAsLoggedinUser?.MasterId}`
			)
			.then((response) => {
				setIsAutoReceiveMail(response.data);
			});
	}, [userInfo?.ViewAsLoggedinUser?.MasterId]);

	const automaticallyReceiveMail = (e) => {
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=putuserpref&id=${userInfo?.ViewAsLoggedinUser?.MasterId}&enable=${e.target.checked}`
			)
			.then(() => {
				axios
					.get(
						`https://internal.supremecomponents.com/api/externalgateway.php?route=getuserpref&id=${userInfo?.ViewAsLoggedinUser?.MasterId}`
					)
					.then((response) => {
						setIsAutoReceiveMail(response.data);
					});
			});
	};

	// Find table data
	const quickFind = (e) => {
		gridApi.setQuickFilter(e.target.value);
	};

	// Default coldefs
	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressKeyboardEvent: suppressNavigation,
			suppressHeaderKeyboardEvent: suppressUpDownNavigation,
		}),
		[]
	);

	// Process cell for Clipboard
	const processCellForClipboard = (p) => {
		if (p.column.colId === 'lastActivity') {
			if (p?.value) {
				return moment(p.value).format('MMM-DD-YYYY hh:mm A');
			}
			return '';
		}
		if (p.column.getColDef().field === 'ssId') {
			if (p?.value === 1061) {
				return 'Mikee';
			}
			if (p?.value === 1076) {
				return 'Cindy';
			}
			if (p?.value === 1) {
				return 'Self';
			}
			return '';
		}
		if (p.column.colId === 'cdMail') {
			if (p?.value) {
				const trimmedString = p?.value?.replace(/=+$/, '');
				return `${messageURl?.url}${trimmedString}%3D.eml`;
			}
			return '';
		}
		if (p.column.colId === 'cdInquiryDateTime') {
			if (p?.value) {
				return moment(p.value).format('MMM-DD-YYYY hh:mm A');
			}
			return '';
		}
		if (p.column.colId === 'sdMail') {
			if (p?.value) {
				const trimmedString = p?.value?.replace(/=+$/, '');
				return `${messageURl?.url}${trimmedString}%3D.eml`;
			}
			return '';
		}
		if (p.column.colId === 'sdSourceDate') {
			if (p?.value) {
				return moment(p.value).format('MMM-DD-YYYY hh:mm A');
			}
			return '';
		}
		if (p.column.colId === 'othDaysOpen') {
			const openDay =
				p?.node?.data?.cdInquiryDateTime &&
				moment(moment().format('MMM-DD-YYYY hh:mm A')).diff(
					moment(p?.node?.data?.cdInquiryDateTime),
					'days'
				);
			return p?.node?.data?.cdInquiryDateTime
				? p?.node?.data?.foundMPN &&
				  p?.node?.data?.foundBrand &&
				  p?.node?.data?.foundMOQ &&
				  p?.node?.data?.foundSPQ &&
				  p?.node?.data?.foundUsdCost &&
				  p?.node?.data?.foundDC &&
				  p?.node?.data?.foundPackaging &&
				  p?.node?.data?.foundLT &&
				  p?.node?.data?.sdSource &&
				  p?.node?.data?.sdSupplier &&
				  p?.node?.data?.sdMail &&
				  p?.node?.data?.sdSubject &&
				  p?.node?.data?.sdSourceDate &&
				  p?.node?.data?.sdSupplierEmail &&
				  p?.node?.data?.sdSupplierName
					? ''
					: openDay === 0
					? 'Today'
					: openDay === 1
					? `${openDay} day`
					: `${openDay} days`
				: '';
		}
		if (p.column.colId === 'othSrRead') {
			if (p?.value === true) {
				return 'Yes';
			}
			if (p?.value === false) {
				return 'No';
			}
			return '';
		}

		return p.value;
	};

	// export excel sheet style
	const excelStyles = [
		{
			id: 'header',
			font: {
				bold: true,
			},
		},
	];

	// Handle cost book view
	const handleCostView = (e) => {
		setCostView(e.target.value);
	};

	// Handle views
	useEffect(() => {
		if (costView === 'View Updated MPN Lines') {
			isNotEmptyArray(mpnCostBookList) &&
				setMpnCostBookRowData(
					mpnCostBookList?.filter((el) => el?.newUpdate === true)
				);
		} else if (costView === 'View Full Cost Book') {
			isNotEmptyArray(mpnCostBookList) &&
				setMpnCostBookRowData(mpnCostBookList);
		} else if (costView === 'View Successful Quotes') {
			isNotEmptyArray(mpnCostBookList) &&
				setMpnCostBookRowData(
					mpnCostBookList?.filter(
						(el) =>
							el?.othOrderReceived === true || el?.othOrderReceived === 'Yes'
					)
				);
		}
	}, [costView, mpnCostBookList, setMpnCostBookRowData]);

	// Copy to clipboard
	const copyToClipboard = () => {
		if (gridApi) {
			gridApi.getSelectedRows()?.length > 0
				? gridApi.getSelectedRows()
				: gridApi?.selectAll();
			gridApi.copySelectedRowsToClipboard({
				includeHeaders: true,
				columnKeys: [
					'id',
					'lastModifiedAgo',
					'smartId',
					'lastActivity',
					'sr',
					'ss',
					'cdMail',
					'cdInquiryDateTime',
					'cdCustomerName',
					'cdContactEmail',
					'cdContactName',
					'cdCustomerSubject',
					'reqCPN',
					'reqMPN',
					'reqBrand',
					'reqQtyReq',
					'reqExtPrice',
					'foundMPN',
					'foundBrand',
					'foundMOQ',
					'foundUsdCost',
					'foundExtPrice',
					'foundDC',
					'foundPackaging',
					'foundLT',
					'sdSource',
					'sdMail',
					'sdSourceDate',
					'sdSubject',
					'sdSupplier',
					'sdSupplierEmail',
					'sdSupplierName',
					'othDaysOpen',
					'othOrderReceived',
					'othSrRead',
					'othRemarks',
				],
			});
			gridApi.deselectAll();
		}
		message.open({
			type: 'success',
			content: 'Copied to Clipboard',
		});
	};

	const onSelectionChanged = (e) => {
		setSelectedRowValue(e.api.getSelectedRows());
	};

	const dispatchClickEvent = (element) => {
		const clickEvent = new MouseEvent('click', {
			view: window,
			bubbles: true,
			cancelable: true,
		});
		element.dispatchEvent(clickEvent);
	};

	const onCellKeyDown = (event) => {
		const handledColIds = [
			'sr',
			'ssId',
			'cdCustomerName',
			'cdCustomerSubject',
			'reqMPN',
			'reqBrand',
			'foundMPN',
			'foundBrand',
			'sdSource',
			'sdMail',
		];

		if (
			event.event.key === 'Enter' &&
			handledColIds.includes(event.column.colId)
		) {
			const rowIndex = event.rowIndex;
			const cellSelector = `.ag-row[row-index="${rowIndex}"] .ag-cell[col-id="${event.column.colId}"]`;
			const cell = document.querySelector(cellSelector);

			if (cell) {
				// First, try to find a span element
				const spanElement = cell.querySelector('span');
				if (spanElement) {
					spanElement.click();
				}

				// If no span found, then try to find an SVG element
				const svgElement = cell.querySelector('svg');
				if (svgElement) {
					dispatchClickEvent(svgElement);
				}
			} else {
			}
		}
	};

	// Check if different queue set is there
	const hasDifferentSecondNumbers = (array) => {
		// Extracting the third elements from the split smartIds
		const thirdNumbers = array?.map((el) => {
			const split = el?.smartId?.split('.');
			return split ? split[2] : null; // Extracting the third number
		});

		// Checking if there are different third numbers
		const firstNumber = thirdNumbers?.[0];
		const hasdifferent = thirdNumbers?.some((num) => num !== firstNumber);

		return { hasdifferent, thirdNumbers };
	};

	const hasDifferentNumbers = hasDifferentSecondNumbers(
		gridApi?.getSelectedRows()
	);

	// Delete selected rows
	const deleteSelectedRows = () => {
		if (!hasDifferentNumbers?.hasdifferent) {
			const idsRemove = gridApi?.getSelectedRows()?.map((el) => el.id);
			setMpnCostBookList(
				filterUniqueIdsAndRecent(
					mpnCostBookList?.filter((item) => !idsRemove?.includes(item.id))
				)
			);
			setMpnCostBookRowData(
				filterUniqueIdsAndRecent(
					mpnCostBookRowData?.filter((item) => !idsRemove?.includes(item.id))
				)
			);

			const formFields = {
				userEmail: userMail,
				ids: idsRemove,
			};

			axios.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=bulkdelete_costbook`,
				JSON.stringify(formFields)
			);

			setSelectedRowValue([]);
			setDeleteQueuePopup(false);
			message.success(
				`RFQ set ID ${
					[...new Set(hasDifferentNumbers.thirdNumbers)]?.[0]
				} is deleted.`
			);
		}
	};

	// Close delete popup
	const closeDeleteQueuePopup = () => {
		setDeleteQueuePopup(false);
		gridApi?.deselectAll();
	};

	// Open delete popup
	const openDeletePopUp = () => {
		if (!hasDifferentNumbers?.hasdifferent) {
			setDeleteQueuePopup(true);
		} else {
			message.error(
				'You have selected multiple RFQ sets to delete. Please only select one RFQ set to delete and try again.',
				6
			);
		}
	};

	// Remove filters shortcut
	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	// Find lowest filter
	function findLowestPricePerSubset(data) {
		const subsets = {};

		// Group items by subset (first four parts of smartId)
		data?.forEach((item) => {
			const subsetId = item.smartId.split('.').slice(0, 4).join('.');
			if (!subsets[subsetId]) {
				subsets[subsetId] = [];
			}
			subsets[subsetId].push(item);
		});

		const lowestPrices = [];

		// Iterate through subsets to find lowest price
		for (const subsetId in subsets) {
			let lowestNonZeroPrice = Infinity;
			let latestZeroPriceDate = null;
			let subsetWithLowestPrice;

			subsets[subsetId].forEach((item) => {
				if (item.foundUsdCost > 0 && item.foundUsdCost < lowestNonZeroPrice) {
					lowestNonZeroPrice = item.foundUsdCost;
					subsetWithLowestPrice = item;
				} else if (item.foundUsdCost === 0 && item.id > latestZeroPriceDate) {
					latestZeroPriceDate = item.id;
					subsetWithLowestPrice = item;
				}
			});

			if (subsetWithLowestPrice) {
				lowestPrices.push(subsetWithLowestPrice);
			}
		}

		return lowestPrices;
	}

	const handleCheckboxChange = (e) => {
		setLowestPrice(e.target.checked);
		if (e.target.checked === true) {
			gridApi?.selectAllFiltered();
			setLowestPriceData(findLowestPricePerSubset(gridApi.getSelectedRows()));
		} else if (e.target.checked === false) {
			gridApi?.deselectAll();
			setLowestPriceData(null);
		}
	};

	const gridOptions = {
		suppressHeaderFocus: true,
		// suppressChangeDetection: true,
		suppressScrollWhenPopupsAreOpen: true,
	};

	return (
		<div className={styles['mpn-cost-book-wrapper']}>
			<BreadCrumb
				route1="Sourcing"
				route2="MPN Cost Book"
				icon={<MoneyCollectOutlined style={{ paddingRight: '5px' }} />}
			/>
			<SectionInfo
				title="MPN Cost Book"
				info="This section provides you access to MPN cost book for requote later."
			/>
			<InnerHeader
				exportToExcel={exportToExcel}
				updatePage
				copyToClipboard={copyToClipboard}
				onUpdate={sourcingMpnQueueCostBook}
				updateText="Refresh CRM Data"
			/>

			<div className={styles['mpn-cost-book']}>
				{userType !== 'ss' && (
					<NotificationBar message={<Notification />} wraningBar />
				)}
				{(userType === 'sr' || userType === 'ss') && (
					<>
						<CheckBox
							checked={isAutoReceiveMail}
							onChange={automaticallyReceiveMail}
						>
							{userType === 'sr' &&
								'Automatically received an email containing all the costs that were added/modified by the Sourcing Specialist every 30 minutes.'}

							{userType === 'ss' &&
								'Automatically send an email to the assigned SR containing all the costs that were added/modified in table every 30 minutes.'}
						</CheckBox>
						<Divider
							style={{
								margin: '10px 0',
								borderBlockStart: '1px solid rgb(216, 216, 216)',
							}}
						/>
					</>
				)}
				<div className={styles['dropdown-actions-wrapper']}>
					<div className={styles['dropdown-wrapper']}>
						<AntdRadio.Group
							defaultValue={costBookType}
							onChange={checkBuyorBid}
							disabled={mpnCostBookLoading}
							value={costBookType}
						>
							<RadioTwo value={true}>Buy Cost Book</RadioTwo>
							<RadioTwo value={false}>Bid Cost Book</RadioTwo>
						</AntdRadio.Group>
						<Select
							title="Date Range:"
							disabled={mpnCostBookLoading}
							style={{
								width: 100,
							}}
							defaultValue={mpnDateRange}
							placeholder="Date Range"
							options={[
								{ value: '1W', label: '1 Week' },
								{ value: '2W', label: '2 Weeks' },
								{ value: '3W', label: '3 Weeks' },
								{ value: '4W', label: '4 Weeks' },
								{ value: '', label: 'All' },
							]}
							onChange={(value) => setMpnDateRange(value)}
						/>
					</div>
					<div className={styles['actions']}>
						<Button onClick={() => setBackorderSitesModal(true)}>
							<IoMdShareAlt style={{ fontSize: '16px' }} />
							View Backorder Sites
						</Button>
					</div>
				</div>

				<AntdRadio.Group
					defaultValue={costView}
					onChange={handleCostView}
					style={{ marginBottom: '10px' }}
					disabled={mpnCostBookLoading}
				>
					<RadioTwo value="View Updated MPN Lines">
						View Updated MPN Lines
					</RadioTwo>
					<RadioTwo value="View Full Cost Book">View Full Cost Book</RadioTwo>
					<RadioTwo value="View Successful Quotes">
						View Successful Quotes
					</RadioTwo>
				</AntdRadio.Group>

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '10px',
						marginBottom: '10px',
					}}
				>
					<Button onClick={addCostBook} disabled={mpnCostBookLoading}>
						<IoMdAddCircle style={{ fontSize: '16px' }} />
						Add MPN Cost
					</Button>

					<BulkUploadPasteModal />
					<Button onClick={() => setShowBorders(!showBorders)}>
						{showBorders ? 'Hide Borders' : 'Show Borders'}
					</Button>

					{selectedRowValue?.length > 0 && (
						<Popconfirm
							placement="bottomLeft"
							okButtonColor="red"
							open={deleteQueuePopup}
							title="Delete"
							description="You cannot undo this action. Do you confirm the deletion of the selected row?"
							okText="Yes"
							cancelText="Cancel"
							onConfirm={deleteSelectedRows}
							onCancel={closeDeleteQueuePopup}
							icon={
								<QuestionCircleOutlined
									style={{
										color: 'red',
									}}
								/>
							}
						>
							<Button color="red" onClick={openDeletePopUp}>
								Delete Selected Rows
							</Button>
						</Popconfirm>
					)}
					{/* {lowestPrice && ( */}
					<CheckBox
						disabled={mpnCostBookLoading}
						checked={lowestPrice}
						onChange={handleCheckboxChange}
					>
						Filter lowest pirce
					</CheckBox>
					{/* )} */}
				</div>

				{!mpnCostBookLoading &&
					!mpnCostBookError &&
					isNotEmptyArray(mpnCostBookRowData) && (
						<>
							<div className={styles['table-info']}>
								<TableDataCountInput
									transactions={`Showing ${
										mpnCostBookRowData?.length || 0
									} transactions`}
									onChange={quickFind}
									allowClear
								/>
							</div>
							<div className={`ag-theme-custom ${styles['table-wrapper']}`}>
								<AgGridReact
									ref={gridRef}
									onGridReady={onGridReady}
									gridOptions={gridOptions}
									onCellKeyDown={onCellKeyDown}
									rowData={lowestPrice ? lowestPriceData : mpnCostBookRowData}
									columnDefs={mpnCostBookColumns}
									rowSelection={'multiple'}
									rowMultiSelectWithClick={true}
									suppressRowClickSelection={true}
									defaultColDef={defaultColDef}
									animateRows={true}
									pagination={true}
									rowHeight={30}
									onCellValueChanged={onCellValueChanged}
									undoRedoCellEditing={true}
									onSelectionChanged={onSelectionChanged}
									undoRedoCellEditingLimit={5}
									enableCellChangeFlash={true}
									suppressLastEmptyLineOnPaste={true}
									enableRangeSelection={true}
									processCellForClipboard={processCellForClipboard}
									excelStyles={excelStyles}
									paginationPageSize={50}
									overlayNoRowsTemplate={`<p style='font-size:16px; font-weight:600'>Click on Add MPN Cost Button to add an MPN Cost<p/>`}
									paginationPageSizeSelector={false}
									getRowId={getRowId}
								/>
							</div>
						</>
					)}
				{drawerMail && (
					<Drawer
						title={
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<p className={styles['mail-subject']}>
									<ToolTip
										placement="bottomLeft"
										title={drawerMailData?.subject}
									>
										{drawerMailData?.subject}
									</ToolTip>
								</p>
								<Link
									style={{ textDecoration: 'none' }}
									target="_blank"
									to={`${messageURl?.url}${cellData?.value?.replace(
										/=+$/,
										''
									)}%3D.eml`}
								>
									<Button type="bordered">Download Mail</Button>
								</Link>
							</div>
						}
						placement="right"
						onClose={closeDrawer}
						open={drawerMail}
						size={'large'}
					>
						{drawerMailLoading && <LoadingOutlined />}
						{drawerMailData && (
							<>
								<div className={styles['mail-details-wrapper']}>
									<div className={styles['mail-user-info']}>
										<Avatar
											className={styles['avatar']}
											style={{
												backgroundColor:
													avatarBgColor[drawerMailData?.fromName?.charAt(0)] ||
													avatarBgColor.default,
											}}
										>
											{drawerMailData?.fromName?.charAt(0)?.toUpperCase()}
										</Avatar>
										<div className={styles['users-info']}>
											<p className={styles['sender-name']}>
												{drawerMailData?.fromName}
											</p>
											{drawerMailData?.toRecipients && (
												<p className={styles['to']}>
													<span className={styles['title']}>To:</span>{' '}
													{drawerMailData?.toRecipients}
												</p>
											)}
											{drawerMailData?.ccRecipients && (
												<p className={styles['cc']}>
													<span className={styles['title']}>Cc:</span>{' '}
													{drawerMailData?.ccRecipients}
												</p>
											)}
											{drawerMailData?.bccRecipients && (
												<p className={styles['bcc']}>
													<span className={styles['title']}>Bcc:</span>{' '}
													{drawerMailData?.bccRecipients}
												</p>
											)}
										</div>
									</div>

									<p className={styles['date-info']}>
										{moment(drawerMailData?.receivedDateTime).format(
											'MMM-DD-YYYY hh:mm A'
										)}
									</p>
								</div>
								<div
									dangerouslySetInnerHTML={{ __html: drawerMailData?.html }}
								/>
							</>
						)}
					</Drawer>
				)}
			</div>

			{mpnCostBookLoading && (
				<div className={styles['loading-wrapper']}>
					<LoadingMessage message="Loading..." />
				</div>
			)}

			{mpnCostBookError && !mpnCostBookLoading && (
				<div className={styles['error-wrapper']}>
					<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
				</div>
			)}

			{!isNotEmptyArray(mpnCostBookRowData) &&
				!mpnCostBookError &&
				!mpnCostBookLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here." />
					</div>
				)}
			{backorderSitesModal && (
				<ViewBackorderSites
					open={backorderSitesModal}
					onCancel={() => setBackorderSitesModal(false)}
				/>
			)}
		</div>
	);
};

export default MPNCostBook;
