import React, { useContext } from 'react';
import { Col, Divider, Form, Row } from 'antd';

import Modal from '../../../../components/modal';
import Select from '../../../../components/select';
import Button from '../../../../components/button';
import EdenContext from '../../../../context/edenContext';

import styles from '../sourcingAssignments.module.scss';

const AddSSForm = ({
	open,
	onCancel,
	onFinish,
	form,
	initialValues,
	setSSValues,
	setSRValues,
	formDisabled,
}) => {
	const { ssData, srData } = useContext(EdenContext);

	const ssDetails = [
		...ssData,
		{
			salesRepCrmId: 1,
			salesRepEmail: 'No SS',
			salesRepName: 'No SS',
		},
	];

	const ssValues = (value, option) => {
		setSSValues(option);
	};

	const srValues = (value, option) => {
		setSRValues(option);
	};

	return (
		<Modal
			title={
				<>
					<p>Add Sourcing Assignment</p>
					<Divider
						style={{
							margin: '5px 0',
							borderBlockStart: '1px solid rgb(216, 216, 216)',
						}}
					/>
				</>
			}
			open={open}
			onCancel={onCancel}
			destroyOnClose
			width={700}
		>
			<Form
				name="add-tags"
				form={form}
				onFinish={onFinish}
				validateTrigger={['onBlur', 'onChange']}
				labelCol={{ span: 24 }}
				requiredMark={false}
				initialValues={initialValues}
				disabled={formDisabled}
			>
				<Row gutter={16}>
					<Col sm={24} md={12}>
						<Form.Item
							label="Sales Rep"
							name="sales_rep"
							rules={[
								{
									required: true,
									message: 'Please select Sales Rep!',
								},
								// () => ({
								// 	validator(_, value) {
								// 		const existingSR = sourcingAssList.map(
								// 			(item) => item?.salesRep?.salesRepName
								// 		);
								// 		if (existingSR.includes(value)) {
								// 			return Promise.reject(
								// 				new Error('This Sales Rep is already present!')
								// 			);
								// 		}
								// 		return Promise.resolve();
								// 	},
								// }),
							]}
						>
							<Select
								showSearch
								placeholder="Select Sales Rep"
								type="bordered"
								options={srData?.map((el) => ({
									value: el?.salesRepName,
									label: el?.salesRepName,
									id: el?.salesRepCrmId,
									email: el?.salesRepEmail,
								}))}
								onChange={srValues}
							/>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item
							label="Sourcing Specialist"
							name="ss"
							rules={[
								{
									required: true,
									message: 'Please select Sourcing Specialist!',
								},
							]}
						>
							<Select
								showSearch
								placeholder="Select Sourcing Specialist"
								type="bordered"
								options={ssDetails?.map((el) => ({
									value: el?.salesRepEmail,
									label: el?.salesRepName,
									id: el?.salesRepCrmId,
								}))}
								onChange={ssValues}
							/>
						</Form.Item>
					</Col>

					<Col sm={24}>
						<Form.Item style={{ marginBottom: '0px' }}>
							<div className={styles['actions']}>
								<Button type="bordered" onClick={onCancel}>
									Cancel
								</Button>
								<Button htmlType="submit">Process</Button>
							</div>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default AddSSForm;
