import { useContext, useMemo, useState } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Avatar, Divider, message } from 'antd';
import { HiOutlineSearch } from 'react-icons/hi';
import { MdEdit, MdMail } from 'react-icons/md';
import { Base64 } from 'js-base64';
import { LoadingOutlined } from '@ant-design/icons';

import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import EdenContext from '../../../../context/edenContext';
import NotificationBar from '../../../../components/notificationBar';
import Input from '../../../../components/input';
import ErrorMessage from '../../../../components/errorMessage';
import { filterUniqueIdsAndRecent, isNotEmptyArray } from '../../../../utils';
import NoData from '../../../../components/nodata';
import LoadingMessage from '../../../../components/loaderMessage';
import ToolTip from '../../../../components/tooltip';
import Drawer from '../../../../components/drawer';
import { avatarBgColor } from '../../../../constant';
import MpnQueueContext from '../../../../context/mpnQueueContext';

import styles from './customerProspect.module.scss';

const dateFilterParams = {
	filters: [
		{
			filter: 'agDateColumnFilter',
			filterParams: {
				comparator: (a, b) => {
					return getDate(a).getTime() - getDate(b).getTime();
				},
			},
		},
	],
};

const getDate = (value) => {
	const date = moment.utc(value).tz('Asia/Singapore').format('DD/MM/YYYY');
	var dateParts = date.split('/');
	return new Date(
		Number(dateParts[2]),
		Number(dateParts[1]) - 1,
		Number(dateParts[0])
	);
};

const CustomerProspect = (p) => {
	const { me, userMail, messageURl, crmAccCusSupp } = useContext(EdenContext);
	const { setMpnQueueList } = useContext(MpnQueueContext);
	const { accounts } = useMsal();
	const userData = accounts?.[0];
	const [modalOpen, setModalOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [rowSelectionData, setRowSelectionData] = useState(null);
	const [customerProspectData, setCustomerProspectData] = useState([]);
	const [customerProspectLoading, setCustomerProspectLoading] = useState(false);
	const [customerProspectError, setCustomerProspectError] = useState(null);
	const [paramsData, setParamsData] = useState(null);
	const [addingMail, setAddingMail] = useState(false);
	const [drawerMail, setDrawerMail] = useState(false);
	const [drawerMailData, setDrawerMailData] = useState(null);
	const [drawerMailLoading, setDrawerMailLoading] = useState(false);
	const [cellData, setCellData] = useState(null);

	const closeDrawer = () => {
		setDrawerMail(!drawerMail);
		setDrawerMailData(null);
		setCellData(null);
	};

	const openDrawer = (cid) => {
		setDrawerMail(true);
		setDrawerMailLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_mail_html&cid=${cid}`
			)
			.then((response) => {
				setDrawerMailData(response.data);
			})
			.finally(() => {
				setDrawerMailLoading(false);
			});
	};

	const [columnDefs] = useState([
		{
			checkboxSelection: true,
			field: 'subject',
			headerName: 'Subject Line',
		},
		{
			field: 'sciCustomer',
			headerName: 'Customer',
		},
		{
			field: 'contactName',
			headerName: 'Customer Name',
		},
		{
			field: 'from',
			headerName: 'From',
		},
		{
			field: 'recipients',
			headerName: 'To',
		},
		{
			field: 'sciSalesRep',
			headerName: 'Sales Rep',
		},
		{
			field: 'receivedDateTime',
			headerName: 'Received Date',
			filterParams: dateFilterParams,
			minWidth: 190,
			sort: 'desc',
			cellRenderer: (p) => (
				<>
					{p.value
						? moment
								.utc(p.value)
								.tz('Asia/Singapore')
								.format('MMM-DD-YYYY hh:mm A')
						: '--'}
				</>
			),
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},

		{
			field: 'email',
			headerName: 'Email',
			floatingFilter: false,
			cellRenderer: (p) => {
				const trimmedString = p?.data?.webLink?.replace(/=+$/, '');
				return (
					<ToolTip title="Preview mail">
						<MdMail
							onClick={(e) => {
								if (e.ctrlKey && e.button === 0) {
									window.open(
										`${messageURl?.url}${trimmedString}%3D.eml`,
										'_blank'
									);
								} else {
									openDrawer(p.data?.webLink);
									setCellData(p);
								}
							}}
							style={{ fontSize: '16px', cursor: 'pointer' }}
						/>
					</ToolTip>
				);
			},
			cellStyle: { padding: '4px 0 0 25px' },
			maxWidth: 80,
		},
	]);

	const openModal = () => {
		setModalOpen(true);
		setParamsData(p?.data);
	};

	const closeModal = () => {
		setModalOpen(false);
		setCustomerProspectData([]);
		setRowSelectionData([]);
		setParamsData(null);
		setSearchValue('');
	};

	const handleSearchValue = () => {
		if (searchValue?.length > 2) {
			setCustomerProspectLoading(true);
			axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=findmail&keyword=${encodeURIComponent(
						Base64.encode(searchValue)
					)}&acctype=5&pageno=1&rowsperpage=1000`
				)
				.then((response) => {
					setCustomerProspectData(response.data);
					setCustomerProspectError(null);
				})
				.catch((err) => {
					setCustomerProspectError(err);
					setCustomerProspectData(null);
					setCustomerProspectLoading(false);
				})
				.finally(() => {
					setCustomerProspectLoading(false);
				});
		}
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);

	const onSelectionChanged = (p) => {
		setRowSelectionData(p.api.getSelectedRows());
	};

	const addtoRfqQueue = () => {
		const rowData = { ...rowSelectionData[0] };

		const formFields = {
			...paramsData,
			email: userMail,
			accountName: paramsData?.accountName
				? paramsData?.accountName
				: rowData?.sciCustomer === null
				? ''
				: rowData?.sciCustomer,
			customerProspect: paramsData?.customerProspect
				? paramsData?.customerProspect
				: crmAccCusSupp?.filter(
						(el) => rowData?.sciCustomer === el?.ACCOUNT_NAME
				  )?.[0]?.ID,
			cp_customer: rowData?.sciCustomer,
			cp_customer_mail: rowData?.from,
			cp_received_date: rowData?.receivedDateTime,
			cp_rfq_email: rowData?.webLink,
			cp_rfq_id: rowData?.id,
			cp_salesRep_mail: rowData?.recipients,
			cp_subject_line: rowData?.subject,
			smartId: '',
		};

		setAddingMail(true);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=updatempnque`,
				JSON.stringify(formFields)
			)
			.then(() => {
				axios
					.get(
						`https://internal.supremecomponents.com/api/externalgateway.php?route=getmpnquebyid&id=${paramsData?.id}&email=${userMail}`
					)
					.then((response) => {
						setMpnQueueList((prevData) => {
							const updatedData = [
								...prevData.filter(
									(item) => item.id !== response?.data?.data?.[0].id
								),
								response?.data?.data?.[0],
							];

							return filterUniqueIdsAndRecent(updatedData);
						});
						setParamsData(null);
					})
					.then(() => {
						message.open({
							type: 'success',
							content: 'RFQ updated successfully',
						});
						setAddingMail(false);
						const column = p.column.colDef.field;
						setTimeout(() => {
							p.api.refreshCells({
								force: true,
								columns: [column],
								rowNodes: [p.node],
							});
							p?.api?.tabToNextCell();
						}, 500);
					});
			})
			.catch((err) => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				setAddingMail(false);
			});
	};

	return (
		<>
			{p?.data?.cp_subject_line ? (
				<div className={styles['data-wrapper']}>
					<MdEdit onClick={openModal} className={styles['edit-icon']} />
					<span className={styles['data']}>
						<ToolTip placement="bottomLeft" title={p?.data?.cp_subject_line}>
							{p?.data?.cp_subject_line}
						</ToolTip>
					</span>
				</div>
			) : (
				<p
					className={styles['blue-underline']}
					onClick={p?.data?.salesRepCrmId && openModal}
					onKeyDown={(e) => e.key === 'Enter' && openModal}
				>
					Insert
				</p>
			)}

			<Modal
				open={modalOpen}
				title={
					<>
						Add Email to the MPN Queue <Divider style={{ margin: '10px 0' }} />
					</>
				}
				onCancel={closeModal}
				width={900}
				destroyOnClose
			>
				<p className={styles['greeting-title']}>
					Hi {userData?.name || me?.givenName || me?.displayName}, please search
					for your customer/prospect email by entering in any part of the
					subject line or sender's email address in the search box below.
				</p>

				<NotificationBar
					message={
						<ul style={{ listStyle: 'disc', paddingLeft: '10px' }}>
							<li>
								If you don’t see your email. Please close this popup and
								refresh. It usually takes a minute for Eden to catch it.
							</li>
							<li>
								Note: Eden can only retrieve emails from the past 10 days.
							</li>
						</ul>
					}
					wraningBar
				/>
				{searchValue?.length < 3 && (
					<NotificationBar
						redBar
						message="A minimum of 3 characters are required to view the results."
					/>
				)}

				<div className={styles['input-searchButton']}>
					<Input
						placeholder="Search for Subject Line or Sender's Email Address"
						onChange={(e) => setSearchValue(e.target.value)}
						onPressEnter={handleSearchValue}
						disabled={customerProspectLoading}
					/>
					<Button
						onClick={handleSearchValue}
						disabled={customerProspectLoading || searchValue?.length < 3}
					>
						Search <HiOutlineSearch className={styles['search-icon']} />
					</Button>
				</div>

				{customerProspectLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage height={60} width={60} message="Loading..." />
					</div>
				)}

				{customerProspectError && !customerProspectLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage
							style={{ width: '200px' }}
							messageStyle={{ fontSize: '14px' }}
							message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed."
						/>
					</div>
				)}

				{!isNotEmptyArray(customerProspectData) &&
					!customerProspectError &&
					!customerProspectLoading && (
						<div className={styles['no-data-wrapper']}>
							<NoData
								style={{ width: '200px' }}
								messageStyle={{ fontSize: '14px' }}
								message="Its all empty here. Search for Subject Line or Sender's Email Address"
							/>
						</div>
					)}

				{!customerProspectLoading &&
					!customerProspectError &&
					isNotEmptyArray(customerProspectData) && (
						<div className={`ag-theme-custom ${styles['table-wrapper']}`}>
							<AgGridReact
								columnDefs={columnDefs}
								rowData={customerProspectData}
								defaultColDef={defaultColDef}
								rowSelection={'single'}
								onSelectionChanged={onSelectionChanged}
								animateRows={true}
								pagination={true}
								paginationPageSize={10}
								paginationPageSizeSelector={false}
							/>
						</div>
					)}

				<div className={styles['actions']}>
					<Button type="bordered" onClick={closeModal}>
						Cancel
					</Button>
					<Button
						disabled={
							customerProspectLoading ||
							addingMail ||
							!isNotEmptyArray(customerProspectData) ||
							!rowSelectionData
						}
						loading={addingMail}
						onClick={addtoRfqQueue}
					>
						Add to RFQ Queue
					</Button>
				</div>
			</Modal>

			{drawerMail && (
				<Drawer
					title={
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<p className={styles['mail-subject']}>
								<ToolTip placement="bottomLeft" title={drawerMailData?.subject}>
									{drawerMailData?.subject}
								</ToolTip>
							</p>
							<Link
								style={{ textDecoration: 'none' }}
								target="_blank"
								to={`${messageURl?.url}${cellData?.data?.webLink?.replace(
									/=+$/,
									''
								)}%3D.eml`}
							>
								<Button type="bordered">Download Mail</Button>
							</Link>
						</div>
					}
					placement="right"
					onClose={closeDrawer}
					open={drawerMail}
					size={'large'}
				>
					{drawerMailLoading && <LoadingOutlined />}
					{drawerMailData && (
						<>
							<div className={styles['mail-details-wrapper']}>
								<div className={styles['mail-user-info']}>
									<Avatar
										className={styles['avatar']}
										style={{
											backgroundColor:
												avatarBgColor[drawerMailData?.fromName?.charAt(0)] ||
												avatarBgColor.default,
										}}
									>
										{drawerMailData?.fromName?.charAt(0)?.toUpperCase()}
									</Avatar>
									<div className={styles['users-info']}>
										<p className={styles['sender-name']}>
											{drawerMailData?.fromName}
										</p>
										{drawerMailData?.toRecipients && (
											<p className={styles['to']}>
												<span className={styles['title']}>To:</span>{' '}
												{drawerMailData?.toRecipients}
											</p>
										)}
										{drawerMailData?.ccRecipients && (
											<p className={styles['cc']}>
												<span className={styles['title']}>Cc:</span>{' '}
												{drawerMailData?.ccRecipients}
											</p>
										)}
										{drawerMailData?.bccRecipients && (
											<p className={styles['bcc']}>
												<span className={styles['title']}>Bcc:</span>{' '}
												{drawerMailData?.bccRecipients}
											</p>
										)}
									</div>
								</div>

								<p className={styles['date-info']}>
									{moment(drawerMailData?.receivedDateTime).format(
										'MMM-DD-YYYY hh:mm A'
									)}
								</p>
							</div>
							<div dangerouslySetInnerHTML={{ __html: drawerMailData?.html }} />
						</>
					)}
				</Drawer>
			)}
		</>
	);
};

export default CustomerProspect;
