import { useState, useEffect, useMemo, useContext, memo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { RiContactsFill } from 'react-icons/ri';
import axios from 'axios';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import { isNotEmptyArray, titleCase } from '../../utils';
import NoData from '../../components/nodata';
import InnerHeader from '../../components/innerHeader';
import Select from '../../components/select';
import EdenContext from '../../context/edenContext';
import { CountryCellRenderer } from '../../columns/countryCellRenderer';
import { COUNTRY_CODES } from '../../columns';

import styles from './customerContacts.module.scss';

const CustomerContacts = () => {
	const { userInfo, userType, userMail } = useContext(EdenContext);
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [gridApi, setGridApi] = useState(null);
	const [sr, setSr] = useState([]);
	const [filterSr, setFilterSr] = useState([]);
	const abortControllerRef = useRef(null);

	const [columnDefs] = useState([
		{
			field: 'SalesRep',
			headerName: 'SalesRep',
			cellRenderer: (p) => titleCase(p.value),
			filter: 'agSetColumnFilter',
			filterParams: {
				applyMiniFilterWhileTyping: true,
			},
		},
		{
			field: 'Account',
			headerName: 'Account',
		},
		{
			field: 'PrimaryCompanyType',
			headerName: 'Primary Company Type',
		},
		{
			field: 'AcctCountry',
			headerName: 'Acct Country',
			cellRenderer: memo(CountryCellRenderer),
			filter: 'agSetColumnFilter',
			filterParams: {
				cellRenderer: memo(CountryCellRenderer),
				cellRendererParams: { isFilterRenderer: true },
				applyMiniFilterWhileTyping: true,
			},
		},
		{
			field: 'ContactFirstName',
			headerName: 'Contact First Name',
		},
		{
			field: 'ContactEmail',
			headerName: 'Contact Email',
		},
		{
			field: 'ContactPhone',
			headerName: 'Contact Phone no.',
		},
		{
			field: 'JobTitle',
			headerName: 'Job Title',
		},
	]);

	const getData = () => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}
		abortControllerRef.current = new AbortController();
		const signal = abortControllerRef.current.signal;

		setIsLoading(true);

		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_allocated_accounts_contacts&is_admin=${
					userType === 'admin' || userType === 'ceo' ? true : false
				}&emails=${
					userType === 'sr'
						? userMail
						: userType === 'sm'
						? userInfo?.EmailFilters?.ViewAsSM
						: userType === 'ss'
						? userInfo?.EmailFilters?.ViewAsSS
						: userType === 'admin' || userType === 'ceo'
						? userInfo?.EmailFilters?.ViewAsAdmin
						: ''
				}&master_id=`,
				{ signal }
			)
			.then((response) => {
				setData(response.data?.data);
				setSr([...new Set(response.data?.data?.map((el) => el.SalesRep))]);
				setError(null);
			})
			.catch((err) => {
				if (axios.isCancel(err)) {
					console.log('Request canceled:', err.message);
				} else {
					setError(err);
					setData(null);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getData(); //eslint-disable-next-line
	}, [userType]);

	const onGridReady = (p) => {
		setGridApi(p.api);
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	const exportToExcel = () => {
		const customerContactParams = {
			sheetName: 'Customer Contact',
			fileName: 'Customer Contact.xlsx',
		};
		gridApi.exportDataAsExcel(customerContactParams);
	};

	const excelStyles = [
		{
			id: 'header',
			font: {
				bold: true,
			},
		},
	];

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
				setSr([]);
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	useEffect(() => {
		if (filterSr.length > 0 && gridApi) {
			const filterInstance = gridApi.getFilterInstance('SalesRep');

			if (filterInstance) {
				filterInstance.setModel({
					values: filterSr,
				});
				gridApi.onFilterChanged();
			}
		}
		if (filterSr.length === 0 && gridApi) {
			gridApi.setFilterModel(null);
			gridApi?.setQuickFilter('');
		}
	}, [filterSr, gridApi]);

	const context = useMemo(() => {
		return {
			COUNTRY_CODES: COUNTRY_CODES,
		};
	}, []);

	const dataWithFlags = data?.filter((d) => {
		return COUNTRY_CODES[d?.AcctCountry] === undefined
			? d
			: COUNTRY_CODES[d?.AcctCountry];
	});

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="My Customer Contacts"
				icon={<RiContactsFill style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo removeshortcutkeys title="My Customer Contacts" info="" />
			<InnerHeader exportToExcel={exportToExcel} />
			<div className={styles['customer-contacts']}>
				<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
					{userType !== 'sr' && (
						<Select
							disabled={isLoading}
							title="Select Sales Rep"
							style={{ minWidth: '200px' }}
							multiple
							options={sr?.map((el) => ({
								value: el,
								label: el,
							}))}
							popupMatchSelectWidth
							maxTagCount={4}
							placeholder="Select Sales Rep"
							onChange={(value) => setFilterSr(value)}
							value={filterSr}
						/>
					)}
				</div>

				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(data) && !error && !isLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here. Select a date range." />
					</div>
				)}

				{!isLoading && !error && isNotEmptyArray(data) && (
					<div className={styles['table-wrapper']}>
						<div className={`ag-theme-custom ${styles['table']} `}>
							<AgGridReact
								onGridReady={onGridReady}
								rowData={dataWithFlags}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								animateRows={true}
								pagination={true}
								paginationPageSizeSelector={false}
								excelStyles={excelStyles}
								context={context}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default CustomerContacts;
