import { Checkbox as AntdCheckbox } from 'antd';

import styles from './checkBox.module.scss';

const CheckBox = ({ children, onChange, disabled, checked }) => {
	return (
		<div className={styles['wrapper']}>
			<AntdCheckbox
				className={styles['checkbox']}
				onChange={onChange}
				disabled={disabled}
				checked={checked}
			>
				{children}
			</AntdCheckbox>
		</div>
	);
};

export default CheckBox;
