import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Col, Divider, Form, Radio, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import Modal from '../../../../components/modal';
import ToolTip from '../../../../components/tooltip';
import Select from '../../../../components/select';
import Button from '../../../../components/button';
import EdenContext from '../../../../context/edenContext';

import styles from '../epAccTags.module.scss';

const AddTagForm = ({ open, onCancel, onFinish, form, initialValues }) => {
	const { crmAccCusSupp } = useContext(EdenContext);

	const applyAtAccountLevel = Form.useWatch('applied_at_account_level', form);
	const crmAccount = Form.useWatch('crm_account', form);

	const [keywordTagValue, setKeywordTagValue] = useState('');
	const [crmAccountContact, setCrmAccountContact] = useState([]);

	const crm = [keywordTagValue, ...crmAccountContact];

	const rfqLocation = [
		{
			value: 'Attachment',
			label: 'Attachment',
		},
		{
			value: 'Body',
			label: 'Body',
		},
	];

	const crmAccountContactData = () => {
		if (crmAccount) {
			axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=get_emails_by_account&account=${crmAccount}`
				)
				.then((response) => {
					setCrmAccountContact(response.data);
				});
		}
	};

	useEffect(() => {
		crmAccountContactData(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crmAccount]);

	return (
		<Modal
			title={
				<>
					<p>Add Tags</p> <Divider style={{ margin: '5px 0' }} />
				</>
			}
			open={open}
			onCancel={onCancel}
			destroyOnClose
			width={700}
		>
			<Form
				name="add-tags"
				form={form}
				onFinish={onFinish}
				validateTrigger={['onBlur', 'onChange']}
				labelCol={{ span: 24 }}
				requiredMark={false}
				initialValues={initialValues}
			>
				<Row gutter={16}>
					<Col sm={24} md={12}>
						<Form.Item
							label="CRM Account"
							name="crm_account"
							rules={[
								{
									required: true,
									message: 'Please select CRM Account!',
								},
							]}
						>
							<Select
								showSearch
								placeholder="Select CRM Account"
								type="bordered"
								options={crmAccCusSupp?.map((el) => ({
									value: el?.ACCOUNT_NAME,
									label: el?.ACCOUNT_NAME,
								}))}
							/>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item
							label={
								<div className={styles['form-label']}>
									Apply rule at account level?
									<ToolTip title="Do you want to apply the following configuration to all associated contacts for this account?">
										<InfoCircleOutlined className={styles['icon']} />
									</ToolTip>
								</div>
							}
							name="applied_at_account_level"
							rules={[
								{
									required: true,
									message: 'Please select Account level!',
								},
							]}
						>
							<Radio.Group>
								<Radio value="Yes">Yes</Radio>
								<Radio value="No">No</Radio>
							</Radio.Group>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item
							label="Contact Email"
							name="contact_email"
							rules={[
								{
									required: applyAtAccountLevel === 'No',
									message: 'Please select Contact Email!',
								},
							]}
						>
							<Select
								showSearch
								placeholder="Select Contact Email"
								type="bordered"
								options={crm?.map((el) => ({
									value: el,
									label: el,
								}))}
								disabled={applyAtAccountLevel === 'Yes'}
								multiple
								maxTagCount="responsive"
								onSearch={(value) => setKeywordTagValue(value)}
							/>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item
							label="Data Location"
							name="rfq_location"
							rules={[
								{
									required: true,
									message: 'Please select Data Location!',
								},
							]}
						>
							<Select
								placeholder="Select Data Location"
								type="bordered"
								options={rfqLocation}
							/>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item
							label="MPN Header Tags"
							name="mpn_header_tags"
							rules={[
								{
									required: true,
									message: 'Please enter MPN Header Tags!',
								},
								() => ({
									validator(_, value) {
										if (value?.length > 5) {
											return Promise.reject(
												new Error('Only 5 tags are allowed!')
											);
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<Select
								multiple
								type="bordered"
								placeholder="Enter MPN Header Tags"
								options={[
									{
										value: keywordTagValue,
										label: keywordTagValue,
									},
								]}
								onSearch={(value) => setKeywordTagValue(value)}
							/>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item
							label="Brand Header Name Tags"
							name="brand_header_name_tags"
							rules={[
								{
									required: true,
									message: 'Please enter Brand Header Name Tags!',
								},
								() => ({
									validator(_, value) {
										if (value?.length > 5) {
											return Promise.reject(
												new Error('Only 5 tags are allowed!')
											);
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<Select
								multiple
								type="bordered"
								placeholder="Enter Brand Header Name Tags"
								options={[
									{
										value: keywordTagValue,
										label: keywordTagValue,
									},
								]}
								onSearch={(value) => setKeywordTagValue(value)}
							/>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item
							label="QTY Header Tags"
							name="qty_header_tags"
							// rules={[
							// 	{
							// 		required: true,
							// 		message: 'Please enter QTY Header Tags!',
							// 	},
							// 	() => ({
							// 		validator(_, value) {
							// 			if (value?.length > 5) {
							// 				return Promise.reject(
							// 					new Error('Only 5 tags are allowed!')
							// 				);
							// 			}
							// 			return Promise.resolve();
							// 		},
							// 	}),
							// ]}
						>
							<Select
								multiple
								type="bordered"
								placeholder="Enter QTY Header Tags"
								options={[
									{
										value: keywordTagValue,
										label: keywordTagValue,
									},
								]}
								onSearch={(value) => setKeywordTagValue(value)}
							/>
						</Form.Item>
					</Col>
					{/* hi there */}
					<Col sm={24} md={12}>
						<Form.Item label="DC Header Tags" name="dc_header_tags">
							<Select
								multiple
								type="bordered"
								placeholder="Enter DC Header Tags"
								options={[
									{
										value: keywordTagValue,
										label: keywordTagValue,
									},
								]}
								onSearch={(value) => setKeywordTagValue(value)}
							/>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item label="LT Header Tags" name="lt_header_tags">
							<Select
								multiple
								type="bordered"
								placeholder="Enter LT Header Tags"
								options={[
									{
										value: keywordTagValue,
										label: keywordTagValue,
									},
								]}
								onSearch={(value) => setKeywordTagValue(value)}
							/>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item label="PRC Header Tags" name="prc_header_tags">
							<Select
								multiple
								type="bordered"
								placeholder="Enter PRC Header Tags"
								options={[
									{
										value: keywordTagValue,
										label: keywordTagValue,
									},
								]}
								onSearch={(value) => setKeywordTagValue(value)}
							/>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item label="MOQ Header Tags" name="moq_header_tags">
							<Select
								multiple
								type="bordered"
								placeholder="Enter MOQ Header Tags"
								options={[
									{
										value: keywordTagValue,
										label: keywordTagValue,
									},
								]}
								onSearch={(value) => setKeywordTagValue(value)}
							/>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item label="SPQ Header Tags" name="spq_header_tags">
							<Select
								multiple
								type="bordered"
								placeholder="Enter SPQ Header Tags"
								options={[
									{
										value: keywordTagValue,
										label: keywordTagValue,
									},
								]}
								onSearch={(value) => setKeywordTagValue(value)}
							/>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item label="PKG Header Tags" name="pkg_header_tags">
							<Select
								multiple
								type="bordered"
								placeholder="Enter PKG Header Tags"
								options={[
									{
										value: keywordTagValue,
										label: keywordTagValue,
									},
								]}
								onSearch={(value) => setKeywordTagValue(value)}
							/>
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Form.Item style={{ marginBottom: '0px' }}>
							<div className={styles['actions']}>
								<Button type="bordered" onClick={onCancel}>
									Cancel
								</Button>
								<Button htmlType="submit">Add Tags</Button>
							</div>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default AddTagForm;
