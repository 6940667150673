import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { MdManageAccounts } from 'react-icons/md';
import moment from 'moment';
import { message } from 'antd';

import ErrorMessage from '../../../components/errorMessage';
import LoadingMessage from '../../../components/loaderMessage';
import NoData from '../../../components/nodata';
import { isNotEmptyArray } from '../../../utils';
import BreadCrumb from '../../../components/breadcrumb';
import SectionInfo from '../../../components/sectionInfo';
import Button from '../../../components/button';
import { dateFilterParams } from '../../../filters';

import styles from './srAccountsSummary.module.scss';

// Custom comparator for sorting filter values
const customComparator = (a, b) => {
	const specialOrder = [
		'(Select All)',
		'(Blanks)',
		'Today',
		'Tomorrow',
		'Expired',
	];
	const aIndex = specialOrder.indexOf(a);
	const bIndex = specialOrder.indexOf(b);

	if (aIndex > -1 && bIndex > -1) {
		return aIndex - bIndex;
	} else if (aIndex > -1) {
		return -1;
	} else if (bIndex > -1) {
		return 1;
	}

	const aDays = parseInt(a?.split(' ')[0]);
	const bDays = parseInt(b?.split(' ')[0]);

	return aDays - bDays;
};

const labelFormatter = (value) => {
	if (value === 0) {
		return 'Today';
	} else if (value === 1) {
		return '1 Day';
	} else if (value > 1) {
		return `${value} Days`;
	} else if (value === -1) {
		return 'Tomorrow';
	} else if (value < -1) {
		return `${Math.abs(value)} Days Ahead`;
	}
	return value;
};

const SRAccountsSummary = () => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [selectedRowValue, setSelectedRowValue] = useState([]);
	const [allocatingAcc, setAllocatingAcc] = useState(false);
	const [expiry, setExpiry] = useState(false);
	const [graduate, setGraduate] = useState(false);
	const [gridApi, setGridApi] = useState(null);
	const [columnDefs] = useState([
		{
			field: 'SALES_REP',
			headerName: 'Sales Rep',
			showDisabledCheckboxes: true,
			checkboxSelection: true,
			minWidth: 150,
		},
		{ field: 'ACCOUNT_NAME', headerName: 'Account' },
		{
			field: 'CREATED_DATE',
			headerName: 'Created Date',
			minWidth: 170,
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			cellRenderer: (p) => p.value && moment(p.value).format('MMM-DD-YYYY'),
		},
		{
			field: 'SWITCHOVER_DATE',
			headerName: 'Switchover Date',
			minWidth: 170,
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			cellRenderer: (p) => p.value && moment(p.value).format('MMM-DD-YYYY'),
		},
		{
			field: 'lastBilledDate',
			headerName: 'Last Billed Date​',
			minWidth: 170,
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			cellRenderer: (p) => p.value && moment(p.value).format('MMM-DD-YYYY'),
		},
		{
			field: 'lastBilledSalesRep',
			headerName: 'Last Billed Sales Rep',
			minWidth: 120,
		},
		{
			field: 'EXPIRY_DATE',
			headerName: 'Expiry Date',
			minWidth: 170,
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			cellRenderer: (p) => p.value && moment(p.value).format('MMM-DD-YYYY'),
		},
		{
			field: 'EXPIRY_DATE_label',
			headerName: '# of Days left',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 100,
			filter: 'agSetColumnFilter',
			cellRenderer: (params) => {
				return labelFormatter(params.value);
			},
			filterParams: {
				comparator: customComparator,
				valueFormatter: (params) => labelFormatter(params.value),
			},
		},
		{
			field: 'LAST_INBOUND_EMAIL',
			headerName: 'Last Inbound Email to SR',
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			cellRenderer: (p) =>
				p.value && moment(p.value).format('MMM-DD-YYYY hh:mm A'),
		},
		{ field: 'IN_PROBATION', headerName: 'In Probation Y/N?' },
	]);

	const SINGAPORE_TZ = 'Asia/Singapore';

	const preprocessData = (data, dateFields) => {
		return data.map((row) => {
			const newRow = { ...row };
			dateFields.forEach((field) => {
				if (row[field]) {
					const dateValue = moment.tz(row[field], SINGAPORE_TZ).startOf('day');
					const today = moment.tz(SINGAPORE_TZ).startOf('day');
					const openDay = dateValue.diff(today, 'days'); // Remove Math.abs()

					// If the date has passed, set the label to null (or "Expired")
					newRow[`${field}_label`] = openDay >= 0 ? openDay : 'Expired';
				} else {
					newRow[`${field}_label`] = null;
				}
			});
			return newRow;
		});
	};

	const getData = () => {
		setIsLoading(true);

		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_all_allocations`
			)
			.then((response) => {
				setData(preprocessData(response.data, ['EXPIRY_DATE']));
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getData(); //eslint-disable-next-line
	}, []);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	const onSelectionChanged = (e) => {
		setSelectedRowValue(e.api.getSelectedRows());
	};

	const allocateAccount = () => {
		setAllocatingAcc(true);

		axios.get(
			`https://internal.supremecomponents.com/api/externalgateway.php?route=get_all_allocations_generate`
		);

		const promises = selectedRowValue.map((el, index, arr) =>
			axios.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=release_account_to_house&email=${el?.SALES_REP_EMAIL}&account_id=${el?.ACCOUNT_ID}&master_id=${el?.MASTER_ID}`
			)
		);
		Promise.all(promises)
			.then(() => {
				message.success(`Congratulations, account has been released to house`);
				getData();
			})
			.catch((error) => {
				message.error('Something went wrong please try again later');
			})
			.finally(() => {
				setAllocatingAcc(false);
				setSelectedRowValue([]);
			});
	};

	const graduateToPostProb = () => {
		setGraduate(true);

		const promises = selectedRowValue.map((el, index, arr) =>
			axios.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=bypass_probation&account_id=${el?.ACCOUNT_ID}&sales_rep_id=${el?.MASTER_ID}&sales_rep_email=${el?.SALES_REP_EMAIL}&account_name=${el?.ACCOUNT_NAME}&sales_rep_name=${el?.SALES_REP}`
			)
		);
		Promise.all(promises)
			.then(() => {
				message.success(
					`Congratulations, ${selectedRowValue[0]?.ACCOUNT_NAME} has been graduated to post probation status`
				);
				getData();
			})
			.catch((error) => {
				message.error('Something went wrong please try again later');
			})
			.finally(() => {
				setGraduate(false);
				setSelectedRowValue([]);
			});
	};

	const extendExpiry = () => {
		setExpiry(true);
		const promises = selectedRowValue.map((el, index, arr) =>
			axios.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=extend_expiry&account_id=${el?.ACCOUNT_ID}&sales_rep_id=${el?.MASTER_ID}`
			)
		);

		Promise.all(promises)
			.then(() => {
				message.success(`Congratulations, expiry date has been extended`);
				getData();
			})
			.catch((error) => {
				message.error('Something went wrong please try again later');
			})
			.finally(() => {
				setExpiry(false);
				setSelectedRowValue([]);
			});
	};

	// const isRowSelectable = useCallback((params) => {
	// 	return !!params.data && params.data.IN_PROBATION === 'Yes';
	// }, []);

	const onGridReady = (e) => {
		setGridApi(e.api);
	};

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="SR Accounts Summary"
				icon={<MdManageAccounts style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo removeshortcutkeys title="SR Accounts Summary" info="" />
			<div className={styles['account-allocation-log']}>
				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(data) && !error && !isLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here" />
					</div>
				)}

				{!isLoading && !error && isNotEmptyArray(data) && (
					<div className={styles['table-wrapper']}>
						<div className={styles['buttons']}>
							<Button
								color={allocatingAcc ? 'dark' : 'red'}
								onClick={allocateAccount}
								loading={allocatingAcc}
								disabled={
									allocatingAcc ||
									expiry ||
									graduate ||
									selectedRowValue?.length <= 0
								}
							>
								{allocatingAcc
									? 'Releasing to House Accounts... Please Wait'
									: 'Release to the House Accounts List​'}
							</Button>
							<Button
								onClick={graduateToPostProb}
								loading={graduate}
								disabled={
									allocatingAcc ||
									expiry ||
									graduate ||
									selectedRowValue?.length <= 0 ||
									selectedRowValue?.length > 1
								}
							>
								Graduate to Post-Probation
							</Button>
							<Button
								onClick={extendExpiry}
								loading={expiry}
								disabled={
									allocatingAcc ||
									expiry ||
									graduate ||
									selectedRowValue?.length <= 0
								}
							>
								Extend Expiry Date by 6 months
							</Button>
						</div>
						<div className={`ag-theme-custom ${styles['table']} `}>
							<AgGridReact
								rowData={data}
								onGridReady={onGridReady}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								groupDisplayType={'multipleColumns'}
								rowSelection={'multiple'}
								suppressRowClickSelection={true}
								rowMultiSelectWithClick={true}
								onSelectionChanged={onSelectionChanged}
								rowGroupPanelShow="always"
								animateRows={true}
								pagination={true}
								paginationPageSizeSelector={false}
								// isRowSelectable={isRowSelectable}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SRAccountsSummary;
