import { useState } from 'react';
import { IoMdClose } from 'react-icons/io';

import styles from './tableTooltipNotification.module.scss';

const TabelToolTipNotification = ({ title, showNotificationLeft }) => {
	const [notification, setNotification] = useState(true);

	setTimeout(() => {
		setNotification(false);
	}, 10000);

	return (
		<>
			{notification &&
				(showNotificationLeft ? (
					<div className={styles['left-wrapper']}>
						<p className={styles['title']}>{title}</p>
						<IoMdClose
							className={styles['close']}
							onClick={() => setNotification(false)}
						/>
					</div>
				) : (
					<div className={styles['wrapper']}>
						<IoMdClose
							className={styles['close']}
							onClick={() => setNotification(false)}
						/>
						<p className={styles['title']}>{title}</p>
					</div>
				))}
		</>
	);
};

export default TabelToolTipNotification;
