import axios from 'axios';
import { useContext, useState } from 'react';
import { Upload, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MdEdit } from 'react-icons/md';

import EdenContext from '../../../../context/edenContext';
import { filterUniqueIdsAndRecent } from '../../../../utils';
import MpnQueueContext from '../../../../context/mpnQueueContext';

import styles from './cfFile.module.scss';

const CFfileActions = (p) => {
	const { userMail } = useContext(EdenContext);
	const { setMpnQueueList } = useContext(MpnQueueContext);
	const [uploadingExcel, setUploadingExcel] = useState(false);

	const uploadExcel = (file) => {
		const formData = new FormData();
		formData.append('atchmnt', file);
		setUploadingExcel(true);
		axios
			.post(
				'https://internal.supremecomponents.com/api/awsbucket/pipe.php',
				formData
			)
			.then((response) => {
				if (response?.data?.access_url) {
					const formFields = {
						...p?.data,
						email: userMail,
						webLink: response?.data?.access_url,
						cfFile: response?.data?.file_name,
						updateCounter:
							p?.data?.noOfLines > 0 ? p?.data?.updateCounter + 1 : 0,
						smartId: '',
					};

					axios
						.post(
							`https://internal.supremecomponents.com/api/externalgateway.php?route=updatempnque`,
							JSON.stringify(formFields)
						)
						.then(() => {
							axios
								.get(
									`https://internal.supremecomponents.com/api/externalgateway.php?route=getmpnquebyid&id=${p?.data?.id}&email=${userMail}`
								)
								.then((response) => {
									setMpnQueueList((prevData) => {
										const updatedData = [
											...prevData.filter(
												(item) => item.id !== response?.data?.data?.[0].id
											),
											response?.data?.data?.[0],
										];

										return filterUniqueIdsAndRecent(updatedData);
									});
								})
								.then(() => {
									message.open({
										type: 'success',
										content: 'RFQ updated successfully',
									});
									setTimeout(() => {
										const rowIndex = p.rowIndex;
										const colKey = p.column.colId;
										p?.api?.setFocusedCell(rowIndex, colKey);
										const focusedCell = document.querySelector(
											'.ag-row-focus .ag-cell-focus'
										);
										if (focusedCell) {
											focusedCell.setAttribute('tabindex', '0');
										}
									}, 100);
								});
						})
						.catch((err) => {
							message.open({
								type: 'error',
								content: 'Something went wrong please try again later!',
							});
						});
				}
			})
			.catch((err) => {
				message.open({
					type: 'error',
					content: 'File upload failed',
				});
			})
			.finally(() => {
				setUploadingExcel(false);
			});
	};

	const props = {
		disabled: !p?.data?.salesRepCrmId,
		name: 'file',
		action: '',
		fileList: [],
		accept: '.xlsx',
		showUploadList: false,
		maxCount: 1,
		beforeUpload: (file) => {
			const isExcel =
				file.type ===
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
			if (!isExcel) {
				message.error('You can only upload Excel file!');
				return false;
			}
			if (isExcel) {
				uploadExcel(file);
			}
		},
		customRequest: () => null,
	};

	const downloadFile = () => {
		if (p?.data?.webLink) {
			const aTag = document.createElement('a');
			aTag.href = p?.data?.webLink;
			aTag.download = p?.data?.cfFile;
			aTag.target = '_blank';
			document.body.appendChild(aTag);
			aTag.click();
			document.body.removeChild(aTag);
		}
	};

	return (
		<div className={styles['wrapper']}>
			{uploadingExcel && (
				<div className={styles['uploading-spinner']}>
					<LoadingOutlined />
				</div>
			)}
			{p?.data?.webLink ? (
				<div className={styles['data-wrapper']}>
					<Upload {...props}>
						<MdEdit className={styles['edit-icon']} />
					</Upload>
					<p onClick={downloadFile} className={styles['blue-underline']}>
						{p?.data?.cfFile}
					</p>
				</div>
			) : (
				<>
					{!uploadingExcel && (
						<Upload {...props}>
							<p
								className={styles['blue-underline']}
								style={{ paddingLeft: '20px' }}
							>
								Upload
							</p>
						</Upload>
					)}
				</>
			)}
		</div>
	);
};

export default CFfileActions;
