import { createContext, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

const HelpDeskTrackerContext = createContext();

export const HelpDeskTrackerProvider = ({ children }) => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cascaderOption, setCascaderOption] = useState('');
	const [ticketsView, setTicketsView] = useState('OPEN');
	const [mailError, setMailError] = useState(false);

	const SINGAPORE_TZ = 'Asia/Singapore';

	const preprocessData = (data, dateFields) => {
		return data.map((row) => {
			const newRow = { ...row };
			dateFields.forEach((field) => {
				if (row[field]) {
					const dateValue = moment.tz(row[field], SINGAPORE_TZ).startOf('day');
					const today = moment.tz(SINGAPORE_TZ).startOf('day');
					const openDay = today.diff(dateValue, 'days');

					newRow[`${field}_label`] = openDay;
				} else {
					newRow[`${field}_label`] = null;
				}
			});
			return newRow;
		});
	};

	const getData = () => {
		setIsLoading(true);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_log_tickets&employee_id=${
					cascaderOption ? cascaderOption : ''
				}&status=${ticketsView}`
			)
			.then((response) => {
				setData(preprocessData(response.data?.data, ['CREATED_AT']));
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<HelpDeskTrackerContext.Provider
			value={{
				data,
				setData,
				isLoading,
				error,
				getData,
				cascaderOption,
				setCascaderOption,
				ticketsView,
				setTicketsView,
				mailError,
				setMailError,
			}}
		>
			{children}
		</HelpDeskTrackerContext.Provider>
	);
};

export default HelpDeskTrackerContext;
