import React, { useState } from 'react';
import { Divider } from 'antd';

import ToolTip from '../../../components/tooltip';
import Modal from '../../../components/modal';

import styles from './mpnDetails.module.scss';

const MPNDetails = (p) => {
	const [modal, setModal] = useState(false);

	const openModal = () => {
		setModal(true);
	};
	const closeModal = () => {
		setModal(false);
	};

	return (
		<>
			{p.value > 0 ? (
				<>
					<span
						onClick={openModal}
						style={{
							cursor: 'pointer',
							color: '#0086D2',
							fontWeight: 'bold',
						}}
					>
						<ToolTip title="Click here to see alternate parts (FFF & Functional Equivalents) for this MPN">
							{p.value}
						</ToolTip>
					</span>{' '}
					<Modal
						title={
							<>
								<p>#Alternate Parts (FFF & Functional Equivalents)</p>
								<Divider
									style={{
										margin: '5px 0',
										borderBlockStart: '1px solid rgb(216, 216, 216)',
									}}
								/>
							</>
						}
						open={modal}
						onCancel={closeModal}
						destroyOnClose
						width={420}
					>
						<table className={styles['table']}>
							<thead>
								<th className={styles['th']}>S No.</th>
								<th className={styles['th']}>MPN</th>
								<th className={styles['th']}>Qty</th>
							</thead>
							<tbody>
								{p.data?.ALTERNATES?.data?.map((el, index) => (
									<tr key={index}>
										<td className={styles['td']}>{index + 1}</td>
										<td className={styles['td']}>{el?.mpn}</td>
										<td className={styles['td']}>
											{el?.stock?.toLocaleString('en-US')}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</Modal>
				</>
			) : null}
		</>
	);
};

export default MPNDetails;
