import { useContext, useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';

import EdenContext from '../../../../../context/edenContext';
import { filterUniqueIdsAndRecent } from '../../../../../utils';

const FcExtPrice = (p) => {
	const {
		userMail,
		setMpnCostBookList,
		mpnCostBookList,
		costBookType,
		MCBlastUserActiviry,
	} = useContext(EdenContext);

	const [loading, setLoading] = useState(false);

	const getFcExtPrice = () => {
		if (p.data?.reqMPN && p.data?.reqBrand && p.data?.reqQtyReq) {
			setLoading(true);
			axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=search_fc&mpn=${p.data?.reqMPN}&brand=${p.data?.reqBrand}&qty=${p.data?.reqQtyReq}`
				)
				.then((response) => {
					const fieldData = {
						...p.data,
						userEmail: userMail,
						reqExtPrice: response?.data ? response?.data?.edenExtPrice : '',
						othOrderReceived:
							p.data?.othOrderReceived === 'Yes' ||
							p.data?.othOrderReceived === true
								? true
								: p.data?.othOrderReceived === 'No' ||
								  p.data?.othOrderReceived === false
								? false
								: null,
					};
					axios
						.post(
							`https://internal.supremecomponents.com/api/externalgateway.php?route=updatempncostbook`,
							JSON.stringify(fieldData)
						)
						.then(() => {
							axios
								.get(
									`https://internal.supremecomponents.com/api/externalgateway.php?route=getmpncostbookbyid&id=${p.data?.id}&isbuy=${costBookType}&userEmail=${userMail}&lastUserActivity=${MCBlastUserActiviry}`
								)
								.then((response) => {
									setMpnCostBookList(
										filterUniqueIdsAndRecent([
											...mpnCostBookList,
											response?.data?.[0],
										])
									);
								})
								.then(() => {
									message.success('MPN updated successfully');
									setLoading(false);
									const column = p.column.colDef.field;
									p.column.colDef.cellStyle = { 'background-color': 'cyan' };
									p.api.refreshCells({
										force: true,
										columns: [column],
										rowNodes: [p.node],
									});
									// setTimeout(() => {
									// 	const rowIndex = p.rowIndex;
									// 	const colKey = p.column.colId;
									// 	p?.api?.setFocusedCell(rowIndex, colKey);
									// 	const focusedCell = document.querySelector(
									// 		'.ag-row-focus .ag-cell-focus'
									// 	);
									// 	if (focusedCell) {
									// 		focusedCell.setAttribute('tabindex', '0');
									// 	}
									// }, 100);
								})
								.finally(() => {
									setTimeout(() => {
										p?.api?.tabToNextCell();
									}, 200);
								});
						});
				});
		} else {
			message.error('MPN, Brand and Qty is required to get the FC Ext $');
		}
	};

	return (
		<div>
			{loading ? (
				<LoadingOutlined />
			) : (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						height: '25px',
						gap: '5px',
					}}
				>
					{p.value ? '$' + p.value?.toLocaleString('en-US') : 0}
					<ReloadOutlined
						style={{ color: 'blue', fontSize: '14px', cursor: 'pointer' }}
						onClick={getFcExtPrice}
						onKeyDown={(e) => e.key === 'Enter' && getFcExtPrice()}
						tabIndex={0}
					/>
				</div>
			)}
		</div>
	);
};

export default FcExtPrice;
