import { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { MdManageAccounts } from 'react-icons/md';

import ErrorMessage from '../../../components/errorMessage';
import LoadingMessage from '../../../components/loaderMessage';
import NoData from '../../../components/nodata';
import { isNotEmptyArray } from '../../../utils';
import BreadCrumb from '../../../components/breadcrumb';
import SectionInfo from '../../../components/sectionInfo';
import { dateFilterParams } from '../../../filters';

import styles from './accountAllocationLog.module.scss';

const AccountAllocationLog = () => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [columnDefs] = useState([
		{ field: 'ACCOUNT_NAME', headerName: 'Account' },
		{ field: 'FIELD', headerName: 'Field' },
		{
			field: 'LOG_DATE',
			headerName: 'Log Date',
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			cellRenderer: (p) => p.value && moment(p.value).format('MMM-DD-YYYY'),
		},
		{ field: 'SALES_REPRESENTATIVE', headerName: 'Sales Rep' },
	]);

	const getData = () => {
		setIsLoading(true);

		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_account_allocation_log`
			)
			.then((response) => {
				setData(response.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getData();
	}, []);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
		}),
		[]
	);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="Account Allocation Log"
				icon={<MdManageAccounts style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo removeshortcutkeys title="Account Allocation Log" info="" />
			<div className={styles['account-allocation-log']}>
				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(data) && !error && !isLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here. Select a date range." />
					</div>
				)}

				{!isLoading && !error && isNotEmptyArray(data) && (
					<div className={styles['table-wrapper']}>
						<div className={`ag-theme-custom ${styles['table']} `}>
							<AgGridReact
								rowData={data}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								animateRows={true}
								pagination={true}
								paginationPageSizeSelector={false}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default AccountAllocationLog;
