import { createContext, useContext, useState } from 'react';
import axios from 'axios';

import EdenContext from '../edenContext';
import { COUNTRY_CODES } from '../../columns';

const AssignToHouseContext = createContext();

export const AssignToHouseProvider = ({ children }) => {
	const { userMail, userInfo } = useContext(EdenContext);

	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const getData = () => {
		setIsLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_allocated_accounts&email=${userMail}&master_id=${userInfo?.ViewAsLoggedinUser?.MasterId}`
			)
			.then((response) => {
				setData(response.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const uniqueRoundedValues = new Set();

	const processRowData = data?.data
		?.filter((row) => COUNTRY_CODES[row?.Country] !== undefined)
		?.map((row) => {
			const sixMonthBillingsValue = Math.round(row.sixMonthBillings);
			const fiveYearBillingsValue = Math.round(row.fiveYearBillings);
			uniqueRoundedValues.add(sixMonthBillingsValue);
			uniqueRoundedValues.add(fiveYearBillingsValue);
			return {
				...row,
				PastSixMonthBilling: sixMonthBillingsValue,
				PastFiveYearBilling: fiveYearBillingsValue,
			};
		});

	return (
		<AssignToHouseContext.Provider
			value={{
				data,
				isLoading,
				error,
				getData,
				processRowData,
			}}
		>
			{children}
		</AssignToHouseContext.Provider>
	);
};

export default AssignToHouseContext;
