import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { FcInTransit } from 'react-icons/fc';
import { Link } from 'react-router-dom';

import SectionInfo from '../../components/sectionInfo';
import BreadCrumb from '../../components/breadcrumb';
import Button from '../../components/button';
import ToolTip from '../../components/tooltip';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import { isNotEmptyArray } from '../../utils';
import NoData from '../../components/nodata';
import InnerHeader from '../../components/innerHeader';
import EdenContext from '../../context/edenContext';
import FreightContext from '../../context/freightContext';
import Actions from './Actions';

import styles from './freightTable.module.scss';

const FreightTable = () => {
	const { userType } = useContext(EdenContext);
	const { getData, data, setData, isLoading, error, onCellValueChanged } =
		useContext(FreightContext);

	const [showBorders, setShowBorders] = useState(false);
	const [gridApi, setGridApi] = useState();

	useEffect(() => {
		getData(); //eslint-disable-next-line
	}, []);

	const onGridReady = (p) => {
		setGridApi(p.api);
	};

	const columnDefs = [
		{ field: 'currentTime', hide: true, sort: 'desc' },
		{ field: 'ID', sort: 'desc', hide: true },
		{
			field: 'actions',
			headerName: 'Actions',
			maxWidth: 90,
			cellRenderer: memo(Actions),
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			hide: userType !== 'admin',
			editable: false,
		},
		{
			headerName: 'S No.',
			field: 'sno',
			cellRenderer: (p) => p.rowIndex + 1,
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
			maxWidth: 55,
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			editable: false,
		},
		{
			headerName: 'Discounts',
			field: 'DISCOUNTS',
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
			cellRenderer: (p) => (
				<ToolTip title={p.value} placement="bottomLeft">
					{p.value}
				</ToolTip>
			),
		},
		{
			headerName: 'Distributor',
			field: 'DISTRIBUTOR',
			cellRenderer: (p) => (
				<Link
					to={p.data.DISTRIBUTOR_WEB_LINK}
					target="_blank"
					style={{ color: 'blue', textDecoration: 'none' }}
				>
					{p.value}
				</Link>
			),
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
		},
		{
			headerName: 'DISTRIBUTOR_WEB_LINK',
			field: 'DISTRIBUTOR_WEB_LINK',
			hide: true,
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
			cellEditor: 'agLargeTextCellEditor',
			cellEditorPopup: true,
			cellEditorParams: {
				maxLength: 256,
				rows: 5,
				cols: 50,
			},
		},
		{
			headerName: 'Ship-to Location',
			field: 'SHIP_TO_LOCATION',
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
		},
		{
			headerName: 'MOV*',
			field: 'MOV',
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
		},
		{
			headerName: 'ORDER MOV FOR FREE SHIPPING*',
			field: 'ORDER_MOV_FOR_FREE_SHIPPING',
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
		},
		{
			headerName: 'Freight Type\n(Intl. vs. Domestic)',
			field: 'FREIGHT_TYPE',
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
		},
		{
			headerName: 'Freight Charge (in currency)*',
			field: 'FREIGHT_CHARGE',
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
			cellEditor: 'agLargeTextCellEditor',
			cellEditorPopup: true,
			cellEditorParams: {
				maxLength: 256,
				rows: 5,
				cols: 50,
			},
		},
		{
			headerName: 'ORIGIN',
			field: 'ORIGIN',
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
		},
		{
			headerName: 'SHIPMENT DETAILS',
			field: 'SHIPMENT_DETAILS',
			cellRenderer: (p) => (
				<ToolTip title={p.value} placement="bottomLeft">
					{p.value}
				</ToolTip>
			),
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
			cellEditor: 'agLargeTextCellEditor',
			cellEditorPopup: true,
			cellEditorParams: {
				maxLength: 256,
				rows: 5,
				cols: 50,
			},
		},
		{
			headerName: 'Freight T&C URL',
			field: 'FREIGHT_TC_URL',
			cellRenderer: (p) => (
				<Link
					to={p.value}
					target="_blank"
					style={{ color: 'blue', textDecoration: 'none' }}
				>
					{p.value}
				</Link>
			),
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
			cellEditor: 'agLargeTextCellEditor',
			cellEditorPopup: true,
			cellEditorParams: {
				maxLength: 256,
				rows: 5,
				cols: 50,
			},
		},
		{
			headerName: 'MOV T&C URL',
			field: 'MOV_TC_URL',
			cellRenderer: (p) => (
				<Link
					to={p.value}
					target="_blank"
					style={{ color: 'blue', textDecoration: 'none' }}
				>
					{p.value}
				</Link>
			),
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
			cellEditor: 'agLargeTextCellEditor',
			cellEditorPopup: true,
			cellEditorParams: {
				maxLength: 256,
				rows: 5,
				cols: 50,
			},
		},
		{
			headerName: 'Name on FindChips',
			field: 'NAME_OF_FINDCHIPS',
			cellStyle: {
				borderRight: showBorders && '1px solid #EAECEE',
			},
		},
		{
			headerName: 'Name on Octopart',
			field: 'NAME_ON_OCTOPART',
		},
	];

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
			editable: userType === 'admin',
		}),
		[userType]
	);

	const addRow = () => {
		const newRow = { currentTime: Date.now() };
		setData([newRow, ...data]);
	};

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.altKey && (event.key === 'A' || event.key === 'a')) {
				gridApi.autoSizeAllColumns();
			}
			if (event.altKey && (event.key === 'R' || event.key === 'r')) {
				gridApi.resetColumnState();
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="Freight/MOV by Supplier"
				icon={<FcInTransit style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="Freight/MOV by Supplier"
				info="For all major online suppliers, the following table lists the typical Minimum Order Value (MOV) for purchase along with any freight charges.
        Use this information along with the extended prices for the items you’re quoting to identify if the supplier can ship to you and what the associated freight charges are."
			/>

			<InnerHeader
				updateText="Refresh Data"
				updatePage
				onUpdate={() => getData()}
			/>
			<div className={styles['freight-table']}>
				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(data) && !error && !isLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here. Select a date range." />
					</div>
				)}

				{!isLoading && !error && isNotEmptyArray(data) && (
					<>
						<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
							{userType === 'admin' && (
								<Button onClick={addRow}>Add Freight</Button>
							)}
							<Button onClick={() => setShowBorders(!showBorders)}>
								{showBorders ? 'Hide Borders' : 'Show Borders'}
							</Button>
						</div>
						<div className={styles['table-wrapper']}>
							<div className={`ag-theme-custom ${styles['table']} `}>
								<AgGridReact
									onGridReady={onGridReady}
									rowData={data}
									columnDefs={columnDefs}
									defaultColDef={defaultColDef}
									animateRows={true}
									pagination={true}
									paginationPageSizeSelector={false}
									onCellValueChanged={onCellValueChanged}
									suppressLastEmptyLineOnPaste={true}
									enableCellChangeFlash={true}
									enableRangeSelection={true}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default FreightTable;
