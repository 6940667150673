import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';

import { CountryCellRenderer } from '../../../columns/countryCellRenderer';
import { COUNTRY_CODES } from '../../../columns';
import BreadCrumb from '../../../components/breadcrumb';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';
import { dateFilterParams, dollarFilterParams } from '../../../filters';

import styles from './houseAccountExclusions.module.scss';

const HouseAccountExclusions = () => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [gridApi, setGridApi] = useState(null);

	const agGridRef = useRef(null);

	const getData = async () => {
		setIsLoading(true);
		try {
			const [exclusionsResponse, accountsResponse] = await Promise.all([
				axios.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=get_house_exclusions`
				),
				axios.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=get_all_admin_accounts_excl`
				),
			]);

			const data1 = [accountsResponse.data, exclusionsResponse.data];

			const uniqueRoundedValues = new Set();

			const accountIdMap = new Map(
				data1[1].map(({ ACCOUNT_ID }) => [ACCOUNT_ID, true])
			);

			const updatedData = data1[0].map((obj) => {
				const fiveYearBillingsValue = Math.round(obj.Billing);
				uniqueRoundedValues.add(fiveYearBillingsValue);
				return {
					...obj,
					isHouseAccount: accountIdMap.has(obj.AcctId),
					PastFiveYearBilling: fiveYearBillingsValue,
				};
			});

			setData(updatedData);
			setError(null);
		} catch (err) {
			setError(err.message);
			setData([]);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const [columnDefs] = useState([
		{
			field: 'isHouseAccount',
			headerName: 'Exclude from House?',
			cellRenderer: 'agCheckboxCellRenderer',
			cellEditor: 'agCheckboxCellEditor',
			editable: true,
			sort: 'desc',
		},
		{
			field: 'AcctCreatedDate',
			headerName: 'Created Date',
			minWidth: 170,
			cellRenderer: (p) => p.value && moment(p.value).format('MMM-DD-YYYY'),
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
		},
		{ field: 'Account', headerName: 'Account', minWidth: 300 },
		{ field: 'PrimaryCompanyType', headerName: 'Account Type' },
		{
			field: 'AcctCountry',
			headerName: 'Country',
			cellRenderer: CountryCellRenderer,
			filter: 'agSetColumnFilter',
			filterParams: {
				cellRenderer: CountryCellRenderer,
				cellRendererParams: { isFilterRenderer: true },
			},
		},
		{
			field: 'PastFiveYearBilling',
			headerName: 'Past 5-year Billings',
			sort: 'desc',
			sortingOrder: ['desc', 'asc'],
			filter: 'agSetColumnFilter',
			filterParams: dollarFilterParams,
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
		},
	]);

	const onGridReady = useCallback((params) => {
		setGridApi(params.api);
	}, []);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
		}),
		[]
	);

	const context = useMemo(() => {
		return {
			COUNTRY_CODES: COUNTRY_CODES,
		};
	}, []);

	/**
	 * On cell value change for isSenior column
	 */
	const onCellValueChanged = useCallback((e) => {
		const cellValue = e.data;
		console.log(cellValue);
		axios
			.post(
				cellValue.isHouseAccount === true
					? `https://internal.supremecomponents.com/api/externalgateway.php?route=save_house_exclusions&account_id=${cellValue.AcctId}`
					: `https://internal.supremecomponents.com/api/externalgateway.php?route=delete_house_exclusions&account_id=${cellValue.AcctId}`
			)
			.then((response) => {
				const column = e.column.colDef.field;
				setTimeout(() => {
					e.api.refreshCells({
						force: true,
						columns: [column],
						rowNodes: [e.node],
					});
					e?.api?.tabToNextCell();
				}, 500);
			});
	}, []);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb route1="House Account Exclusions" />

			<div className={styles['house-accounts']}>
				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(data) && !error && !isLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here. Select a date range." />
					</div>
				)}

				{!isLoading && !error && isNotEmptyArray(data) && (
					<>
						<div className={styles['table-wrapper']}>
							<div className={`ag-theme-custom ${styles['table']} `}>
								<AgGridReact
									ref={agGridRef}
									rowData={data}
									onGridReady={onGridReady}
									columnDefs={columnDefs}
									defaultColDef={defaultColDef}
									rowSelection={'multiple'}
									suppressRowClickSelection={true}
									rowMultiSelectWithClick={true}
									animateRows={true}
									pagination={true}
									paginationPageSizeSelector={false}
									suppressContextMenu={true}
									context={context}
									onCellValueChanged={onCellValueChanged}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default HouseAccountExclusions;
