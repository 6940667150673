import { useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { SiBrandfolder } from 'react-icons/si';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import InnerHeader from '../../components/innerHeader';

import styles from './preferredsuppliersbyBrand.module.scss';

const PreferredsuppliersbyBrand = () => {
	const [gridApi, setGridApi] = useState();
	const [columnDefs] = useState([
		{ field: 'brand', headerName: 'Brand' },
		{ field: 'supplier', headerName: 'Supplier' },
	]);

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	const data = [
		{ brand: '3M', supplier: 'Heilind' },
		{ brand: '3M', supplier: 'Mouser-Direct' },
		{ brand: 'ABB', supplier: 'Masterelectronics-Direct' },
		{ brand: 'ABB', supplier: 'Sager' },
		{ brand: 'ABRACON', supplier: 'Future' },
		{ brand: 'ALLEGRO', supplier: 'Mouser-Direct' },
		{ brand: 'ALLIANCE MEMORY INC', supplier: 'Future' },
		{ brand: 'ALPHA WIRE', supplier: 'Element14-Direct' },
		{ brand: 'ALPHA WIRE', supplier: 'Masterelectronics-Direct' },
		{ brand: 'ALPHA WIRE', supplier: 'Sager' },
		{ brand: 'ALTECH', supplier: 'Future' },
		{ brand: 'AMPHENOL', supplier: 'Future' },
		{ brand: 'AMPHENOL', supplier: 'Heilind' },
		{ brand: 'AMPHENOL', supplier: 'Mouser-Direct' },
		{ brand: 'ANDERSON POWER', supplier: 'Masterelectronics-Direct' },
		{ brand: 'ANDERSON POWER', supplier: 'Mouser-Direct' },
		{ brand: 'ANDERSON POWER', supplier: 'Mouser-Direct (2)' },
		{ brand: 'ANDERSON POWER', supplier: 'Sager' },
		{ brand: 'AROMAT CORP', supplier: 'Masterelectronics-Direct' },
		{ brand: 'AVX', supplier: 'Future' },
		{ brand: 'AVX', supplier: 'Mouser-Direct' },
		{ brand: 'AVX', supplier: 'Rutronik-Direct' },
		{ brand: 'BELDEN', supplier: 'Sager' },
		{ brand: 'BELL ELECTRICAL SUPPLY', supplier: 'Sager' },
		{ brand: 'BIVAR', supplier: 'Mouser-Direct' },
		{ brand: 'BOURNS', supplier: 'Masterelectronics-Direct' },
		{ brand: 'BOURNS', supplier: 'Mouser-Direct' },
		{ brand: 'BRADY', supplier: 'Future' },
		{ brand: 'C&K COMPONENTS', supplier: 'Future' },
		{ brand: 'C&K COMPONENTS', supplier: 'Sager' },
		{ brand: 'CENTRAL SEMI', supplier: 'Future' },
		{ brand: 'CONEC', supplier: 'Masterelectronics-Direct' },
		{ brand: 'CONEC', supplier: 'Mouser-Direct' },
		{ brand: 'COOPER BUSSMAN', supplier: 'Tti-Direct' },
		{ brand: 'COSEL', supplier: 'Sager' },
		{ brand: 'CRYDOM', supplier: 'Sager' },
		{ brand: 'CTS', supplier: 'Future' },
		{ brand: 'CTS', supplier: 'Mouser-Direct' },
		{ brand: 'CYPRESS', supplier: 'Mouser-Direct' },
		{ brand: 'DELEVAN', supplier: 'Future' },
		{ brand: 'DIODES INC.', supplier: 'Future' },
		{ brand: 'EATON', supplier: 'Future' },
		{ brand: 'EATON', supplier: 'Masterelectronics-Direct' },
		{ brand: 'EATON', supplier: 'Sager, Ortecs' },
		{ brand: 'EBM PAPST', supplier: 'Sager' },
		{ brand: 'E-T-A ENGINEERING TECHNOLOGY', supplier: 'Sager' },
		{ brand: 'EYECAP INTERNATIONAL', supplier: 'Masterelectronics-Direct' },
		{ brand: 'FCI', supplier: 'Heilind' },
		{ brand: 'FERRAZ', supplier: 'Masterelectronics-Direct' },
		{ brand: 'FTG', supplier: 'Mouser-Direct' },
		{ brand: 'GRAYHILL', supplier: 'Sager' },
		{ brand: 'HARTING', supplier: 'HARTING' },
		{ brand: 'HELLERMANNTYTON', supplier: 'Masterelectronics-Direct' },
		{ brand: 'HELLERMANNTYTON', supplier: 'Mouser-Direct' },
		{ brand: 'HERAEUS HOLDING', supplier: 'Mouser-Direct' },
		{ brand: 'HEYCO', supplier: 'Future' },
		{ brand: 'HEYCO PRODUCTS', supplier: 'Heilind' },
		{ brand: 'HUBBELLORPORATED', supplier: 'Sager' },
		{ brand: 'IDEC', supplier: 'Sager' },
		{ brand: 'INFINEON', supplier: 'Data JCE' },
		{ brand: 'INTERNATIONAL RECTIFIER', supplier: 'Mouser-Direct' },
		{ brand: 'IRC', supplier: 'Future' },
		{ brand: 'ISSI', supplier: 'Mouser-Direct' },
		{ brand: 'ITT CANNON', supplier: 'Masterelectronics-Direct' },
		{ brand: 'ITT CANNON', supplier: 'Mouser-Direct, Ortecs' },
		{ brand: 'KELLEMS', supplier: 'Sager' },
		{ brand: 'KEMET', supplier: 'Mouser-Direct' },
		{ brand: 'KEMET', supplier: 'Tti-Direct' },
		{ brand: 'KEYSTONE ELECTRONICS', supplier: 'Mouser-Direct' },
		{ brand: 'KOA', supplier: 'Future' },
		{ brand: 'KOA SPEER', supplier: 'Mouser-Direct' },
		{ brand: 'KOA SPEER', supplier: 'Tti-Direct' },
		{ brand: 'KYCON', supplier: 'Mouser-Direct' },
		{ brand: 'KYCON', supplier: 'Sager' },
		{ brand: 'LAIRD', supplier: 'Mouser-Direct' },
		{ brand: 'LATTICE SEMI', supplier: 'Mouser-Direct' },
		{ brand: 'LITE-ON', supplier: 'Future' },
		{ brand: 'LITTELFUSE', supplier: 'Future' },
		{ brand: 'LITTELFUSE', supplier: 'Rutronik-Direct' },
		{ brand: 'LUMEX', supplier: 'Heilind' },
		{ brand: 'MAXLINEAR', supplier: 'Future' },
		{ brand: 'MICRO COMMERCIAL COMP', supplier: 'Future' },
		{ brand: 'MICROCHIP', supplier: 'Masterelectronics-Direct' },
		{ brand: 'MICRON', supplier: 'Mouser-Direct' },
		{ brand: 'MILL MAX', supplier: 'Future' },
		{ brand: 'MOLEX', supplier: 'Masterelectronics-Direct' },
		{ brand: 'MOLEX', supplier: 'Mouser-Direct' },
		{ brand: 'MOLEX', supplier: 'Sager' },
		{ brand: 'MURATA', supplier: 'Future' },
		{ brand: 'MURATA', supplier: 'Mouser-Direct' },
		{ brand: 'MURATA', supplier: 'Rutronik-Direct' },
		{ brand: 'MURATA', supplier: 'Sager' },
		{ brand: 'NATIONAL SEMICONDUCTOR', supplier: 'Mouser-Direct' },
		{ brand: 'NEXPERIA', supplier: 'Future' },
		{ brand: 'NIC', supplier: 'NIC' },
		{ brand: 'NICHICON', supplier: 'Future' },
		{ brand: 'NICHICON', supplier: 'Tti-Direct' },
		{ brand: 'NMB TECHNOLOGIES', supplier: 'Sager' },
		{ brand: 'NVENT', supplier: 'Element14-Direct' },
		{ brand: 'OMRON', supplier: 'Sager' },
		{ brand: 'ON SEMICONDUCTOR', supplier: 'Mouser-Direct' },
		{ brand: 'ONSEMI', supplier: 'Future' },
		{ brand: 'PANASONIC', supplier: 'Future' },
		{ brand: 'PANASONIC', supplier: 'Masterelectronics-Direct' },
		{ brand: 'PANASONIC', supplier: 'Tti-Direct' },
		{ brand: 'PANDUIT', supplier: 'Heilind' },
		{ brand: 'PANDUIT', supplier: 'Sager' },
		{ brand: 'PHOENIX CONTACT', supplier: 'Masterelectronics-Direct' },
		{ brand: 'PHOENIX CONTACT', supplier: 'Mouser-Direct' },
		{ brand: 'PHOENIX CONTACT', supplier: 'Sager' },
		{ brand: 'POSITRONIC', supplier: 'Mouser-Direct' },
		{ brand: 'QUALTEK', supplier: 'Sager' },
		{ brand: 'QUALTEK ELECTRONICS', supplier: 'Sager' },
		{ brand: 'RAF', supplier: 'Heilind' },
		{ brand: 'RECOM', supplier: 'Sager' },
		{ brand: 'RECOM POWER INC.', supplier: 'Future' },
		{ brand: 'RENESAS', supplier: 'Mouser-Direct' },
		{ brand: 'RENESAS', supplier: 'Future' },
		{ brand: 'ROHM', supplier: 'Future' },
		{ brand: 'SAMSUNG', supplier: 'Future' },
		{ brand: 'SAMSUNG', supplier: 'Rutronik-Direct' },
		{ brand: 'SAMTEC', supplier: 'Mouser-Direct' },
		{ brand: 'SAMTEC', supplier: 'Sager' },
		{ brand: 'SANYO DENKI', supplier: 'Sager' },
		{ brand: 'SCHAFFNER', supplier: 'Sager' },
		{ brand: 'SCHURTER', supplier: 'Future' },
		{ brand: 'SEMTECH', supplier: 'Future' },
		{ brand: 'SENSATA', supplier: 'Sager' },
		{ brand: 'STACKPOLE', supplier: 'Mouser-Direct' },
		{ brand: 'STACKPOLE ELECTRONICS', supplier: 'Future' },
		{ brand: 'STMICROELECTRONICS', supplier: 'Mouser-Direct' },
		{ brand: 'SUSUMU', supplier: 'Mouser-Direct' },
		{ brand: 'SUSUMU', supplier: 'Future' },
		{ brand: 'TAIYO YUDEN', supplier: 'Future' },
		{ brand: 'TAIYO YUDEN', supplier: 'Mouser-Direct' },
		{ brand: 'TDK', supplier: 'Future' },
		{ brand: 'TDK', supplier: 'Sager' },
		{ brand: 'TE CONNECTIVITY', supplier: 'Future' },
		{ brand: 'TE CONNECTIVITY', supplier: 'Masterelectronics-Direct' },
		{ brand: 'TE CONNECTIVITY', supplier: 'Ortecs' },
		{ brand: 'TE CONNECTIVITY', supplier: 'Sager' },
		{ brand: 'TE CONNECTIVITY', supplier: 'Tti-Direct' },
		{ brand: 'TEXAS INSTRUMENTS', supplier: 'Mouser-Direct' },
		{ brand: 'TT ELECTRONICS', supplier: 'Tti-Direct' },
		{ brand: 'VCC', supplier: 'Mouser-Direct' },
		{ brand: 'VISHAY', supplier: 'Ecomal' },
		{ brand: 'VISHAY', supplier: 'Future' },
		{ brand: 'VISHAY', supplier: 'Mouser-Direct' },
		{ brand: 'VISHAY', supplier: 'Tti-Direct' },
		{ brand: 'VISHAY', supplier: 'Vishay' },
		{ brand: 'VISHAY INTERTECHNOLOGY', supplier: 'Tti-Direct' },
		{ brand: 'WAGO', supplier: 'Future' },
		{ brand: 'WALSIN', supplier: 'Future' },
		{ brand: 'WALSIN', supplier: 'Mouser-Direct' },
		{ brand: 'WEIDMULLER', supplier: 'Ecomal' },
		{ brand: 'WEIDMULLER', supplier: 'Masterelectronics-Direct' },
		{ brand: 'WEIDMULLER', supplier: 'Mouser-Direct' },
		{ brand: 'WE-MIDCOM', supplier: 'Future' },
		{ brand: 'YAGEO', supplier: 'Future' },
		{ brand: 'YAGEO', supplier: 'Rabyte' },
		{ brand: 'YAGEO', supplier: 'Rutronik-Direct' },
		{ brand: 'ZF ELECTRONICS', supplier: 'Future' },
	];

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
		}),
		[]
	);

	const exportToExcel = () => {
		const customerReportExcelParams = {
			sheetName: 'Preferred suppliers by Brand',
			fileName: 'Preferred suppliers by Brand.xlsx',
		};
		gridApi.exportDataAsExcel(customerReportExcelParams);
	};

	const excelStyles = useMemo(() => {
		return [
			{
				id: 'header',
				font: {
					bold: true,
				},
			},
		];
	}, []);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="Preferred Suppliers by Brand"
				icon={<SiBrandfolder style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="Preferred Suppliers by Brand"
				info="This section shows information about Preferred Suppliers by Brand."
			/>
			<InnerHeader exportToExcel={exportToExcel} />
			<div className={styles['api-token-limits']}>
				<div className={styles['table-wrapper']}>
					<div className={`ag-theme-custom ${styles['table']} `}>
						<AgGridReact
							rowData={data}
							columnDefs={columnDefs}
							defaultColDef={defaultColDef}
							animateRows={true}
							pagination={true}
							paginationPageSizeSelector={false}
							onGridReady={onGridReady}
							excelStyles={excelStyles}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreferredsuppliersbyBrand;
