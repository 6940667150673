import { Tooltip as AntdToolTip } from 'antd';

const ToolTip = ({
	children,
	title,
	mouseEnterDelay,
	trigger,
	align,
	placement,
	defaultOpen,
	color,
	overlayStyle,
	overlayInnerStyle,
}) => {
	return (
		<AntdToolTip
			title={title}
			mouseEnterDelay={mouseEnterDelay}
			trigger={trigger}
			align={align}
			placement={placement ? placement : 'bottom'}
			defaultOpen={defaultOpen}
			color={color}
			overlayStyle={overlayStyle}
			overlayInnerStyle={overlayInnerStyle}
		>
			{children}
		</AntdToolTip>
	);
};

export default ToolTip;
