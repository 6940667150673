import { useContext } from 'react';
import { Col, Divider, Form, Row } from 'antd';

import Modal from '../../../../components/modal';
import Select from '../../../../components/select';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import EdenContext from '../../../../context/edenContext';

import styles from '../bobcatExclusionList.module.scss';

const AddExcluForm = ({
	open,
	onCancel,
	onFinish,
	form,
	initialValues,
	formDisabled,
}) => {
	const { bobcatExcluList } = useContext(EdenContext);
	return (
		<Modal
			title={
				<>
					<p>Add Exclusion List</p>
					<Divider
						style={{
							margin: '5px 0',
							borderBlockStart: '1px solid rgb(216, 216, 216)',
						}}
					/>
				</>
			}
			open={open}
			onCancel={onCancel}
			destroyOnClose
			width={700}
		>
			<Form
				name="add-bobcat-exclusion-list"
				form={form}
				onFinish={onFinish}
				validateTrigger={['onBlur', 'onChange']}
				labelCol={{ span: 24 }}
				requiredMark={false}
				initialValues={initialValues}
				disabled={formDisabled}
			>
				<Row gutter={16}>
					<Col sm={24} md={12}>
						<Form.Item
							label="Type"
							name="type"
							rules={[
								{
									required: true,
									message: 'Please select Type!',
								},
							]}
						>
							<Select
								placeholder="Select Type"
								type="bordered"
								options={[
									{ value: 'Email', label: 'Email' },
									{ value: 'Keyword', label: 'Keyword' },
								]}
							/>
						</Form.Item>
					</Col>
					<Col sm={24} md={12}>
						<Form.Item
							label="Parameter"
							name="parameter"
							rules={[
								{
									required: true,
									message: 'Please enter Parameter!',
								},
								() => ({
									validator(_, value) {
										const existingSR = bobcatExcluList?.map(
											(item) => item?.parameter
										);
										if (existingSR?.includes(value)) {
											return Promise.reject(
												new Error('This Parameter is already present!')
											);
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<Input placeholder="Enter Parameter" />
						</Form.Item>
					</Col>

					<Col sm={24}>
						<Form.Item style={{ marginBottom: '0px' }}>
							<div className={styles['actions']}>
								<Button type="bordered" onClick={onCancel}>
									Cancel
								</Button>
								<Button htmlType="submit">Process</Button>
							</div>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default AddExcluForm;
