import { Input as AntdInput } from 'antd';

import styles from './input.module.scss';

const Input = ({
	onChange,
	value,
	placeholder,
	disabled,
	defaultValue,
	type,
	style,
	onPressEnter,
	id,
	min,
	step,
	prefix,
	allowClear,
	onClear,
	maxLength,
	variant,
}) => {
	return (
		<>
			<AntdInput
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				style={style}
				className={styles['input']}
				disabled={disabled}
				defaultValue={defaultValue}
				type={type}
				onPressEnter={onPressEnter}
				id={id}
				min={min}
				step={step}
				prefix={prefix}
				allowClear={allowClear}
				onClear={onClear}
				maxLength={maxLength}
				variant={variant}
			/>
		</>
	);
};

export default Input;
