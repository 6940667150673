import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { notification } from 'antd';

import AutoQC from './pages/autoQC';
import OutlookRFQ from './pages/OutlookRFQ';
import PrivateRoutes from './components/layout/privateRoutes';
import { EdenProvider } from './context/edenContext';
import ContextComposer from './context/ContextComposer';
import router from './routes';

import './App.css';

const App = () => {
	// eslint-disable-next-line
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		window.addEventListener('offline', () => {
			setIsOnline(false);
			notification.open({
				key: 'close-offline-notification',
				message: 'You are Offline',
				description:
					'It’s funny how the internet is always required to browse the web. Guessing everyone needs some space, just waiting for the connection to be back.',
				duration: 0,
				type: 'error',
				style: {
					backgroundColor: '#fff2f0',
					border: '1px solid #ffccc7',
					borderRadius: '2px',
				},
			});
			notification.destroy('close-online-notification');
		});
		window.addEventListener('online', () => {
			setIsOnline(true);
			notification.destroy('close-offline-notification');
			notification.open({
				key: 'close-online-notification',
				message: 'You are back online',
				description: 'Eden is all yours!',
				type: 'success',
				style: {
					backgroundColor: '#f6ffed',
					border: '1px solid #b7eb8f',
					borderRadius: '2px',
				},
			});
		});
		return () => {
			window.removeEventListener('offline', () => {
				setIsOnline(false);
				notification.destroy('close-offline-notification');
			});
			window.removeEventListener('online', () => {
				setIsOnline(true);
				notification.destroy('close-online-notification');
			});
		};
	}, []);

	return (
		<EdenProvider>
			<ContextComposer>
				<Routes>
					<Route element={<PrivateRoutes />}>
						{router.map((el, index) => (
							<Route key={index} path={el.path} element={el.element} />
						))}
					</Route>

					{/* Auto QC */}
					<Route path="/auto-qc" element={<AutoQC />} />
					{/* Outlook RFQ */}
					<Route path="/outlook-rfq" element={<OutlookRFQ />} />
				</Routes>
			</ContextComposer>
		</EdenProvider>
	);
};

export default App;
