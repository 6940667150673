import React, { useContext, useState } from 'react';
import { Form, message } from 'antd';
import axios from 'axios';
import { QuestionCircleOutlined } from '@ant-design/icons';

import Popconfirm from '../../../../components/popConfirm';
import ToolTip from '../../../../components/tooltip';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { MdModeEdit } from 'react-icons/md';
import AddExcluForm from '../AddExcluForm';
import EdenContext from '../../../../context/edenContext';

const BobcatExclusionAction = (p) => {
	const { getBobcatExcluList } = useContext(EdenContext);
	const [form] = Form.useForm();
	const [deleteBobcatExcluListPopup, setDeleteBobcatExcluListPopup] =
		useState(false);
	const [deleteBobcatExcluListLoading, setDeleteBobcatExcluListLoading] =
		useState(false);
	const [editBobcatExcluListModal, setEditBobcatExcluListModal] =
		useState(false);

	const openDeletePopUp = () => {
		setDeleteBobcatExcluListPopup(true);
	};

	const deleteBobcatExcluList = () => {
		setDeleteBobcatExcluListLoading(true);
		const formData = new FormData();
		formData.append('id', p?.data?.id);
		formData.append('is_active', '0');
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=bobcat_delete_excl_email`,
				formData
			)
			.then(() => {
				setDeleteBobcatExcluListPopup(false);
				message.open({
					type: 'success',
					content: 'Exclusion deleted successfully',
				});
				getBobcatExcluList();
			})
			.catch(() => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				setDeleteBobcatExcluListPopup(false);
			})
			.finally(() => {
				setDeleteBobcatExcluListLoading(false);
			});
	};

	const closeDeleteBobcatExcluListPopup = () => {
		setDeleteBobcatExcluListPopup(false);
	};

	const openEditBobcatExcluListModal = () => {
		setEditBobcatExcluListModal(true);
	};

	const closeEditBobcatExcluListModal = () => {
		setEditBobcatExcluListModal(false);
	};

	const editExcluList = (values) => {
		const formData = new FormData();
		formData.append('id', p?.data?.id);
		formData.append('type', values.type);
		formData.append('parameter', values.parameter);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=bobcat_edit_excl_email`,
				formData
			)
			.then(() => {
				form.resetFields();
				message.open({
					type: 'success',
					content: 'Exclusion list edited successfully',
				});
				setEditBobcatExcluListModal(false);
				getBobcatExcluList();
			})
			.catch((err) => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				form.resetFields();
				setEditBobcatExcluListModal(false);
			});
	};

	const initialValues = {
		type: p?.data?.type,
		parameter: p?.data?.parameter,
	};

	return (
		<div style={{ display: 'flex', gap: '10px' }}>
			<Popconfirm
				placement="bottomLeft"
				okButtonColor="red"
				open={deleteBobcatExcluListPopup}
				title="Delete"
				description="You cannot undo this action. Do you confirm the deletion of the selected row?"
				okText="Yes"
				cancelText="Cancel"
				onConfirm={deleteBobcatExcluList}
				onCancel={closeDeleteBobcatExcluListPopup}
				okButtonLoading={deleteBobcatExcluListLoading}
				icon={
					<QuestionCircleOutlined
						style={{
							color: 'red',
						}}
					/>
				}
			>
				<ToolTip title="Delete">
					<RiDeleteBin6Fill
						style={{ fontSize: '18px', cursor: 'pointer', color: '#C00000' }}
						onClick={openDeletePopUp}
					/>
				</ToolTip>
			</Popconfirm>
			<ToolTip title="Edit">
				<MdModeEdit
					style={{ fontSize: '18px', cursor: 'pointer' }}
					onClick={openEditBobcatExcluListModal}
				/>
			</ToolTip>
			{editBobcatExcluListModal && (
				<AddExcluForm
					open={editBobcatExcluListModal}
					form={form}
					onCancel={closeEditBobcatExcluListModal}
					onFinish={editExcluList}
					initialValues={initialValues}
				/>
			)}
		</div>
	);
};

export default BobcatExclusionAction;
