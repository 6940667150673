import { useEffect } from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';

import styles from './notAuthorized.module.scss';

const NotAuthorized = () => {
	useEffect(() => {
		const redirectTimer = setTimeout(() => {
			window.location.href = 'https://www.supremecomponents.com/';
		}, 5000);

		return () => clearTimeout(redirectTimer);
	}, []);

	return (
		<div className={styles['wrapper']}>
			<div className={styles['message-wrapper']}>
				<RiErrorWarningFill className={styles['icon']} />
				<p className={styles['message']}>
					You do not have permission to access this resource.
				</p>
			</div>
		</div>
	);
};

export default NotAuthorized;
