import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from '../authConfig';
import { callMsGraph } from '../graph';
import useIsMainWindow from '../hooks/checkMultipleTabs';
// import { message } from 'antd';

const EdenContext = createContext();

export const EdenProvider = ({ children }) => {
	const { instance, accounts } = useMsal();

	const isAuthenticated = !!localStorage.getItem('username');
	const userData = JSON.parse(localStorage.getItem('userdata'));
	const userMail = userData?.[0]?.username;
	// const userMail = 'zeeshan@supremecomponents.com';
	const me = JSON.parse(localStorage.getItem('me'));

	const isMain = useIsMainWindow();

	const navigate = useNavigate();

	function RequestProfileData() {
		// Silently acquires an access token which is then attached to a request for MS Graph data
		instance
			.acquireTokenSilent({
				...loginRequest,
				account: accounts[0],
			})
			.then((response) => {
				callMsGraph(response.accessToken).then((response) =>
					localStorage.setItem('me', JSON.stringify(response))
				);
			});
	}

	const authhandler = () => {
		const email = accounts?.[0]?.username;
		const getDomain = email?.substring(email?.indexOf('@') + 1);
		if (getDomain === 'supremecomponents.com') {
			navigate('/');
			localStorage.setItem('userdata', JSON.stringify(accounts));
			localStorage.setItem('username', accounts?.[0]?.username);
			setTimeout(() => {
				sessionStorage.clear();
			}, 1000);
		}
	};

	useEffect(() => {
		if (!isAuthenticated) {
			authhandler();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accounts]);

	const logout = () => {
		localStorage.clear();
		sessionStorage.clear();
		navigate('/');
		window.location.reload(false);
	};

	const [userInfo, setUserInfo] = useState(null);
	const [userInfoLoading, setUserInfoLoading] = useState(true);
	const [userInfoError, setUserInfoError] = useState(null);
	const [userType, setUserType] = useState('');
	const [cascaderDesignation, setCascaderDesignation] = useState('');
	const [cascaderOption, setCascaderOption] = useState('');
	const [cascaderDesignationMpn, setCascaderDesignationMpn] = useState('');
	const [cascaderOptionMpn, setCascaderOptionMpn] = useState('');
	const [cascaderDesignationCCE, setCascaderDesignationCCE] = useState('');
	const [cascaderOptionCCE, setCascaderOptionCCE] = useState('');
	const [cascaderOptionEE, setCascaderOptionEE] = useState('');
	const [converStatTabEE, setConverStatTabEE] = useState(true);
	const [incomOutEmailTabEE, setIncomeOutEmailTabEE] = useState(true);
	const [converStatTableEE, setConverStatTableEE] = useState(false);
	const [incomOutEmailTableEE, setIncomeOutEmailTableEE] = useState(false);
	const [dateRange, setDateRange] = useState(2);
	// Admin ChatGpt Keywords states
	const [chatGptKeywords, setChatGptKeywords] = useState(null);
	const [chatGptKeywordsLoading, setChatGptKeywordsLoading] = useState(false);
	const [chatGptKeywordsError, setChatGptKeywordsError] = useState(null);
	// Admin Email Exclusion list states
	const [emailExclusionList, setEmailExclusionList] = useState(null);
	const [emailExclusionListLoading, setEmailExclusionListLoading] =
		useState(false);
	const [emailExclusionListError, setEmailExclusionListError] = useState(null);
	// Admin Eagle Preprocessor: Account Tags states
	const [epTagsList, setEpTagsList] = useState(null);
	const [epTagsListLoading, setEpTagsListLoading] = useState(false);
	const [epTagsListError, setEpTagsListError] = useState(null);
	// Admin Sourcing assignments states
	const [sourcingAssList, setSourcingAssList] = useState(null);
	const [sourcingAssListLoading, setSourcingAssListLoading] = useState(false);
	const [sourcingAssListError, setSourcingAssListError] = useState(null);
	// sourcing MPN Cost Book
	const [mpnCostBookList, setMpnCostBookList] = useState([]);
	const [mpnCostBookLoading, setMpnCostBookLoading] = useState(false);
	const [mpnCostBookError, setMpnCostBookError] = useState(null);
	const [costBookType, setCostBookType] = useState(true);
	const [mpnCostBookRowData, setMpnCostBookRowData] = useState([]);
	const [fcExtPriceLoading, setFcExtPriceLoading] = useState(false);
	const [MCBlastUserActiviry, setMCBlastUserActiviry] = useState('');
	const [mpnDateRange, setMpnDateRange] = useState('1W');
	// Bobcat Exclusion list
	const [bobcatExcluList, setBobcatExcluList] = useState(null);
	const [bobcatExcluLoading, setBobcatExcluLoading] = useState(false);
	const [bobcatExcluError, setBobcatExcluError] = useState(null);
	// CRM Accounts data
	const [crmAccounts, setCrmAccounts] = useState([]);
	// CRM Accounts Customer and Supplier data
	const [crmAccCusSupp, setCrmAccCusSupp] = useState([]);
	// SS data
	const [ssData, setSSData] = useState([]);
	// SR data
	const [srData, setSRData] = useState([]);
	// SR data by ID
	const [srDataByEmail, setSrDataByEmail] = useState({});
	// CRM Suppliers data
	const [crmSuppliers, setCrmSuppliers] = useState([]);
	// Message URL
	const [messageURl, setMessageUrl] = useState();
	// sales rep for MPN Queue and MPN Cost Book
	const [cascaderOptions, setCascaderOptions] = useState(null);
	// MPN and Brand
	const [mpnBrand, setMpnBrand] = useState(null);
	// KeywordsforAttachmentIdentification
	const [keywordsforAttachRowData, setKeywordsforAttachRowData] = useState([]);

	const fetchUserData = () => {
		setUserInfoLoading(true);
		fetch(
			`https://internal.supremecomponents.com/api/externalgateway.php?route=getInfoByEmail&email=${userMail}`
		)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				// localStorage.setItem('userInfo', JSON.stringify(data));
				let user = data?.ViewAsLoggedinUser?.Designation;

				if (user === 'Admin') {
					user = 'admin';
				}
				if (user === 'CEO') {
					user = 'ceo';
				}
				if (user === 'Sourcing Specialist') {
					user = 'ss';
				}
				if (user === 'Sales Manager') {
					user = 'sm';
				}
				if (user === 'Sales Rep') {
					user = 'sr';
				}
				setUserType(user);
				setUserInfo(data);
				setUserInfoError(null);
			})
			.catch((err) => {
				setUserInfoError(err);
				setUserInfo(null);
				setUserInfoLoading(false);
			})
			.finally(() => {
				setUserInfoLoading(false);
			});
	};

	const crmAccountsData = () => {
		axios
			.get(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=get_all_crm_accounts'
			)
			.then((response) => {
				setCrmAccounts(response.data);
			});
	};

	const crmAccCusSuppData = () => {
		axios
			.get(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=get_crm_accounts'
			)
			.then((response) => {
				setCrmAccCusSupp(response.data);
			});
	};

	const getAllSSData = () => {
		axios
			.get(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=getallss'
			)
			.then((response) => {
				setSSData(response.data);
			});
	};

	const getAllSRData = () => {
		axios
			.get(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=getallsalesreps'
			)
			.then((response) => {
				setSRData(response.data);
			});
	};

	const getSRDataByEmail = () => {
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=getsalesrepsbyemail&email=${userMail?.toLowerCase()}`
			)
			.then((response) => {
				setSrDataByEmail(response.data);
			});
	};

	const crmSupplierData = () => {
		axios
			.get(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=get_all_suppliers'
			)
			.then((response) => {
				setCrmSuppliers(response.data);
			});
	};

	const getMailUrl = () => {
		axios
			.get(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=gets3host'
			)
			.then((response) => {
				setMessageUrl(response?.data);
			});
	};

	const getMPNBrand = () => {
		axios
			.get(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=get_mpn_brand'
			)
			.then((response) => {
				setMpnBrand(response?.data);
			});
	};

	const salesRepForQueueCostBook = () => {
		axios
			.get(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=gets3host'
			)
			.then((response) => {
				axios
					.get(
						`https://internal.supremecomponents.com/api/externalgateway.php?route=getInfoByEmail&email=vick@supremecomponents.com`
					)
					.then((response) => {
						setCascaderOptions(
							response.data?.ViewAsAdmin?.map((el) => ({
								value: el?.MasterId,
								label: el?.SalesRepName,
								children: el?.SalesTeam?.map((el) => ({
									value: el?.MasterId,
									label: el?.SalesRepName,
									children: el?.SalesTeam?.map((el) => ({
										value: el?.MasterId,
										label: el?.SalesRepName,
										children: el?.SalesTeam?.map((el) => ({
											value: el?.MasterId,
											label: el?.SalesRepName,
										})),
									})),
								})),
							}))
						);
					});
			});
	};

	useEffect(() => {
		if (isAuthenticated) {
			RequestProfileData();
			fetchUserData();
			setCascaderOption(userMail);
			setCascaderOptionMpn(userMail);
			setCascaderOptionCCE(userMail);
			crmAccountsData();
			crmAccCusSuppData();
			getAllSSData();
			getAllSRData();
			getSRDataByEmail();
			crmSupplierData();
			getMailUrl();
			salesRepForQueueCostBook();
			getMPNBrand();
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userMail]);

	//header userTypes
	let userTypes;
	if (
		userInfo?.ViewAsLoggedinUser?.Designation === 'Admin' ||
		userInfo?.ViewAsLoggedinUser?.Designation === 'CEO'
	) {
		userTypes = [
			{ id: '1', type: 'sr', color: 'green' },
			{ id: '2', type: 'sm', color: 'blue' },
			{ id: '4', type: 'ss', color: 'red' },
			{ id: '3', type: 'ceo', color: 'purple' },
			{ id: '5', type: 'admin', color: 'primary' },
		];
	} else if (userInfo?.ViewAsLoggedinUser?.Designation === 'Sales Manager') {
		userTypes = [
			{ id: '1', type: 'sr', color: 'green' },
			{ id: '2', type: 'sm', color: 'blue' },
		];
	} else if (userInfo?.ViewAsLoggedinUser?.Designation === 'Sales Rep') {
		userTypes = [{ id: '1', type: 'sr', color: 'green' }];
	} else if (
		userInfo?.ViewAsLoggedinUser?.Designation === 'Sourcing Specialist'
	) {
		userTypes = [{ id: '1', type: 'ss', color: 'red' }];
	}

	const clearCache = () => {
		localStorage.clear();
		fetch(
			'https://internal.supremecomponents.com/api/externalgateway.php?route=clearcache'
		)
			.then((response) => response.json())
			.then(function (data) {
				localStorage.clear();
				navigate('/');
				window.location.reload(false);
			});
	};

	// Admin get all ChatGpt Keywords
	const chatGptAllkeywords = () => {
		const formdata = new FormData();
		formdata.append('is_active', '1');
		setChatGptKeywordsLoading(true);
		axios
			.post(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=get_all_chatgpt_keyword',
				formdata
			)
			.then((response) => {
				setChatGptKeywords(response?.data);
				setChatGptKeywordsError(null);
			})
			.catch((err) => {
				setChatGptKeywordsError(err);
				setChatGptKeywords(null);
				setChatGptKeywordsLoading(false);
			})
			.finally(() => {
				setChatGptKeywordsLoading(false);
			});
	};

	// Admin get all Email Exclusion list
	const emailExclusions = () => {
		const formdata = new FormData();
		formdata.append('is_active', '1');
		setEmailExclusionListLoading(true);
		axios
			.post(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=get_all_excl_email',
				formdata
			)
			.then((response) => {
				setEmailExclusionList(response?.data);
				setEmailExclusionListError(null);
			})
			.catch((err) => {
				setEmailExclusionListError(err);
				setEmailExclusionList(null);
				setEmailExclusionListLoading(false);
			})
			.finally(() => {
				setEmailExclusionListLoading(false);
			});
	};

	// Admin get all Eagle Preprocessor: Account Tags
	const epTags = () => {
		const formdata = new FormData();
		formdata.append('is_active', '1');
		setEpTagsListLoading(true);
		axios
			.post(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=get_tags',
				formdata
			)
			.then((response) => {
				setEpTagsList(response?.data);
				setEpTagsListError(null);
			})
			.catch((err) => {
				setEpTagsListError(err);
				setEpTagsList(null);
				setEpTagsListLoading(false);
			})
			.finally(() => {
				setEpTagsListLoading(false);
			});
	};

	// Admin get all Sourcing Assignments
	const sourcingAssignment = () => {
		setSourcingAssListLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=getallacsourcingreq&email=${userMail}`
			)
			.then((response) => {
				setSourcingAssList(response?.data);
				setSourcingAssListError(null);
			})
			.catch((err) => {
				setSourcingAssListError(err);
				setSourcingAssList(null);
				setSourcingAssListLoading(false);
			})
			.finally(() => {
				setSourcingAssListLoading(false);
			});
	};

	// Sourcing MPN cost book
	const sourcingMpnQueueCostBook = () => {
		const url = window.location.pathname;
		const sectionName = url
			?.substring(url?.lastIndexOf('/'))
			.replace(/[/-]/g, ' ')
			.toUpperCase()
			.trim();

		let lastUserActivity = '';

		axios
			.get(
				`https://internal.supremecomponents.com/api/gettracking.php?user_email=${userMail}&section_name=${sectionName}`
			)
			.then((response) => {
				lastUserActivity = new Date(
					response.data[0]?.SECTION_LEAVE_TIME
				).toISOString();
				setMCBlastUserActiviry(
					new Date(response.data[0]?.SECTION_LEAVE_TIME).toISOString()
				);
			})
			.then(() => {
				setMpnCostBookLoading(true);
				axios
					.get(
						`https://internal.supremecomponents.com/api/externalgateway.php?route=getallmpncostbook&isbuy=${costBookType}&email=${userMail}&lastUserActivity=${lastUserActivity}&interval=${mpnDateRange}`
					)
					.then((response) => {
						setMpnCostBookList(response?.data);
						setMpnCostBookError(null);
					})
					.catch((err) => {
						setMpnCostBookError(err);
						setMpnCostBookList(null);
						setMpnCostBookLoading(false);
					})
					.finally(() => {
						setMpnCostBookLoading(false);
					});
			});
	};

	// Admin get all Bobcat Exclusion list
	const getBobcatExcluList = () => {
		const formdata = new FormData();
		setBobcatExcluLoading(true);
		axios
			.post(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=bobcat_get_all_excl_email',
				formdata
			)
			.then((response) => {
				setBobcatExcluList(response?.data);
				setBobcatExcluError(null);
			})
			.catch((err) => {
				setBobcatExcluError(err);
				setBobcatExcluList(null);
				setBobcatExcluLoading(false);
			})
			.finally(() => {
				setBobcatExcluLoading(false);
			});
	};

	return (
		<EdenContext.Provider
			value={{
				userMail,
				me,
				userData,
				isAuthenticated,
				userTypes,
				userType,
				setUserType,
				logout,
				userInfo,
				userInfoLoading,
				userInfoError,
				clearCache,
				cascaderDesignation,
				setCascaderDesignation,
				cascaderOption,
				setCascaderOption,
				cascaderDesignationMpn,
				setCascaderDesignationMpn,
				cascaderOptionMpn,
				setCascaderOptionMpn,
				cascaderDesignationCCE,
				setCascaderDesignationCCE,
				cascaderOptionCCE,
				setCascaderOptionCCE,
				cascaderOptionEE,
				setCascaderOptionEE,
				converStatTabEE,
				setConverStatTabEE,
				incomOutEmailTabEE,
				setIncomeOutEmailTabEE,
				converStatTableEE,
				setConverStatTableEE,
				incomOutEmailTableEE,
				setIncomeOutEmailTableEE,
				chatGptKeywords,
				chatGptKeywordsLoading,
				chatGptKeywordsError,
				chatGptAllkeywords,
				emailExclusionList,
				emailExclusionListLoading,
				emailExclusionListError,
				emailExclusions,
				epTagsList,
				epTagsListLoading,
				epTagsListError,
				epTags,
				crmAccounts,
				crmAccCusSupp,
				ssData,
				srData,
				srDataByEmail,
				sourcingAssList,
				sourcingAssListLoading,
				sourcingAssListError,
				sourcingAssignment,
				mpnCostBookList,
				mpnCostBookLoading,
				mpnCostBookError,
				setMpnCostBookList,
				mpnCostBookRowData,
				setMpnCostBookRowData,
				sourcingMpnQueueCostBook,
				costBookType,
				setCostBookType,
				setMpnDateRange,
				mpnDateRange,
				fcExtPriceLoading,
				setFcExtPriceLoading,
				MCBlastUserActiviry,
				setMCBlastUserActiviry,
				crmSuppliers,
				crmSupplierData,
				messageURl,
				dateRange,
				setDateRange,
				bobcatExcluList,
				bobcatExcluLoading,
				bobcatExcluError,
				getBobcatExcluList,
				cascaderOptions,
				mpnBrand,
				keywordsforAttachRowData,
				setKeywordsforAttachRowData,
				isMain,
			}}
		>
			{children}
		</EdenContext.Provider>
	);
};

export default EdenContext;
