import moment from 'moment';

export const dateFilterParams = {
	comparator: (filterLocalDateAtMidnight, cellValue) => {
		if (!cellValue) return -1;

		const cellDate = moment(cellValue, 'YYYY-MM-DD').toDate();

		if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
			return 0;
		}
		if (cellDate < filterLocalDateAtMidnight) {
			return -1;
		}
		if (cellDate > filterLocalDateAtMidnight) {
			return 1;
		}
		return 0;
	},
	browserDatePicker: true,
	suppressAndOrCondition: true,
};

export const dollarFilterParams = {
	comparator: (a, b) => {
		const valA = a == null ? 0 : parseFloat(a);
		const valB = b == null ? 0 : parseFloat(b);
		if (valA === valB) return 0;
		return valA > valB ? 1 : -1;
	},
	applyMiniFilterWhileTyping: true,
	valueFormatter: (p) => {
		return p.value != null ? `$ ${Math.floor(p.value)}` : '';
	},
};

export const dollarDecimalFilterParams = {
	comparator: (a, b) => {
		const valA = a == null ? 0 : parseFloat(a);
		const valB = b == null ? 0 : parseFloat(b);
		if (valA === valB) return 0;
		return valA > valB ? 1 : -1;
	},
	applyMiniFilterWhileTyping: true,
	valueFormatter: (p) => {
		return p.value != null ? `$ ${p.value}` : '';
	},
};

export const decimalFilterParams = {
	comparator: (a, b) => {
		const valA = a == null ? 0 : parseFloat(a);
		const valB = b == null ? 0 : parseFloat(b);
		if (valA === valB) return 0;
		return valA > valB ? 1 : -1;
	},
	applyMiniFilterWhileTyping: true,
	valueFormatter: (p) => {
		return p.value != null ? p.value?.toFixed(2) : '';
	},
};

export const decimalFilterParams1 = {
	comparator: (a, b) => {
		const valA = a == null ? 0 : parseFloat(a);
		const valB = b == null ? 0 : parseFloat(b);
		if (valA === valB) return 0;
		return valA > valB ? 1 : -1;
	},
	applyMiniFilterWhileTyping: true,
	valueFormatter: (p) => {
		return p.value != null ? p.value : '';
	},
};

export const numberFilterParams = {
	comparator: (a, b) => {
		var valA = a == null ? 0 : parseInt(a);
		var valB = b == null ? 0 : parseInt(b);
		if (valA === valB) return 0;
		return valA > valB ? 1 : -1;
	},
	applyMiniFilterWhileTyping: true,
};
