import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';

import EdenContext from '../../../context/edenContext';
import AccessDenied from '../../../components/accessDenied';
import BreadCrumb from '../../../components/breadcrumb';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import SectionInfo from '../../../components/sectionInfo';
import useFetch from '../../../hooks';
import { API_BASE_URL } from '../../../config/baseURLs';

import styles from './apiTokenLimits.module.scss';

const APITokenLimits = () => {
	const { userType } = useContext(EdenContext);

	const { apiData } = useFetch(`${API_BASE_URL}/admin/api-token-limits`);

	const [columnDefs] = useState([
		{ field: 'apiSource', headerName: 'API Source' },
		{ field: 'apiCallLimits', headerName: 'API Call Limits' },
		{
			field: 'sourceLink',
			headerName: 'Source Link',
			cellRenderer: (p) => (
				<Link
					style={{ textDecoration: 'none', color: 'blue' }}
					target="_blank"
					to={p.value}
				>
					{p.value && p.data.apiSource}
				</Link>
			),
		},
	]);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	);

	return (
		<>
			{userType === 'admin' ? (
				<div className={styles['wrapper']}>
					<BreadCrumb
						route1="Admin"
						route2="API Token Limits"
						icon={<MdOutlineAdminPanelSettings style={{ fontSize: '20px' }} />}
					/>
					<SectionInfo
						removeshortcutkeys
						title="API Token Limits"
						info="This section shows information about API Token Limits"
					/>
					<div className={styles['api-token-limits']}>
						<div className={styles['table-wrapper']}>
							<div className={`ag-theme-custom ${styles['table']} `}>
								<AgGridReact
									rowData={apiData?.data}
									columnDefs={columnDefs}
									defaultColDef={defaultColDef}
									animateRows={true}
									pagination={true}
									paginationPageSizeSelector={false}
								/>
							</div>
						</div>
					</div>
				</div>
			) : (
				<AccessDenied message="You are not an Admin" />
			)}
		</>
	);
};

export default APITokenLimits;
