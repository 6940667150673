import {
	memo,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import { AgGridReact } from 'ag-grid-react';
import { message, Radio } from 'antd';
import moment from 'moment';
import axios from 'axios';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import InnerHeader from '../../components/innerHeader';
import EdenContext from '../../context/edenContext';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import NoData from '../../components/nodata';
import { RadioTwo } from '../../components/radio';
import HelpDeskTrackerContext from '../../context/helpdeskTrackerContext';
import { isNotEmptyArray, titleCase } from '../../utils';
import ComposeTicket from './ComposeTicket';
import { dateFilterParams } from '../../filters';
import MailPreview from '../../components/MailPreview';
import NotificationBar from '../../components/notificationBar';
import Select from '../../components/select';
import HelpDesk from '../../images/Help desk.png';
import Description from './Description';
import Actions from './Actions';

import styles from './helpDeskTracker.module.scss';

const ticketStatus = [
	{ value: 'OPEN', label: 'Open/New' },
	{ value: 'IN_PROGRESS', label: 'In-progress' },
	{ value: 'WAITING_FOR_APPROVAL', label: 'Waiting for Approval' },
	{
		value: 'ESCALATED',
		label: 'Escalated',
	},
	{ value: 'PENDING_EMPLOYEE_RESPONSE', label: 'Pending Employee Response ' },
	{ value: 'CLOSED_AND_RESOLVED', label: 'Closed and Resolved' },
	{ value: 'CLOSED_AND_NOT_RESOLVED', label: 'Closed and Not Resolved' },
];

const ticketStatusFormatter = (value) => {
	if (value === 'OPEN') {
		return 'Open/New';
	} else if (value === 'IN_PROGRESS') {
		return 'In-progress';
	} else if (value === 'WAITING_FOR_APPROVAL') {
		return 'Waiting for Approval';
	} else if (value === 'ESCALATED') {
		return 'Escalated';
	} else if (value === 'PENDING_EMPLOYEE_RESPONSE') {
		return 'Pending Employee Response';
	} else if (value === 'CLOSED_AND_RESOLVED') {
		return 'Closed and Resolved';
	} else if (value === 'CLOSED_AND_NOT_RESOLVED') {
		return 'Closed and Not Resolved';
	}
	return value;
};

// Custom comparator for sorting filter values
const customComparator = (a, b) => {
	const specialOrder = ['(Select All)', '(Blanks)', 'Today', 'Tomorrow'];

	// Handle special cases
	const aIndex = specialOrder.indexOf(a);
	const bIndex = specialOrder.indexOf(b);

	if (aIndex > -1 && bIndex > -1) {
		return aIndex - bIndex;
	} else if (aIndex > -1) {
		return -1; // a comes first
	} else if (bIndex > -1) {
		return 1; // b comes first
	}

	// Handle nulls (blanks) - Push them to the end
	if (a === null || a === undefined || a === '(Blanks)') return 1;
	if (b === null || b === undefined || b === '(Blanks)') return -1;

	// Parse numeric day values from strings like '1 Day', '2 Days'
	const aDays = parseInt(a?.split(' ')[0]);
	const bDays = parseInt(b?.split(' ')[0]);

	return aDays - bDays;
};

const labelFormatter = (value) => {
	if (value === 0) {
		return 'Today';
	} else if (value === 1) {
		return '1 Day';
	} else if (value > 1) {
		return `${value} Days`;
	} else if (value === -1) {
		return 'Tomorrow';
	} else if (value < -1) {
		return `${Math.abs(value)} Days Ahead`;
	}
	return value;
};

const downloadFile = (p) => {
	if (p?.value) {
		const aTag = document.createElement('a');
		aTag.href = `https://internal.supremecomponents.com/api/${p.value}`;
		aTag.download = `https://internal.supremecomponents.com/api/${p.value}`;
		aTag.target = '_blank';
		document.body.appendChild(aTag);
		aTag.click();
		document.body.removeChild(aTag);
	}
};

const HelpDeskTracker = () => {
	const gridRef = useRef();
	const { userType, srData, messageURl } = useContext(EdenContext);
	const {
		data,
		isLoading,
		error,
		getData,
		cascaderOption,
		setCascaderOption,
		ticketsView,
		setTicketsView,
		mailError,
	} = useContext(HelpDeskTrackerContext);

	const [gridApi, setGridApi] = useState(null);
	const [sortActive, setSortActive] = useState(false);
	const [filterActive, setFilterActive] = useState(false);

	const getShadeOfRed = (orderIndex) => {
		const minIntensity = 100; // Lightest shade (higher order index)
		const maxIntensity = 255; // Darkest shade (lower order index)

		// Calculate intensity so higher order index results in lighter color
		const intensity = Math.min(minIntensity + orderIndex * 20, maxIntensity);
		return `rgb(${intensity}, 0, 0)`;
	};

	const [columnsDefs, setColumnDefs] = useState([
		{
			headerName: 'Ticket ID',
			field: 'TICKET_ID_LBL',
			editable: false,
			cellStyle: () => ({}),
		},
		{
			headerName: 'Requester',
			field: 'EMPLOYEE_NAME',
			editable: false,
			cellRenderer: (p) => titleCase(p.value),
			filter: 'agSetColumnFilter',
			filterParams: {
				valueFormatter: (params) => titleCase(params.value),
			},
		},
		{
			headerName: 'Created Date Time',
			field: 'CREATED_AT',
			editable: false,
			minWidth: 180,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value
						? moment
								.utc(p.value)
								.tz('Asia/Singapore')
								.format('MMM-DD-YYYY hh:mm A')
						: ''}
				</span>
			),
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},
		{
			headerName: 'Description',
			field: 'ISSUE_DETAILS',
			editable: false,
			cellRenderer: memo(Description),
		},
		{
			headerName: 'Ticket Type',
			field: 'TICKET_TYPE',
			editable: false,
			cellRenderer: (p) => titleCase(p.value),
			filter: 'agSetColumnFilter',
			filterParams: {
				valueFormatter: (params) => titleCase(params.value),
			},
		},
		{
			headerName: 'Assigned To',
			field: 'ASSIGNED_TO',
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams: {
				values: ['Arun', 'Gautam', 'Marc', 'Nitin', 'Zeeshan'],
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 120,
			},
		},
		{
			headerName: 'Ticket Status',
			field: 'STATUS',
			cellRenderer: (params) => {
				const status = ticketStatus.find((item) => item.value === params.value);
				return status ? status.label : params.value;
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				valueFormatter: (params) => ticketStatusFormatter(params.value),
			},
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams: {
				values: ticketStatus.map((status) => status.value), // only the values (keys)
				formatValue: (value) => {
					const status = ticketStatus.find((item) => item.value === value);
					return status ? status.label : value;
				},
			},
			valueFormatter: (params) => {
				const status = ticketStatus.find((item) => item.value === params.value);
				return status ? status.label : params.value;
			},
			valueParser: (params) => {
				const status = ticketStatus.find(
					(item) => item.label === params.newValue
				);
				return status ? status.value : params.newValue;
			},
		},
		{
			headerName: 'Attachment (Email)',
			field: 'ATTACH_EMAIL_ID',
			editable: false,
			cellClass: 'hyperlinks',
			minWidth: 80,
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			cellRenderer: memo(MailPreview),
			cellStyle: { padding: '4px 0 0 25px' },
		},
		{
			headerName: 'Attachment (Files)',
			field: 'ATTACHMENT_FILES',
			editable: false,
			cellClass: 'hyperlinks',
			cellRenderer: (p) =>
				p.value?.length > 0 && (
					<p
						onClick={() => downloadFile(p)}
						className={styles['blue-underline']}
					>
						Download File
					</p>
				),
		},
		{
			headerName: 'IT HD Modified Date',
			field: 'UPDATED_AT',
			editable: false,
			minWidth: 180,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value
						? moment
								.utc(p.value)
								.tz('Asia/Singapore')
								.format('MMM-DD-YYYY hh:mm A')
						: ''}
				</span>
			),
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},
		{
			headerName: 'Days Open',
			field: 'CREATED_AT_label',
			editable: false,
			filter: 'agSetColumnFilter',
			cellRenderer: (params) => {
				return labelFormatter(params.value);
			},
			filterParams: {
				comparator: customComparator,
				valueFormatter: (params) => labelFormatter(params.value),
			},
		},
		{
			headerName: 'Actions',
			field: 'actions',
			editable: false,
			cellRenderer: memo(Actions),
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			cellStyle: { padding: '2px 0 0 0' },
		},
	]);

	useEffect(() => {
		// Update cellStyle based on usertype
		const updatedColumnDefs = columnsDefs.map((colDef) => {
			if (colDef.field === 'TICKET_ID_LBL') {
				return {
					...colDef,
					cellStyle: (params) => {
						const orderIndex = parseInt(params.data.ORDER_INDEX, 10);
						return {
							backgroundColor:
								userType === 'admin' &&
								ticketsView === 'OPEN' &&
								params.data.ORDER_INDEX !== null
									? getShadeOfRed(orderIndex)
									: 'white',
							color:
								userType === 'admin' &&
								ticketsView === 'OPEN' &&
								params.data.ORDER_INDEX !== null
									? 'white'
									: 'black',
							fontWeight:
								userType === 'admin' &&
								ticketsView === 'OPEN' &&
								params.data.ORDER_INDEX !== null
									? 'bold'
									: 'normal',
						};

						// No style for non-admin
					},
				};
			}
			return colDef; // Return other column definitions unchanged
		});

		setColumnDefs(updatedColumnDefs); //eslint-disable-next-line
	}, [userType, ticketsView]);

	useEffect(() => {
		getData(); //eslint-disable-next-line
	}, [ticketsView, cascaderOption]);

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	//Export to excel
	const exportToExcel = () => {
		gridApi.exportDataAsExcel();
	};

	// export excel sheet style
	const excelStyles = [
		{
			id: 'header',
			font: {
				bold: true,
			},
		},
		{
			id: 'hyperlinks',
			font: {
				underline: 'Single',
				color: '#358ccb',
			},
		},
	];

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			floatingFilter: true,
			suppressMovable: true,
			filter: 'agMultiColumnFilter',
			editable: userType === 'admin',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[userType]
	);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	// For cascader
	const onChange = (value, selectedOptions) => {
		setCascaderOption(selectedOptions?.id);
	};

	// on clear cascader
	const clearCascader = () => {
		setCascaderOption('');
	};

	const handleTicketsView = (e) => {
		setTicketsView(e.target.value);
	};

	const onCellValueChanged = useCallback((e) => {
		const {
			ASSIGNED_TO,
			STATUS,
			TICKET_ID,
			TICKET_ID_LBL,
			EMPLOYEE_EMAIL,
			EMPLOYEE_NAME,
			ISSUE_DETAILS,
			TICKET_TYPE,
			CREATED_AT,
		} = e.data;

		const formData = new FormData();

		formData.append('TICKET_ID', TICKET_ID);
		formData.append('ASSIGNED_TO', ASSIGNED_TO ? ASSIGNED_TO : '');
		formData.append('STATUS', STATUS);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=edit_ticket_by_id`,
				formData
			)
			.then((response) => {
				const column = e.column.colDef.field;

				e.api.refreshCells({
					force: true,
					columns: [column],
					rowNodes: [e.node],
				});
				if (STATUS === 'IN_PROGRESS') {
					const formData = new FormData();

					formData.append('ticket_lbl', TICKET_ID_LBL);
					formData.append('employee_email', EMPLOYEE_EMAIL);
					formData.append('employee_name', titleCase(EMPLOYEE_NAME));
					axios
						.post(
							`https://internal.supremecomponents.com/api/externalgateway.php?route=hd_notify_in_progress`,
							formData
						)
						.then((response) =>
							message.success('Ticket marked as In-Progress')
						);
				} else if (STATUS === 'CLOSED_AND_RESOLVED') {
					const formData = new FormData();

					formData.append('ticket_lbl', TICKET_ID_LBL);
					formData.append('employee_email', EMPLOYEE_EMAIL);
					formData.append('employee_name', titleCase(EMPLOYEE_NAME));
					formData.append('issue', ISSUE_DETAILS);
					formData.append('type', titleCase(TICKET_TYPE));
					formData.append('ticket_raise_date_time', CREATED_AT);
					axios
						.post(
							`https://internal.supremecomponents.com/api/externalgateway.php?route=hd_notify_ticket_closed_resolved`,
							formData
						)
						.then((response) =>
							message.success('Ticket marked as Closed and Resolved')
						);
				} else if (STATUS === 'CLOSED_AND_NOT_RESOLVED') {
					const formData = new FormData();

					formData.append('ticket_lbl', TICKET_ID_LBL);
					formData.append('employee_email', EMPLOYEE_EMAIL);
					formData.append('employee_name', titleCase(EMPLOYEE_NAME));
					formData.append('issue', ISSUE_DETAILS);
					formData.append('type', titleCase(TICKET_TYPE));
					formData.append('ticket_raise_date_time', CREATED_AT);
					axios
						.post(
							`https://internal.supremecomponents.com/api/externalgateway.php?route=hd_notify_ticket_closed_not_resolved`,
							formData
						)
						.then((response) =>
							message.success('Ticket marked as Closed and not Resolved')
						);
				}
			})
			.catch((err) => {
				message.error(err);
			}); // eslint-disable-next-line
	}, []);

	const defaultExcelExportParams = useMemo(() => {
		return {
			sheetName: 'Help Desk',
			fileName: 'Help Desk.xlsx',
			autoConvertFormulas: true,
			columnKeys: [
				'TICKET_ID_LBL',
				'EMPLOYEE_NAME',
				'CREATED_AT',
				'ISSUE_DETAILS',
				'TICKET_TYPE',
				'ASSIGNED_TO',
				'STATUS',
				'ATTACH_EMAIL_ID',
				'ATTACHMENT_FILES',
				'UPDATED_AT',
				'CREATED_AT_label',
			],
			processCellCallback: (p) => {
				if (
					p.column.colId === 'EMPLOYEE_NAME' ||
					p.column.colId === 'TICKET_TYPE'
				) {
					if (p?.value) {
						return titleCase(p.value);
					}
					return '';
				}
				if (
					p.column.colId === 'CREATED_AT' ||
					p.column.colId === 'UPDATED_AT'
				) {
					if (p?.value) {
						return moment
							.utc(p.value)
							.tz('Asia/Singapore')
							.format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				if (p.column.colId === 'ATTACH_EMAIL_ID') {
					if (p?.value) {
						const trimmedString = p?.value?.replace(/=+$/, '');
						return `=HYPERLINK("${messageURl?.url}${trimmedString}%3D.eml")`;
					}
					return '';
				}
				if (p.column.colId === 'STATUS') {
					if (p?.value) {
						return ticketStatusFormatter(p.value);
					}
					return '';
				}
				if (p.column.colId === 'ATTACHMENT_FILES') {
					if (p?.value) {
						return `=HYPERLINK("https://internal.supremecomponents.com/api/${p.value}")`;
					}
					return '';
				}
				if (p.column.colId === 'CREATED_AT_label') {
					if (p?.value) {
						return labelFormatter(p.value);
					}
					return '';
				}
				return p.value;
			},
		}; //eslint-disable-next-line
	}, []);

	const defaultCsvExportParams = useMemo(() => {
		return {
			sheetName: 'Help Desk Tracker',
			fileName: 'Help Desk Tracker.csv',
			columnKeys: [
				'TICKET_ID_LBL',
				'EMPLOYEE_NAME',
				'CREATED_AT',
				'ISSUE_DETAILS',
				'TICKET_TYPE',
				'ASSIGNED_TO',
				'STATUS',
				'ATTACH_EMAIL_ID',
				'ATTACHMENT_FILES',
				'UPDATED_AT',
				'CREATED_AT_label',
			],
			processCellCallback: (p) => {
				if (
					p.column.colId === 'EMPLOYEE_NAME' ||
					p.column.colId === 'TICKET_TYPE'
				) {
					if (p?.value) {
						return titleCase(p.value);
					}
					return '';
				}
				if (
					p.column.colId === 'CREATED_AT' ||
					p.column.colId === 'UPDATED_AT'
				) {
					if (p?.value) {
						return moment
							.utc(p.value)
							.tz('Asia/Singapore')
							.format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				if (p.column.colId === 'ATTACH_EMAIL_ID') {
					if (p?.value) {
						const trimmedString = p?.value?.replace(/=+$/, '');
						return `=HYPERLINK("${messageURl?.url}${trimmedString}%3D.eml")`;
					}
					return '';
				}
				if (p.column.colId === 'STATUS') {
					if (p?.value) {
						return ticketStatusFormatter(p.value);
					}
					return '';
				}
				if (p.column.colId === 'ATTACHMENT_FILES') {
					if (p?.value) {
						return `=HYPERLINK("https://internal.supremecomponents.com/api/${p.value}")`;
					}
					return '';
				}
				if (p.column.colId === 'CREATED_AT_label') {
					if (p?.value) {
						return labelFormatter(p.value);
					}
					return '';
				}
				return p.value;
			},
		}; //eslint-disable-next-line
	}, []);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi.resetColumnState();
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	const rowDragEnd = useCallback(
		(e) => {
			if (!sortActive && !filterActive) {
				const indexTicketId = e.node.parent.allLeafChildren
					.map((el) => `${el.rowIndex}-${el.data.TICKET_ID}`)
					.join(',');

				const formData = new FormData();
				formData.append('data', indexTicketId);

				axios
					.post(
						`https://internal.supremecomponents.com/api/externalgateway.php?route=hd_set_order`,
						formData
					)
					.then((response) => {
						message.success(response.data);
					})
					.catch((err) => {
						message.error('Ticket Update Failed!');
					});
			} else {
				message.error('Please remove filters and sorting');
			}
		},
		[sortActive, filterActive]
	);

	const onSortChanged = useCallback(() => {
		const colState = gridRef.current.api.getColumnState() || [];
		const sortActiveState = colState.some((c) => c.sort);
		setSortActive(sortActiveState);

		const suppressRowDrag = sortActiveState || filterActive;
		gridRef.current.api.setSuppressRowDrag(suppressRowDrag);
	}, [filterActive]);

	const onFilterChanged = useCallback(() => {
		const filterActiveState = gridRef.current.api.isAnyFilterPresent();
		setFilterActive(filterActiveState);

		const suppressRowDrag = sortActive || filterActiveState;
		gridRef.current.api.setSuppressRowDrag(suppressRowDrag);
	}, [sortActive]);

	return (
		<div className={styles['wrapper']}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					// alignItems: 'center',
					margin: '10px 20px 10px 0',
				}}
			>
				<div>
					<BreadCrumb
						route1="Help Desk"
						icon={<TfiHeadphoneAlt style={{ fontSize: '20px' }} />}
					/>
					<SectionInfo
						removeshortcutkeys
						title="Help Desk"
						info="This help desk section allows you to add a new Help Desk ticket and view/manage existing tickets."
					/>
				</div>
				<img src={HelpDesk} alt="Help Desk" style={{ width: '300px' }} />
			</div>
			<InnerHeader
				exportToExcel={exportToExcel}
				updatePage
				onUpdate={getData}
				updateText="Refresh"
			/>

			<div className={styles['help-desk-tracker']}>
				{mailError && (
					<NotificationBar
						redBar
						message={`Oops! There seems to be a problem with Eden’s email system. Please send your ticket manually to tickets@supremecomponents.com.​`}
					/>
				)}
				<div className={styles['dropdown-wrapper']}>
					<Select
						placeholder="User"
						options={srData?.map((el) => ({
							value: el?.salesRepName,
							label: el?.salesRepName,
							id: el?.salesRepCrmId,
							email: el?.salesRepEmail,
						}))}
						onChange={onChange}
						allowClear={true}
						onClear={clearCascader}
						changeOnSelect={true}
						disabled={isLoading}
						title="Select Sales Rep"
						style={{ width: '170px' }}
						showSearch
					/>
				</div>
				<Radio.Group
					defaultValue={ticketsView}
					onChange={handleTicketsView}
					disabled={isLoading}
					style={{ marginBottom: '10px' }}
				>
					<RadioTwo value="OPEN">View Open Tickets</RadioTwo>
					<RadioTwo value="">View All Tickets</RadioTwo>
					<RadioTwo value="CLOSED">View Closed Tickets</RadioTwo>
					{userType === 'admin' && (
						<RadioTwo value="ARCHIVE">View Archive Tickets</RadioTwo>
					)}
				</Radio.Group>{' '}
				<ComposeTicket />
				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}
				{!isNotEmptyArray(data) && !isLoading && !error && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="No Data" />
					</div>
				)}
				{!isLoading && !error && isNotEmptyArray(data) && (
					<div className={`ag-theme-custom ${styles['table-wrapper']} `}>
						<AgGridReact
							ref={gridRef}
							onGridReady={onGridReady}
							rowData={data}
							columnDefs={columnsDefs}
							defaultColDef={defaultColDef}
							animateRows={true}
							pagination={true}
							floatingFilter={true}
							excelStyles={excelStyles}
							tooltipShowDelay={1500}
							paginationPageSizeSelector={false}
							onCellValueChanged={onCellValueChanged}
							defaultExcelExportParams={defaultExcelExportParams}
							defaultCsvExportParams={defaultCsvExportParams}
							rowDragManaged={userType === 'admin' && ticketsView === 'OPEN'}
							rowDragEntireRow={userType === 'admin' && ticketsView === 'OPEN'}
							onRowDragEnd={rowDragEnd}
							onSortChanged={onSortChanged}
							onFilterChanged={onFilterChanged}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default HelpDeskTracker;
