import { createContext, useCallback, useContext, useState } from 'react';
import axios from 'axios';
import { message } from 'antd';

import { API_BASE_URL } from '../../config/baseURLs';
import EdenContext from '../edenContext';

const FreightContext = createContext();

export const FreightProvider = ({ children }) => {
	const { userMail } = useContext(EdenContext);

	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const filterUniqueIdsAndRecent = (arr) => {
		const uniqueIdsMap = new Map();

		arr?.forEach((obj) => {
			if (obj.ID) {
				const currentId = obj.ID;
				const existingObj = uniqueIdsMap.get(currentId);

				if (
					!existingObj ||
					new Date(obj.DATE_TIME_MODIFIED) >
						new Date(existingObj.DATE_TIME_MODIFIED)
				) {
					uniqueIdsMap.set(currentId, obj);
				}
			}
		});

		return Array.from(uniqueIdsMap.values());
	};

	const getData = () => {
		setIsLoading(true);
		axios
			.get(`${API_BASE_URL}/freight`)
			.then((response) => {
				setData(response.data?.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onCellValueChanged = useCallback((e) => {
		const {
			DISCOUNTS,
			DISTRIBUTOR,
			DISTRIBUTOR_WEB_LINK,
			FREIGHT_CHARGE,
			FREIGHT_TC_URL,
			FREIGHT_TYPE,
			MOV,
			ORDER_MOV_FOR_FREE_SHIPPING,
			MOV_TC_URL,
			NAME_OF_FINDCHIPS,
			NAME_ON_OCTOPART,
			ORIGIN,
			SHIPIN_MAX_DAYS,
			SHIPIN_MIN_DAYS,
			SHIPMENT_DETAILS,
			SHIP_TO_LOCATION,
			ID,
		} = e.data;

		const formFields = {
			DISCOUNTS,
			DISTRIBUTOR,
			DISTRIBUTOR_WEB_LINK,
			FREIGHT_CHARGE,
			FREIGHT_TC_URL,
			FREIGHT_TYPE,
			MOV,
			ORDER_MOV_FOR_FREE_SHIPPING,
			MOV_TC_URL,
			NAME_OF_FINDCHIPS,
			NAME_ON_OCTOPART,
			ORIGIN,
			SHIPIN_MAX_DAYS,
			SHIPIN_MIN_DAYS,
			SHIPMENT_DETAILS,
			SHIP_TO_LOCATION,
			USER_EMAIL: userMail,
		};

		axios({
			method: ID ? 'put' : 'post',
			url: ID ? `${API_BASE_URL}/freight/${ID}` : `${API_BASE_URL}/freight`,
			data: formFields,
		})
			.then((response) => {
				if (!ID) {
					axios
						.get(`${API_BASE_URL}/freight/${response.data?.data}`)
						.then((response) => {
							setData((prev) =>
								filterUniqueIdsAndRecent([...prev, response.data?.data])
							);
							const column = e.column.colDef.field;

							e.api.refreshCells({
								force: true,
								columns: [column],
								rowNodes: [e.node],
							});
							message.success(response.data?.message);
						});
				}
			})
			.catch((err) => {
				message.error(err);
			}); // eslint-disable-next-line
	}, []);

	return (
		<FreightContext.Provider
			value={{
				getData,
				data,
				setData,
				isLoading,
				error,
				filterUniqueIdsAndRecent,
				onCellValueChanged,
			}}
		>
			{children}
		</FreightContext.Provider>
	);
};

export default FreightContext;
