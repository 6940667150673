import { createContext, useCallback, useContext, useState } from 'react';
import axios from 'axios';
import { message } from 'antd';

import { API_BASE_URL } from '../../config/baseURLs';
import EdenContext from '../edenContext';

const KeyForAttachContext = createContext();

export const KeyForAttachProvider = ({ children }) => {
	const { userMail } = useContext(EdenContext);

	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const filterUniqueIdsAndRecent = (arr) => {
		const uniqueIdsMap = new Map();

		arr?.forEach((obj) => {
			// Check if the object has an ID property
			if (obj.ID) {
				const currentId = obj.ID;
				const existingObj = uniqueIdsMap.get(currentId);

				if (
					!existingObj ||
					new Date(obj.DATE_TIME_MODIFIED) >
						new Date(existingObj.DATE_TIME_MODIFIED)
				) {
					uniqueIdsMap.set(currentId, obj);
				}
			}
		});

		return Array.from(uniqueIdsMap.values());
	};

	const getData = () => {
		setIsLoading(true);
		axios
			.get(`${API_BASE_URL}/admin/keywords-for-attachment`)
			.then((response) => {
				setData(response.data?.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onCellValueChanged = useCallback((e) => {
		const cellValue = e.data;

		const formFields = {
			KEYWORD: cellValue?.KEYWORD,
			USER_EMAIL: userMail,
		};

		axios({
			method: cellValue?.ID ? 'put' : 'post',
			url: cellValue?.ID
				? `${API_BASE_URL}/admin/keywords-for-attachment/${cellValue?.ID}`
				: `${API_BASE_URL}/admin/keywords-for-attachment`,
			data: formFields,
		})
			.then((response) => {
				axios
					.get(
						`${API_BASE_URL}/admin/keywords-for-attachment/${response.data?.data}`
					)
					.then((response) => {
						setData((prev) =>
							filterUniqueIdsAndRecent([...prev, response.data?.data])
						);
						const column = e.column.colDef.field;

						e.api.refreshCells({
							force: true,
							columns: [column],
							rowNodes: [e.node],
						});
						message.success(response.data?.message);
					});
			})
			.catch((err) => {
				message.error(err);
			}); // eslint-disable-next-line
	}, []);

	return (
		<KeyForAttachContext.Provider
			value={{
				data,
				setData,
				isLoading,
				error,
				getData,
				filterUniqueIdsAndRecent,
				onCellValueChanged,
			}}
		>
			{children}
		</KeyForAttachContext.Provider>
	);
};

export default KeyForAttachContext;
