import { useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gsap, Power2 } from 'gsap';
import {
	BsArrowRight,
	BsArrow90DegRight,
	BsArrow90DegUp,
	BsArrowLeft,
	BsArrowDown,
} from 'react-icons/bs';
import EdenContext from '../../context/edenContext';
import userAvatar from '../../images/userAvatar.svg';

import styles from './home.module.scss';

const Home = () => {
	const { userType, me } = useContext(EdenContext);

	const userJourney = useRef(null);
	const userWrapper = useRef(null);
	const userRightArrow = useRef(null);
	const userJourneyCardWrapper = useRef(null);
	const userJourneyCard = useRef(null);
	const customerWrapper = useRef(null);
	const arrow90DegRight = useRef(null);
	const emailEngagement = useRef(null);
	const emailEngagementChildren = useRef(null);
	const customerArrowRight = useRef(null);
	const rfqOpportunities = useRef(null);
	const rfqOpportunitiesChildren = useRef(null);
	const customerArrowDown = useRef(null);
	const quotes = useRef(null);
	const quotesChildren = useRef(null);
	const customerArrowLeft = useRef(null);
	const sources = useRef(null);
	const sourcesChildren = useRef(null);
	const arrow90DegUp = useRef(null);

	const journey = {
		srJourney: {
			me: {
				id: 1,
				title: 'My Sales Performance',
				data: [
					{
						id: 1,
						title: 'Get insights into my performance',
						active: false,
						navigate: '/sales-performance/insights',
					},
					{
						id: 2,
						title: 'View my YOY trends',
						active: false,
						navigate: '/sales-performance/trends',
					},
					{
						id: 3,
						title: 'View Account-level record of transactions',
						active: true,
						navigate: '/sales-performance/customer-report',
					},
					{
						id: 4,
						title: 'View MPN-level record of transactions',
						active: true,
						navigate: '/sales-performance/mpn-report',
					},
				],
			},
			customer: {
				emailEngagement: {
					id: 1,
					title: 'My Email Engagement',
					data: [
						// {
						// 	id: 1,
						// 	title: 'View Insights',
						// 	active: false,
						// 	navigate: '/email-engagement/insights',
						// },
						{
							id: 2,
							title: 'View Customer email activity',
							active: true,
							navigate: '/email-engagement/customer-contact-engagement',
						},
						{
							id: 3,
							title: 'View Conversation statics',
							active: false,
							navigate: '/email-engagement/conversation-statics',
						},
					],
				},
				rfqOpportunities: {
					id: 2,
					title: 'My RFQ Opportunities',
					data: [
						{
							id: 1,
							title: 'View My RFQ stats by account',
							active: false,
							navigate: '/rfq-opportunities/rfq-stats',
						},
						{
							id: 2,
							title: 'View my RFQ by account',
							active: true,
							navigate: '/rfq-opportunities/rfq-accounts',
						},
					],
				},
				quotes: {
					id: 3,
					title: 'My Quotes',
					data: [
						{
							id: 1,
							title: 'View my Quote Summaries',
							active: false,
							navigate: '/my-quotes/quote-summary',
						},
						{
							id: 2,
							title: 'View my Quote Book',
							active: false,
							navigate: '/my-quotes/mpn-quote-book',
						},
					],
				},
				sources: {
					id: 4,
					title: 'My Sources',
					data: [
						{
							id: 1,
							title: 'View My MPN Cost book',
							active: true,
							navigate: '/my-sources/mpn-cost-book',
						},
						{
							id: 2,
							title: 'View the sourcing queue',
							active: true,
							navigate: '/my-sources/mpn-queue',
						},
					],
				},
			},
		},
		smJourney: {
			me: {
				id: 1,
				title: 'My Team’s Sales Performance',
				data: [
					{
						id: 1,
						title: 'Get my team’s insights into our performance',
						active: false,
						navigate: '/sales-performance/insights',
					},
					{
						id: 2,
						title: 'View my team’s YOY trends',
						active: false,
						navigate: '/sales-performance/trends',
					},
					{
						id: 3,
						title: 'View my team’s Account-level record of transactions',
						active: true,
						navigate: '/sales-performance/customer-report',
					},
					{
						id: 4,
						title: 'View my team’s MPN-level record of transactions',
						active: true,
						navigate: '/sales-performance/mpn-report',
					},
				],
			},
			customer: {
				emailEngagement: {
					id: 1,
					title: 'My Team’s Email Engagement',
					data: [
						// {
						// 	id: 1,
						// 	title: 'View my team’s customer email activity',
						// 	active: false,
						// 	navigate: '/email-engagement/insights',
						// },
						{
							id: 2,
							title: 'View my team’s contact email activity',
							active: true,
							navigate: '/email-engagement/customer-contact-engagement',
						},
						{
							id: 3,
							title: 'View my team’s conversation statistics',
							active: false,
							navigate: '/email-engagement/conversation-statics',
						},
					],
				},
				rfqOpportunities: {
					id: 2,
					title: 'My Team’s RFQ opportunities',
					data: [
						{
							id: 1,
							title: 'View my team’s RFQ stats by account',
							active: false,
							navigate: '/rfq-opportunities/rfq-stats',
						},
						{
							id: 2,
							title: 'View my team’s RFQs by account',
							active: true,
							navigate: '/rfq-opportunities/rfq-accounts',
						},
					],
				},
				quotes: {
					id: 3,
					title: 'My Team’s Quotes',
					data: [
						{
							id: 1,
							title: 'View my team’s Quote Summaries',
							active: false,
							navigate: '/my-quotes/quote-summary',
						},
						{
							id: 2,
							title: 'View my team’s Quote Book',
							active: false,
							navigate: '/my-quotes/mpn-quote-book',
						},
					],
				},
				sources: {
					id: 4,
					title: 'My Team’s Sources',
					data: [
						{
							id: 1,
							title: 'View my team’s MPN cost book',
							active: true,
							navigate: '/my-sources/mpn-cost-book',
						},
						{
							id: 2,
							title: 'View the Sourcing Queue',
							active: true,
							navigate: '/my-sources/mpn-queue',
						},
					],
				},
			},
		},
		ceoJourney: {
			me: {
				id: 1,
				title: 'SCI’s Sales Performance',
				data: [
					{
						id: 1,
						title: 'Get insights into SCI’s performance',
						active: false,
						navigate: '/sales-performance/insights',
					},
					{
						id: 2,
						title: 'View SCI’s team’s YOY trends',
						active: false,
						navigate: '/sales-performance/trends',
					},
					{
						id: 3,
						title: 'View SCI’s team’s Account-level record of transactions',
						active: userType === 'ss' ? false : true,
						navigate: '/sales-performance/customer-report',
					},
					{
						id: 4,
						title: 'View SCI’s team’s MPN-level record of transactions',
						active: userType === 'ss' ? false : true,
						navigate: '/sales-performance/mpn-report',
					},
				],
			},
			customer: {
				emailEngagement: {
					id: 1,
					title: 'SCI’s Email Engagement',
					data: [
						// {
						// 	id: 1,
						// 	title: 'View SCI’s team’s  customer email activity',
						// 	active: false,
						// 	navigate: '/email-engagement/insights',
						// },
						{
							id: 2,
							title: 'View SCI’s team’s contact email activity',
							active: userType === 'ss' ? false : true,
							navigate: '/email-engagement/customer-contact-engagement',
						},
						{
							id: 3,
							title: 'View SCI’s team’s conversation statistics',
							active: false,
							navigate: '/email-engagement/conversation-statics',
						},
					],
				},
				rfqOpportunities: {
					id: 2,
					title: 'SCI’s RFQ opportunities',
					data: [
						{
							id: 1,
							title: 'View SCI’s RFQ stats by account',
							active: false,
							navigate: '/rfq-opportunities/rfq-stats',
						},
						{
							id: 2,
							title: 'View SCI’s RFQs by account',
							active: true,
							navigate: '/rfq-opportunities/rfq-accounts',
						},
					],
				},
				quotes: {
					id: 3,
					title: 'SCI’s Quotes',
					data: [
						{
							id: 1,
							title: 'View SCI’s Quote Summaries',
							active: false,
							navigate: '/my-quotes/quote-summary',
						},
						{
							id: 2,
							title: 'View SCI’s Quote Book',
							active: false,
							navigate: '/my-quotes/mpn-quote-book',
						},
					],
				},
				sources: {
					id: 4,
					title: 'SCI’s Sources',
					data: [
						{
							id: 1,
							title: 'View SCI’s MPN cost book',
							active: true,
							navigate: '/my-sources/mpn-cost-book',
						},
						{
							id: 2,
							title: 'View the Sourcing Queue',
							active: true,
							navigate: '/my-sources/mpn-queue',
						},
					],
				},
			},
		},
	};

	useEffect(() => {
		const from = {
			opacity: 0,
			scale: 0.1,
		};

		const to = {
			opacity: 1,
			scale: 1,
		};

		const stagger = {
			opacity: 1,
			scale: 1,
			stagger: 0.4,
		};

		if (!localStorage.getItem('customer-journey-animation')) {
			gsap
				.timeline({
					defaults: {
						duration: 0.4,
						ease: Power2.easeOut,
					},
				})
				.fromTo(userWrapper.current, from, to)
				.fromTo(userRightArrow.current, from, to)
				.fromTo(userJourneyCardWrapper.current, from, to)
				.fromTo(userJourneyCard.current?.children, from, stagger)
				.fromTo(customerWrapper.current, from, to)
				.fromTo(arrow90DegRight.current, from, to)
				.fromTo(emailEngagement.current, from, to)
				.fromTo(emailEngagementChildren.current?.children, from, stagger)
				.fromTo(customerArrowRight.current, from, to)
				.fromTo(rfqOpportunities.current, from, to)
				.fromTo(rfqOpportunitiesChildren.current?.children, from, stagger)
				.fromTo(customerArrowDown.current, from, to)
				.fromTo(sources.current, from, to)
				.fromTo(sourcesChildren.current?.children, from, stagger)
				.fromTo(customerArrowLeft.current, from, to)
				.fromTo(quotes.current, from, to)
				.fromTo(quotesChildren.current?.children, from, stagger)
				.fromTo(arrow90DegUp.current, from, to);
		} else {
			gsap
				.timeline({
					defaults: {
						duration: 0.8,
						ease: Power2.easeOut,
					},
				})
				.fromTo(userJourney.current, from, to);
		}

		localStorage.setItem('customer-journey-animation', true);
	}, []);

	return (
		<div className={styles['home-wrapper']}>
			<div className={styles['journey']} ref={userJourney}>
				<div className={styles['user-journey-wrapper']}>
					<div className={styles['user-wrapper']} ref={userWrapper}>
						<img
							src={userAvatar}
							alt="user"
							className={styles['user-avatar']}
						/>
						<p className={styles['user-name']}>
							{me?.givenName}&nbsp;
							{me?.surname}
						</p>
					</div>
					<div className={styles['right-arrow']} ref={userRightArrow}>
						<BsArrowRight className={styles['arrow']} />
					</div>
					<div
						className={styles['journey-card-wrapper']}
						ref={userJourneyCardWrapper}
					>
						<p className={styles['journey-name']}>
							{userType === 'sr' && journey.srJourney.me.title}
							{userType === 'sm' && journey.smJourney.me.title}
							{(userType === 'ss' ||
								userType === 'ceo' ||
								userType === 'admin') &&
								journey.ceoJourney.me.title}
						</p>

						<div className={styles['cards-wrapper']} ref={userJourneyCard}>
							{userType === 'sr' &&
								journey.srJourney.me.data.map((data) => (
									<Link
										key={data.id}
										to={data.navigate}
										className={`${styles['link-card']} ${
											data.active === false && styles['disable-card']
										}`}
									>
										<div
											className={`${styles['journey-card']} ${
												data.active === true && styles['card-hover-border-sr']
											}`}
										>
											<p className={styles['title']}>{data.title}</p>
										</div>
									</Link>
								))}
							{userType === 'sm' &&
								journey.smJourney.me.data.map((data) => (
									<Link
										key={data.id}
										to={data.navigate}
										className={`${styles['link-card']} ${
											data.active === false && styles['disable-card']
										}`}
									>
										<div
											className={`${styles['journey-card']} ${
												data.active === true && styles['card-hover-border-sm']
											}`}
										>
											<p className={styles['title']}>{data.title}</p>
										</div>
									</Link>
								))}
							{userType === 'ss' &&
								journey.ceoJourney.me.data.map((data) => (
									<Link
										key={data.id}
										to={data.navigate}
										className={`${styles['link-card']} ${
											data.active === false && styles['disable-card']
										}`}
									>
										<div
											className={`${styles['journey-card']} ${
												data.active === true && styles['card-hover-border-ss']
											}`}
										>
											<p className={styles['title']}>{data.title}</p>
										</div>
									</Link>
								))}
							{userType === 'ceo' &&
								journey.ceoJourney.me.data.map((data) => (
									<Link
										key={data.id}
										to={data.navigate}
										className={`${styles['link-card']} ${
											data.active === false && styles['disable-card']
										}`}
									>
										<div
											className={`${styles['journey-card']} ${
												data.active === true && styles['card-hover-border-ceo']
											}`}
										>
											<p className={styles['title']}>{data.title}</p>
										</div>
									</Link>
								))}
							{userType === 'admin' &&
								journey.ceoJourney.me.data.map((data) => (
									<Link
										key={data.id}
										to={data.navigate}
										className={`${styles['link-card']} ${
											data.active === false && styles['disable-card']
										}`}
									>
										<div
											className={`${styles['journey-card']} ${
												data.active === true && styles['card-hover-border']
											}`}
										>
											<p className={styles['title']}>{data.title}</p>
										</div>
									</Link>
								))}
						</div>
					</div>
				</div>

				<div className={styles['customer-journey-wrapper']}>
					<div className={styles['avatar-arrow-wrapper']}>
						<div className={styles['curve-arrow']} ref={arrow90DegRight}>
							<BsArrow90DegRight className={styles['arrow']} />
						</div>
						<div
							className={styles['my-customer-avatar-name']}
							ref={customerWrapper}
						>
							<img
								src="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80"
								alt="my customer"
								className={styles['avatar']}
							/>
							<p className={styles['name']}>My Customer</p>
						</div>
						<div className={styles['curve-arrow']} ref={arrow90DegUp}>
							<BsArrow90DegUp className={styles['arrow']} />
						</div>
					</div>

					<div className={styles['cards-arrow-wrapper']}>
						<div className={styles['cards-arrow-wrapper-first-section']}>
							<div className={styles['journey']} ref={emailEngagement}>
								<p className={styles['journey-name']}>
									{userType === 'sr' &&
										journey.srJourney.customer.emailEngagement.title}
									{userType === 'sm' &&
										journey.smJourney.customer.emailEngagement.title}
									{(userType === 'ss' ||
										userType === 'ceo' ||
										userType === 'admin') &&
										journey.ceoJourney.customer.emailEngagement.title}
								</p>
								<div
									className={styles['cards-wrapper']}
									ref={emailEngagementChildren}
								>
									{userType === 'sr' &&
										journey.srJourney.customer.emailEngagement.data.map(
											(data) => (
												<Link
													key={data.id}
													to={data.navigate}
													className={`${styles['link-card']} ${
														data.active === false && styles['disable-card']
													}`}
												>
													<div
														className={`${styles['journey-card']} ${
															data.active === true &&
															styles['card-hover-border-sr']
														}`}
													>
														<p className={styles['title']}>{data.title}</p>
													</div>
												</Link>
											)
										)}
									{userType === 'sm' &&
										journey.smJourney.customer.emailEngagement.data.map(
											(data) => (
												<Link
													key={data.id}
													to={data.navigate}
													className={`${styles['link-card']} ${
														data.active === false && styles['disable-card']
													}`}
												>
													<div
														className={`${styles['journey-card']} ${
															data.active === true &&
															styles['card-hover-border-sm']
														}`}
													>
														<p className={styles['title']}>{data.title}</p>
													</div>
												</Link>
											)
										)}
									{userType === 'ss' &&
										journey.ceoJourney.customer.emailEngagement.data.map(
											(data) => (
												<Link
													key={data.id}
													to={data.navigate}
													className={`${styles['link-card']} ${
														data.active === false && styles['disable-card']
													}`}
												>
													<div
														className={`${styles['journey-card']} ${
															data.active === true &&
															styles['card-hover-border-ss']
														}`}
													>
														<p className={styles['title']}>{data.title}</p>
													</div>
												</Link>
											)
										)}
									{userType === 'ceo' &&
										journey.ceoJourney.customer.emailEngagement.data.map(
											(data) => (
												<Link
													key={data.id}
													to={data.navigate}
													className={`${styles['link-card']} ${
														data.active === false && styles['disable-card']
													}`}
												>
													<div
														className={`${styles['journey-card']} ${
															data.active === true &&
															styles['card-hover-border-ceo']
														}`}
													>
														<p className={styles['title']}>{data.title}</p>
													</div>
												</Link>
											)
										)}
									{userType === 'admin' &&
										journey.ceoJourney.customer.emailEngagement.data.map(
											(data) => (
												<Link
													key={data.id}
													to={data.navigate}
													className={`${styles['link-card']} ${
														data.active === false && styles['disable-card']
													}`}
												>
													<div
														className={`${styles['journey-card']} ${
															data.active === true &&
															styles['card-hover-border']
														}`}
													>
														<p className={styles['title']}>{data.title}</p>
													</div>
												</Link>
											)
										)}
								</div>
							</div>
							<div className={styles['right-arrow']} ref={customerArrowRight}>
								<BsArrowRight className={styles['arrow']} />
							</div>
							<div
								className={`${styles['journey']} ${styles['rfq-opportunities']}`}
								ref={rfqOpportunities}
							>
								<p className={styles['journey-name']}>
									{userType === 'sr' &&
										journey.srJourney.customer.rfqOpportunities.title}
									{userType === 'sm' &&
										journey.smJourney.customer.rfqOpportunities.title}
									{(userType === 'ss' ||
										userType === 'ceo' ||
										userType === 'admin') &&
										journey.ceoJourney.customer.rfqOpportunities.title}
								</p>
								<div
									className={styles['cards-wrapper']}
									ref={rfqOpportunitiesChildren}
								>
									{userType === 'sr' &&
										journey.srJourney.customer.rfqOpportunities.data.map(
											(data) => (
												<Link
													key={data.id}
													to={data.navigate}
													className={`${styles['link-card']} ${
														data.active === false && styles['disable-card']
													}`}
												>
													<div
														className={`${styles['journey-card']} ${
															data.active === true &&
															styles['card-hover-border-sr']
														}`}
													>
														<p className={styles['title']}>{data.title}</p>
													</div>
												</Link>
											)
										)}
									{userType === 'sm' &&
										journey.smJourney.customer.rfqOpportunities.data.map(
											(data) => (
												<Link
													key={data.id}
													to={data.navigate}
													className={`${styles['link-card']} ${
														data.active === false && styles['disable-card']
													}`}
												>
													<div
														className={`${styles['journey-card']} ${
															data.active === true &&
															styles['card-hover-border-sm']
														}`}
													>
														<p className={styles['title']}>{data.title}</p>
													</div>
												</Link>
											)
										)}
									{userType === 'ss' &&
										journey.ceoJourney.customer.rfqOpportunities.data.map(
											(data) => (
												<Link
													key={data.id}
													to={data.navigate}
													className={`${styles['link-card']} ${
														data.active === false && styles['disable-card']
													}`}
												>
													<div
														className={`${styles['journey-card']} ${
															data.active === true &&
															styles['card-hover-border-ss']
														}`}
													>
														<p className={styles['title']}>{data.title}</p>
													</div>
												</Link>
											)
										)}
									{userType === 'ceo' &&
										journey.ceoJourney.customer.rfqOpportunities.data.map(
											(data) => (
												<Link
													key={data.id}
													to={data.navigate}
													className={`${styles['link-card']} ${
														data.active === false && styles['disable-card']
													}`}
												>
													<div
														className={`${styles['journey-card']} ${
															data.active === true &&
															styles['card-hover-border-ceo']
														}`}
													>
														<p className={styles['title']}>{data.title}</p>
													</div>
												</Link>
											)
										)}
									{userType === 'admin' &&
										journey.ceoJourney.customer.rfqOpportunities.data.map(
											(data) => (
												<Link
													key={data.id}
													to={data.navigate}
													className={`${styles['link-card']} ${
														data.active === false && styles['disable-card']
													}`}
												>
													<div
														className={`${styles['journey-card']} ${
															data.active === true &&
															styles['card-hover-border']
														}`}
													>
														<p className={styles['title']}>{data.title}</p>
													</div>
												</Link>
											)
										)}
								</div>
								<div className={styles['down-arrow']} ref={customerArrowDown}>
									<BsArrowDown
										className={`${
											!localStorage.getItem('customer-journey-animation')
												? styles['arr']
												: styles['arrow']
										}`}
									/>
								</div>
							</div>
						</div>

						<div className={styles['cards-arrow-wrapper-second-section']}>
							<div className={styles['journey']} ref={quotes}>
								<p className={styles['journey-name']}>
									{userType === 'sr' && journey.srJourney.customer.quotes.title}
									{userType === 'sm' && journey.smJourney.customer.quotes.title}
									{(userType === 'ss' ||
										userType === 'ceo' ||
										userType === 'admin') &&
										journey.ceoJourney.customer.quotes.title}
								</p>
								<div className={styles['cards-wrapper']} ref={quotesChildren}>
									{userType === 'sr' &&
										journey.srJourney.customer.quotes.data.map((data) => (
											<Link
												key={data.id}
												to={data.navigate}
												className={`${styles['link-card']} ${
													data.active === false && styles['disable-card']
												}`}
											>
												<div
													className={`${styles['journey-card']} ${
														data.active === true &&
														styles['card-hover-border-sr']
													}`}
												>
													<p className={styles['title']}>{data.title}</p>
												</div>
											</Link>
										))}
									{userType === 'sm' &&
										journey.smJourney.customer.quotes.data.map((data) => (
											<Link
												key={data.id}
												to={data.navigate}
												className={`${styles['link-card']} ${
													data.active === false && styles['disable-card']
												}`}
											>
												<div
													className={`${styles['journey-card']} ${
														data.active === true &&
														styles['card-hover-border-sm']
													}`}
												>
													<p className={styles['title']}>{data.title}</p>
												</div>
											</Link>
										))}
									{userType === 'ss' &&
										journey.ceoJourney.customer.quotes.data.map((data) => (
											<Link
												key={data.id}
												to={data.navigate}
												className={`${styles['link-card']} ${
													data.active === false && styles['disable-card']
												}`}
											>
												<div
													className={`${styles['journey-card']} ${
														data.active === true &&
														styles['card-hover-border-ss']
													}`}
												>
													<p className={styles['title']}>{data.title}</p>
												</div>
											</Link>
										))}
									{userType === 'ceo' &&
										journey.ceoJourney.customer.quotes.data.map((data) => (
											<Link
												key={data.id}
												to={data.navigate}
												className={`${styles['link-card']} ${
													data.active === false && styles['disable-card']
												}`}
											>
												<div
													className={`${styles['journey-card']} ${
														data.active === true &&
														styles['card-hover-border-ceo']
													}`}
												>
													<p className={styles['title']}>{data.title}</p>
												</div>
											</Link>
										))}
									{userType === 'admin' &&
										journey.ceoJourney.customer.quotes.data.map((data) => (
											<Link
												key={data.id}
												to={data.navigate}
												className={`${styles['link-card']} ${
													data.active === false && styles['disable-card']
												}`}
											>
												<div
													className={`${styles['journey-card']} ${
														data.active === true && styles['card-hover-border']
													}`}
												>
													<p className={styles['title']}>{data.title}</p>
												</div>
											</Link>
										))}
								</div>
							</div>
							<div className={styles['left-arrow']} ref={customerArrowLeft}>
								<BsArrowLeft className={styles['arrow']} />
							</div>
							<div className={styles['journey']} ref={sources}>
								<p className={styles['journey-name']}>
									{userType === 'sr' &&
										journey.srJourney.customer.sources.title}
									{userType === 'sm' &&
										journey.smJourney.customer.sources.title}
									{(userType === 'ss' ||
										userType === 'ceo' ||
										userType === 'admin') &&
										journey.ceoJourney.customer.sources.title}
								</p>
								<div className={styles['cards-wrapper']} ref={sourcesChildren}>
									{userType === 'sr' &&
										journey.srJourney.customer.sources.data.map((data) => (
											<Link
												key={data.id}
												to={data.navigate}
												className={`${styles['link-card']} ${
													data.active === false && styles['disable-card']
												}`}
											>
												<div
													className={`${styles['journey-card']} ${
														data.active === true &&
														styles['card-hover-border-sr']
													}`}
												>
													<p className={styles['title']}>{data.title}</p>
												</div>
											</Link>
										))}
									{userType === 'sm' &&
										journey.smJourney.customer.sources.data.map((data) => (
											<Link
												key={data.id}
												to={data.navigate}
												className={`${styles['link-card']} ${
													data.active === false && styles['disable-card']
												}`}
											>
												<div
													className={`${styles['journey-card']} ${
														data.active === true &&
														styles['card-hover-border-sm']
													}`}
												>
													<p className={styles['title']}>{data.title}</p>
												</div>
											</Link>
										))}
									{userType === 'ss' &&
										journey.ceoJourney.customer.sources.data.map((data) => (
											<Link
												key={data.id}
												to={data.navigate}
												className={`${styles['link-card']} ${
													data.active === false && styles['disable-card']
												}`}
											>
												<div
													className={`${styles['journey-card']} ${
														data.active === true &&
														styles['card-hover-border-ss']
													}`}
												>
													<p className={styles['title']}>{data.title}</p>
												</div>
											</Link>
										))}
									{userType === 'ceo' &&
										journey.ceoJourney.customer.sources.data.map((data) => (
											<Link
												key={data.id}
												to={data.navigate}
												className={`${styles['link-card']} ${
													data.active === false && styles['disable-card']
												}`}
											>
												<div
													className={`${styles['journey-card']} ${
														data.active === true &&
														styles['card-hover-border-ceo']
													}`}
												>
													<p className={styles['title']}>{data.title}</p>
												</div>
											</Link>
										))}
									{userType === 'admin' &&
										journey.ceoJourney.customer.sources.data.map((data) => (
											<Link
												key={data.id}
												to={data.navigate}
												className={`${styles['link-card']} ${
													data.active === false && styles['disable-card']
												}`}
											>
												<div
													className={`${styles['journey-card']} ${
														data.active === true && styles['card-hover-border']
													}`}
												>
													<p className={styles['title']}>{data.title}</p>
												</div>
											</Link>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
