import { useContext, useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { HiOutlineSearch } from 'react-icons/hi';
import { CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons';

import Button from '../../components/button';
import InnerHeader from '../../components/innerHeader';
import Input from '../../components/input';
import NotificationBar from '../../components/notificationBar';
import SectionInfo from '../../components/sectionInfo';
import Select from '../../components/select';
import ErrorMessage from '../../components/errorMessage';
import { isNotEmptyArray } from '../../utils';
import NoData from '../../components/nodata';
import LoadingMessage from '../../components/loaderMessage';
import AccountSummaryCard from '../../components/accountSummaryCard';
import netCompImage from '../../images/netComponent.png';
import ToolTip from '../../components/tooltip';
import MailConfig from './MailConfig';
import EdenContext from '../../context/edenContext';
import CheckBox from '../../components/checkBox';
import TabelToolTipNotification from '../../components/tableTooltipNotification';
import { useQuery } from '../../hooks/use-query';

import styles from './netComponents.module.scss';

var asyncTimesStamp = '';

function setAsyncTimesStamp(time) {
	asyncTimesStamp = time;
}

function getAsyncTimesStamp() {
	return asyncTimesStamp;
}

function generateRandomString() {
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	let length = 10;
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters[randomIndex];
	}
	return result;
}

const numberFilterParams = {
	comparator: (a, b) => {
		var valA = a == null ? 0 : parseInt(a);
		var valB = b == null ? 0 : parseInt(b);
		if (valA === valB) return 0;
		return valA > valB ? 1 : -1;
	},
};

const NetComponents = () => {
	const { userMail } = useContext(EdenContext);
	const { query } = useQuery();
	const mpn = query.get('mpn');

	const [searchValue, setSearchValue] = useState('');
	const [dpValue, setDpValue] = useState('begins');
	const [gridApi, setGridApi] = useState();
	const [rowdata, setRowData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [drawer, setDrawer] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [totalCrmAccounts, setTotalCrmAccounts] = useState(0);
	const [loadingCrmAccounts, setLoadingCrmAccounts] = useState(0);
	const [rowCount, setRowCount] = useState();
	const [inStockInventory, setInStockInventory] = useState(false);
	const [excludeOSuppl, setExcludeOSuppl] = useState(false);

	const columnDefs = [
		{
			field: 'supplier',
			headerName: 'Supplier',
			cellRenderer: (p) => (
				<ToolTip title={p?.value} placement="bottomLeft">
					<span className={styles['font-size']}>
						{p?.value ? p?.value : ''}
					</span>
				</ToolTip>
			),
			checkboxSelection: true,
			headerCheckboxSelection: true,
			headerCheckboxSelectionCurrentPageOnly: true,
			showDisabledCheckboxes: true,
			minWidth: 180,
		},
		{
			field: 'authorised',
			headerName: '',
			sort: 'desc',
			maxWidth: 80,
			cellRenderer: (p) => (
				<span>
					{p?.value ? (
						<span
							className={styles['font-size']}
							style={{ fontWeight: 700, color: '#0070C0' }}
						>
							{p?.value}
						</span>
					) : (
						''
					)}
				</span>
			),
		},
		{
			field: 'crm_account_name',
			headerName: 'CRM A/C',
			minWidth: '100',
			cellRenderer: (p) => {
				const isGrouped = p.node.group;
				if (isGrouped) {
					return '';
				} else {
					return (
						<>
							{p?.value ? (
								<span className={styles['font-size']}>{p?.value}</span>
							) : loadingCrmAccounts === rowCount ? (
								''
							) : (
								<LoadingOutlined />
							)}
						</>
					);
				}
			},
		},
		{
			field: 'num_lines_billed',
			headerName: '# of Lines Transacted',
			cellRenderer: (p) => {
				const isGrouped = p.node.group;
				if (isGrouped) {
					return '';
				} else {
					return (
						<>
							{p?.value ? (
								<span className={styles['font-size']}>{p?.value}</span>
							) : loadingCrmAccounts === rowCount ? (
								''
							) : (
								<LoadingOutlined />
							)}
						</>
					);
				}
			},
		},
		{
			field: 'last_billed_date',
			headerName: 'Last Bill Date (days ago)',
			cellRenderer: (p) => {
				const isGrouped = p.node.group;
				const lastBilled =
					p.value?.length > 1 &&
					moment(moment().format('MM/DD/YYYY')).diff(moment(p?.value), 'days');
				if (isGrouped) {
					return '';
				} else {
					return (
						<>
							{p?.value ? (
								<span
									className={styles['font-size']}
									style={{
										color: `${
											lastBilled < 15
												? 'green'
												: lastBilled < 30
												? 'orange'
												: lastBilled > 60
												? 'red'
												: 'black'
										}`,
									}}
								>
									{lastBilled}
								</span>
							) : loadingCrmAccounts === rowCount ? (
								''
							) : (
								<LoadingOutlined />
							)}
						</>
					);
				}
			},
		},
		{
			field: 'brand_strength',
			headerName: 'Strength',
			filter: 'agSetColumnFilter',
			cellRenderer: (p) => {
				const isGrouped = p.node.group;
				if (isGrouped) {
					return '';
				} else {
					return (
						<>
							{p?.value ? (
								<ToolTip
									title={
										p.value === '-P'
											? 'Preferred'
											: p.value === '-O'
											? 'Open source'
											: p.value === '-F'
											? 'Franchised'
											: p.value === '-S'
											? 'Stock'
											: ''
									}
								>
									<span className={styles['font-size']}>{p?.value}</span>
								</ToolTip>
							) : loadingCrmAccounts === rowCount ? (
								''
							) : (
								<LoadingOutlined />
							)}
						</>
					);
				}
			},
		},
		{
			field: 'part_number',
			headerName: 'MPN',
			cellRenderer: (p) => {
				const inputValue = (
					document.getElementById('searchValue')?.value || ''
				).toLowerCase();
				const cellValue = (p.value || '').toLowerCase();

				const index = cellValue.indexOf(inputValue);
				if (index !== -1) {
					const beforeHighlight = cellValue.substring(0, index);
					const highlighted = cellValue.substring(
						index,
						index + inputValue.length
					);
					const afterHighlight = cellValue.substring(index + inputValue.length);

					return (
						<span className={styles['font-size']}>
							{beforeHighlight.toUpperCase()}
							<strong style={{ color: '#0070C0' }}>
								{highlighted.toUpperCase()}
							</strong>
							{afterHighlight.toUpperCase()}
						</span>
					);
				} else {
					return (
						<span className={styles['font-size']}>
							{cellValue.toUpperCase()}
						</span>
					);
				}
			},
			minWidth: 140,
		},
		{
			field: 'mfr',
			headerName: 'Brand',
			maxWidth: 120,
			cellRenderer: (p) => (
				<ToolTip title={p?.value} placement="bottomLeft">
					<span className={styles['font-size']}>
						{p?.value ? p?.value : ''}
					</span>
				</ToolTip>
			),
		},
		{
			field: 'qty',
			headerName: 'Qty',
			cellRenderer: (p) => (
				<ToolTip
					title={p?.value?.toLocaleString('en-US')}
					placement="bottomLeft"
				>
					<span className={styles['font-size']}>
						{p?.value ? p?.value?.toLocaleString('en-US') : ''}
					</span>
				</ToolTip>
			),
			sort: 'desc',
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
			maxWidth: 90,
		},
		{
			field: 'inventory_type',
			headerName: 'Inventory Type',
			cellRenderer: (p) => (
				<span className={styles['font-size']}>{p?.value ? p?.value : ''}</span>
			),
			filter: 'agSetColumnFilter',
		},
		{
			field: 'dc',
			headerName: 'D/C',
			maxWidth: 80,
			cellRenderer: (p) => (
				<span className={styles['font-size']}>{p?.value ? p?.value : ''}</span>
			),
		},
		{
			field: 'description',
			headerName: 'Description',
			minWidth: 200,
			cellRenderer: (p) => (
				<ToolTip title={p?.value} placement="bottomLeft">
					<span className={styles['font-size']}>
						{p?.value ? p?.value : ''}
					</span>
				</ToolTip>
			),
		},
		{
			field: 'ctr',
			headerName: 'Ctr.',
			maxWidth: 75,
			cellRenderer: (p) => (
				<span className={styles['font-size']}>{p?.value ? p?.value : ''}</span>
			),
		},
		{
			field: 'year_established',
			headerName: 'Est. Year',
			maxWidth: 100,
			cellRenderer: (p) => {
				const isGrouped = p.node.group;
				if (isGrouped) {
					return '';
				} else {
					return (
						<>
							{p?.value ? (
								<span className={styles['font-size']}>{p?.value}</span>
							) : loadingCrmAccounts === rowCount ? (
								''
							) : (
								<LoadingOutlined />
							)}
						</>
					);
				}
			},
		},
		{
			field: 'supplier_email',
			hide: true,
		},
		{
			field: 'year_of_registration',
			headerName: 'Reg. Year',
			maxWidth: 110,
			cellRenderer: (p) => (
				<ToolTip title={p?.value} placement="bottom">
					<span className={styles['font-size']}>
						{p?.value ? p?.value : ''}
					</span>
				</ToolTip>
			),
		},
		{
			field: 'uploaded',
			headerName: 'Uploaded (Days Ago)',
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
			cellRenderer: (p) => (
				<span className={styles['font-size']}>{p?.value ? p?.value : ''}</span>
			),
		},
	];

	const onChange = (value) => {
		setDpValue(value);
	};

	const handleSearch = () => {
		if (searchValue?.length > 3) {
			setLoading(true);
			setSelectedRows([]);
			setTotalCrmAccounts(0);
			setLoadingCrmAccounts(0);
			setInStockInventory(false);
			setExcludeOSuppl(false);
			setAsyncTimesStamp(generateRandomString());

			axios
				.get(
					`https://internal.supremecomponents.com/api/automate/nc/search-api.php?keyword=${searchValue
						?.replace(/[^a-zA-Z0-9]/g, '')
						?.trim()}&logic=${dpValue}&timestamp=${Date.now()}`
				)
				.then((response) => {
					if (dpValue === 'equals') {
						setRowData(
							response?.data?.filter(
								(el) =>
									el?.part_number.toLowerCase() === searchValue?.toLowerCase()
							)
						);
					} else {
						setRowData(response?.data);
					}
					setError(null);
				})
				.catch((err) => {
					setError(err);
					setRowData(null);
					setLoading(false);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		if (mpn && mpn.length > 3) {
			setSearchValue(mpn);
		}
	}, [mpn]);

	useEffect(() => {
		if (searchValue.length > 3) {
			handleSearch();
		} // eslint-disable-next-line
	}, [searchValue]);

	useEffect(() => {
		if (rowdata?.length === 0) {
			handleSearch();
		} // eslint-disable-next-line
	}, []);

	const onGridReady = async (p) => {
		setGridApi(p.api);
		setRowCount(p.api.getDisplayedRowCount());
		setTotalCrmAccounts(0);
		setLoadingCrmAccounts(0);

		let promises = [];

		p.api.forEachNode((rowNode, index) => {
			const { supplier_id, mfr } = rowNode.data;

			const requestPromise = axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=getncextendeddata&timestamp=${asyncTimesStamp}&rowindex=${index}&supplier_id=${supplier_id}&brand=${mfr}&user_email=${userMail}`
				)
				.then((response) => {
					if (rowNode) {
						if (
							response?.data?.crm_account?.length &&
							response?.data?.timestamp === asyncTimesStamp
						) {
							setTotalCrmAccounts((prevTotal) => prevTotal + 1);
						}

						// Update row data with the received data
						rowNode.setDataValue(
							'crm_account_name',
							response?.data?.crm_account || ' '
						);
						rowNode.setDataValue(
							'year_established',
							response?.data?.year_founded || ' '
						);
						rowNode.setDataValue(
							'supplier_email',
							response?.data?.email_addresses || ' '
						);
						rowNode.setDataValue(
							'num_lines_billed',
							response?.data?.num_lines_billed || ' '
						);
						rowNode.setDataValue(
							'last_billed_date',
							response?.data?.last_billed_date || ' '
						);
						rowNode.setDataValue(
							'brand_strength',
							response?.data?.brand_strength || ' '
						);
					}

					if (response?.data?.timestamp === getAsyncTimesStamp()) {
						setLoadingCrmAccounts((prev) => prev + 1);
					}
				})
				.catch((err) => {
					if (rowCount !== loadingCrmAccounts) {
						setLoadingCrmAccounts((prev) => prev + 1);
					}
				});

			promises.push(requestPromise);
		});

		await Promise.all(promises);
	};

	if (loadingCrmAccounts > rowCount) {
		setLoadingCrmAccounts(rowCount);
	}

	// Default column options
	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			enableRowGroup: true,
			enablePivot: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 80,
			filter: 'agMultiColumnFilter',
			headerClass: styles['header-style'],
		}),
		[]
	);

	// export excel sheet style
	const excelStyles = [
		{
			id: 'header',
			font: {
				bold: true,
			},
		},
	];

	const exportToExcel = () => {
		const netComponentsExcelParams = {
			sheetName: 'NetComponents',
			fileName: 'NetComponents.xlsx',
			onlySelected:
				gridApi.getSelectedRows()?.length > 0 && gridApi.getSelectedRows(),
		};
		gridApi.exportDataAsExcel(netComponentsExcelParams);
	};

	const copyToClipboard = () => {
		if (gridApi) {
			gridApi.getSelectedRows()?.length > 0
				? gridApi.getSelectedRows()
				: gridApi?.selectAll();
			gridApi.copySelectedRowsToClipboard({
				includeHeaders: true,
			});
			gridApi.deselectAll();
		}
		message.open({
			type: 'success',
			content: 'Copied to Clipboard',
		});
	};

	const autoGroupColumnDef = useMemo(() => {
		return {
			minWidth: 200,
			filter: 'agGroupColumnFilter',
		};
	}, []);

	const onSelectionChanged = (event) => {
		setSelectedRows(event.api.getSelectedRows());
	};

	const selecteCRMSupplier = () => {
		gridApi?.forEachNode((rowNode, index) => {
			if (rowNode?.data?.crm_account_name?.length >= 2) {
				rowNode.setSelected(true);
			}
		});
	};

	const noOfSupplier = [
		...new Set(rowdata?.map((item) => item?.supplier)),
	]?.filter((value) => value !== null && value !== '');

	const summaryData = [
		{
			id: 1,
			title: '# of Suppliers Found',
			value: noOfSupplier?.length,
			elementID: 'noOfSuppliers',
		},
		{
			id: 2,
			title: '# of CRM Accounts',
			value: totalCrmAccounts || 0,
			elementID: 'noOfCrmAccounts',
		},
		{
			id: 3,
			title: '# of Selected Rows',
			value: selectedRows?.length,
			elementID: 'noOfSelectedRows',
		},
		{
			id: 4,
			title: 'Fetching results',
			value: loadingCrmAccounts || 0,
			elementID: 'loading-crm-account',
		},
	];

	const isRowSelectable = useMemo(() => {
		return (params) => {
			return params.data?.supplier_email?.length > 0;
		};
	}, []);

	const selectInStockInventory = (e) => {
		setInStockInventory(e.target.checked);
		const inventoryType = gridApi?.getFilterInstance('inventory_type');
		if (e.target.checked === true) {
			inventoryType?.setModel({ values: ['In-Stock Inventory'] });
			gridApi?.onFilterChanged();
		} else if (e.target.checked === false) {
			inventoryType?.setModel({
				values: ['In-Stock Inventory', 'Brokered Inventory Listings'],
			});
			gridApi?.onFilterChanged();
		}
	};

	const excludeOSupplier = (e) => {
		setExcludeOSuppl(e.target.checked);
		const removeOSupplier = gridApi?.getFilterInstance('brand_strength');
		if (e.target.checked === true) {
			removeOSupplier?.setModel({ values: ['-P', '-F', '-S', ' '] });
			gridApi?.onFilterChanged();
		} else if (e.target.checked === false) {
			removeOSupplier?.setModel({ values: ['-P', '-F', '-S', '-O', ' '] });
			gridApi?.onFilterChanged();
		}
	};

	return (
		<div className={styles['wrapper']}>
			<SectionInfo
				title={
					<img
						src={netCompImage}
						alt="NetComponent"
						style={{ width: '300px' }}
					/>
				}
				info="This section allows you to find any parts listed in the NetComponents website. Search for parts and contact those who you would like to connect with.
        "
			/>
			<InnerHeader
				exportToExcel={exportToExcel}
				updatePage
				onUpdate={handleSearch}
				updateText="Refresh Page"
				copyToClipboard={copyToClipboard}
				massMail
				sendMassmail={() => setDrawer(true)}
				disableMassMail={
					selectedRows?.length <= 0 ||
					selectedRows?.length > 100 ||
					loadingCrmAccounts !== rowCount
				}
			/>
			<div style={{ padding: '10px 10px 0 10px' }}>
				{searchValue?.length <= 3 && (
					<NotificationBar
						redBar
						message="A minimum of 4 characters for MPN are required to view results"
					/>
				)}
				{selectedRows?.length > 100 && !loading && (
					<NotificationBar
						redBar
						message={
							<span>
								Oops! Our email pigeons can only handle up to 100 suppliers at a
								time. We tried training them to do more, but they just demanded
								more birdseed! <br /> Try reducing your selection to 100 or
								fewer rows, and our pigeons will happily fly for you!
							</span>
						}
					/>
				)}
			</div>

			<div className={styles['dropdown-wrapper']}>
				<div className={styles['right-side']}>
					<div className={styles['select-input-wrapper']}>
						<Select
							placeholder="Drill down by"
							style={{
								width: 110,
							}}
							options={[
								{ value: 'begins', label: 'Starts with' },
								{ value: 'equals', label: 'Equals' },
							]}
							defaultValue={'Starts with'}
							onChange={onChange}
							disabled={loading}
						/>
						<Input
							style={{ width: '350px' }}
							placeholder="Find Stock in NetComponents"
							onChange={(e) => setSearchValue(e.target.value)}
							onPressEnter={handleSearch}
							disabled={loading}
							id="searchValue"
							maxLength={30}
							value={searchValue}
						/>
					</div>
					<Button
						color="green"
						onClick={handleSearch}
						disabled={loading || searchValue?.length <= 3}
					>
						Search <HiOutlineSearch className={styles['search-icon']} />
					</Button>
					<Button
						color="blue"
						onClick={selecteCRMSupplier}
						disabled={!rowdata || loading}
					>
						Select All CRM Suppliers
					</Button>
					<CheckBox
						disabled={loading || !rowdata || loadingCrmAccounts !== rowCount}
						onChange={selectInStockInventory}
						checked={inStockInventory}
					>
						In-stock Inventory only
					</CheckBox>
					<CheckBox
						disabled={loading || !rowdata || loadingCrmAccounts !== rowCount}
						onChange={excludeOSupplier}
						checked={excludeOSuppl}
					>
						Exclude -O suppliers
					</CheckBox>
				</div>
			</div>
			<div
				className={
					!loading && !error && isNotEmptyArray(rowdata)
						? styles['net-components-dark-background']
						: styles['net-components']
				}
			>
				{!searchValue?.length <= 3 && !rowdata && !error && !loading && (
					<div className={styles['no-data-wrapper']}>
						<NoData search message="Please Search an MPN to Continue" />
					</div>
				)}

				{loading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Fetching the Results..." />
					</div>
				)}

				{error && !loading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(rowdata) &&
					rowdata !== null &&
					!error &&
					!loading && (
						<div className={styles['no-data-wrapper']}>
							<NoData
								message={
									<>
										<p style={{ fontSize: '20px', marginBottom: '5px' }}>
											Oops... we didn't find anything that matched this search.
										</p>
										<p style={{ fontSize: '12px' }}>
											Try shaving off characters in your search descriptor to
											expand your search results.
										</p>
									</>
								}
							/>
						</div>
					)}

				{!loading && !error && isNotEmptyArray(rowdata) && (
					<div style={{ maxWidth: '1350px' }}>
						<AccountSummaryCard>
							<div className={styles['summary-data']}>
								<>
									{summaryData?.map((el) => (
										<div key={el.id} className={styles['data-wrapper']}>
											<p className={styles['title']}>{el.title}:</p>
											<p id={el.elementID} className={styles['value']}>
												{el.value}
											</p>
											{el.id === 4 && (
												<span className={styles['value']}>/ {rowCount}</span>
											)}
										</div>
									))}
									{rowCount === loadingCrmAccounts && (
										<div
											style={{
												display: 'flex',
												gap: '5px',
												alignItems: 'center',
											}}
										>
											<CheckCircleTwoTone twoToneColor="#52c41a" />{' '}
											<span
												style={{
													color: '#52c41a',
													fontSize: '14px',
													fontWeight: 600,
												}}
											>
												Fetched all Data
											</span>
										</div>
									)}
								</>
							</div>
						</AccountSummaryCard>
						<div className={styles['table-wrapper']}>
							<div className={`ag-theme-custom ${styles['table']}`}>
								{rowCount === loadingCrmAccounts && (
									<div className={styles['table-tooltip-notification']}>
										<TabelToolTipNotification
											showNotificationLeft
											title="The data has been retrieved. Please continue to apply filters and select the supplier(s) to send mass mail."
										/>
									</div>
								)}
								<AgGridReact
									onGridReady={onGridReady}
									rowSelection={'multiple'}
									rowGroupPanelShow="always"
									groupDisplayType={'multipleColumns'}
									autoGroupColumnDef={autoGroupColumnDef}
									rowData={rowdata}
									columnDefs={columnDefs}
									defaultColDef={defaultColDef}
									animateRows={true}
									pagination={true}
									enableRangeSelection={true}
									excelStyles={excelStyles}
									rowMultiSelectWithClick={true}
									suppressRowHoverHighlight={true}
									rowClass={styles['row-style']}
									paginationPageSize={30}
									onSelectionChanged={onSelectionChanged}
									isRowSelectable={isRowSelectable}
									paginationPageSizeSelector={false}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
			{drawer && (
				<MailConfig
					drawer={drawer}
					setDrawer={setDrawer}
					selectedRows={selectedRows}
					gridApi={gridApi}
				/>
			)}
		</div>
	);
};

export default NetComponents;
