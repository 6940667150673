import React, { useContext, useEffect, useState } from 'react';
import { IoOpen } from 'react-icons/io5';

import MailPreview from '../../../components/MailPreview';
import CustomerPricesContext from '../../../context/customerPricesContext';

const Actions = (p) => {
	const { view, setView } = useContext(CustomerPricesContext);

	const [showFilterData, setShowFilterData] = useState(null);

	useEffect(() => {
		if (showFilterData && p.api) {
			const filterInstance = p.api.getFilterInstance('INTERNET_MESSAGE_ID');

			if (filterInstance) {
				if (view === 'mpn-view') {
					filterInstance.setModel({
						values: [showFilterData.INTERNET_MESSAGE_ID],
					});
					p.api.onFilterChanged();
					setView('rfq-view');
					setShowFilterData(null);
				} else if (view === 'rfq-view') {
					filterInstance.setModel({
						values: [showFilterData.INTERNET_MESSAGE_ID],
					});
					p.api.onFilterChanged();
					setView('mpn-view');
					setShowFilterData(null);
				}
			}
		} //eslint-disable-next-line
	}, [showFilterData, p.api]);

	return (
		<>
			{p.column.colId === 'uniqueMails' ? (
				<strong
					style={{ cursor: 'pointer', color: '#0070C0' }}
					onClick={() => {
						setShowFilterData(p.data);
					}}
				>
					{p.value}
				</strong>
			) : (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<MailPreview value={p.value} />
					<MailPreview
						value={p.data.REPLIED_MAIL_ID}
						color="#00684B"
						previewTooltip="Preview Replied Mail"
					/>
					<IoOpen
						onClick={() => {
							setShowFilterData(p.data);
						}}
						style={{
							fontSize: '16px',
							cursor: 'pointer',
						}}
					/>
					<span>{p.data?.CURRENCY_DETECTED}</span>
				</div>
			)}
		</>
	);
};

export default Actions;
