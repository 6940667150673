import { useContext, useState } from 'react';
import axios from 'axios';
import { Form, message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { MdModeEdit } from 'react-icons/md';

import Popconfirm from '../../../../components/popConfirm';
import ToolTip from '../../../../components/tooltip';
import AddTagForm from '../AddTagForm';
import EdenContext from '../../../../context/edenContext';

const EPAccTagsAction = (p) => {
	const { epTags } = useContext(EdenContext);
	const [form] = Form.useForm();
	const [deleteTagsPopup, setDeleteTagsPopup] = useState(false);
	const [deleteTagsLoading, setDeleteTagsLoading] = useState(false);
	const [addTagsModal, setAddTagsModal] = useState(false);

	const openDeletePopUp = () => {
		setDeleteTagsPopup(true);
	};

	const deleteTags = () => {
		setDeleteTagsLoading(true);
		const formdata = new FormData();
		formdata.append('id', p?.data?.id);
		axios
			.post(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=delete_tags',
				formdata
			)
			.then(() => {
				setDeleteTagsPopup(false);
				message.open({
					type: 'success',
					content: 'Keyword deleted successfully',
				});
				epTags();
			})
			.catch(() => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				setDeleteTagsPopup(false);
			})
			.finally(() => {
				setDeleteTagsLoading(false);
			});
	};

	const closeDeleteMailPopup = () => {
		setDeleteTagsPopup(false);
	};

	const openAddTagsModal = () => {
		setAddTagsModal(true);
	};

	const closeAddTagsModal = () => {
		setAddTagsModal(false);
	};

	const addTags = (values) => {
		const formData = new FormData();
		formData.append('crm_account', values.crm_account);
		formData.append(
			'applied_at_account_level',
			values.applied_at_account_level
		);
		formData.append(
			'contact_email',
			JSON.stringify(values.contact_email) || ''
		);
		formData.append('rfq_location', values.rfq_location);
		formData.append(
			'mpn_header_tags',
			JSON.stringify(values.mpn_header_tags) || ''
		);
		formData.append(
			'brand_header_name_tags',
			JSON.stringify(values.brand_header_name_tags) || ''
		);
		formData.append(
			'qty_header_tags',
			JSON.stringify(values.qty_header_tags) || ''
		);
		formData.append('id', p?.data?.id);
		formData.append('is_active', '1');
		axios
			.post(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=edit_tags',
				formData
			)
			.then(() => {
				message.open({
					type: 'success',
					content: 'Keyword updated successfully',
				});
				setAddTagsModal(false);
				epTags();
			})
			.catch(() => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				setAddTagsModal(false);
			});
	};

	const initialValues = {
		...p?.data,
		contact_email:
			p?.data?.contact_email === 'undefined' || p?.data?.contact_email === ''
				? null
				: JSON.parse(p?.data?.contact_email) || '',
		keyword_tags: p.data?.keyword_tags ? JSON.parse(p?.data?.keyword_tags) : '',
		mpn_header_tags: p?.data?.mpn_header_tags
			? JSON.parse(p?.data?.mpn_header_tags)
			: '',
		brand_header_name_tags: p?.data?.brand_header_name_tags
			? JSON.parse(p?.data?.brand_header_name_tags)
			: '',
		qty_header_tags: p?.data?.qty_header_tags
			? JSON.parse(p?.data?.qty_header_tags)
			: '',
	};

	return (
		<div style={{ display: 'flex', gap: '10px' }}>
			<Popconfirm
				placement="bottomLeft"
				okButtonColor="red"
				open={deleteTagsPopup}
				title="Delete"
				description="Are you sure you want to delete this row?"
				okText="Yes"
				cancelText="Cancel"
				onConfirm={deleteTags}
				onCancel={closeDeleteMailPopup}
				okButtonLoading={deleteTagsLoading}
				icon={
					<QuestionCircleOutlined
						style={{
							color: 'red',
						}}
					/>
				}
			>
				<ToolTip title="Delete">
					<RiDeleteBin6Fill
						style={{ fontSize: '18px', cursor: 'pointer', color: '#C00000' }}
						onClick={openDeletePopUp}
					/>
				</ToolTip>
			</Popconfirm>
			<ToolTip title="Edit">
				<MdModeEdit
					style={{ fontSize: '18px', cursor: 'pointer' }}
					onClick={openAddTagsModal}
				/>
			</ToolTip>
			<AddTagForm
				open={addTagsModal}
				form={form}
				onCancel={closeAddTagsModal}
				onFinish={addTags}
				initialValues={initialValues}
			/>
		</div>
	);
};

export default EPAccTagsAction;
