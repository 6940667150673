import { useContext, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Avatar, Divider } from 'antd';
import { MdMail } from 'react-icons/md';
import { LoadingOutlined } from '@ant-design/icons';

import Modal from '../../../../components/modal';
import ToolTip from '../../../../components/tooltip';
import LoadingMessage from '../../../../components/loaderMessage';
import ErrorMessage from '../../../../components/errorMessage';
import NoData from '../../../../components/nodata';
import { isNotEmptyArray } from '../../../../utils';
import EdenContext from '../../../../context/edenContext';
import Drawer from '../../../../components/drawer';
import Button from '../../../../components/button';
import { avatarBgColor } from '../../../../constant';

import styles from './mailDetails.module.scss';

const MailDetails = (p) => {
	const { colDef, data } = p;
	const { messageURl, dateRange } = useContext(EdenContext);
	const [modal, setModal] = useState(false);
	const [cellData, setCellData] = useState(null);
	const [mailDetails, setMailDetails] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [drawerMail, setDrawerMail] = useState(false);
	const [drawerMailData, setDrawerMailData] = useState(null);
	const [drawerMailLoading, setDrawerMailLoading] = useState(false);
	const [modalCellData, setModalCellData] = useState(null);

	const closeDrawer = () => {
		setDrawerMail(!drawerMail);
		setDrawerMailData(null);
		setModalCellData(null);
	};

	const openDrawer = (cid) => {
		setDrawerMail(true);
		setDrawerMailLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_mail_html&cid=${cid}`
			)
			.then((response) => {
				setDrawerMailData(response.data);
			})
			.finally(() => {
				setDrawerMailLoading(false);
			});
	};

	const gridRef = useRef();
	const [columnDefs] = useState([
		{
			headerName: 'Contact',
			field: 'contact',
		},
		{
			headerName: 'Email Address',
			field: 'emailAddress',
		},
		{
			headerName: 'Date/Time',
			field: 'receivedDate',
			sort: 'desc',
			cellRenderer: (p) => (
				<> {p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : '--'}</>
			),
		},
		{
			headerName: 'Subject Line',
			field: 'subject',
		},
		{
			headerName: 'Mail',
			field: 'webLink',
			floatingFilter: false,
			cellRenderer: (p) => {
				const trimmedString = p?.data?.messageId?.replace(/=+$/, '');
				return (
					<ToolTip title="Preview mail">
						<MdMail
							onClick={(e) => {
								if (e.ctrlKey && e.button === 0) {
									window.open(
										`${messageURl?.url}${trimmedString}%3D.eml`,
										'_blank'
									);
								} else {
									openDrawer(p.data?.messageId);
									setModalCellData(p);
								}
							}}
							style={{ fontSize: '16px', cursor: 'pointer' }}
						/>
					</ToolTip>
				);
			},
			cellStyle: { padding: '4px 0 0 25px' },
			maxWidth: 80,
		},
	]);

	const openModal = () => {
		setModal(true);
		setCellData(p);
		setLoading(true);
		axios
			.get(
				colDef?.field === 'CS_Total1wayInboundsRFQ'
					? `https://internal.supremecomponents.com/api/externalgateway.php?route=edenee1way&cemail=${
							data?.ContactEmail ? data?.ContactEmail : ''
					  }&isrfq=true&aid=${
							data?.AcctId ? data?.AcctId : data?.AccountId
					  }&wk=${dateRange}`
					: colDef?.field === 'CS_Total1wayInbounds'
					? `https://internal.supremecomponents.com/api/externalgateway.php?route=edenee1way&cemail=${
							data?.ContactEmail ? data?.ContactEmail : ''
					  }&isrfq=false&aid=${
							data?.AcctId ? data?.AcctId : data?.AccountId
					  }&wk=${dateRange}`
					: ''
			)
			.then((response) => {
				setMailDetails(response?.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setMailDetails(null);
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const closeModal = () => {
		setMailDetails(null);
		setCellData(null);
		setModal(false);
	};

	// Table default value
	const defaultColDef = useMemo(
		() => ({
			enableRowGroup: true,
			enablePivot: true,
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);

	return (
		<>
			<p onClick={openModal} className={styles['value']}>
				{p.value}
			</p>
			<Modal
				open={modal}
				onCancel={closeModal}
				width={800}
				title={
					<>
						{loading ? (
							''
						) : mailDetails?.data?.length > 0 ? (
							<>
								<p>
									{cellData?.colDef?.field === 'CS_Total1wayInbounds'
										? `${mailDetails?.data?.[0]?.accountName} (1-way Inbound)`
										: cellData?.colDef?.field === 'CS_Total1wayInboundsRFQ'
										? `${mailDetails?.data?.[0]?.accountName} (1-way Inbound w/ RFQs)`
										: ''}
								</p>
								<Divider style={{ margin: '10px 0' }} />
							</>
						) : (
							''
						)}
					</>
				}
			>
				{loading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}

				{error && !loading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(mailDetails?.data) && !error && !loading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here." />
					</div>
				)}

				{!loading && !error && isNotEmptyArray(mailDetails?.data) && (
					<div className={`ag-theme-custom ${styles['table-wrapper']}`}>
						<AgGridReact
							ref={gridRef}
							columnDefs={columnDefs}
							rowData={mailDetails?.data}
							defaultColDef={defaultColDef}
							pagination={true}
							paginationPageSize={15}
							paginationPageSizeSelector={false}
						/>
					</div>
				)}
				{drawerMail && (
					<Drawer
						title={
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<p className={styles['mail-subject']}>
									<ToolTip
										placement="bottomLeft"
										title={drawerMailData?.subject}
									>
										{drawerMailData?.subject}
									</ToolTip>
								</p>
								<Link
									style={{ textDecoration: 'none' }}
									target="_blank"
									to={`${
										messageURl?.url
									}${modalCellData?.data?.messageId?.replace(
										/=+$/,
										''
									)}%3D.eml`}
								>
									<Button type="bordered">Download Mail</Button>
								</Link>
							</div>
						}
						placement="right"
						onClose={closeDrawer}
						open={drawerMail}
						size={'large'}
					>
						{drawerMailLoading && <LoadingOutlined />}
						{drawerMailData && (
							<>
								<div className={styles['mail-details-wrapper']}>
									<div className={styles['mail-user-info']}>
										<Avatar
											className={styles['avatar']}
											style={{
												backgroundColor:
													avatarBgColor[drawerMailData?.fromName?.charAt(0)] ||
													avatarBgColor.default,
											}}
										>
											{drawerMailData?.fromName?.charAt(0)?.toUpperCase()}
										</Avatar>
										<div className={styles['users-info']}>
											<p className={styles['sender-name']}>
												{drawerMailData?.fromName}
											</p>
											{drawerMailData?.toRecipients && (
												<p className={styles['to']}>
													<span className={styles['title']}>To:</span>{' '}
													{drawerMailData?.toRecipients}
												</p>
											)}
											{drawerMailData?.ccRecipients && (
												<p className={styles['cc']}>
													<span className={styles['title']}>Cc:</span>{' '}
													{drawerMailData?.ccRecipients}
												</p>
											)}
											{drawerMailData?.bccRecipients && (
												<p className={styles['bcc']}>
													<span className={styles['title']}>Bcc:</span>{' '}
													{drawerMailData?.bccRecipients}
												</p>
											)}
										</div>
									</div>

									<p className={styles['date-info']}>
										{moment(drawerMailData?.receivedDateTime).format(
											'MMM-DD-YYYY hh:mm A'
										)}
									</p>
								</div>
								<div
									dangerouslySetInnerHTML={{ __html: drawerMailData?.html }}
								/>
							</>
						)}
					</Drawer>
				)}
			</Modal>
		</>
	);
};

export default MailDetails;
