import React, { useContext, useState } from 'react';
import { Form, message } from 'antd';
import axios from 'axios';
import { QuestionCircleOutlined } from '@ant-design/icons';

import Popconfirm from '../../../../components/popConfirm';
import ToolTip from '../../../../components/tooltip';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { MdModeEdit } from 'react-icons/md';
import AddSSForm from '../AddSSForm';
import EdenContext from '../../../../context/edenContext';

const SourcingAssignmentAction = (p) => {
	const { userMail, sourcingAssignment } = useContext(EdenContext);
	const [form] = Form.useForm();
	const [deleteSSAssignmentPopup, setDeleteSSAssignmentPopup] = useState(false);
	const [deleteSSAssignmentLoading, setDeleteSSAssignmentLoading] =
		useState(false);
	const [editSSModal, setEditSSModal] = useState(false);
	const [ssvalues, setSSValues] = useState(null);
	const [srvalues, setSRValues] = useState(null);

	const openDeletePopUp = () => {
		setDeleteSSAssignmentPopup(true);
	};

	const deleteSSAssignment = () => {
		setDeleteSSAssignmentLoading(true);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=deletesourcing&id=${p?.data?.id}&email=${userMail}`
			)
			.then(() => {
				setDeleteSSAssignmentPopup(false);
				message.open({
					type: 'success',
					content: 'Assignment revoked successfully',
				});
				sourcingAssignment();
			})
			.catch(() => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				setDeleteSSAssignmentPopup(false);
			})
			.finally(() => {
				setDeleteSSAssignmentLoading(false);
			});
	};

	const closeDeleteSSAssignmentPopup = () => {
		setDeleteSSAssignmentPopup(false);
	};

	const openEditSSModal = () => {
		setEditSSModal(true);
	};

	const closeEditSSModal = () => {
		setEditSSModal(false);
	};

	const editSS = (values) => {
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=updateacsourcingreq&id=${
					p?.data?.id
				}&loggedinuseremail=${userMail}&salesrepcrmid=${
					srvalues === null ? p?.data?.salesRep?.salesRepCrmId : srvalues?.id
				}&salesrepname=${values?.sales_rep}&salesrepemail=${
					srvalues === null ? p?.data?.salesRep?.salesRepEmail : srvalues?.email
				}&sscrmid=${
					ssvalues === null ? p?.data?.sourcingSpecialistCrmId : ssvalues?.id
				}&ssemail=${
					ssvalues === null ? p?.data?.sourcingSpecialistEmail : ssvalues?.value
				}`
			)
			.then(() => {
				form.resetFields();
				message.open({
					type: 'success',
					content: 'Sourcing Specialist Assignment edited successfully',
				});
				setEditSSModal(false);
				sourcingAssignment();
			})
			.catch((err) => {
				message.open({
					type: 'error',
					content: 'Something went wrong please try again later!',
				});
				form.resetFields();
				setEditSSModal(false);
			});
	};

	const initialValues = {
		sales_rep: p?.data?.salesRep?.salesRepName,
		ss: p?.data?.sourcingSpecialistEmail,
	};

	return (
		<div style={{ display: 'flex', gap: '10px' }}>
			<Popconfirm
				placement="bottomLeft"
				okButtonColor="red"
				open={deleteSSAssignmentPopup}
				title="Delete"
				description="Are you sure you want to delete this Sourcing Assignment?"
				okText="Yes"
				cancelText="Cancel"
				onConfirm={deleteSSAssignment}
				onCancel={closeDeleteSSAssignmentPopup}
				okButtonLoading={deleteSSAssignmentLoading}
				icon={
					<QuestionCircleOutlined
						style={{
							color: 'red',
						}}
					/>
				}
			>
				<ToolTip title="Delete">
					<RiDeleteBin6Fill
						style={{ fontSize: '18px', cursor: 'pointer', color: '#C00000' }}
						onClick={openDeletePopUp}
					/>
				</ToolTip>
			</Popconfirm>
			<ToolTip title="Edit">
				<MdModeEdit
					style={{ fontSize: '18px', cursor: 'pointer' }}
					onClick={openEditSSModal}
				/>
			</ToolTip>
			{editSSModal && (
				<AddSSForm
					open={editSSModal}
					form={form}
					onCancel={closeEditSSModal}
					onFinish={editSS}
					setSSValues={setSSValues}
					setSRValues={setSRValues}
					initialValues={initialValues}
				/>
			)}
		</div>
	);
};

export default SourcingAssignmentAction;
