import { Steps as AntdSteps } from 'antd';

import styles from './steps.module.scss';

const Steps = ({ current, onChange, items, size, style, type }) => {
	return (
		<>
			<AntdSteps
				size={size}
				className={styles['steps']}
				current={current}
				onChange={onChange}
				items={items}
				style={style}
				type={type}
			/>
		</>
	);
};

export default Steps;
