import { createContext, useEffect, useState } from 'react';
import axios from 'axios';

import { COUNTRY_CODES } from '../../columns';

const AdminHouseAccountContext = createContext();

export const AdminHouseAccountProvider = ({ children }) => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [userStatics, setUserStatics] = useState(null);
	const [sr, setSr] = useState(null);

	const getData = () => {
		setIsLoading(true);
		setSr(null);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_all_admin_accounts`
			)
			.then((response) => {
				setData(response.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const getUserStatics = () => {
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_allocated_account_count&email=${sr?.email}`
			)
			.then((response) => setUserStatics(response.data));
	};

	useEffect(() => {
		if (sr) {
			getUserStatics();
		} //eslint-disable-next-line
	}, [sr]);

	const uniqueRoundedValues = new Set();

	const processRowData = data
		?.filter((row) => COUNTRY_CODES[row?.AcctCountry] !== undefined)
		?.map((row) => {
			const billingsValue = Math.round(row.Billing);

			uniqueRoundedValues.add(billingsValue);

			return {
				...row,
				PastFiveYearBilling: billingsValue,
			};
		});

	return (
		<AdminHouseAccountContext.Provider
			value={{
				data,
				isLoading,
				error,
				getData,
				userStatics,
				sr,
				setSr,
				getUserStatics,
				processRowData,
			}}
		>
			{children}
		</AdminHouseAccountContext.Provider>
	);
};

export default AdminHouseAccountContext;
