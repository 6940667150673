import { useEffect, useMemo, useState } from 'react';
import { Radio as AntdRadio, Form } from 'antd';
import { AgGridReact } from 'ag-grid-react';

import TextArea from '../../../../../../components/textArea';
import Input from '../../../../../../components/input';
import Select from '../../../../../../components/select';
import NoData from '../../../../../../components/nodata';
import Button from '../../../../../../components/button';

import styles from './copyPaste.module.scss';

const CopyPaste = ({ copyPasteForm, copyPasteData, setCopyPasteData }) => {
	const [paste, setPaste] = useState('');
	const [delimiterValue, setDelimiterValue] = useState('/t');
	const [otherValue, setOtherValue] = useState('');
	const [rowDataStartFrom, setRowDataStartFrom] = useState(1);
	const [backupData, setBackupData] = useState();

	const [selectedColumns, setSelectedColumns] = useState({
		'MPN Req': undefined,
		'Brand Req': undefined,
		'QTY Req': undefined,
	});

	const onOtherChange = (e) => {
		if (e.target.value) {
			setDelimiterValue(null);
			setOtherValue(e.target.value);
		}
	};

	useEffect(() => {
		if (paste.length > 0) {
			// copyPasteForm.setFieldsValue({
			// 	'MPN Req': 'A',
			// 	'Brand Req': 'A',
			// 	'QTY Req': 'A',
			// });
			setRowDataStartFrom(1);
		} else if (paste === '') {
			copyPasteForm.resetFields();
			setRowDataStartFrom(null);
		}
	}, [paste, copyPasteForm]);

	const getDelimiters = () => {
		let delimiters = [delimiterValue ? delimiterValue : otherValue];

		return delimiters.length > 0 ? delimiters : [' ']; // Default to space if none selected
	};

	// const isMPN = (word) => {
	// 	return /^[\w\W]{5,}$/.test(word) && /\d/.test(word);
	// };

	// const isQuantity = (word) => {
	// 	return /^\d+[km]?$/.test(word);
	// };

	const processPartsData = (input, delimiters) => {
		const delimiterRegex = new RegExp(
			'\\s*[' +
				delimiters.join('').replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&') +
				']\\s*'
		);
		const lines = input.trim().split(/\n/);
		const partsData = [];

		lines.forEach((line) => {
			const words = line.split(delimiterRegex);
			const rowData = {};

			words.forEach((word, index) => {
				const key = String.fromCharCode(65 + index); // A, B, C, ...
				rowData[key] = word;
			});

			partsData.push(rowData);
		});

		return partsData;
	};

	const processNowPastedValue = () => {
		const input = paste;
		const delimiters = getDelimiters();
		const useTabDelimiter = delimiters.includes('\\t');
		const useTabDelimiter1 = delimiters.includes('/t');
		let data;
		let columnDefs;

		if (input.trim() !== '') {
			if (useTabDelimiter || useTabDelimiter1) {
				const lines = input.split('\n');
				data = lines
					.map((line, index) => {
						return line.trim() !== ''
							? processPartsData(line, ['\t'])[0]
							: null;
					})
					.filter((item) => item !== null);

				// Generate column definitions dynamically based on the keys in the first row
				if (data.length > 0) {
					columnDefs = Object.keys(data[0]).map((key) => ({
						headerName: key,
						field: key,
					}));
				}
			} else {
				data = processPartsData(input, delimiters);
				if (data.length > 0) {
					columnDefs = Object.keys(data[0]).map((key) => ({
						headerName: key,
						field: key,
					}));
				}
			}
		}

		const values = {
			'MPN Req': 'A',
			'Brand Req': 'B',
			'QTY Req': 'C',
		};

		const processedData = data?.map((row) => {
			const mappedRow = {};

			Object.entries(values).forEach(([key, column]) => {
				if (column !== undefined && row[column] !== undefined) {
					mappedRow[key] = row[column];
				}
			});

			return mappedRow;
		});

		return { data, columnDefs, processedData };
	};

	const { data, columnDefs, processedData } = processNowPastedValue();

	useEffect(() => {
		setCopyPasteData(processedData);
		setBackupData(processedData); //eslint-disable-next-line
	}, [paste]);

	const onDropdownSelect = (changedValues, values) => {
		setSelectedColumns(values);
		setRowDataStartFrom(1);

		const processedDataforDropdown = data?.map((row) => {
			const mappedRow = {};

			Object.entries(values).forEach(([key, column]) => {
				if (column !== undefined && row[column] !== undefined) {
					mappedRow[key] = row[column];
				}
			});

			return mappedRow;
		});

		setBackupData(processedDataforDropdown);
		setCopyPasteData(processedDataforDropdown);
	};

	const selectedRowStartsFrom = (value) => {
		setRowDataStartFrom(value);
		if (value > 0 && value <= backupData?.length) {
			setCopyPasteData(backupData?.slice(value - 1));
		}
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);

	const resetFields = () => {
		setPaste('');
		copyPasteForm?.resetFields();
		setOtherValue('');
		setRowDataStartFrom(null);
	};

	return (
		<div className={styles['wrapper']}>
			<div className={styles['left-side']}>
				<div className={styles['form-item']}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<h2 className={styles['title']}>
							1. Paste your RFQ/BOM list below
						</h2>
						<Button onClick={resetFields} style={{ marginBottom: '10px' }}>
							Reset
						</Button>
					</div>
					<TextArea
						autoSize={{ minRows: 5, maxRows: 8 }}
						allowClear={true}
						placeholder="Paste your values"
						value={paste}
						onChange={(e) => setPaste(e.target.value)}
					/>
					<div className={styles['radio-other']}>
						<AntdRadio.Group
							disabled={paste.length <= 0}
							value={delimiterValue}
							onChange={(e) => setDelimiterValue(e.target.value)}
						>
							<AntdRadio value="/t">Tab</AntdRadio>
							<AntdRadio value=" ">Space</AntdRadio>
							<AntdRadio value=";">Semicolon</AntdRadio>
							<AntdRadio value=",">Comma</AntdRadio>
							<AntdRadio value=":">Colon</AntdRadio>
							<AntdRadio value="/">Slash</AntdRadio>
						</AntdRadio.Group>
						<div className={styles['other-wrapper']}>
							<h2 className={styles['other-title']}>Other:</h2>
							<Input
								disabled={paste.length <= 0}
								onChange={onOtherChange}
								style={{ width: '30px' }}
							/>
						</div>
					</div>
				</div>
				<div className={styles['form-item']}>
					<h2 className={styles['title']}>2. Data Row Starts</h2>
					<Select
						disabled={paste.length <= 0}
						style={{ width: '50%' }}
						showSearch
						placeholder="Select data row"
						type="bordered"
						onChange={selectedRowStartsFrom}
						options={data?.map((el, index) => ({
							value: index + 1,
						}))}
						value={rowDataStartFrom}
					/>
				</div>
				<div className={styles['map-columns-wrapper']}>
					<h2 className={styles['map-columns-title']}>3. Map Columns</h2>

					<Form
						name="mpn-cost-book-copy-paste"
						labelCol={{ span: 24 }}
						form={copyPasteForm}
						onValuesChange={onDropdownSelect}
						disabled={paste.length <= 0}
					>
						<div className={styles['map-column-dropdowns']}>
							{Object.keys(selectedColumns).map((key) => (
								<Form.Item label={key.toUpperCase()} name={key} key={key}>
									<Select
										allowClear
										style={{ width: '100%' }}
										showSearch
										placeholder={`Select ${key.toUpperCase()} column`}
										type="bordered"
										options={columnDefs?.map((el) => ({
											value: el.field,
											label: el.headerName,
										}))}
									/>
								</Form.Item>
							))}
						</div>
					</Form>
				</div>
			</div>

			<div className={styles['right-side']}>
				<h1 className={styles['right-side-title']}>4. Preview</h1>
				{!data && (
					<div style={{ marginTop: '80px' }}>
						<NoData
							messageStyle={{ width: '200px' }}
							message="Paste value in Textarea to preview"
						/>
					</div>
				)}
				{data && (
					<div
						className={`ag-theme-custom ${styles['copy-paste-table-wrapper']}`}
					>
						<AgGridReact
							defaultColDef={defaultColDef}
							columnDefs={columnDefs}
							rowData={data}
							animateRows={true}
							pagination={true}
							paginationPageSize={30}
							paginationPageSizeSelector={false}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default CopyPaste;
