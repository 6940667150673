import { useMemo } from 'react';
import { Divider } from 'antd';
import { AgGridReact } from 'ag-grid-react';

import Modal from '../../../../components/modal';
import NotificationBar from '../../../../components/notificationBar';
import { backorderSitesColumn } from '../../../../columns';
import { backorderSitesData } from '../../../../constant';

import styles from './viewBackorderSites.module.scss';

const ViewBackorderSites = ({ open, onCancel }) => {
	const Notification = () => {
		return (
			<p>
				The following websites contain backorder data. Please navigate to these
				sites and search for the MPN that you’d like to look for backorder data.
				Anything Missing? Please let tickets@supremecomponents.com know!
			</p>
		);
	};

	const defaultColDef = useMemo(
		() => ({
			resizable: true,
			flex: 2,
			minWidth: 100,
			suppressHeaderMenuButton: true,
		}),
		[]
	);

	return (
		<Modal
			open={open}
			onCancel={onCancel}
			title={
				<>
					<p>Backorder Sites Data</p> <Divider style={{ margin: '5px 0' }} />
				</>
			}
			width={600}
		>
			<NotificationBar message={<Notification />} wraningBar />
			<div className={styles['table-wrapper']}>
				<div className={`ag-theme-custom ${styles['table']} `}>
					<AgGridReact
						rowData={backorderSitesData}
						columnDefs={backorderSitesColumn}
						defaultColDef={defaultColDef}
						animateRows={true}
						paginationPageSizeSelector={false}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ViewBackorderSites;
