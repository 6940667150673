import styles from './loader.module.scss';

const Loader = () => {
	return (
		<div className={styles['container']}>
			<svg
				className={styles['logo']}
				width="78"
				height="78"
				viewBox="0 0 78 78"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="39" cy="39" r="39" fill="#001433" />
				<path
					d="M26.5866 58V21.6364H50.2372V27.1584H33.174V37.0305H49.0121V42.5526H33.174V52.478H50.3793V58H26.5866Z"
					fill="white"
				/>
			</svg>

			<div className={styles['shadow']}></div>
		</div>
	);
};

export default Loader;
