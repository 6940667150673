import moment from 'moment';
import { RedoOutlined } from '@ant-design/icons';
import { MdOutlineContentCopy } from 'react-icons/md';
import { RiMailSendLine } from 'react-icons/ri';

import ExportToExcelImage from '../../images/exporttoexcel.svg';
// import PowerBiImage from '../../images/powerbi.svg';
import ToolTip from '../tooltip';

import styles from './innerHeader.module.scss';

const InnerHeader = ({
	exportToExcel,
	// exportMontlyPerf,
	copyToClipboard,
	copyToClipboardText,
	updatePage,
	lastReport,
	lastReportText,
	updateText,
	onUpdate,
	disableUpdate,
	children,
	massMail,
	lastReportTitle,
	sendMassmail,
	disableMassMail,
	rightSideChildren,
	disableExcelExport,
}) => {
	return (
		<div className={styles['inner-header-wrapper']}>
			<div className={styles['inner-header']}>
				<div className={styles['left-section']}>
					{/* <ToolTip title="Go back to the previous page" placement="bottomLeft">
						<ArrowLeftOutlined className={styles['back-icon']} />
					</ToolTip> */}
					{exportToExcel && (
						<ToolTip
							placement="bottomLeft"
							title="Export the data grid below into an Excel format for additional calculations or adding pivot tables"
						>
							<button
								className={`${styles['export-btn']} ${
									disableExcelExport && styles['export-montly']
								}`}
								onClick={exportToExcel}
							>
								<img
									className={styles['export-image']}
									src={ExportToExcelImage}
									alt="Export to Excel"
								/>
								<span>Export to Excel</span>
							</button>
						</ToolTip>
					)}
					{/* {exportMontlyPerf && (
						<ToolTip
							title="Download one or more Monthly performance Sales Rep reports"
							placement="bottomLeft"
						>
							<button
								className={`${styles['export-btn']} ${styles['export-montly']}`}
							>
								<img
									className={styles['export-image']}
									src={PowerBiImage}
									alt="Monthly Performance"
								/>
								<span>Export Monthly Performance Report</span>
							</button>
						</ToolTip>
					)} */}
					{copyToClipboard && (
						<ToolTip
							title="Copy the data grid below for additional calculations or adding pivot tables"
							placement="bottomLeft"
						>
							<button
								onClick={copyToClipboard}
								className={`${styles['export-btn']}`}
								style={{ marginRight: '5px' }}
							>
								<MdOutlineContentCopy style={{ fontSize: '18px' }} />
								<span>
									{copyToClipboardText
										? copyToClipboardText
										: 'Copy to Clipboard'}
								</span>
							</button>
						</ToolTip>
					)}
					{massMail && (
						<button
							onClick={sendMassmail}
							className={`${styles['export-btn']} ${
								disableMassMail && styles['export-montly']
							}`}
						>
							<RiMailSendLine style={{ fontSize: '18px' }} />
							<span>Send Mass Mail</span>
						</button>
					)}
					{rightSideChildren && rightSideChildren}
				</div>
				<div className={styles['right-section']}>
					{lastReport && (
						<p className={styles['last-report']}>
							{lastReportText ? lastReportText : 'Last Report on'}:{' '}
							{lastReport
								? moment(lastReport).format('MMM-DD-YYYY h:mm A')
								: '--'}
						</p>
					)}
					{children}
					{updatePage && (
						<ToolTip
							title="Refresh the table to reflect most recent data."
							placement="bottomRight"
						>
							<div
								className={`${styles['update-page']} ${
									disableUpdate && styles['disable-update-page']
								}`}
								onClick={onUpdate}
							>
								<RedoOutlined className={styles['update-icon']} />
								<p className={styles['update']}>{updateText}</p>
							</div>
						</ToolTip>
					)}
				</div>
			</div>
		</div>
	);
};

export default InnerHeader;
