import { useMemo, useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { LineChartOutlined } from '@ant-design/icons';

import BreadCrumb from '../../../components/breadcrumb';
import InnerHeader from '../../../components/innerHeader';
import NotificationBar from '../../../components/notificationBar';
import Select from '../../../components/select';
import TableDataCountInput from '../../../components/tableDataCountInput';
import Cascader from '../../../components/cascader';
import useFetch from '../../../hooks';
import EdenContext from '../../../context/edenContext';
import { COUNTRY_CODES } from '../../../columns';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import NoData from '../../../components/nodata';
import TabelToolTipNotification from '../../../components/tableTooltipNotification';
import { useQuery } from '../../../hooks/use-query';
import { capitalizeFirstLetter, formatNumberWithCommas } from '../../../utils';
import {
	dateFilterParams,
	dollarDecimalFilterParams,
	numberFilterParams,
} from '../../../filters';
import moment from 'moment';
import { CountryCellRenderer } from '../../../columns/countryCellRenderer';

import styles from './mpnReport.module.scss';

const MPNReport = () => {
	const navigate = useNavigate();
	const { query } = useQuery();
	const customer = query.get('customer');
	const salesRep = query.get('salesrep');
	const {
		userType,
		me,
		userInfo,
		cascaderDesignationMpn,
		setCascaderDesignationMpn,
		cascaderOptionMpn,
		setCascaderOptionMpn,
	} = useContext(EdenContext);

	const [drillOption, setDrillOption] = useState('Billing');
	const [fy, setFy] = useState('2025');

	const [gridApi, setGridApi] = useState();

	const [columnDefs] = useState([
		{
			field: 'SalesRep',
			headerName: 'Sales Rep',
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset'],
				applyMiniFilterWhileTyping: true,
			},
			cellRenderer: (p) => p.value && capitalizeFirstLetter(p.value),
			minWidth: 110,
			headerTooltip: 'Name of sales rep responsible for the order',
		},
		{
			field: 'Date',
			headerName: 'Date',
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			cellRenderer: (p) =>
				p.value &&
				moment.utc(p.value).tz('Asia/Singapore').format('MMM-DD-YYYY'),
			minWidth: 170,
			headerTooltip: 'Date on which SO was created',
		},
		{
			field: 'SO',
			headerName: 'SO',
			cellRenderer: (p) => p.value && p.value,
			minWidth: 100,
			headerTooltip: 'Sales Order #',
		},
		{
			field: 'Supplier',
			headerName: 'Supplier',
			cellRenderer: (p) => p.value && p.value,
			minWidth: 110,
			headerTooltip: 'Supplier from who the part was purchased',
		},
		{
			field: 'Division',
			headerName: 'Division',
			cellRenderer: (p) => p.value && p.value,
			minWidth: 110,
			headerTooltip: 'Division which the customer belongs to',
		},
		{ field: 'ParentAccount', headerName: 'Parent Account', minWidth: 120 },
		{
			field: 'Customer',
			headerName: 'Customer',
			cellRenderer: (p) => p.value && p.value,
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset'],
				applyMiniFilterWhileTyping: true,
			},
			minWidth: 120,
			headerTooltip: 'Name of the customer account from the CRM',
		},
		{
			field: 'Country',
			headerName: 'Country',
			cellRenderer: CountryCellRenderer,
			filter: 'agSetColumnFilter',
			filterParams: {
				cellRenderer: CountryCellRenderer,
				cellRendererParams: { isFilterRenderer: true },
			},
			minWidth: 110,
			headerTooltip: 'Location of the customer account from the CRM',
		},
		{
			field: 'MPN',
			headerName: 'MPN',
			cellRenderer: (p) => p.value && p.value,
			minWidth: 90,
			headerTooltip: 'MPN name',
			cellClass: 'String',
		},
		{
			field: 'Brand',
			headerName: 'Brand',
			cellRenderer: (p) => p.value && p.value,
			minWidth: 90,
			headerTooltip: 'MFR/Brand that the MPN belongs to',
		},
		{
			field: 'SOQty',
			cellClass: 'number',
			headerName: 'SO Qty',
			cellRenderer: (p) => p.value && p.value?.toLocaleString('en-US'),
			minWidth: 110,
			headerTooltip: 'Quantity stated in SO ',
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
		},
		{
			field: 'SIQty',
			cellClass: 'number',
			headerName: 'SI Qty',
			cellRenderer: (p) => p.value && p.value?.toLocaleString('en-US'),
			minWidth: 110,
			headerTooltip: 'Quantity invoiced (from the SI)',
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
		},
		{
			field: 'UnitCost',
			cellClass: 'numberType',
			headerName: 'Unit Cost',
			cellRenderer: (p) => p.value && `$ ${formatNumberWithCommas(p.value)}`,
			minWidth: 110,
			headerTooltip: 'Unit cost of the line item (in USD)',
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
		},
		{
			field: 'CostSubtotal',
			cellClass: 'numberType',
			headerName: 'Cost Subtotal',
			cellRenderer: (p) => p.value && `$ ${formatNumberWithCommas(p.value)}`,
			minWidth: 135,
			headerTooltip: 'Gross cost value for the line (in USD)',
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
		},
		{
			field: 'MarkupPTS',
			cellClass: 'decimal',
			headerName: 'Markup PTS',
			cellRenderer: (p) => p.value && p.value?.toFixed(4),
			minWidth: 110,
			headerTooltip: 'Markup (in points) added to the cost',
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
		},
		{
			field: 'UnitSale',
			cellClass: 'numberType',
			headerName: 'Unit Sale',
			cellRenderer: (p) => p.value && `$ ${formatNumberWithCommas(p.value)}`,
			minWidth: 110,
			headerTooltip: 'Unit sale price of the line item (in USD)',
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
		},
		{
			field: 'SalesSubtotal',
			cellClass: 'numberType',
			headerName: 'Sales Subtotal',
			cellRenderer: (p) => p.value && `$ ${formatNumberWithCommas(p.value)}`,
			minWidth: 130,
			headerTooltip: 'Gross sale value for the line (in USD)',
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
		},
	]);

	// go to customer report tooltip
	const GoToCustomerReport = () => {
		return (
			<p className={styles['customer-report-message']}>
				To access your overall sales performance, please visit My Sales
				Performance &gt;{' '}
				<span
					className={styles['link']}
					onClick={() => navigate('/customer-report')}
				>
					Customer Report
				</span>
				.
			</p>
		);
	};

	// for Billing Booking and Backorder
	const handleChange = (value) => {
		setDrillOption(value);
	};

	// for handling FY
	const handleFy = (value) => {
		setFy(value);
	};

	// API call
	const { isLoading, serverError, apiData } = useFetch(
		`https://internal.supremecomponents.com/api/externalgateway.php?route=getMPNDataTable&type=${drillOption}&viewas=${
			cascaderDesignationMpn ? cascaderDesignationMpn : userType
		}&user=${cascaderOptionMpn ? cascaderOptionMpn : me?.mail}&fy=${fy}`,
		'mpn-report-table-data'
	);

	const dataWithFlags = apiData?.Data?.filter((d) => {
		return COUNTRY_CODES[d?.Country] === undefined
			? d
			: COUNTRY_CODES[d?.Country];
	});

	// On grid ready footer total
	const onGridReady = (p) => {
		setGridApi(p.api);

		const calculateFooterValue = () => {
			let soQtyTotal = 0;
			let siQtyTotal = 0;
			let unitCostTotal = 0;
			let costSubTotal = 0;
			let unitSaleTotal = 0;
			let salesSubTotal = 0;
			let markupPtsTotal = 0;
			let markupPtsCount = 0;

			p.api.forEachNodeAfterFilter(function (node) {
				soQtyTotal += node?.data?.SOQty;
				siQtyTotal += node?.data?.SIQty;
				unitCostTotal += node?.data?.UnitCost;
				costSubTotal += node?.data?.CostSubtotal;
				unitSaleTotal += node?.data?.UnitSale;
				salesSubTotal += node?.data?.SalesSubtotal;
				const markupPts = node?.data?.MarkupPTS;
				if (markupPts > 0) {
					markupPtsTotal += markupPts;
					markupPtsCount++;
				}
			});

			//average for MarkupPts column
			const averageMarkUpPts =
				markupPtsCount > 0 ? markupPtsTotal / markupPtsCount : 0;

			const footerData = [
				{
					SalesRep: 'Total:',
					Date: '',
					DateString: '',
					SO: '',
					Supplier: '',
					Division: '',
					Customer: '',
					Country: '',
					MPN: '',
					Brand: '',
					SOQty: soQtyTotal,
					SIQty: siQtyTotal,
					UnitCost: unitCostTotal,
					CostSubtotal: costSubTotal,
					MarkupPTS: averageMarkUpPts,
					UnitSale: unitSaleTotal,
					SalesSubtotal: salesSubTotal,
				},
			];
			p.api.setPinnedBottomRowData(footerData);
		};
		calculateFooterValue();

		p.api.addEventListener('filterChanged', calculateFooterValue);
	};

	// export excel sheet style
	const excelStyles = useMemo(() => {
		return [
			{
				id: 'numberType',
				numberFormat: {
					format: '$ #,##0.000000',
				},
				alignment: {
					horizontal: 'Distributed',
				},
			},
			{ id: 'String', dataType: 'String' },
			{
				id: 'number',
				numberFormat: {
					format: '#,##0',
				},
				alignment: {
					horizontal: 'Distributed',
				},
			},
			// {
			// 	id: 'decimal',
			// 	numberFormat: {
			// 		format: '0.0000',
			// 	},
			// 	alignment: {
			// 		horizontal: 'Distributed',
			// 	},
			// },
			{
				id: 'header',
				font: {
					bold: true,
				},
			},
		];
	}, []);

	const getRowStyle = useCallback((params) => {
		if (params.node.rowPinned) {
			return { fontWeight: 600, fontSize: '14px' };
		}
	}, []);

	// Quick find
	const quickFind = (e) => {
		gridApi.setQuickFilter(e.target.value);
	};

	// export to excel
	const exportToExcel = () => {
		const mpnReportExcelParams = {
			sheetName: 'MPN Report',
			fileName: 'MPN Report.xlsx',
		};
		gridApi.exportDataAsExcel(mpnReportExcelParams);
	};

	// Default column options
	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			floatingFilter: true,
			suppressMovable: true,
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	const autoGroupColumnDef = useMemo(() => {
		return {
			minWidth: 200,
			filter: 'agGroupColumnFilter',
		};
	}, []);

	// Billing Booking and Backorder options
	const options = [
		{
			value: 'Billing',
			label: 'Billing',
		},
		{
			value: 'Booking',
			label: 'Booking',
		},
		{
			value: 'Backorder',
			label: 'Backorder',
		},
	];

	// fy options
	const fyOptions = [
		{
			value: '2025',
			label: 'FY25',
		},
		{
			value: '2024',
			label: 'FY24',
		},
		{
			value: '2023',
			label: 'FY23',
		},
		{
			value: '2022',
			label: 'FY22',
		},
		{
			value: '2021',
			label: 'FY21',
		},
		{
			value: '2020',
			label: 'FY20',
		},
		{
			value: '2019',
			label: 'FY19',
		},
	];

	// Cascader options
	const filterAndMapSalesReps = (salesReps) => {
		return salesReps
			.filter((rep) => rep?.isActive)
			.map((rep) => ({
				value: rep?.SalesRepEmail,
				label: rep?.SalesRepName,
				designation: rep?.Designation,
				disabled: rep?.isActive === false,
				children: rep?.SalesTeam ? filterAndMapSalesReps(rep.SalesTeam) : [],
			}));
	};

	let cascaderOptions;
	if (userType === 'admin' || userType === 'ceo') {
		cascaderOptions = filterAndMapSalesReps(userInfo?.ViewAsAdmin || []);
	}

	if (userType === 'sm') {
		cascaderOptions = filterAndMapSalesReps(userInfo?.ViewAsSM || []);
	}

	// On cascader change
	const onChange = (value, selectedOptions) => {
		let designation =
			selectedOptions[selectedOptions?.length - 1].designation.toLowerCase();
		const salesRepname =
			selectedOptions[selectedOptions?.length - 1].label.toLowerCase();
		const containSelf = salesRepname?.includes('self');

		if (
			selectedOptions[selectedOptions?.length - 1].value ===
			'piyush@supremecomponents.com'
		) {
			designation = 'sm';
		}

		if (containSelf === true) {
			designation = 'sr';
		}

		if (designation === 'sales rep') {
			designation = 'sr';
		}
		if (designation === 'sales manager') {
			designation = 'sm';
		}

		let mail = value[value?.length - 1];
		setCascaderDesignationMpn(designation);
		setCascaderOptionMpn(mail);
	};

	// On cascader clear
	const clearCascader = () => {
		setCascaderDesignationMpn(userType);
		setCascaderOptionMpn(me?.mail);
	};

	const context = useMemo(() => {
		return {
			COUNTRY_CODES: COUNTRY_CODES,
		};
	}, []);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	useEffect(() => {
		if (customer && gridApi) {
			const getCustomerColumn = gridApi?.getFilterInstance('Customer');

			getCustomerColumn?.setModel({ values: [customer] });
			gridApi?.onFilterChanged();
		}
		if (salesRep && gridApi) {
			const getSalesRepColumn = gridApi?.getFilterInstance('SalesRep');

			getSalesRepColumn?.setModel({ values: [salesRep] });
			gridApi?.onFilterChanged();
		}

		//eslint-disable-next-line
	}, [gridApi]);

	return (
		<div className={styles['mpnReport-wrapper']}>
			<BreadCrumb
				route1="Sales Performance"
				route2="MPN Report"
				icon={<LineChartOutlined style={{ paddingRight: '5px' }} />}
			/>
			<InnerHeader
				exportToExcel={exportToExcel}
				lastReport={apiData?.LastUpdated}
				// exportMontlyPerf
			/>
			<div className={styles['mpn-report']}>
				<NotificationBar wraningBar message={<GoToCustomerReport />} />
				<div className={styles['dropdown-wrapper']}>
					<Select
						multiple
						options={fyOptions}
						style={{
							width: 380,
						}}
						placeholder="Drill down by"
						onChange={handleFy}
						defaultValue={fy}
						showSearch={false}
						disabled={isLoading}
						title="Drill down by:"
					/>
					<Select
						options={options}
						style={{
							width: 110,
						}}
						placeholder="Drill down by"
						onChange={handleChange}
						defaultValue="Billing"
						disabled={isLoading}
						title="Drill down by:"
					/>
					{userType !== 'sr' && (
						<Cascader
							placeholder="SR, SM"
							onChange={onChange}
							options={cascaderOptions}
							onClear={clearCascader}
							disabled={isLoading}
							changeOnSelect={true}
							title="Drill down by:"
						/>
					)}
				</div>
				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="That’s a huge dataset we’ve got here. Give us a moment as we retrieve everything and organize them for you." />
					</div>
				)}
				{serverError && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{apiData?.Success === false && !isLoading && !serverError && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Oops! Seems like you have not made any transactions for the selected period. Try toggling the FY data if you have some orders in other years." />
					</div>
				)}

				{!isLoading && !serverError && apiData?.Success === true && (
					<>
						<TableDataCountInput
							transactions={`Showing ${
								apiData?.Data?.length?.toLocaleString('en-US') || 0
							} transactions`}
							onChange={quickFind}
							allowClear
						/>
						<div className={`ag-theme-custom ${styles['table-wrapper']}`}>
							<>
								{!localStorage.getItem('mpn-report-table-data') ? (
									<div className={styles['table-tooltip-notification']}>
										<TabelToolTipNotification title="Hover and hold your cursor over any column header to see a description" />
									</div>
								) : (
									''
								)}
								<AgGridReact
									onGridReady={onGridReady}
									rowData={dataWithFlags}
									columnDefs={columnDefs}
									context={context}
									defaultColDef={defaultColDef}
									autoGroupColumnDef={autoGroupColumnDef}
									groupDisplayType={'multipleColumns'}
									animateRows={true}
									rowGroupPanelShow="always"
									pagination={true}
									floatingFilter={true}
									tooltipShowDelay={1500}
									enableRangeSelection={true}
									excelStyles={excelStyles}
									paginationPageSizeSelector={false}
									getRowStyle={getRowStyle}
								/>
							</>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default MPNReport;
