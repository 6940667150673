import React from 'react';
import { Breadcrumb } from 'antd';

import styles from './breadcrumb.module.scss';

const BreadCrumb = ({ route1, route2, icon }) => {
	return (
		<div className={styles['breadcrumb-wrapper']}>
			<Breadcrumb
				separator=">"
				items={[
					{
						title: (
							<p className={styles['breadcrumb-item']}>
								{icon} {route1}
							</p>
						),
					},
					{
						title: <p className={styles['breadcrumb-item-2']}>{route2}</p>,
					},
				]}
			/>
		</div>
	);
};

export default BreadCrumb;
