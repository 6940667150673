import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { IoMdAddCircle } from 'react-icons/io';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';

import BreadCrumb from '../../../components/breadcrumb';
import SectionInfo from '../../../components/sectionInfo';
import Button from '../../../components/button';
import EdenContext from '../../../context/edenContext';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';
import Popconfirm from '../../../components/popConfirm';
import ToolTip from '../../../components/tooltip';
import InnerHeader from '../../../components/innerHeader';
import LinkedCleanserContext from '../../../context/linkedCleanserContext';

import styles from './linkedInCleanser.module.scss';

const MpnQueueAction = (p) => {
	const { userMail } = useContext(EdenContext);
	const { setData, data, filterUniqueIdsAndRecent } = useContext(
		LinkedCleanserContext
	);
	const [deletePopup, setDeletePopup] = useState(false);

	const closeDeletePopup = () => {
		setDeletePopup(false);
	};

	const openDeletePopUp = () => {
		setDeletePopup(true);
	};

	const deleteTitle = () => {
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=delete_li_cleanser&email=${userMail}&id=${p.data?.id}`
			)
			.then(() => {
				setDeletePopup(false);
				setData(
					filterUniqueIdsAndRecent(
						data?.filter((item) => item.id !== p?.data?.id)
					)
				);
				message.success('Job Title deleted succesfully');
			});
	};

	return (
		<div>
			<Popconfirm
				placement="bottomLeft"
				okButtonColor="red"
				open={deletePopup}
				title="Delete"
				description="You cannot undo this action. Do you confirm the deletion of the selected row?"
				okText="Yes"
				cancelText="Cancel"
				onConfirm={deleteTitle}
				onCancel={closeDeletePopup}
				icon={
					<QuestionCircleOutlined
						style={{
							color: 'red',
						}}
					/>
				}
			>
				<ToolTip title="Delete">
					<RiDeleteBin6Fill
						style={{
							fontSize: '18px',
							cursor: 'pointer',
							color: '#C00000',
							display: 'flex',
							alignItems: 'center',
						}}
						onClick={openDeletePopUp}
					/>
				</ToolTip>
			</Popconfirm>
		</div>
	);
};

const LinkedInCleanser = () => {
	const { data, setData, isLoading, error, getData, onCellValueChanged } =
		useContext(LinkedCleanserContext);

	const [columnDefs] = useState([
		{ field: 'currentTime', hide: true, sort: 'desc' },
		{ field: 'id', headerName: 'ID', sort: 'desc' },
		{ field: 'jobtitle', headerName: 'Job Title', editable: true },
		{
			field: 'action',
			headerName: 'Action',
			floatingFilter: false,
			cellRenderer: memo(MpnQueueAction),
			maxWidth: 140,
			cellStyle: { padding: '4px 0 0 15px' },
		},
	]);

	useEffect(() => {
		getData(); //eslint-disable-next-line
	}, []);

	const addJobTitle = () => {
		const newRow = { currentTime: Date.now() };
		setData([newRow, ...data]);
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="LinkedIn Cleanser"
				icon={<BsLinkedin style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo removeshortcutkeys title="LinkedIn Cleanser" info="" />
			<InnerHeader updatePage onUpdate={getData} updateText="Refresh" />
			<div className={styles['linkedin-cleanser']}>
				<Button onClick={addJobTitle}>
					<IoMdAddCircle style={{ fontSize: '16px' }} />
					Add Job Title
				</Button>

				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}

				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(data) && !error && !isLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here." />
					</div>
				)}

				{!isLoading && !error && isNotEmptyArray(data) && (
					<div className={styles['table-wrapper']}>
						<div className={`ag-theme-custom ${styles['table']} `}>
							<AgGridReact
								rowData={data}
								pagination={true}
								animateRows={true}
								columnDefs={columnDefs}
								enableRangeSelection={true}
								defaultColDef={defaultColDef}
								paginationPageSizeSelector={false}
								onCellValueChanged={onCellValueChanged}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default LinkedInCleanser;
