import { Divider, Form, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { IoCloseCircleSharp } from 'react-icons/io5';
import axios from 'axios';

import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import Select from '../../../../components/select';
import EdenContext from '../../../../context/edenContext';
import AddMail from './Addmail';
import UploadCopyPasteParts from './UploadCopyPasteParts';
import Steps from '../../../../components/steps';
import { getPrimaryBrand } from '../../../../utils';

import styles from './bulkUploadPaste.module.scss';

const BulkUploadPasteModal = () => {
	const {
		ssData,
		srData,
		crmAccounts,
		userMail,
		userType,
		sourcingMpnQueueCostBook,
		costBookType,
		setCostBookType,
		setMpnCostBookList,
	} = useContext(EdenContext);

	const [form] = Form.useForm();

	const isMPNForCopyPaste = Form.useWatch('MPN Req', form);

	const [openModal, setOpenModal] = useState(false);
	const [customer, setCustomer] = useState(null);
	const [salesRep, setSalesRep] = useState(null);
	const [ssValue, setSSValue] = useState(null);
	const [isBuyBid, setIsBuyBid] = useState(null);
	const [current, setCurrent] = useState(0);
	const [customerProspectData, setCustomerProspectData] = useState([]);
	const [rowSelectionData, setRowSelectionData] = useState([]);
	const [excelFileName, setExcelFileName] = useState(null);
	const [excelData, setExcelData] = useState([]);
	const [selectedSheet, setSelectedSheet] = useState(null);
	const [sheetNames, setSheetNames] = useState(null);
	const [workbook, setWorkbook] = useState(null);
	const [bulkUploadData, setBulkUploadData] = useState(null);
	const [uploadingBulk, setUploadingBulk] = useState(false);
	const [isBomOrCopyPaste, setIsBomOrCopyPaste] = useState('upload-bom');
	const [copyPasteData, setCopyPasteData] = useState(null);

	useEffect(() => {
		const data = {
			customer,
			salesRep,
			ssValue,
			isBuyBid,
			rowSelection: rowSelectionData[0],
			excelData,
			copyPasteData,
		};
		setBulkUploadData(data);
	}, [
		customer,
		salesRep,
		ssValue,
		isBuyBid,
		rowSelectionData,
		excelData,
		copyPasteData,
	]);

	const onChange = (value) => {
		if (rowSelectionData?.length > 0) {
			setCurrent(value);
		} else {
			message.error('Please select your email');
		}
	};

	const steps = [
		{
			title: 'Add Your Email',
			content: (
				<AddMail
					setRowSelectionData={setRowSelectionData}
					customerProspectData={customerProspectData}
					setCustomerProspectData={setCustomerProspectData}
					rowSelectionData={rowSelectionData}
					setCurrent={setCurrent}
				/>
			),
		},
		{
			title: 'Upload/Copy Paste Parts',
			content: (
				<UploadCopyPasteParts
					excelData={excelData}
					setExcelData={setExcelData}
					excelFileName={excelFileName}
					setExcelFileName={setExcelFileName}
					selectedSheet={selectedSheet}
					setSelectedSheet={setSelectedSheet}
					sheetNames={sheetNames}
					setSheetNames={setSheetNames}
					workbook={workbook}
					setWorkbook={setWorkbook}
					copyPasteForm={form}
					isBomOrCopyPaste={isBomOrCopyPaste}
					setIsBomOrCopyPaste={setIsBomOrCopyPaste}
					copyPasteData={copyPasteData}
					setCopyPasteData={setCopyPasteData}
				/>
			),
		},
	];

	const closeModal = () => {
		setOpenModal(false);
		setBulkUploadData(null);
		setSalesRep(null);
		setSSValue(null);
		setCustomer(null);
		setRowSelectionData([]);
		setExcelFileName(null);
		setSheetNames(null);
		setSelectedSheet(null);
		setExcelData([]);
		setRowSelectionData([]);
		setCustomerProspectData([]);
		setCurrent(0);
		form.resetFields();
		setIsBuyBid(null);
	};

	const onSelectCustomer = (value, data) => {
		setCustomer(data);
	};

	const filterCRMAccount = crmAccounts?.filter((el) => el.INACTIVE !== 'Yes');

	const onSelectSalesRep = (value, option) => {
		setSalesRep(option);
	};

	const onSelectSS = (value, option) => {
		setSSValue(option);
	};

	const onSelectBuyBid = (value) => {
		setIsBuyBid(value);
	};

	const ssDetails = [
		...ssData,
		{
			salesRepCrmId: 1,
			salesRepEmail: 'Self',
			salesRepName: 'Self',
		},
		{
			salesRepCrmId: 0,
			salesRepEmail: 'Blank',
			salesRepName: 'Blank',
		},
	];

	const processBulkUpload = () => {
		const fieldValuesBulkUpload = bulkUploadData?.excelData?.map((el) => ({
			isBuy: isBuyBid,
			userEmail: userMail,
			queueId: '',
			srId: salesRep?.id,
			sr: salesRep?.label,
			ssId: ssValue?.id,
			ss: ssValue?.label,
			cdMail: bulkUploadData?.rowSelection?.webLink,
			cdCustomerSubject: bulkUploadData?.rowSelection?.subject,
			cdInquiryDateTime: bulkUploadData?.rowSelection?.receivedDateTime,
			cdCustomerId: customer?.value,
			cdCustomerName: customer?.label,
			cdContactEmail: bulkUploadData?.rowSelection?.from,
			cdContactName: bulkUploadData?.rowSelection?.contactName || '',
			reqCPN: el?.['CPN']?.toString() || '',
			reqMPN: el?.['MPN Req']?.toString() || '',
			reqBrand: getPrimaryBrand(el?.['Brand Req'])
				? getPrimaryBrand(el?.['Brand Req'])
				: el?.['Brand Req']?.toString() || '',
			reqQtyReq: el?.['QTY Req'] || 0,
			reqExtPrice: 0,
			foundMPN: el?.['MPN Found'] || '',
			foundBrand: el?.['Brand Found'] || '',
			foundMOQ: el?.['MOQ'] || 0,
			foundSPQ: el?.['SPQ'] || 0,
			foundUsdCost: el?.['USD Cost'] || 0,
			foundExtPrice: el?.['Ext Price'] || 0,
			foundDC: el?.['DC'] || '',
			foundPackaging: el?.['Packaging'] || '',
			foundLT: el?.['LT (w/ units)'] || '',
			sdSource: el?.['CF/MM/Online'] || '',
			sdMail: '',
			sdSubject: '',
			sdSourceDate: null,
			sdSupplier: '',
			sdSupplierName: '',
			sdSupplierEmail: '',
			othDaysOpen: 0,
			othRemarks: '',
			othOrderReceived: false,
			othSrRead: userType === 'sr' || userType === 'sm' ? true : false,
			smartId: '',
			parentId: 0,
			bomLineNo: 0,
		}));

		const fieldValuesCopyPaste = copyPasteData?.map((el) => ({
			isBuy: isBuyBid,
			userEmail: userMail,
			queueId: '',
			srId: salesRep?.id,
			sr: salesRep?.label,
			ssId: ssValue?.id,
			ss: ssValue?.label,
			cdMail: bulkUploadData?.rowSelection?.webLink,
			cdCustomerSubject: bulkUploadData?.rowSelection?.subject,
			cdInquiryDateTime: bulkUploadData?.rowSelection?.receivedDateTime,
			cdCustomerId: customer?.value,
			cdCustomerName: customer?.label,
			cdContactEmail: bulkUploadData?.rowSelection?.from,
			cdContactName: bulkUploadData?.rowSelection?.contactName || '',
			reqCPN: el?.['CPN']?.toString() || '',
			reqMPN: el?.['MPN Req'] || '',
			reqBrand: getPrimaryBrand(el?.['Brand Req'])
				? getPrimaryBrand(el?.['Brand Req'])
				: el?.['Brand Req']?.toString() || '',
			reqQtyReq: parseInt(el?.['QTY Req']?.replace(/,/g, '')) || 0,
			reqExtPrice: 0,
			foundMPN: el?.['MPN Found'] || '',
			foundBrand: el?.['Brand Found'] || '',
			foundMOQ: parseFloat(el?.['MOQ']) || 0,
			foundSPQ: parseFloat(el?.['SPQ']) || 0,
			foundUsdCost: parseFloat(el?.['USD Cost']) || 0,
			foundExtPrice: parseFloat(el?.['Ext Price']) || 0,
			foundDC: el?.['DC'] || '',
			foundPackaging: el?.['Packaging'] || '',
			foundLT: el?.['LT (w/ units)'] || '',
			sdSource: el?.['CF/MM/Online'] || '',
			sdMail: '',
			sdSubject: '',
			sdSourceDate: null,
			sdSupplier: '',
			sdSupplierName: '',
			sdSupplierEmail: '',
			othDaysOpen: 0,
			othRemarks: '',
			othOrderReceived: false,
			othSrRead: userType === 'sr' || userType === 'sm' ? true : false,
			smartId: '',
			parentId: 0,
			bomLineNo: 0,
		}));

		if (isBomOrCopyPaste === 'copy-paste') {
			setUploadingBulk(true);
			axios
				.post(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=bulk_add_costbook`,
					JSON.stringify(fieldValuesCopyPaste)
				)
				.then(() => {
					setCostBookType(isBuyBid);
					setMpnCostBookList([]);
					message.open({
						type: 'success',
						content: 'MPN Uploaded Successfully',
					});
					if (costBookType === isBuyBid) {
						sourcingMpnQueueCostBook();
					}
				})
				.catch((err) => {
					if (axios.isCancel(err)) {
						message.open({
							type: 'error',
							content: 'Bulk Upload Canceled!',
						});
					} else {
						message.open({
							type: 'error',
							content: 'Something went wrong. Please try again later!',
						});
					}
				})
				.finally(() => {
					setBulkUploadData(null);
					closeModal();
					setUploadingBulk(false);
				});
		}

		if (isBomOrCopyPaste === 'upload-bom') {
			setUploadingBulk(true);
			axios
				.post(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=bulk_add_costbook`,
					JSON.stringify(fieldValuesBulkUpload)
				)
				.then(() => {
					setCostBookType(isBuyBid);
					setMpnCostBookList([]);
					message.open({
						type: 'success',
						content: 'MPN Uploaded Successfully',
					});
					if (costBookType === isBuyBid) {
						sourcingMpnQueueCostBook();
					}
				})
				.catch((err) => {
					if (axios.isCancel(err)) {
						message.open({
							type: 'error',
							content: 'Bulk Upload Canceled!',
						});
					} else {
						message.open({
							type: 'error',
							content: 'Something went wrong. Please try again later!',
						});
					}
				})
				.finally(() => {
					setBulkUploadData(null);
					closeModal();
					setUploadingBulk(false);
				});
		}
	};

	return (
		<div className={styles['wrapper']}>
			<Button onClick={() => setOpenModal(true)}>Upload Customer Parts</Button>
			{openModal && (
				<Modal
					open={openModal}
					closable={false}
					width={1300}
					title={
						<>
							<div className={styles['title-wrapper']}>
								<span className={styles['title']}>
									Upload or Copy BOM/RFQs​
								</span>
								<IoCloseCircleSharp
									className={styles['close-icons']}
									onClick={closeModal}
								/>
							</div>

							<Divider
								style={{
									margin: '10px 0',
									borderBlockStart: '1px solid rgb(216, 216, 216)',
								}}
							/>
						</>
					}
				>
					<div className={styles['body-wrapper']}>
						<div className={styles['inner-title-wrapper']}>
							<p>
								Import or copy/paste your BOM/RFQs and map the respective fields
								needed to add to the Cost Book. 
							</p>
						</div>

						<div className={styles['dropdown-wrapper']}>
							<div style={{ display: 'flex', gap: '20px' }}>
								<div className={styles['form-item']}>
									<h2 className={styles['title']}>Select Sales Rep</h2>
									<Select
										style={{ width: '100%' }}
										showSearch
										placeholder="Select Sales Rep"
										type="bordered"
										options={srData?.map((el) => ({
											value: el?.salesRepName,
											label: el?.salesRepName,
											id: el?.salesRepCrmId,
											email: el?.salesRepEmail,
										}))}
										onChange={onSelectSalesRep}
									/>
								</div>

								<div className={styles['form-item']}>
									<h2 className={styles['title']}>Select SS</h2>
									<Select
										style={{ width: '100%' }}
										showSearch
										placeholder="Select Sourcing Specialist"
										type="bordered"
										options={ssDetails?.map((el) => ({
											value: el?.salesRepEmail,
											label: el?.salesRepName,
											id: el?.salesRepCrmId,
										}))}
										onChange={onSelectSS}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', gap: '20px' }}>
								<div className={styles['form-item']} style={{ width: '50%' }}>
									<h2 className={styles['title']}>Select Buy/Bid</h2>
									<Select
										showSearch
										type="bordered"
										placeholder="Select Buy/Bid"
										defaultValue={isBuyBid}
										style={{ width: '100%' }}
										options={[
											{
												value: true,
												label: 'Buy',
											},
											{
												value: false,
												label: 'Bid',
											},
										]}
										onChange={onSelectBuyBid}
										allowClear
									/>
								</div>
								<div className={styles['form-item']} style={{ width: '50%' }}>
									<h2 className={styles['title']}>Select Customer</h2>
									<Select
										showSearch
										type="bordered"
										placeholder="Select CRM Account"
										style={{ width: '100%' }}
										options={filterCRMAccount?.map((el) => ({
											value: el?.ID,
											label: el?.ACCOUNT_NAME,
										}))}
										onChange={onSelectCustomer}
										optionFilterProp={'label'}
										allowClear
									/>
								</div>
							</div>
						</div>

						<div className={styles['tabs-wrapper']}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Steps
									size="small"
									current={current}
									onChange={onChange}
									type="navigation"
									style={{ width: '600px' }}
									items={steps.map((item) => ({
										key: item.title,
										title: item.title,
									}))}
								/>
								<div
									style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
								>
									{(copyPasteData?.length > 0 || bulkUploadData?.length > 0) &&
										uploadingBulk && (
											<p className={styles['please-wait']}>
												<b>Please wait.</b> Your data is being processed...
											</p>
										)}
									{isBomOrCopyPaste === 'upload-bom'
										? bulkUploadData?.customer?.label &&
										  bulkUploadData?.salesRep?.label &&
										  bulkUploadData?.ssValue?.label &&
										  (bulkUploadData?.isBuyBid === true ||
												bulkUploadData?.isBuyBid === false) &&
										  bulkUploadData?.rowSelection &&
										  bulkUploadData?.excelData?.length > 0 && (
												<Button
													onClick={processBulkUpload}
													loading={uploadingBulk}
												>
													Upload Now
												</Button>
										  )
										: isBomOrCopyPaste === 'copy-paste'
										? bulkUploadData?.customer?.label &&
										  bulkUploadData?.salesRep?.label &&
										  bulkUploadData?.ssValue?.label &&
										  (bulkUploadData?.isBuyBid === true ||
												bulkUploadData?.isBuyBid === false) &&
										  bulkUploadData?.rowSelection &&
										  isMPNForCopyPaste && (
												<Button
													id="bulk-upload"
													htmlType="submit"
													onClick={processBulkUpload}
													loading={uploadingBulk}
												>
													Upload Now
												</Button>
										  )
										: null}
								</div>
							</div>
							<div className={styles['steps-content']}>
								{steps[current].content}
							</div>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default BulkUploadPasteModal;
