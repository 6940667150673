import NodataImage from '../../images/nodata.svg';
import searchImage from '../../images/search.svg';

import styles from './nodata.module.scss';

const NoData = ({ message, style, messageStyle, search }) => {
	return (
		<div className={styles['wrapper']}>
			<img
				className={styles['img']}
				src={search ? searchImage : NodataImage}
				alt="No data"
				style={style}
			/>
			{message && (
				<p className={styles['message']} style={messageStyle}>
					{message}
				</p>
			)}
		</div>
	);
};

export default NoData;
