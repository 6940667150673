import { Oval } from 'react-loader-spinner';

import styles from './loaderMessage.module.scss';

const LoadingMessage = ({ height, width, message, color, secondaryColor }) => {
	return (
		<div className={styles['wrapper']}>
			<Oval
				height={height ? height : 80}
				width={width ? width : 80}
				color={color ? color : '#000000'}
				wrapperStyle={{}}
				wrapperClass=""
				visible={true}
				ariaLabel="oval-loading"
				secondaryColor={secondaryColor ? secondaryColor : '#9f9f9f'}
				strokeWidth={2}
				strokeWidthSecondary={2}
			/>
			{message && <p className={styles['message']}>{message}</p>}
		</div>
	);
};

export default LoadingMessage;
