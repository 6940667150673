import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import EdenContext from '../context/edenContext';

const useFetch = (url, key) => {
	const [isLoading, setIsloading] = useState(false);
	const [apiData, setApiData] = useState(null);
	const [serverError, setServerError] = useState(null);
	const location = useLocation();

	const {
		setConverStatTabEE,
		setIncomeOutEmailTabEE,
		setConverStatTableEE,
		setIncomeOutEmailTableEE,
	} = useContext(EdenContext);

	useEffect(() => {
		const fetchData = () => {
			setIsloading(true);
			fetch(url)
				.then((response) => {
					if (!response.ok) {
						throw new Error(`${response.status}`);
					}
					return response.json();
				})
				.then((data) => {
					setApiData(data);
					setServerError(null);
					setTimeout(() => {
						key && localStorage.setItem(key, true);
					}, 2000);
					if (location.pathname.includes('email-engagement')) {
						setConverStatTabEE(true);
						setIncomeOutEmailTabEE(true);
						setConverStatTableEE(false);
						setIncomeOutEmailTableEE(false);
					}
				})
				.catch((err) => {
					setServerError(err);
					setApiData(null);
					setIsloading(false);
				})
				.finally(() => {
					setIsloading(false);
				});
		};

		fetchData(); // eslint-disable-next-line
	}, [url]);

	return { isLoading, apiData, serverError };
};

export default useFetch;
